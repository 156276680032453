/* tslint:disable */
import {
  Procurement
} from '../index';

declare var Object: any;
export interface ProcurementItemInterface {
  "description"?: string;
  "name": string;
  "size"?: string;
  "quantity": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "procurementId"?: number;
  procurement?: Procurement;
}

export class ProcurementItem implements ProcurementItemInterface {
  "description": string;
  "name": string;
  "size": string;
  "quantity": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "procurementId": number;
  procurement: Procurement;
  constructor(data?: ProcurementItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ProcurementItem`.
   */
  public static getModelName() {
    return "ProcurementItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ProcurementItem for dynamic purposes.
  **/
  public static factory(data: ProcurementItemInterface): ProcurementItem{
    return new ProcurementItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ProcurementItem',
      plural: 'ProcurementItems',
      path: 'ProcurementItems',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "size": {
          name: 'size',
          type: 'string'
        },
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "procurementId": {
          name: 'procurementId',
          type: 'number'
        },
      },
      relations: {
        procurement: {
          name: 'procurement',
          type: 'Procurement',
          model: 'Procurement',
          relationType: 'belongsTo',
                  keyFrom: 'procurement_id',
          keyTo: 'id'
        },
      }
    }
  }
}
