//  generic enums

export enum FormChangeType {
  ADD = 1,
  EDIT = 2
}

export enum Status {
  PENDING = 0,
  COMPLETED = 1
}

export enum SelectionEnum {
  CENTER = 1,
  BATCH = 2,
  STUDENT = 3,
  STAFF = 4
}
