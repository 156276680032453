import { Component, OnInit } from '@angular/core';

import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;
@Component({
  selector: 'app-multiple-file-dropzone',
  templateUrl: 'mutipleDropzone.component.html'
})
export class MultipleDropZoneComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    let currentMultipleFile;
    // multiple dropzone file - accepts any type of file
    new Dropzone(document.getElementById('dropzone-multiple'), {
      url: 'https://',
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName('dz-preview-multiple')[0],
      previewTemplate: document.getElementsByClassName('dz-preview-multiple')[0].innerHTML,
      maxFiles: null,
      acceptedFiles: null,
      init() {
        this.on('addedfile', (file) => {
          if (currentMultipleFile) {
          }
          currentMultipleFile = file;
        });
      }
    });
    document.getElementsByClassName('dz-preview-multiple')[0].innerHTML = '';
  }
}
