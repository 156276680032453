import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AppAuthLayoutComponent } from './layouts/app-auth-layout/app-auth-layout.component';
import { PresentationComponent } from './pages/presentation/presentation.component';
import { StudentDashboardComponent } from './layouts/student-dashboard/student-dashboard.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import { CoachLayoutComponent } from './layouts/coach-layout/coach-layout.component';
import { StaffLayoutComponent } from './layouts/staff-layout/staff-layout.component';
import { StudentGuard } from './guards/student.guard';
import { CoachGuard } from './guards/coach.guard';
import { CoachRoutes } from './coach/coach.routing';
import { StaffGuard } from './guards/staff.guard';
import { AdminGuard } from './guards/admin.guard';
import { SettingsComponent } from './settings/settings.component';
import { SuccessPaymentComponent } from './pages/successpayment/successpayment.component';
import { VideoSessionComponent } from './pages/video-session/video-session.component';
import { FeaturesComponent } from './pages/features/features.component';
import { HomePageComponent } from './pages/home-page/home-page.component'
import { TeamProgressComponent } from './pages/team-progress/team-progress.component';
import { PaymentComponent } from './pages/subscription-payment/components/payment/payment.component';
import { FailedPaymentComponent } from './pages/failedpayment/failedpayment.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home-page',
    pathMatch: 'full'
  },
  {
    path: 'presentation',
    loadChildren: './pages/presentation/presentation.module#PresentationModule',
    component: PresentationComponent
  },
  {
    path: 'home-page',
    loadChildren: './pages/home-page/home-page.module#homePageModule',
    component: HomePageComponent
  },
  {
    path: 'subscription-payment',
    loadChildren: './pages/subscription-payment/subscription-payment.module#SubscriptionPaymentModule',
    component: PaymentComponent
  },
  {
    path: 'features/:feature',
    loadChildren: 'src/app/pages/features/features.module#FeaturesModule',
    component: FeaturesComponent
  },
  {
    path: 'successpayment',
    // loadChildren: 'src/app/pages/successpayment/successpayment.module#SuccessPaymentModule',
    component: SuccessPaymentComponent
  },
  {
    path: 'failedpayment',
   // loadChildren: 'src/app/pages/failedpayment/failedpayment.module#FailedPaymentModule',
   component: FailedPaymentComponent
  },
  {
    path: 'contact/:contactUs',
    loadChildren: 'src/app/pages/contact/contact.module#ContactModule',
  },
  {
    path: 'bookademo',
    loadChildren: 'src/app/pages/bookademo/bookademo.module#BookADemoModule',
  },
  {
    path: 'free-trail',
    loadChildren: 'src/app/pages/free-trail/free-trail.module#FreeTrailModule',
  },
  {
    path: 'pricing',
    loadChildren: 'src/app/pages/pricing/pricing.module#PricingModule',
  },
  {
    path: 'about-us',
    loadChildren: 'src/app/pages/about-us/about-us.module#AboutUsModule',
  },
  {
    path: 'our-team',
    loadChildren: 'src/app/pages/our-team/our-team.module#OurTeamModule',
  },
  {
    path: 'faq',
    loadChildren: 'src/app/pages/faq/faq.module#FaqModule',
  },
  {
    path: 'join-our-team',
    loadChildren: 'src/app/pages/join-our-team/join-our-team.module#JoinOurTeamModule',
  },
  {
    path: 'bookandplay',
    loadChildren: 'src/app/pages/bookandplay/bookandplay.module#BookAndPlayModule',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: 'src/app/pages/login/login.module#LoginModule'
      },
      {
        path: 'verifyotp',
        loadChildren: 'src/app/pages/otp/otp.module#OtpModule'
      },
      {
        path: 'register',
        loadChildren: 'src/app/pages/register/register.module#RegisterModule'
      },
      {
        path: 'forgotpassword',
        loadChildren: 'src/app/pages/forgotpassword/forgotpassword.module#ForgotpasswordModule'
      },
      {
        path: 'login-with-otp',
        loadChildren: 'src/app/pages/forgotpassword/forgotpassword.module#ForgotpasswordModule'
      },
      {
        path: 'forgotpassword_otp',
        loadChildren: 'src/app/pages/forgot_pswd_otp/forgot_pswd_otp.module#ForgotPasswordOtpModule'
      },
      {
        path: 'book-a-trial-session',
        loadChildren: 'src/app/pages/player-demo-signup/player-demo-signup.module#PlayerDemoSignupModule'
      },
      {
        path: 'account-switcher',
        loadChildren: 'src/app/pages/account-switcher/account-switcher.module#AccountSwitcherModule'
      },
      {
        path: 'terms-conditions',
        loadChildren: 'src/app/pages/terms-conditions/terms-conditions.module#TermsConditionsModule'
      },
      {
        path: 'privacypolicy',
        loadChildren: 'src/app/pages/privacypolicy/privacypolicy.module#PrivacypolicyModule'
      },
      {
        path: 'refundpolicy',
        loadChildren: 'src/app/pages/refund-policy/refund-policy.module#RefundPolicyModule'
      },
      {
        path: 'liability-policy',
        loadChildren: 'src/app/pages/liability-policy/liability-policy.module#LiabilityPolicyModule'
      }

    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: 'src/app/pages/dashboard/dashboard.module#DashboardModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'settings',
        loadChildren: 'src/app/settings/settings.module#SettingsModule',
        // component:SettingsComponent
      },
      {
        path: 'myprofile',
        loadChildren: 'src/app/myprofile/myprofile.module#MyprofileModule',
      },
      {
        path: 'student',
        loadChildren: 'src/app/pages/studentlist/studentlist.module#StudentListModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'staff',
        loadChildren: 'src/app/pages/staff/staff.module#StaffModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'center',
        loadChildren: 'src/app/pages/center/center.module#CenterModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'role',
        loadChildren: 'src/app/pages/role/role.module#RoleModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'tags',
        loadChildren: 'src/app/pages/tags/tags.module#TagsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'course',
        loadChildren: 'src/app/pages/course/course.module#CourseModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'coach',
        loadChildren: 'src/app/pages/coach/coach.module#CoachModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'maps',
        loadChildren: 'src/app/pages/maps/maps.module#MapsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'news',
        loadChildren: 'src/app/pages/post/post.module#PostModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'chequetracking',
        loadChildren: 'src/app/pages/chequetracking/chequetracking.module#ChequetrackingModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'maintenance',
        loadChildren: 'src/app/pages/maintenance/maintenance.module#MaintenanceModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'payments',
        loadChildren: 'src/app/pages/payments/payments.module#PaymentsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'payment-tracker',
        loadChildren: 'src/app/pages/payment-tracker/payment-tracker.module#PaymenTrackerModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'assessment',
        loadChildren: 'src/app/pages/assessment/assessment.module#AssessmentModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'assessment-list',
        loadChildren: 'src/app/pages/assessment-list/assessment-list.module#AssessmentListModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'form',
        loadChildren: 'src/app/pages/form/form.module#FormModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'take-assessment',
        loadChildren: 'src/app/pages/take-assessment/take-assessment.module#TakeAssessmentModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'attendance',
        loadChildren: 'src/app/pages/attendance/attendance.module#AttendanceModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'request',
        loadChildren: 'src/app/pages/request/request.module#RequestModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'calendar',
        loadChildren: 'src/app/pages/calendar/calendar.module#CalendarModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'student_profile',
        loadChildren: 'src/app/pages/student_profile/student_profile.module#StudentProfileModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'student/ranking-tracker',
        loadChildren: 'src/app/pages/ranking-tracker/ranking-tracker.module#RankingTrackerModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'student/injury-log',
        loadChildren: 'src/app/pages/injury-log/injury-log.module#InjuryLogModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'student/activity-requests',
        loadChildren: 'src/app/pages/student-activities/student-activities.module#StudentActivitiesModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'formbuild',
        loadChildren: 'src/app/pages/formbuild/formbuild.module#FormbuildModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'batches/',
        loadChildren: 'src/app/pages/batches/batches.module#BatchesModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'batch/students',
        loadChildren: 'src/app/pages/studentlist/studentlist.module#StudentListModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'view-form',
        loadChildren: 'src/app/pages/view-form/view-form.module#ViewFormModule',
        canActivate: [AdminGuard]
      },
      // {
      //   path: 'center-profile',
      //   loadChildren: 'src/app/pages/center-profile/center-profile.module#CenterProfileModule',
      //   canActivate: [AdminGuard]
      // },
      {
        path: 'assessment/assessment-reports',
        loadChildren: 'src/app/pages/reports/reports.module#ReportsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'assessment/assessment-score',
        loadChildren: 'src/app/pages/assessment-score/assessment-score.module#AssessmentScoreModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'create-view-test',
        loadChildren: 'src/app/pages/create-view-test/create-view-test.module#CreateViewTestModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'schedule',
        loadChildren: 'src/app/pages/schedule/schedule.module#ScheduleModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'scoring',
        loadChildren: 'src/app/pages/scoring/scoring.module#ScoringModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'coaching-drills',
        loadChildren: 'src/app/pages/coaching-drills/coaching-drills.module#CoachingDrillsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'team-progress',
        loadChildren: 'src/app/pages/team-progress/team-progress.module#TeamProgressModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'lesson-plans',
        loadChildren: 'src/app/pages/lesson-plans/lesson-plans.module#LessonPlansModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'reports',
        loadChildren: 'src/app/pages/report/report.module#ReportModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'reports/attendance',
        loadChildren: 'src/app/pages/attendance/attendance.module#AttendanceModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'reports/assessment-reports',
        loadChildren: 'src/app/pages/reports/reports.module#ReportsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'reports/assessment-score',
        loadChildren: 'src/app/pages/assessment-score/assessment-score.module#AssessmentScoreModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'fee-plans',
        loadChildren: 'src/app/pages/fee-plans/fee-plans.module#FeePlansModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'student-import',
        loadChildren: 'src/app/pages/student-import/student-import.module#StudentImportModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'report-card',
        loadChildren: 'src/app/pages/report-card/report-card.module#ReportCardModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'trend',
        loadChildren: 'src/app/pages/trend/trend.module#TrendModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'messaging',
        loadChildren: 'src/app/pages/messaging/messaging.module#MessagingModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'competition-tracker',
        loadChildren: 'src/app/pages/competition-tracker/competition-tracker.module#CompTrackerModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'video-session',
        loadChildren: 'src/app/pages/video-session/video-session.module#VideoSessionModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'academy-settings',
        loadChildren: 'src/app/pages/academy-settings/academy-settings.module#AcademySettingsModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'workout-plans',
        loadChildren: 'src/app/pages/workout-plans/workout-plans.module#WorkoutPlansModule',
        canActivate: [AdminGuard]
      },
      {
        path: 'publish',
        loadChildren: 'src/app/pages/publish/publish.module#PublishModule',
        canActivate: [AdminGuard]
      }

    ]
  },
  {
    path: '',
    component: StudentDashboardComponent,

    children: [
      {
        path: 'student/dashboard',
        loadChildren: 'src/app/student/student.module#StudentModule',
        canActivate: [StudentGuard]
      },
      {
        path: 'student/student_profile',
        loadChildren: 'src/app/pages/student_profile/student_profile.module#StudentProfileModule',
        canActivate: [StudentGuard]
      },
      {
        path: 'student/calendar',
        loadChildren: 'src/app/pages/calendar/calendar.module#CalendarModule',
        canActivate: [StudentGuard]
      },
      {
        path: 'student/student-payment',
        loadChildren: 'src/app/pages/student-payment/student-payment.module#StudentPaymentModule',
        canActivate: [StudentGuard]
      },
      {
        path: 'student/attendance',
        loadChildren: 'src/app/pages/attendance/attendance.module#AttendanceModule',
        canActivate: [StudentGuard]
      },
      {
        path: 'student/assessment-result',
        loadChildren: 'src/app/student/assessment-result/assessment-result.module#AssessmentResultModule',
        canActivate: [StudentGuard]
      }
    ]
  },
  {
    path: '',
    component: CoachLayoutComponent,

    children: [
      {
        path: 'coach/dashboard',
        loadChildren: 'src/app/coach/coach.module#CoachModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/center',
        loadChildren: 'src/app/pages/center/center.module#CenterModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/coach-profile',
        loadChildren: 'src/app/pages/coach-profile/coach-profile.module#CoachProfileModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/attendance',
        loadChildren: 'src/app/pages/attendance/attendance.module#AttendanceModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/assessment',
        loadChildren: 'src/app/pages/assessment/assessment.module#AssessmentModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/form-build',
        loadChildren: 'src/app/pages/formbuild/formbuild.module#FormbuildModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/view-form',
        loadChildren: 'src/app/pages/view-form/view-form.module#ViewFormModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/batches',
        loadChildren: 'src/app/pages/batches/batches.module#BatchesModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/batch/students',
        loadChildren: 'src/app/pages/studentlist/studentlist.module#StudentListModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/student/student_profile',
        loadChildren: 'src/app/pages/student_profile/student_profile.module#StudentProfileModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/assessment-list',
        loadChildren: 'src/app/pages/assessment-list/assessment-list.module#AssessmentListModule',
        canActivate: [CoachGuard]
      },
      {
        path: 'coach/take-assessment',
        loadChildren: 'src/app/pages/take-assessment/take-assessment.module#TakeAssessmentModule',
        canActivate: [CoachGuard]
      }
    ]
  },
  {
    path: '',
    component: StaffLayoutComponent,
    children: [
      {
        path: 'staff/dashboard',
        loadChildren: 'src/app/staff-dashboard/staff-dashboard.module#StaffDashboardModule',
        // loadChildren: 'src/app/pages/dashboard/dashboard.module#DashboardModule',
        canActivate: [StaffGuard]
      },
      {
        path: 'staff/center',
        loadChildren: 'src/app/pages/center/center.module#CenterModule',
        canActivate: [StaffGuard]
      },
      {
        path: 'staff/payments-tracker',
        loadChildren: 'src/app/staff/payments-tracker/payments-tracker.module#PaymentsTrackerModule',
        canActivate: [StaffGuard]
      },
      {
        path: 'staff/fee-plans',
        loadChildren: 'src/app/staff/fee-plans/fee-plans.module#FeePlansModule',
        canActivate: [StaffGuard]
      },
      {
        path: 'staff/fee-reports',
        loadChildren: 'src/app/staff/fee-reports/fee-reports.module#FeeReportsModule',
        canActivate: [StaffGuard]
      }
    ]
  },
  {
    path: 'blogs',
    loadChildren: 'src/app/pages/blog/blog.module#BlogModule',
  },
  {
    path: 'blogs/:slug',
    loadChildren: 'src/app/pages/blog-view/blog-view.module#BlogViewModule',
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
