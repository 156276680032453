import { SelectionEnum } from '../enums/generic.enum';

export const Constants = {
  roles: {
    VENDOR: 'VENDOR',
    STUDENT: 'STUDENT',
    GUARDIAN: 'GUARDIAN',
    COACH: 'COACH',
    MANAGER: 'MANAGER',
    HR: 'HR',
    ADMIN: 'ADMIN',
    FINANCE: 'FINANCE',
    SUPPORT: 'SUPPORT',
    OPERATIONS: 'OPERATIONS',
    DEVELOPER: 'DEVELOPER',
    SYSTEMADMIN: 'SYSTEMADMIN',
    BUSINESSADMIN: 'BUSINESSADMIN',
    ONEADMIN: 'ONEADMIN'
  },
  PATHS: {
    LOGIN: '/login',
    LOGINWITHOTP: '/login-with-otp',
    OPSLOGIN: '/ops/login',
    LOGOUT: '/logout',
    FORGOTPASSWORD: '/forgot-password',
    OPSFORGOTPASSWORD: '/ops/forgot-password',
    FORGOTUSERID: '/forgot-userid',
    RMFORGOTUSERID: '/ops/forgot-userid',
    ACCOUNTSWITCHER: '/account-switcher',
    DASHBOARD: '/dashboard',
    SDASHBOARD: '/student/dashboard',
    COACHDASHBOARD: '/coach/dashboard',
    STAFFDASHBOARD: '/staff/dashboard',
    RMDASHBOARD: '/ops/dashboard',
    CUSTOMERDASHBOARD: '/customer/dashboard',
    SAAUDITLOGS: '/system-admin/audit-logs',
    OPERATIONSPLAYERMASTER: '/ops/player-master',
    COMINGSOON: 'coming-soon',
    OPSPLAYERDASHBOARD: '/ops/player-dashboard',
    OPSREPORTSDOWNLOAD: '/ops/download-reports',
    ONEADMIN: '/one-admin/audit-logs',
    VERIFYOTP: '/verifyotp',
    FORGOTPASSWORDOTP: '/forgotpassword_otp',
    FORMBUILDER: '/formbuild',
    VIEWFORM: 'view-form',
    ASSESSMENTLIST: '/assessment-list',
    TAKEASSESSMENT: '/take-assessment',
    COACHASSESSMENT: '/coach/assessment',
    COACHFORMBUILDER: '/coach/form-build',
    COACHVIEWFORM: '/coach/view-form',
    COACHBATCHES: '/coach/batches',
    COACHSTUDENTPROFILE: '/coach/student/student_profile',
    COACHASSESSMENTLIST: '/coach/assessment-list',
    COACHTAKEASSESSMENT: '/coach/take-assessment'
  },
  EVENTS: {
    'bg-info': 'HoliDay',
    ' ': 'Tournament',
    'bg-success': 'Assessment'
  },
  COLOR: {
    HoliDay: 'bg-info',
    Tournament: ' ',
    Assessment: 'bg-success'
  },
  debounceTime: 200,
  itemsPerPage: 20,
  minCharForSearch: 3,
  dashboardImageRefreshInterval: 10000,
  notificationReloadInterval: 15000, // 15 seconds
  nonAuthenticatedRoutes: ['/signup', '/login'],
  defaultGender: 1, //Male,
  salutations: [
    {
      id: 1,
      itemName: 'Mr'
    },
    {
      id: 2,
      itemName: 'Ms'
    },
    {
      id: 3,
      itemName: 'Others'
    }
  ],
  reportFormats: [
    {
      id: 1,
      name: 'pdf'
    },
    {
      id: 2,
      name: 'xlsx'
    }
  ],
  OPTIONS: {
    gender: 'GENDER',
    booldGroup: 'BLOODGROUP',
    dominantSide: 'DOMINANTSIDE',
    postStatus: 'POSTSTATUS',
    courseCategory: 'COURSECATEGORY',
    procurementStatus: 'PROCUREMENTSTATUS',
    maintenanceStatus: 'MAINTENANCESTATUS',
    chequeStatus: 'CHEQUESTATUS',
    AttendanceType: 'ATTENDANCETYPE',
    feePlanType: 'FEETYPE',
    recurringFrequency: 'RECURRINGFREQUENCY',
    paymentMethod: 'PAYMENTMETHOD',
    paymentStatus: 'PAYMENTSTATUS',
    renewalCriteria: 'RENEWALCRITERIA',
    coachLevel: 'COACHLEVEL',
    courseLevel: 'COURSELEVEL',
    grade: 'GRADE',
    centerType: 'CENTERTYPE'
  },

  // selection array
  SelectionArray: [
    {
      id: SelectionEnum.BATCH,
      title: 'Batch'
    },
    {
      id: SelectionEnum.CENTER,
      title: 'Center'
    },
    {
      id: SelectionEnum.STAFF,
      title: 'Staff'
    },
    {
      id: SelectionEnum.STUDENT,
      title: 'Student'
    }
  ]
};
