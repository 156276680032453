import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseApi, OptionApi, SportApi, StateApi } from 'src/app/shared/sdk';
import { NgForm } from '@angular/forms';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/class/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.scss']
})
export class AddCourseComponent implements OnInit {

  public isRequired = false;
  public sport: any;
  public courseCategory: any;
  public level: any;
  public file: any;
  public message: any;
  public levels: any = [];
  public sports: any = [];
  public tempSports: any = [];
  public tempLevels: any = [];
  public courseCategories: any = [];
  public isNext = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fileUploadService: FileUploadService,
    private courseApi: CourseApi,
    private optionApi: OptionApi,
    private sportApi: SportApi,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadCourseData();
  }

  loadCourseData() {
    this.optionApi
      .find({ where: { identifier: Constants.OPTIONS.courseLevel } })
      .toPromise()
      .then((levelOptions) => {
        this.tempLevels = levelOptions;
        this.levels = levelOptions;
      });
    this.sportApi.find({}).toPromise().then((sportData: any) => {
      this.tempSports = sportData;
      this.sports = sportData;
    })
    this.optionApi
      .find({ where: { identifier: Constants.OPTIONS.courseCategory } })
      .toPromise()
      .then((res: any) => {
        console.log('res------------------------');
        console.log(res);
        this.courseCategories = res;
      });
  }

  addCourse(course: NgForm) {
    if (this.sport === undefined || this.sport === 0 || this.sport === null) {
      this.isRequired = true;
      return null;
    } else {
      this.isRequired = false;
    }
    if (this.courseCategory === undefined || this.courseCategory === 0 || this.courseCategory === null) {
      this.isRequired = true;
      return null;
    } else {
      this.isRequired = false;
    }
    if (this.level === undefined || this.level === 0 || this.level === null) {
      this.isRequired = true;
      return null;
    } else {
      this.isRequired = false;
    }
    this.courseApi
      .create({
        name: course.value.courseName,
        category: this.courseCategory,
        isActive: true,
        level: this.level,
        sportId: this.sport,
        isMedicalRequired: course.value.medicalCheckup,
        isCertificable: course.value.certificatedCourse,
        description: course.value.description
      })
      .toPromise()
      .then((res: any) => {
        if (this.file) {
          this.uploadCoursePic(res.id);
        }
      })
      .then(() => {
        this.isNext = true;
        // Reset the form
        course.reset();
        // this.toastr.success('Course has been added successfully');
      })
      .catch((err) => {
        console.log(err);
        this.message = err.message;
        this.activeModal.close(false);
      });
  }

  uploadCoursePic(courseId: any) {
    this.fileUploadService.uploadPicture(courseId, 'Courses_uploadCoursePicture', this.file).then(() => {
    });
  }

  setCourseCategory($event: any) {
    this.courseCategory = parseInt($event.target.value, 10);
  }
  setCourseLevel($event: any) {
    this.level = parseInt($event.target.value, 10);
  }
  setSport($event: any) {
    this.sport = parseInt($event.target.value, 10);
  }

  setImage(image: any) {
    this.file = image;
  }

  next() {
    this.activeModal.close(true);
  }

}
