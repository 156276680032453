import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountApi, UserRoleScopeApi, OrganizationApi, BrandingPreferenceApi } from 'src/app/shared/sdk';
import { environment } from 'src/environments/environment';
const misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
  showMenu?:boolean;
  isPremium?:boolean;
  scopes?: any[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'ni-shop text-red',
    showMenu: false,
    isPremium:false,
    scopes: ['read:dashboard', 'read:dashboardCount', 'read:fetchDashboard', 'DEFAULT']
  },
  {
    path: '/fee-plans',
    title: 'memom memom',
    type: 'link',
    icontype: 'ni-ui-04 text-red',
    showMenu: false,
    isPremium:false,
    scopes: ['read:fetchFeePlanById', 'read:fetchFeePlanByAccountId']
  },
  {
    path: '/payment-tracker',
    title: 'Payment Tracker',
    type: 'link',
    icontype: 'ni-chart-pie-35 text-red',
    showMenu: false,
    scopes: ['create:addOrder', 'update:editOrder', 'create:createOrder', 'read:fetchPaymentByPayamentId'],
    isPremium:true
  },
  {
    path: '/reports',
    title: 'Reports',
    type: 'link',
    icontype: 'ni-collection text-red',
    showMenu: false,
    scopes: ['update:deRegisterUser', 'update:deActivateAccount'],
    isPremium:true

  },

  {
    path: '/request',
    title: 'Resource Requests',
    type: 'link',
    icontype: 'ni-notification-70 text-red',
    showMenu: false,
    scopes: [
      'read:fetchProcurementsById',
      'update:updateProcurement',
      'update:deleteProcurement',
      'create:addProcurement',
      'update:uploadProcurementPicture'
    ],
    isPremium:true

  },
  {
    path: "/student",
    title: "Students",
    type: "link",
    icontype: "ni-single-02 text-yellow",
    isPremium:false,
    scopes: ['DEFAULT']


  },
  {
    path: "/staff",
    title: "Staff",
    type: "link",
    icontype: "ni-single-02 text-danger",
    isPremium:false,
    scopes: ['DEFAULT']


  },
  {
    path: '/center',
    title: 'Centers',
    type: 'link',
    icontype: 'ni-ungroup text-red',
    showMenu: false,
    isPremium:false,
    scopes: ['read:coachStudentsCenterhWise', 'update:assignCenterToStaff', 'update:courseInfrastructure', 'read:fetchCoachListOfOtherInfrastructures', 'update:uploadInfrastructurePicture']
  },
  {
    path: '/attendance',
    title: 'Attendance Tracker',
    type: 'link',
    icontype: 'ni-align-left-2 text-red',
    showMenu: false,
    scopes: [
      'read:fetchAttendanceByAccountId',
      'create:markAttendance',
      'read:viewBatchAttendance',
      'read:userAttendance',
      'read:fetchWorkoutAttendeesCount',
      'create:bulkAttendance',
      'create:sessionAttendance',
      'update:updateBulkAttendance',
      'create:markCoachAttendance'
    ],
    isPremium:true

  },
  {
    path: '/role',
    title: 'Roles',
    type: 'link',
    showMenu: false,
    icontype: 'ni-archive-2 text-red',
    scopes: ['update:userRole'],
    isPremium:false
  },
  {
    path: "/course",
    title: "Courses",
    type: "link",
    icontype: "ni-bullet-list-67 text-info",
    isPremium:false

  },

  {
    path: '/messaging',
    title: 'Messaging',
    type: 'link',
    icontype: 'ni-email-83 text-red',
    showMenu: false,
    scopes: [
      'create:createActivity',
      'read:readActivity',
      'update:updateActivity',
      'delete:deleteActivity',
      'create:sendSMS',
      'create:sendMobileNotification',
      'create:sendMessage',
      'read:firebaseToken',
      'create:sendBothNotifications'
    ],
    isPremium:true

  },
  {
    path: '/news',
    title: 'News',
    type: 'link',
    icontype: 'ni-box-2 text-red',
    showMenu: false,
    isPremium:true,
    scopes: ['update:updatePost', 'update:uploadPostPicture', 'create:createPost', 'read:fetchPostByAccountId']
  },
  {
    path: '/assessment',
    title: 'Assessments',
    type: 'link',
    icontype: 'ni-album-2 text-red',
    showMenu: false,
    scopes: ['read:getAssessmentResultById'],
    isPremium:true

  },
  // {
  //   path: "/form",
  //   title: "Forms",
  //   type: "link",
  //   icontype: "ni-single-copy-04 text-yellow"
  // },

  // {
  //   path: "/maintenance",
  //   title: "Maintenance",
  //   type: "link",
  //   icontype: "ni-support-16 text-warning"
  // },
  // {
  //   path: "/payments",
  //   title: "Payments",
  //   type: "link",
  //   icontype: "ni-money-coins text-yellow"
  // },
  // {
  //   path: '/chequetracking',
  //   title: 'Cheque Tracking',
  //   type: 'link',
  //   icontype: 'ni-support-16 text-red'
  // },
  // {
  //   path: "/paymentracking",
  //   title: "Payment Tracking",
  //   type: "link",
  //   icontype: "ni-books text-green"
  // },

  {
    path: '/coaching-drills',
    title: 'Coaching Drills',
    type: 'link',
    icontype: 'ni-chart-bar-32 text-red',
    showMenu: false,
    scopes: [
      'update:uploadCoachingDrillImage',
      'update:uploadCoachingDrillVideo',
      'update:AssignDrillToSchedulePlan',
      'read:fetchCoachingDrillAttendeesCount',
      'update:assignCoachingDrillToAccount',
      'update:assignWorkoutPlanToAccount',
      'update:createScheduleSession',
      'update:assignCoachingScheduleToAccount',
      'update:updateInjuryDetails',
      'delete:deleteInjuryDetails',
      'read:fetchCalendarDetails',
      'delete:deleteActivity',
      'read:upcomingSession'
    ],
    isPremium:true

  },
  // {
  //   path: '/lesson-plans',
  //   title: 'Session Plans',
  //   type: 'link',
  //   icontype: 'ni-compass-04 text-red',
  //   showMenu: false,
  //   scopes: ['update:createLessonPlan']
  // },
  {
    path: '/competition-tracker',
    title: 'Competition Tracker',
    type: 'link',
    icontype: 'ni-user-run text-red',
    showMenu: false,
    scopes: ['update:updateCertification', 'delete:deleteCertification'],
    isPremium:true

  },
  {
    path: '/workout-plans',
    title: 'Workout Plans',
    type: 'link',
    icontype: 'ni-atom text-red',
    showMenu: false,
    scopes: ['DEFAULT'],
    isPremium:true

  },
  {
    path: '/video-session',
    title: 'Video Session',
    type: 'link',
    icontype: 'ni-calendar-grid-58 text-red',
    showMenu: false,
    isPremium:true,
    scopes: ['read:fetchVideoSessionByAccountId', 'read:fetchVideoSessionAttendeesCount', 'update:assignVideoSessionToAccount']
  },
  {
    path: '/team-progress',
    title: 'Team Progress',
    type: 'link',
    icontype: 'ni-chart-bar-32 text-red',
    showMenu: false,
    scopes: ['DEFAULT'],
    isPremium:true
  },
  {
    path: '/tags',
    title: 'Tags',
    type: 'link',
    icontype: 'ni-tag text-red',
    showMenu: false,
    scopes: [
      'create:addStudentTag',
      'create:createStudentStudentTag',
      'delete:deleteStudentTag',
      'update:UntagStudent',
      'read:fetchTaggedStudentsCount'
    ],
    isPremium:true
  },
  // {
  //   path: "/calendar",
  //   title: "Calendar",
  //   type: "link",
  //   icontype: "ni-calendar-grid-58 text-warning"
  // }

  {
    path: '/publish',
    title: 'Publish',
    type: 'link',
    icontype: 'ni-send text-red',
    showMenu: false,
    scopes: ['DEFAULT']
  }
];
@Component({
  selector: 'app-free-lance-coach-sidebar',
  templateUrl: './free-lance-coach-sidebar.component.html',
  styleUrls: ['./free-lance-coach-sidebar.component.scss']
})
export class FreeLanceCoachSidebarComponent implements OnInit {

  public menuItems: any = [];
  public isCollapsed = true;
  public appUserId: any = '';
  public userRoleScope: any = [];
  public permittedScopes: any = [];
  public showSideBarMenuItem = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private accountApi: AccountApi,
    private ursApi: UserRoleScopeApi,
    private organizationApi: OrganizationApi,
    private brandingPreferenceApi:BrandingPreferenceApi
  ) {}

  public brandLogo: any;
  bandLogoUrl = environment.apiUrl;
  // constructor(private router: Router, ,) {}

  ngOnInit() {
    this.getOrganizationDetails();
    this.getUserOfAccountId();

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.menuItems.map((item: any, index: any) => {
      if (item.scopes) this.menuItems[index].showMenu = false;
    });

    this.fetchLogo();
    // console.log('menu item',this.menuItems);
  }

  // getting of the organization api
  private  getOrganizationDetails(){
    this.organizationApi.find().toPromise().then((org:any)=>{
      // condition to hide and show the publish functionality. 
      if(org[0]?.isPublished ==null || Number(org[0]?.isPublished)!==1 ){
        this.menuItems = this.menuItems.filter((res)=>res.title.toLowerCase()!=='publish')
      }
    })
  }



  fetchLogo() {
    // this.http.get(this.bandLogoUrl + '/branding-preference/fetch-logo').subscribe((showResult: any) => {
    //   //  console.log(showResult);
    //   this.brandLogo = showResult.data;
    // });
    this.brandingPreferenceApi.find({where:{isActive:1},include:['organizationLogoFile']}).toPromise().then((response:any)=>{
      // console.log(response);
       this.brandLogo = response;
     })
  }

  getUserOfAccountId() {
    const adminScopes = [];
    this.accountApi
      .find({ where: { id: localStorage.getItem('currentAccountId') } })
      .toPromise()
      .then((accountresp: any) => {
        this.appUserId = accountresp[0].appUserId;
      })
      .then(() => {
        this.ursApi
          .find({ where: { app_user_id: this.appUserId, appUserId: this.appUserId } })
          .toPromise()
          .then((ursResp: any) => {
            Promise.all(
              ursResp[0].scope.map((val: any) => {
                Object.keys(val.scopes).map((scopeValues) => {
                  // this.permittedScopes.push(scopeValues);
                  adminScopes.push(scopeValues);
                });
              })
            ).then(() => {
              this.permittedScopes = adminScopes;
              // console.log('check permitted',this.permittedScopes)
              this.checkSidebarPermissions();
            });
          });
      });
  }

  checkSidebarPermissions() {
    // console.log('menu',this.menuItems)
    this.menuItems.map((item: any, index: any) => {
      if (item.scopes !== undefined) {
        item.scopes.map((scp: any) => {
          // console.log(scp);
          if (this.permittedScopes.includes(scp)) {
            this.menuItems[index].showMenu = true;
          }
        });
      }
    });

    // console.log(this.menuItems, this.permittedScopes);
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('g-sidenav-pinned')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
      misc.sidebar_mini_active = true;
    }
  }

}
