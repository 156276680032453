/* tslint:disable */
import {
  Account,
  WorkoutPlanSchedule
} from '../index';

declare var Object: any;
export interface MapWorkoutStudentInterface {
  "type": number;
  "status"?: number;
  "comment"?: string;
  "reason"?: string;
  "completionDate"?: Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account?: Account;
  workoutPlanSchedule?: WorkoutPlanSchedule;
}

export class MapWorkoutStudent implements MapWorkoutStudentInterface {
  "type": number;
  "status": number;
  "comment": string;
  "reason": string;
  "completionDate": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account: Account;
  workoutPlanSchedule: WorkoutPlanSchedule;
  constructor(data?: MapWorkoutStudentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapWorkoutStudent`.
   */
  public static getModelName() {
    return "MapWorkoutStudent";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapWorkoutStudent for dynamic purposes.
  **/
  public static factory(data: MapWorkoutStudentInterface): MapWorkoutStudent{
    return new MapWorkoutStudent(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapWorkoutStudent',
      plural: 'MapWorkoutStudents',
      path: 'MapWorkoutStudents',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "reason": {
          name: 'reason',
          type: 'string'
        },
        "completionDate": {
          name: 'completionDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        workoutPlanSchedule: {
          name: 'workoutPlanSchedule',
          type: 'WorkoutPlanSchedule',
          model: 'WorkoutPlanSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'workout_plan_schedule_id',
          keyTo: 'id'
        },
      }
    }
  }
}
