/* tslint:disable */

declare var Object: any;
export interface CoachEvaluationParameterInterface {
  "xParameter": string;
  "yParameter": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class CoachEvaluationParameter implements CoachEvaluationParameterInterface {
  "xParameter": string;
  "yParameter": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: CoachEvaluationParameterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoachEvaluationParameter`.
   */
  public static getModelName() {
    return "CoachEvaluationParameter";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoachEvaluationParameter for dynamic purposes.
  **/
  public static factory(data: CoachEvaluationParameterInterface): CoachEvaluationParameter{
    return new CoachEvaluationParameter(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoachEvaluationParameter',
      plural: 'CoachEvaluationParameters',
      path: 'CoachEvaluationParameters',
      idName: 'id',
      properties: {
        "xParameter": {
          name: 'xParameter',
          type: 'string'
        },
        "yParameter": {
          name: 'yParameter',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
