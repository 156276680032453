import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppUserApi } from 'src/app/shared/sdk';
import swal from 'sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewportScroller } from '@angular/common';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 1500, noPause: false, showIndicators: false } }
  ],
})
export class PresentationComponent implements OnInit {

  public imagesUrl;
  test: Date = new Date();
  isCollapsed = true;
  windowScrolled = false;

  public contactForm = this.fb.group({
    name: ['', [Validators.required, Validators.pattern('^(?![0-9 ]*$)[a-zA-Z0-9 ]+$')]],
    email: ['', [Validators.required]],
    message: ['', [Validators.required]]
  });
showIndicator: any;
noWrapSlides: any;
  get f() {
    return this.contactForm.controls;
  }

  slides = [
    // {image: 'assets/img/slides/slide1.png'},
    // {image: 'assets/img/slides/slide2.png'},
    { image: 'assets/img/slides/mainSlide.png' }
  ];


  testimonials = [
    {
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      name: 'John Cena',
      image: 'assets/img/theme/male.png'
    },
    {
      message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
      name: 'Sam Smith',
      image: 'assets/img/theme/female-user.png'
    },
    {
      message: 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      name: 'David Johnson',
      image: 'assets/img/theme/stafficon.png'
    },
    {
      // tslint:disable-next-line:max-line-length
      message: 'Lorem ipsum dolor sit amet, sed do eiusmod tempor magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      name: 'David Johnson',
      image: 'assets/img/theme/team-3.jpg'
    }
  ];
  // logoSlider=[
  //   {images:"assets/img/slides/reeds.png"},
  //   {images:"assets/img/slides/snsa.png"},
  //   {images:"assets/img/slides/sdfc.png"}
  // ]
  ngAfterViewInit() {
    $('.slick-carousel').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
    });
    // $('.slick-carousel-logo').slick({
    //   slidesToShow: 3,
    //   slidesToScroll: 1,
    //   dots: false,
    //   infinite: true,
    //   autoplay: true,
    //   autoplaySpeed: 4000,
    // });
  }



  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  constructor(private fb: FormBuilder, private appUserApi: AppUserApi,
    private modalService: BsModalService,
    private scroller: ViewportScroller,
    private translate: TranslateService
  ) { translate.setDefaultLang('en'); }

  ngOnInit() {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });

    // logo slides
    this.imagesUrl = ['assets/img/slides/reeds.png', 'assets/img/slides/snsa.png', 'assets/img/slides/sdfc.png'];
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  contactUs() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      return this.appUserApi
        .contactUs(this.contactForm.value.email, this.contactForm.value.name, this.contactForm.value.message)
        .toPromise().then((response: any) => {
          console.log(response);
          swal
            .fire({
              title: 'Success!',
              text: 'Message has been sent.',
              type: 'success',
              confirmButtonClass: 'btn btn-success',
              buttonsStyling: false
            })
            .then((result) => {
              if (result.value) {
                location.reload();
              }
            });
        }).catch((err) => {
          console.log(err.error.statusCode);
        });
    }
  }
  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
