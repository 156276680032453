/* tslint:disable */
import {
  Account,
  Transaction,
  Order
} from '../index';

declare var Object: any;
export interface UtrTrackerInterface {
  "amount": number;
  "utrNo": string;
  "bankName"?: string;
  "branchName"?: string;
  "status": number;
  "isCts"?: boolean;
  "recievedDate": Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "created_by"?: number;
  "order_id"?: number;
  "orderId"?: number;
  account?: Account;
  createdBy?: Account;
  transaction?: Transaction;
  order?: Order;
}

export class UtrTracker implements UtrTrackerInterface {
  "amount": number;
  "utrNo": string;
  "bankName": string;
  "branchName": string;
  "status": number;
  "isCts": boolean;
  "recievedDate": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "created_by": number;
  "order_id": number;
  "orderId": number;
  account: Account;
  createdBy: Account;
  transaction: Transaction;
  order: Order;
  constructor(data?: UtrTrackerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UtrTracker`.
   */
  public static getModelName() {
    return "UtrTracker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UtrTracker for dynamic purposes.
  **/
  public static factory(data: UtrTrackerInterface): UtrTracker{
    return new UtrTracker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UtrTracker',
      plural: 'UtrTrackers',
      path: 'UtrTrackers',
      idName: 'id',
      properties: {
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "utrNo": {
          name: 'utrNo',
          type: 'string'
        },
        "bankName": {
          name: 'bankName',
          type: 'string'
        },
        "branchName": {
          name: 'branchName',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "isCts": {
          name: 'isCts',
          type: 'boolean',
          default: false
        },
        "recievedDate": {
          name: 'recievedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "created_by": {
          name: 'created_by',
          type: 'number'
        },
        "order_id": {
          name: 'order_id',
          type: 'number'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by',
          keyTo: 'id'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction',
          model: 'Transaction',
          relationType: 'belongsTo',
                  keyFrom: 'transaction_id',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'order_id',
          keyTo: 'id'
        },
      }
    }
  }
}
