/* tslint:disable */
import {
  Infrastructure,
  Course
} from '../index';

declare var Object: any;
export interface CourseInfrastructureInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  infrastructure?: Infrastructure;
  course?: Course;
}

export class CourseInfrastructure implements CourseInfrastructureInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  infrastructure: Infrastructure;
  course: Course;
  constructor(data?: CourseInfrastructureInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CourseInfrastructure`.
   */
  public static getModelName() {
    return "CourseInfrastructure";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CourseInfrastructure for dynamic purposes.
  **/
  public static factory(data: CourseInfrastructureInterface): CourseInfrastructure{
    return new CourseInfrastructure(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CourseInfrastructure',
      plural: 'CourseInfrastructures',
      path: 'CourseInfrastructures',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
        course: {
          name: 'course',
          type: 'Course',
          model: 'Course',
          relationType: 'belongsTo',
                  keyFrom: 'course_id',
          keyTo: 'id'
        },
      }
    }
  }
}
