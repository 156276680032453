import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AccountBatchApi,
  BatchApi, BatchTimingApi,
  CourseApi, CourseInfrastructureApi,
  InfrastructureApi, OptionApi,
  SportApi
} from 'src/app/shared/sdk';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/class/constants';
import Selectr from 'mobius1-selectr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-batch',
  templateUrl: './add-batch.component.html',
  styleUrls: ['./add-batch.component.scss']
})
export class AddBatchComponent implements OnInit {

  public dateValidation = '';
  public courseMessage = '';
  public timingsError: any = [];
  public lastRegistrationDate = '';
  public courseSelector: any;
  public batchWeekDays: any = [];
  public timeValidation = '';
  public batchMessage = '';
  public availableCoaches: any = [];
  public centerId = 0;
  public batchMultipleselector: any;
  public message = '';
  public batchTimings: any = [];
  selectrmultiple: any;
  public courseId: any;
  public onChangeStartTime: any;
  public onChangeEndTime: any;
  public coachIds: any;
  public centers: any = [];
  public isBatchDaysEditing: any = false;
  public isLs = true;
  public isELS = true;
  public isNext = false;

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private batchApi: BatchApi,
    private batchTimingApi: BatchTimingApi,
    private accountBatchApi: AccountBatchApi,
    private courseInfrastructureApi: CourseInfrastructureApi,
    private infrastructureApi: InfrastructureApi,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  public editBatchForm = this.fb.group({
    batchId: [''],
    description: [''],
    name: [''],
    startDate: [''],
    endDate: [''],
    startTime: [''],
    endTime: [''],
    limitedSessions: [''],
    batchCapacity: ['', Validators.required],
    coachName: [''],
    numberOfSessions: [''],
    monday: [''],
    tuesday: [''],
    wednesday: [''],
    thursday: [''],
    friday: [''],
    saturday: [''],
    sunday: [''],
    status: [''],
    lastRegistrationDate: ['', Validators.required]
  });


  ngOnInit(): void {
    this.getInfrastructures();
  }

  getInfrastructures() {
    this.centers = [];
    this.infrastructureApi
      .find()
      .toPromise()
      .then((infraDetails: any) => {
        infraDetails.map((infraObj: any) => {
          const infra: any = {};
          infra.text = infraObj.name;
          infra.value = infraObj.id;
          this.centers.push(infra);
        });
      })
  }

  addBatch(batch: NgForm) {
    this.dateValidation = '';
    this.courseMessage = '';
    this.timingsError = '';
    this.lastRegistrationDate = '';
    if (parseInt(this.courseSelector.selectedValue, 10) === 0) {
      this.courseMessage = 'Select a course';
      return null;
    }
    if (this.batchWeekDays.length === 0) {
      this.timingsError = 'Select atleast one day in a week';
      return null;
    }
    const batchform = batch.value;
    const startDay = new Date(batchform.startDate);
    const endDay = new Date(batchform.endDate);
    // batch.startTime !== undefined ? batch.startTime.split(':') :'';
    const startTime = batchform.startTime.split(':');
    const endTime = batchform.endTime.split(':');
    if (batchform.startDate != null && batchform.endDate != null && batchform.endDate <= batchform.startDate) {
      this.dateValidation = 'End date should be grater then start date.';
      return null;
    } else {
      this.dateValidation = '';
    }
    if (batchform.lastRegistrationDate != null && batchform.endDate != null && batchform.endDate < batchform.lastRegistrationDate) {
      this.lastRegistrationDate = 'Last registartion date should be less then batch end date.';
      return null;
    } else {
      this.lastRegistrationDate = '';
    }
    if (batchform.startTime != null && batchform.endTime != null && batchform.endTime <= batchform.startTime) {
      this.timeValidation = 'End time cannot be lessthan or equal to start time.';
      return null;
    } else {
      this.timeValidation = '';
    }
    if (batchform.batchCapacity === 0) {
      this.batchMessage = 'Batch capacity cannot be zero';
      return false;
    }
    this.spinner.show();
    console.log(batchform.startDate ? batchform.startDate : new Date())
    console.log(batchform.endDate ? batchform.endDate : new Date())
    this.batchApi
      .create({
        name: batchform.name,
        description: batchform.description,
        // startDate: batchform.startDate + 'T' + batchform.startTime + ':00.000Z',
        // new Date(startDay.getFullYear(),startDay.getMonth(),startDay.getDate(),startTime[0],startTime[1]),
        // endDate: batchform.endDate + 'T' + batchform.endTime + ':00.000Z',
        // new Date(endDay.getFullYear(),endDay.getMonth(),endDay.getDate(),endTime[0],endTime[1]),
        startDate: batchform.startDate ? batchform.startDate : new Date(),
        endDate: batchform.endDate ? batchform.endDate : new Date(),
        lastRegistrationDate: batchform.lastRegistrationDate ? batchform.lastRegistrationDate : null,
        limitedSessions: batchform.limitedSessions,
        numberOfSessions: batchform.numberOfSessions,
        batchCapacity: batchform.batchCapacity,
        isActive: true,
        infrastructureId: this.centerId,
        courseId: this.courseSelector.selectedValue
      })
      .toPromise()
      .then((res: any) => {
        const promises: any = [];
        this.batchWeekDays.map((dayTiming: any) => {
          promises.push(
            this.batchTimingApi
              .create({
                startTime: batchform.startTime,
                endTime: batchform.endTime,
                day: dayTiming,
                batchId: res.id
              })
              .toPromise()
          );
        });
        if (this.batchMultipleselector.selectedValues) {
          const newAccIds = [];
          Promise.all(this.batchMultipleselector.selectedValues.map((accountId: any) => {
            newAccIds.push(parseInt(accountId, 10));
          })).then(() => {
            console.log('new', newAccIds);
            this.accountBatchApi.changeCoachToBatch(res.id, newAccIds, Number(res.courseId)).toPromise();
          })
        }

        Promise.all(promises).then((res1) => {
          this.spinner.hide();
          this.isNext = true;
          // Reset the form
          batch.reset();
          // this.toastr.success('Batch has been added successfully');
        });
      })
      .catch((err) => {
        this.message = err.message;
        this.spinner.hide();
        this.activeModal.close(false);
      });
  }

  selectedCourse(value) {
    //  console.log(value);
    this.courseId = value;
    this.loadCoachAccounts();
  }

  loadCoachAccounts() {
    let action = '';
    if (this.courseId === 0) {
      action = 'add';
    } else {
      action = 'edit';
    }
    this.batchApi.filterCoachByTime(this.centerId, this.courseId, this.onChangeStartTime,
      this.onChangeEndTime, action).toPromise().then((coachAccounts: any) => {
        console.log('coachaccount', coachAccounts);
        this.availableCoaches = [];
        // this.availableCoaches = coachAccounts;

        console.log('availableCoaches')
        console.log(this.availableCoaches)
        this.batchMultipleselector.add(this.availableCoaches);
        coachAccounts.map((CoachesObj: any) => {
          const Coaches: any = {};
          Coaches.text = CoachesObj.name;
          Coaches.value = CoachesObj.id;
          this.availableCoaches.push(Coaches);
        });
        this.batchMultipleselector.reset();
        this.batchMultipleselector.removeAll();
        this.batchMultipleselector.add(this.availableCoaches);
        if (this.coachIds) {
          this.coachIds.map(id => {
            this.batchMultipleselector.setValue(id);
          })
        }
      });
  }

  selectedCenter(value) {
    this.centerId = value;
    this.batchTimings = [];
    this.batchWeekDays = [];
    this.availableCoaches = [];
    this.courseMessage = '';
    const courses: any = [{ value: 0, text: 'Select a Course' }];
    this.courseInfrastructureApi
      .find({ where: { isActive: true, infrastructureId: this.centerId }, include: ['course'] })
      .toPromise()
      .then((response) => {
        response.map((course: any) => {
          courses.push({ value: course.course.id, text: course.course.name + ' ' + course.course.levelLabel });
        });
      })
      .then(() => {
        if (document.getElementById('courses')) {
          const selectr: any = document.getElementById('courses');
          this.courseSelector = new Selectr(selectr, { data: courses });
        } else {
          const selectr: any = document.getElementById('edit-batch-courses');
          this.courseSelector = new Selectr(selectr, { data: courses });
        }
        this.selectrmultiple = document.getElementById('selectr-multiple-coaches');
        console.log('hi', this.selectrmultiple)
        const optionsMultiple = {
          allowDeselect: false,
          clearable: false,
          multiple: true,
          placeholder: 'Select Coaches',
          data: this.availableCoaches
        };
        this.batchMultipleselector = new Selectr(this.selectrmultiple, optionsMultiple);
      })
  }

  loadCoachS($event: any) {
    this.onChangeStartTime = $event.target.value;
    console.log('in load', this.onChangeStartTime);
    if (this.onChangeEndTime !== undefined) {
      this.loadCoachAccounts();
    }
  }


  loadCoachE($event: any) {
    this.onChangeEndTime = $event.target.value;
    if (this.onChangeStartTime !== undefined) {
      this.loadCoachAccounts();
    }

  }
  changeTimings(event: any, value: any) {
    // console.log(event.target.checked,value,this.batchWeekDays)
    this.isBatchDaysEditing = true;
    if (event.target.checked) {
      this.batchWeekDays.push(value);
    } else {
      if (this.batchWeekDays.includes(value)) {
        this.batchWeekDays = this.batchWeekDays.filter((item: any) => item !== value)
      }
    }
  }

  onCheckLS(event: any) {
    if (event.target.checked) {
      this.isLs = false;
    } else {
      this.isLs = true;
    }
  }
  onCheckELS(event: any) {
    if (event.target.checked) {
      this.editBatchForm.controls.numberOfSessions.enable();
    } else {
      this.editBatchForm.controls.numberOfSessions.disable();
    }
  }

  next() {
    this.activeModal.close(true);
  }

}
