/* tslint:disable */

declare var Object: any;
export interface OptionInterface {
  "value": number;
  "label": string;
  "identifier": string;
  "sequence": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class Option implements OptionInterface {
  "value": number;
  "label": string;
  "identifier": string;
  "sequence": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: OptionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Option`.
   */
  public static getModelName() {
    return "Option";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Option for dynamic purposes.
  **/
  public static factory(data: OptionInterface): Option{
    return new Option(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Option',
      plural: 'Options',
      path: 'Options',
      idName: 'id',
      properties: {
        "value": {
          name: 'value',
          type: 'number'
        },
        "label": {
          name: 'label',
          type: 'string'
        },
        "identifier": {
          name: 'identifier',
          type: 'string'
        },
        "sequence": {
          name: 'sequence',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
