/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WeekAtAGlanceArea } from '../../models/WeekAtAGlanceArea';


/**
 * Api services for the `WeekAtAGlanceArea` model.
 *
 * **Details**
 *
 * This model represents week at a glance areas.
 */
@Injectable()
export class WeekAtAGlanceAreaApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `WeekAtAGlanceArea` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/WeekAtAGlanceAreas";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id WeekAtAGlanceArea id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `WeekAtAGlanceArea` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/WeekAtAGlanceAreas/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `currentMonthName` – `{string}` - 
   *
   *  - `currentWeek` – `{number}` - 
   *
   *  - `standardFormatDays` – `{any}` - 
   *
   *  - `wagAreasWithData` – `{any}` - 
   *
   *  - `wagDates` – `{any}` - 
   *
   *  - `wagSchedules` – `{any}` - 
   *
   *  - `wagSports` – `{any}` - 
   *
   *  - `weekDatesWithData` – `{any}` - 
   *
   *  - `res` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public generateAndMergePDFs(currentMonthName: any, currentWeek: any, standardFormatDays: any, wagAreasWithData: any, wagDates: any, wagSchedules: any, wagSports: any, weekDatesWithData: any, res: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/WeekAtAGlanceAreas/generate-pdfs";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        currentMonthName: currentMonthName,
        currentWeek: currentWeek,
        standardFormatDays: standardFormatDays,
        wagAreasWithData: wagAreasWithData,
        wagDates: wagDates,
        wagSchedules: wagSchedules,
        wagSports: wagSports,
        weekDatesWithData: weekDatesWithData
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `WeekAtAGlanceArea`.
   */
  public getModelName() {
    return "WeekAtAGlanceArea";
  }
}
