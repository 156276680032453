import { Component, OnInit, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import Dropzone from 'dropzone';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

Dropzone.autoDiscover = false;
@Component({
  selector: 'app-text-box-modal',
  templateUrl: 'text-box-modal.html',
  styleUrls: ['./text-box-modal.scss']
})
export class TextBoxModalComponent implements OnInit {
  @Input() formElement: any;
  @Input() edit: true;
  @Output()
  formElementEvent: EventEmitter<File> = new EventEmitter<File>();

  public formElementForm = this.fb.group({
    name: ['', [Validators.required, Validators.pattern('^(?![0-9 ]*$)[a-zA-Z0-9 ]+$')]],
    placeHolder: ['', Validators.required],
    id: [''],
    description: [''],
    maxPoints: ['', [Validators.required, Validators.pattern('^[0-9]*')]]
  });
  public formModal: BsModalRef;
  form = {
    animated: true,
    keyboard: true,
    class: 'modal-dialog-centered modal-md',
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(private modalService: BsModalService, private fb: FormBuilder) {}

  ngOnInit() {}

  openFormModal(modalForm: TemplateRef<any>) {
    // this.formElementForm.setValue({
    //   name: [''],
    //   placeHolder: [''],
    //   id:[''],
    //   description:'',
    //   maxPoints:['',]
    // });
    this.formElementForm.reset();
    this.formModal = this.modalService.show(modalForm, this.form);
    if (this.formElement) {
      // this.formElement &&
      this.formElementForm.setValue({
        name: this.formElement.name,
        placeHolder: this.formElement.placeHolder,
        id: this.formElement.id,
        description: this.formElement.description,
        maxPoints: this.formElement.maxPoints
      });
    }
  }
  get f() {
    return this.formElementForm.controls;
  }

  setFormElement() {
    this.formElementForm.markAllAsTouched();
    if (this.formElementForm.valid) {
      const formElement = this.formElementForm.value;
      this.formModal.hide();
      this.formElementEvent.emit(formElement);
    }
  }
}
