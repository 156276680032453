/* tslint:disable */
import {
  AppUser
} from '../index';

declare var Object: any;
export interface AppUserTokenInterface {
  "mobileNumber"?: string;
  "androidFirebaseToken"?: string;
  "androidTokenTimestamp"?: Date;
  "iosFirebaseToken"?: string;
  "iosTokenTimestamp"?: Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appUser?: AppUser;
}

export class AppUserToken implements AppUserTokenInterface {
  "mobileNumber": string;
  "androidFirebaseToken": string;
  "androidTokenTimestamp": Date;
  "iosFirebaseToken": string;
  "iosTokenTimestamp": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appUser: AppUser;
  constructor(data?: AppUserTokenInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppUserToken`.
   */
  public static getModelName() {
    return "AppUserToken";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppUserToken for dynamic purposes.
  **/
  public static factory(data: AppUserTokenInterface): AppUserToken{
    return new AppUserToken(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppUserToken',
      plural: 'AppUserTokens',
      path: 'AppUserTokens',
      idName: 'id',
      properties: {
        "mobileNumber": {
          name: 'mobileNumber',
          type: 'string'
        },
        "androidFirebaseToken": {
          name: 'androidFirebaseToken',
          type: 'string'
        },
        "androidTokenTimestamp": {
          name: 'androidTokenTimestamp',
          type: 'Date'
        },
        "iosFirebaseToken": {
          name: 'iosFirebaseToken',
          type: 'string'
        },
        "iosTokenTimestamp": {
          name: 'iosTokenTimestamp',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
      }
    }
  }
}
