/* tslint:disable */

declare var Object: any;
export interface SportActivityInterface {
  "year": string;
  "date": string;
  "time": string;
  "sport": number;
  "sportName": string;
  "otherSport"?: string;
  "level"?: string;
  "session": number;
  "equipment"?: string;
  "topic"?: string;
  "coachingdrill"?: string;
  "sets"?: string;
  "reflection"?: string;
  "imageorvideo"?: any;
  "attendeesType"?: string;
  "attendeeIds"?: any;
  "coaches"?: any;
  "students"?: any;
  "createdBy": number;
  "modifiedBy"?: number;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class SportActivity implements SportActivityInterface {
  "year": string;
  "date": string;
  "time": string;
  "sport": number;
  "sportName": string;
  "otherSport": string;
  "level": string;
  "session": number;
  "equipment": string;
  "topic": string;
  "coachingdrill": string;
  "sets": string;
  "reflection": string;
  "imageorvideo": any;
  "attendeesType": string;
  "attendeeIds": any;
  "coaches": any;
  "students": any;
  "createdBy": number;
  "modifiedBy": number;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: SportActivityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SportActivity`.
   */
  public static getModelName() {
    return "SportActivity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SportActivity for dynamic purposes.
  **/
  public static factory(data: SportActivityInterface): SportActivity{
    return new SportActivity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SportActivity',
      plural: 'SportActivity',
      path: 'SportActivity',
      idName: 'id',
      properties: {
        "year": {
          name: 'year',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'string'
        },
        "time": {
          name: 'time',
          type: 'string'
        },
        "sport": {
          name: 'sport',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "otherSport": {
          name: 'otherSport',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "session": {
          name: 'session',
          type: 'number'
        },
        "equipment": {
          name: 'equipment',
          type: 'string'
        },
        "topic": {
          name: 'topic',
          type: 'string'
        },
        "coachingdrill": {
          name: 'coachingdrill',
          type: 'string'
        },
        "sets": {
          name: 'sets',
          type: 'string'
        },
        "reflection": {
          name: 'reflection',
          type: 'string'
        },
        "imageorvideo": {
          name: 'imageorvideo',
          type: 'any'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'string'
        },
        "attendeeIds": {
          name: 'attendeeIds',
          type: 'any'
        },
        "coaches": {
          name: 'coaches',
          type: 'any'
        },
        "students": {
          name: 'students',
          type: 'any'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
