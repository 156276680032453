import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PresentationComponent } from './presentation.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PresentationRoutingModule } from './presentation-routing.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { SliderModule } from 'angular-image-slider';
import { HttpClient,HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [PresentationComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TooltipModule.forRoot(),
     BsDropdownModule.forRoot(), CollapseModule.forRoot(),
     ModalModule.forRoot(),PresentationRoutingModule, CarouselModule, SliderModule,
     HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })]
})
export class PresentationModule {}