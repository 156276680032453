import { createReducer, on } from '@ngrx/store';
import { initialState } from './translate.state';
import { changeLanguage } from './translate.action';

const _translateReducer = createReducer(
    initialState,
    on(changeLanguage, (state, { language }) => {
        localStorage.setItem('selectedLanguage', language);
        return {
            ...state,
            language
        }
    })
);

export function translateReducer(state, action) {
    return _translateReducer(state, action);
}