import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountInfrastructureApi, AppUserApi } from 'src/app/shared/sdk';

@Component({
  selector: 'app-f-dashboard-welcome',
  templateUrl: './f-dashboard-welcome.component.html',
  styleUrls: ['./f-dashboard-welcome.component.scss']
})
export class FDashboardWelcomeComponent implements OnInit {
  public onBoardingCount: any;

  public stepsArray = [
    {
      number: 1,
      step: 'courseCount',
      label: 'Step 1',
      title: 'Create a Course',
      description: 'Create a Course - A course is a program that is associated to a sport and skill level.',
      active: true
    },
    {
      number: 2,
      step: 'centerCount',
      label: 'Step 2',
      title: 'Create a Center',
      description: 'Create a Center - A center is the location where your coaching takes place.',
      active: true
    },
    {
      number: 3,
      step: 'batchCount',
      label: 'Step 3',
      title: 'Create a Batch',
      description: 'Create a Batch - A batch is a different schedule for a particular program. Ex: Morning batch/Evening batch',
      active: true
    },
    {
      number: 4,
      step: 'coachCount',
      label: 'Step 4',
      title: 'update Coach',
      description: 'update a Coach - Associate this coach to a course',
      active: true
    },
    {
      number: 5,
      step: 'feePlanCount',
      label: 'Step 5',
      title: 'Create a Fee Plan',
      description: 'Create a Fee Plan and map it to a course',
      active: true
    },
    {
      number: 6,
      step: 'studentCount',
      label: 'Step 6',
      title: 'Create a Student',
      description: 'Create a Student - Add new students and sign them up for a course',
      active: true
    }
  ];

  public stepsResult: any;
  constructor(
    public activeModal: NgbActiveModal,
    private appUserApi: AppUserApi,
    private accountInfrastructure: AccountInfrastructureApi
  ) {}

  ngOnInit(): void {
    this.getNewAcademicRegistrationDetails();
  }

  async getNewAcademicRegistrationDetails() {
    this.appUserApi
      .onBoardingCount()
      .toPromise()
      .then((response: any) => {
        const counts = response[0];
        // const counts = {
        //   courseCount: 1,
        //   centerCount: 1,
        //   feePlanCount: 1,
        //   batchCount: 1,
        //   coachCount: 0,
        //   studentCount: 0
        // };
        // const modalComponentsArr = this.stepsArray.filter(component => counts[component.step] === 0);
        // Disable the steps which are 0
        this.stepsArray.map((element) => {
          if (counts[element.step] === 0) {
            return (element.active = false);
          }
        });

        this.stepsResult = this.stepsArray;
        this.onBoardingCount = response;
      });

    const accountInfra = await this.accountInfrastructure.find().toPromise();
    if (accountInfra?.length <= 0 && this.onBoardingCount && this.onBoardingCount?.length > 0) {
      this.onBoardingCount[0].coachCount = 0;

      this.onBoardingCount = this.onBoardingCount;
      this.stepsArray.map((element) => {
        if (this.onBoardingCount[0][element.step] === 0) {
          return (element.active = false);
        }
      });
      this.stepsResult = this.stepsArray;
      console.log('on board count --->', this.onBoardingCount);
    }
  }

  continue() {
    this.activeModal.close(true);
  }
}
