/* tslint:disable */
import {
  Account,
  Sport
} from '../index';

declare var Object: any;
export interface AssessmentParameterInterface {
  "name": string;
  "description": string;
  "areas": string;
  "comments"?: string;
  "scoreLabel"?: string;
  "scoreType"?: string;
  "level"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sport_id"?: number;
  account?: Account;
  sport?: Sport;
}

export class AssessmentParameter implements AssessmentParameterInterface {
  "name": string;
  "description": string;
  "areas": string;
  "comments": string;
  "scoreLabel": string;
  "scoreType": string;
  "level": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sport_id": number;
  account: Account;
  sport: Sport;
  constructor(data?: AssessmentParameterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentParameter`.
   */
  public static getModelName() {
    return "AssessmentParameter";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentParameter for dynamic purposes.
  **/
  public static factory(data: AssessmentParameterInterface): AssessmentParameter{
    return new AssessmentParameter(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentParameter',
      plural: 'AssessmentParameters',
      path: 'AssessmentParameters',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "areas": {
          name: 'areas',
          type: 'string'
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "scoreLabel": {
          name: 'scoreLabel',
          type: 'string'
        },
        "scoreType": {
          name: 'scoreType',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "sport_id": {
          name: 'sport_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        sport: {
          name: 'sport',
          type: 'Sport',
          model: 'Sport',
          relationType: 'belongsTo',
                  keyFrom: 'sport_id',
          keyTo: 'id'
        },
      }
    }
  }
}
