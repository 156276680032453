/* tslint:disable */
import {
  AppRole,
  Account,
  UserRoleScope
} from '../index';

declare var Object: any;
export interface AppUserInterface {
  "email"?: string;
  "mobileNumber"?: string;
  "password"?: string;
  "passwordExpiry"?: Date;
  "otp"?: number;
  "otpRetryCount"?: number;
  "otpExpiry"?: Date;
  "loginRetryCount": number;
  "lastLogindate"?: Date;
  "appUserStatus": number;
  "forcePasswordChange": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appRoles?: AppRole[];
  accessTokens?: any;
  accounts?: Account[];
  userRoleScopes?: UserRoleScope[];
}

export class AppUser implements AppUserInterface {
  "email": string;
  "mobileNumber": string;
  "password": string;
  "passwordExpiry": Date;
  "otp": number;
  "otpRetryCount": number;
  "otpExpiry": Date;
  "loginRetryCount": number;
  "lastLogindate": Date;
  "appUserStatus": number;
  "forcePasswordChange": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appRoles: AppRole[];
  accessTokens: any;
  accounts: Account[];
  userRoleScopes: UserRoleScope[];
  constructor(data?: AppUserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppUser`.
   */
  public static getModelName() {
    return "AppUser";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppUser for dynamic purposes.
  **/
  public static factory(data: AppUserInterface): AppUser{
    return new AppUser(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppUser',
      plural: 'AppUsers',
      path: 'AppUsers',
      idName: 'id',
      properties: {
        "email": {
          name: 'email',
          type: 'string'
        },
        "mobileNumber": {
          name: 'mobileNumber',
          type: 'string'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
        "passwordExpiry": {
          name: 'passwordExpiry',
          type: 'Date'
        },
        "otp": {
          name: 'otp',
          type: 'number'
        },
        "otpRetryCount": {
          name: 'otpRetryCount',
          type: 'number',
          default: 0
        },
        "otpExpiry": {
          name: 'otpExpiry',
          type: 'Date'
        },
        "loginRetryCount": {
          name: 'loginRetryCount',
          type: 'number',
          default: 0
        },
        "lastLogindate": {
          name: 'lastLogindate',
          type: 'Date'
        },
        "appUserStatus": {
          name: 'appUserStatus',
          type: 'number'
        },
        "forcePasswordChange": {
          name: 'forcePasswordChange',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        appRoles: {
          name: 'appRoles',
          type: 'AppRole[]',
          model: 'AppRole',
          relationType: 'hasMany',
          modelThrough: 'AppUserRole',
          keyThrough: 'app_role_id',
          keyFrom: 'id',
          keyTo: 'app_user_id'
        },
        accessTokens: {
          name: 'accessTokens',
          type: 'any',
          model: '',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'app_user_id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'AccountUser',
          keyThrough: 'account_id',
          keyFrom: 'id',
          keyTo: 'app_user_id'
        },
        userRoleScopes: {
          name: 'userRoleScopes',
          type: 'UserRoleScope[]',
          model: 'UserRoleScope',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'app_user_id'
        },
      }
    }
  }
}
