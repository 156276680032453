/* tslint:disable */
import {
  AppUser,
  AppRole
} from '../index';

declare var Object: any;
export interface UserRoleScopeInterface {
  "scope"?: Array<any>;
  "authorizedToLogin": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_user_id"?: number;
  "app_role_id"?: number;
  user?: AppUser;
  userRole?: AppRole;
}

export class UserRoleScope implements UserRoleScopeInterface {
  "scope": Array<any>;
  "authorizedToLogin": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_user_id": number;
  "app_role_id": number;
  user: AppUser;
  userRole: AppRole;
  constructor(data?: UserRoleScopeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserRoleScope`.
   */
  public static getModelName() {
    return "UserRoleScope";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserRoleScope for dynamic purposes.
  **/
  public static factory(data: UserRoleScopeInterface): UserRoleScope{
    return new UserRoleScope(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserRoleScope',
      plural: 'UserRoleScopes',
      path: 'UserRoleScopes',
      idName: 'id',
      properties: {
        "scope": {
          name: 'scope',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "authorizedToLogin": {
          name: 'authorizedToLogin',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "app_user_id": {
          name: 'app_user_id',
          type: 'number'
        },
        "app_role_id": {
          name: 'app_role_id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
        userRole: {
          name: 'userRole',
          type: 'AppRole',
          model: 'AppRole',
          relationType: 'belongsTo',
                  keyFrom: 'app_role_id',
          keyTo: 'id'
        },
      }
    }
  }
}
