/* tslint:disable */

declare var Object: any;
export interface MicroPlannerInterface {
  "sportId": number;
  "sportName": string;
  "otherSport"?: string;
  "level"?: string;
  "areaId": number;
  "areaName": string;
  "otherArea"?: string;
  "season": string;
  "months": string;
  "loadingPhase": string;
  "deloadingPhase": string;
  "totalLoad": string;
  "phase": string;
  "intensity": string;
  "volume": string;
  "coaches"?: string;
  "students"?: string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy"?: number;
  "modifiedByName"?: string;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class MicroPlanner implements MicroPlannerInterface {
  "sportId": number;
  "sportName": string;
  "otherSport": string;
  "level": string;
  "areaId": number;
  "areaName": string;
  "otherArea": string;
  "season": string;
  "months": string;
  "loadingPhase": string;
  "deloadingPhase": string;
  "totalLoad": string;
  "phase": string;
  "intensity": string;
  "volume": string;
  "coaches": string;
  "students": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy": number;
  "modifiedByName": string;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: MicroPlannerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MicroPlanner`.
   */
  public static getModelName() {
    return "MicroPlanner";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MicroPlanner for dynamic purposes.
  **/
  public static factory(data: MicroPlannerInterface): MicroPlanner{
    return new MicroPlanner(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MicroPlanner',
      plural: 'MicroPlanners',
      path: 'MicroPlanners',
      idName: 'id',
      properties: {
        "sportId": {
          name: 'sportId',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "otherSport": {
          name: 'otherSport',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "areaId": {
          name: 'areaId',
          type: 'number'
        },
        "areaName": {
          name: 'areaName',
          type: 'string'
        },
        "otherArea": {
          name: 'otherArea',
          type: 'string'
        },
        "season": {
          name: 'season',
          type: 'string'
        },
        "months": {
          name: 'months',
          type: 'string'
        },
        "loadingPhase": {
          name: 'loadingPhase',
          type: 'string'
        },
        "deloadingPhase": {
          name: 'deloadingPhase',
          type: 'string'
        },
        "totalLoad": {
          name: 'totalLoad',
          type: 'string'
        },
        "phase": {
          name: 'phase',
          type: 'string'
        },
        "intensity": {
          name: 'intensity',
          type: 'string'
        },
        "volume": {
          name: 'volume',
          type: 'string'
        },
        "coaches": {
          name: 'coaches',
          type: 'string'
        },
        "students": {
          name: 'students',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "modifiedByName": {
          name: 'modifiedByName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
