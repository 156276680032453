/* tslint:disable */
import {
  Account,
  Batch,
  RankingClassification
} from '../index';

declare var Object: any;
export interface RankingInterface {
  "classification": string;
  "category": string;
  "position"?: string;
  "rank": number;
  "overallRating"?: number;
  "points": number;
  "rankStatus"?: boolean;
  "pointsStatus"?: boolean;
  "date": Date;
  "status": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "ranking_classification_id"?: number;
  account?: Account;
  batch?: Batch;
  rankingClassification?: RankingClassification;
}

export class Ranking implements RankingInterface {
  "classification": string;
  "category": string;
  "position": string;
  "rank": number;
  "overallRating": number;
  "points": number;
  "rankStatus": boolean;
  "pointsStatus": boolean;
  "date": Date;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "ranking_classification_id": number;
  account: Account;
  batch: Batch;
  rankingClassification: RankingClassification;
  constructor(data?: RankingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Ranking`.
   */
  public static getModelName() {
    return "Ranking";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Ranking for dynamic purposes.
  **/
  public static factory(data: RankingInterface): Ranking{
    return new Ranking(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Ranking',
      plural: 'Rankings',
      path: 'Rankings',
      idName: 'id',
      properties: {
        "classification": {
          name: 'classification',
          type: 'string'
        },
        "category": {
          name: 'category',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'string'
        },
        "rank": {
          name: 'rank',
          type: 'number'
        },
        "overallRating": {
          name: 'overallRating',
          type: 'number'
        },
        "points": {
          name: 'points',
          type: 'number'
        },
        "rankStatus": {
          name: 'rankStatus',
          type: 'boolean'
        },
        "pointsStatus": {
          name: 'pointsStatus',
          type: 'boolean'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "ranking_classification_id": {
          name: 'ranking_classification_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        batch: {
          name: 'batch',
          type: 'Batch',
          model: 'Batch',
          relationType: 'belongsTo',
                  keyFrom: 'batch_id',
          keyTo: 'id'
        },
        rankingClassification: {
          name: 'rankingClassification',
          type: 'RankingClassification',
          model: 'RankingClassification',
          relationType: 'belongsTo',
                  keyFrom: 'ranking_classification_id',
          keyTo: 'id'
        },
      }
    }
  }
}
