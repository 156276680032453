/* tslint:disable */
import {
  AssessmentSection,
  Account,
  AppFile
} from '../index';

declare var Object: any;
export interface AssessmentKpiInterface {
  "name": string;
  "description": string;
  "type": number;
  "maxMarks": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_section_id"?: number;
  "account_id"?: number;
  "app_file_id"?: number;
  assessmentSection?: AssessmentSection;
  owner?: Account;
  appFile?: AppFile;
}

export class AssessmentKpi implements AssessmentKpiInterface {
  "name": string;
  "description": string;
  "type": number;
  "maxMarks": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_section_id": number;
  "account_id": number;
  "app_file_id": number;
  assessmentSection: AssessmentSection;
  owner: Account;
  appFile: AppFile;
  constructor(data?: AssessmentKpiInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentKpi`.
   */
  public static getModelName() {
    return "AssessmentKpi";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentKpi for dynamic purposes.
  **/
  public static factory(data: AssessmentKpiInterface): AssessmentKpi{
    return new AssessmentKpi(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentKpi',
      plural: 'AssessmentKpis',
      path: 'AssessmentKpis',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "maxMarks": {
          name: 'maxMarks',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "assessment_section_id": {
          name: 'assessment_section_id',
          type: 'number'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
      },
      relations: {
        assessmentSection: {
          name: 'assessmentSection',
          type: 'AssessmentSection',
          model: 'AssessmentSection',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_section_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        appFile: {
          name: 'appFile',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
