import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AppRoleApi,
} from 'src/app/shared/sdk';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  selectedUserRoleScopes: any = [];
  fetchRolesResponse: any = [];
  scopeListData: any[] = [];
  roleType = '';
  message = '';
  isEnable = false;
  constructor(
    private activeModal: NgbActiveModal,
    private appRoleApi: AppRoleApi,
  ) { }

  ngOnInit(): void {
  }

  addRole(role: NgForm) {

    this.selectedUserRoleScopes = [];

    if (this.fetchRolesResponse !== undefined) {
      this.fetchRolesResponse.map((role1: any, index: any) => {

        this.selectedUserRoleScopes.push({ name: role1.name, description: role1.description, scope: [] });

        role1.scope.map((scope: any) => {
          for (const key in scope.scopes) {
            if (this.scopeListData.includes(key)) {
              this.selectedUserRoleScopes[index].scope.push(key);
            }
          }
        });
      });
    }

    this.appRoleApi.create({
      name: role.value.roleName,
      type: this.roleType,
      scope: this.selectedUserRoleScopes,
      description: role.value.description,
      isActive: true
    }).toPromise()
      .then(() => {
        swal
          .fire({
            text: 'Role Added successfully',
            type: 'success',
            confirmButtonClass: 'btn btn-sm btn-danger',
            buttonsStyling: false
          })
          .then((result) => {
            this.activeModal.close(true);
          });
      })
      .catch((err) => {
        this.message = err.message;
        swal.fire({
          text: this.message,
          type: 'error',
          confirmButtonClass: 'btn btn-sm btn-danger',
          buttonsStyling: false
        }).then((result) => {
          this.activeModal.close('error');
        });
      });
  }

  setroleType(event) {
    console.log(event);
    this.roleType = event;
    if (event === 'center') {
      this.isEnable = true;
    } else {
      this.isEnable = false;
    }
  }

  changeAccess(event, scope) {
    if (event) {
      this.scopeListData.push(scope);
    } else {
      this.scopeListData.slice(scope);
    }
  }

}
