/* tslint:disable */
import {
  Sport,
  Account
} from '../index';

declare var Object: any;
export interface TeamReportInterface {
  "teamName": string;
  "gender": string;
  "ageGroup": string;
  "year": string;
  "playedCount": number;
  "won": number;
  "lost": number;
  "draw": number;
  "winningPercentage": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sportId"?: number;
  "created_by_id"?: number;
  sport?: Sport;
  createdBy?: Account;
}

export class TeamReport implements TeamReportInterface {
  "teamName": string;
  "gender": string;
  "ageGroup": string;
  "year": string;
  "playedCount": number;
  "won": number;
  "lost": number;
  "draw": number;
  "winningPercentage": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sportId": number;
  "created_by_id": number;
  sport: Sport;
  createdBy: Account;
  constructor(data?: TeamReportInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TeamReport`.
   */
  public static getModelName() {
    return "TeamReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TeamReport for dynamic purposes.
  **/
  public static factory(data: TeamReportInterface): TeamReport{
    return new TeamReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TeamReport',
      plural: 'TeamReports',
      path: 'TeamReports',
      idName: 'id',
      properties: {
        "teamName": {
          name: 'teamName',
          type: 'string'
        },
        "gender": {
          name: 'gender',
          type: 'string'
        },
        "ageGroup": {
          name: 'ageGroup',
          type: 'string'
        },
        "year": {
          name: 'year',
          type: 'string'
        },
        "playedCount": {
          name: 'playedCount',
          type: 'number'
        },
        "won": {
          name: 'won',
          type: 'number'
        },
        "lost": {
          name: 'lost',
          type: 'number'
        },
        "draw": {
          name: 'draw',
          type: 'number'
        },
        "winningPercentage": {
          name: 'winningPercentage',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "sportId": {
          name: 'sportId',
          type: 'number'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
      },
      relations: {
        sport: {
          name: 'sport',
          type: 'Sport',
          model: 'Sport',
          relationType: 'belongsTo',
                  keyFrom: 'sportId',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
