/* tslint:disable */

declare var Object: any;
export interface SystematicMethodInterface {
  "frequency": string;
  "paymentMethod": string;
  "amount": string;
  "status": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class SystematicMethod implements SystematicMethodInterface {
  "frequency": string;
  "paymentMethod": string;
  "amount": string;
  "status": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: SystematicMethodInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SystematicMethod`.
   */
  public static getModelName() {
    return "SystematicMethod";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SystematicMethod for dynamic purposes.
  **/
  public static factory(data: SystematicMethodInterface): SystematicMethod{
    return new SystematicMethod(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SystematicMethod',
      plural: 'SystematicMethods',
      path: 'SystematicMethods',
      idName: 'id',
      properties: {
        "frequency": {
          name: 'frequency',
          type: 'string'
        },
        "paymentMethod": {
          name: 'paymentMethod',
          type: 'string'
        },
        "amount": {
          name: 'amount',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
