/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attendance } from '../../models/Attendance';
import { Account } from '../../models/Account';
import { Batch } from '../../models/Batch';
import { Infrastructure } from '../../models/Infrastructure';


/**
 * Api services for the `Attendance` model.
 *
 * **Details**
 *
 * This model represents attendance of the user. It maps between the account and batch
 */
@Injectable()
export class AttendanceApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Fetches belongsTo relation account.
   *
   * @param {any} id Attendance id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public getAccount(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/:id/account";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation createdBy.
   *
   * @param {any} id Attendance id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public getCreatedBy(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/:id/createdBy";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation batch.
   *
   * @param {any} id Attendance id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public getBatch(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/:id/batch";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches belongsTo relation center.
   *
   * @param {any} id Attendance id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public getCenter(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/:id/center";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id Attendance id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for fetching attendance from batch
   *
   * @param {number} batchId 
   *
   * @param {any} accountIds 
   *
   * @param {number} month 
   *
   * @param {number} year 
   *
   * @param {object} req 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public fetchAttendanceByBatchId(batchId: any, accountIds: any = {}, month: any = {}, year: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/fetchAttendanceByBatchId";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof batchId !== 'undefined' && batchId !== null) _urlParams.batchId = batchId;
    if (typeof accountIds !== 'undefined' && accountIds !== null) _urlParams.accountIds = accountIds;
    if (typeof month !== 'undefined' && month !== null) _urlParams.month = month;
    if (typeof year !== 'undefined' && year !== null) _urlParams.year = year;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for getting attendance days from batch
   *
   * @param {number} batchId 
   *
   * @param {number} accountId 
   *
   * @param {number} month 
   *
   * @param {number} year 
   *
   * @param {object} req 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public getPresentAbsentByBatchId(batchId: any, accountId: any, month: any = {}, year: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/getPresentAbsentByBatchId";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof batchId !== 'undefined' && batchId !== null) _urlParams.batchId = batchId;
    if (typeof accountId !== 'undefined' && accountId !== null) _urlParams.accountId = accountId;
    if (typeof month !== 'undefined' && month !== null) _urlParams.month = month;
    if (typeof year !== 'undefined' && year !== null) _urlParams.year = year;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for fetching user Attendance Result
   *
   * @param {number} accountId 
   *
   * @param {number} infrastructureId 
   *
   * @param {string} filter 
   *
   * @param {object} req 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public fetchAttendanceByAccountId(accountId: any, infrastructureId: any, filter: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/:accountId/:infrastructureId/fetchAttendanceByAccountId";
    let _routeParams: any = {
      accountId: accountId,
      infrastructureId: infrastructureId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to create Posts
   *
   * @param {object} data Request data.
   *
   *  - `type` – `{number}` - 
   *
   *  - `date` – `{date}` - 
   *
   *  - `accountIds` – `{any}` - 
   *
   *  - `centerId` – `{number}` - 
   *
   *  - `batchId` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public createBulkAttendance(type: any, date: any, accountIds: any, centerId: any, batchId: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/createBulkAttendance";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        type: type,
        date: date,
        accountIds: accountIds,
        centerId: centerId,
        batchId: batchId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to change bulk attendance
   *
   * @param {object} data Request data.
   *
   *  - `accountIds` – `{any}` - 
   *
   *  - `date` – `{date}` - 
   *
   *  - `type` – `{number}` - 
   *
   *  - `batchId` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public updateBulkAttendance(accountIds: any, date: any, type: any, batchId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/updateBulkAttendance";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        accountIds: accountIds,
        date: date,
        type: type,
        batchId: batchId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to mark Attendance
   *
   * @param {object} data Request data.
   *
   *  - `type` – `{number}` - 
   *
   *  - `date` – `{date}` - 
   *
   *  - `accountId` – `{number}` - 
   *
   *  - `centerId` – `{number}` - 
   *
   *  - `batchId` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public markAttendance(type: any, date: any, accountId: any, centerId: any, batchId: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/markAttendance";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        type: type,
        date: date,
        accountId: accountId,
        centerId: centerId,
        batchId: batchId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to Mark Session Attendance
   *
   * @param {object} data Request data.
   *
   *  - `type` – `{number}` - 
   *
   *  - `date` – `{date}` - 
   *
   *  - `accountCenterBatchIds` – `{any}` - 
   *
   *  - `accountIds` – `{any}` - 
   *
   *  - `sessionId` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public createSessionAttendance(type: any, date: any, accountCenterBatchIds: any, accountIds: any, sessionId: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/createSessionAttendance";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        type: type,
        date: date,
        accountCenterBatchIds: accountCenterBatchIds,
        accountIds: accountIds,
        sessionId: sessionId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to change Session Attendance
   *
   * @param {object} data Request data.
   *
   *  - `accountIds` – `{any}` - 
   *
   *  - `date` – `{date}` - 
   *
   *  - `type` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public updateSessionAttendance(accountIds: any, date: any, type: any, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/updateSessionAttendance";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        accountIds: accountIds,
        date: date,
        type: type
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to mark coach Attendance
   *
   * @param {object} data Request data.
   *
   *  - `type` – `{number}` - 
   *
   *  - `date` – `{date}` - 
   *
   *  - `accountId` – `{number}` - 
   *
   *  - `centerId` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Attendance` object.)
   * </em>
   */
  public markCoachAttendance(type: any, date: any, accountId: any, centerId: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Attendances/markCoachAttendance";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        type: type,
        date: date,
        accountId: accountId,
        centerId: centerId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Attendance`.
   */
  public getModelName() {
    return "Attendance";
  }
}
