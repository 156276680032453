import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-successpayment',
  templateUrl: 'successpayment.component.html',
  styleUrls: ['./successpayment.component.scss']
})
export class SuccessPaymentComponent implements OnInit {
  paymentMode: string = 'Online Payment';
  transactionReferenceNumber: string = 'WCIT6447594408';
  orderId: string = '12345';
  transactionAmount: number = 10000.00;
  transactionStatus: string = 'Success';
  purposeOfPayment: string = 'one time payment for badminton';
  transactionDateTime: Date = new Date();
  statusDesc: string = '';
  merchantLogo: string = '../../../assets/img/academy-logo/success.png';
  brandLogo:any;
  orgName:any="";
  subdomain:any;
  clientUrl=environment.baseUrl;

  constructor(private route: ActivatedRoute,private http:HttpClient) { }

  ngOnInit() { 
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.transactionReferenceNumber = params['transactionId']?params['transactionId']:'';
      this.orderId=params['orderId']?params['orderId']:'';
      this.transactionAmount=params['amount']?params['amount']:'';
      this.transactionDateTime=params['date']?params['date']:'';
      this.statusDesc=params['desc']?params['desc']:'';
      this.subdomain=params['tenant']
    });
    console.log('ddd',this.subdomain)
    let headers = {"headers" : {"version": '1.0.0' ,"source":'app',"x-sub-domain":this.subdomain}};
    let queryParamsData: any = {
      "include":
      {
        relation: "organizationLogoFile",                                      
      }
    };
    queryParamsData = JSON.stringify(queryParamsData);
    this.http.get(this.clientUrl + '/api/BrandingPreferences?filter=' + queryParamsData,headers).toPromise().then((response: any) => { 
      this.brandLogo = response.length>0?response[0].organizationLogoFile.location:'';
    });
    this.http.get(this.clientUrl + '/api/Organizations',headers).toPromise().then((orgData: any) => {
      this.orgName = orgData.length > 0 ? orgData[0].name : '';
    }); 
  }    
}
