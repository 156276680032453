// translate.service.ts
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TranslateAppService {
    constructor(private translate: TranslateService) {
        const selectedLanguage = localStorage.getItem('selectedLanguage');
        if (selectedLanguage) {
            this.translate.setDefaultLang(selectedLanguage);
            this.translate.use(selectedLanguage);
        } else {
            this.translate.setDefaultLang('en');
            this.translate.use('en');
        }
    }

    translateKey(key: string): string {
        return this.translate.instant(key);
    }
}
