import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../class/constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class StaffGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      if (this.authService.accountValidator('Staff')) {
        return true;
      } else {
        this.router.navigate([Constants.PATHS.ACCOUNTSWITCHER]);
        return false;
      }
    } else {
      this.router.navigate([Constants.PATHS.LOGIN]);
      return false;
    }
  }
}
