/* tslint:disable */
import {
  WorkoutPlanSchedule
} from '../index';

declare var Object: any;
export interface WorkoutPlanAttendeeInterface {
  "attendeesId"?: number;
  "attendeesType"?: string;
  "accountId": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "workout_plan_schedule_id"?: number;
  workoutPlanSchedule?: WorkoutPlanSchedule;
}

export class WorkoutPlanAttendee implements WorkoutPlanAttendeeInterface {
  "attendeesId": number;
  "attendeesType": string;
  "accountId": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "workout_plan_schedule_id": number;
  workoutPlanSchedule: WorkoutPlanSchedule;
  constructor(data?: WorkoutPlanAttendeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkoutPlanAttendee`.
   */
  public static getModelName() {
    return "WorkoutPlanAttendee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkoutPlanAttendee for dynamic purposes.
  **/
  public static factory(data: WorkoutPlanAttendeeInterface): WorkoutPlanAttendee{
    return new WorkoutPlanAttendee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkoutPlanAttendee',
      plural: 'WorkoutPlanAttendees',
      path: 'WorkoutPlanAttendees',
      idName: 'id',
      properties: {
        "attendeesId": {
          name: 'attendeesId',
          type: 'number'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'string'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "workout_plan_schedule_id": {
          name: 'workout_plan_schedule_id',
          type: 'number'
        },
      },
      relations: {
        workoutPlanSchedule: {
          name: 'workoutPlanSchedule',
          type: 'WorkoutPlanSchedule',
          model: 'WorkoutPlanSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'workout_plan_schedule_id',
          keyTo: 'id'
        },
      }
    }
  }
}
