/* tslint:disable */
import { Injectable } from '@angular/core';
import { Option } from '../../models/Option';
import { AppUser } from '../../models/AppUser';
import { AppRole } from '../../models/AppRole';
import { AppUserRole } from '../../models/AppUserRole';
import { Country } from '../../models/Country';
import { State } from '../../models/State';
import { City } from '../../models/City';
import { Address } from '../../models/Address';
import { Account } from '../../models/Account';
import { AppFile } from '../../models/AppFile';
import { AccountDetail } from '../../models/AccountDetail';
import { Organization } from '../../models/Organization';
import { Sport } from '../../models/Sport';
import { Course } from '../../models/Course';
import { Infrastructure } from '../../models/Infrastructure';
import { Amenity } from '../../models/Amenity';
import { Batch } from '../../models/Batch';
import { BulkUpload } from '../../models/BulkUpload';
import { Guardian } from '../../models/Guardian';
import { CourseInfrastructure } from '../../models/CourseInfrastructure';
import { Excercise } from '../../models/Excercise';
import { ExcercisesAttendee } from '../../models/ExcercisesAttendee';
import { Maintenance } from '../../models/Maintenance';
import { Attendance } from '../../models/Attendance';
import { Calendar } from '../../models/Calendar';
import { Form } from '../../models/Form';
import { FormElement } from '../../models/FormElement';
import { Event } from '../../models/Event';
import { Interest } from '../../models/Interest';
import { Post } from '../../models/Post';
import { Tag } from '../../models/Tag';
import { FormSubmission } from '../../models/FormSubmission';
import { Procurement } from '../../models/Procurement';
import { Rating } from '../../models/Rating';
import { AccountBatch } from '../../models/AccountBatch';
import { AccountInfrastructure } from '../../models/AccountInfrastructure';
import { Product } from '../../models/Product';
import { Cart } from '../../models/Cart';
import { FeePlan } from '../../models/FeePlan';
import { FeeSubscription } from '../../models/FeeSubscription';
import { FeeInstallment } from '../../models/FeeInstallment';
import { MapFeeplanCourse } from '../../models/MapFeeplanCourse';
import { Order } from '../../models/Order';
import { CartItem } from '../../models/CartItem';
import { Transaction } from '../../models/Transaction';
import { SystematicMethod } from '../../models/SystematicMethod';
import { Pricebook } from '../../models/Pricebook';
import { Assessment } from '../../models/Assessment';
import { AssessmentParameter } from '../../models/AssessmentParameter';
import { AssessmentSection } from '../../models/AssessmentSection';
import { AssessmentKpi } from '../../models/AssessmentKpi';
import { AssessmentSubmission } from '../../models/AssessmentSubmission';
import { RankingClassification } from '../../models/RankingClassification';
import { Ranking } from '../../models/Ranking';
import { BatchTiming } from '../../models/BatchTiming';
import { AccountUser } from '../../models/AccountUser';
import { ChequeTracker } from '../../models/ChequeTracker';
import { UtrTracker } from '../../models/UtrTracker';
import { CoachingDrill } from '../../models/CoachingDrill';
import { LessonPlan } from '../../models/LessonPlan';
import { Dashboard } from '../../models/Dashboard';
import { Certification } from '../../models/Certification';
import { Injury } from '../../models/Injury';
import { Payment } from '../../models/Payment';
import { ActivityTracker } from '../../models/ActivityTracker';
import { Session } from '../../models/Session';
import { AccountSession } from '../../models/AccountSession';
import { VideoSession } from '../../models/VideoSession';
import { VideoSessionAttendees } from '../../models/VideoSessionAttendees';
import { Message } from '../../models/Message';
import { Test } from '../../models/Test';
import { TestAttendee } from '../../models/TestAttendee';
import { CertificationAttendee } from '../../models/CertificationAttendee';
import { Recurring } from '../../models/Recurring';
import { FixedTimeDuration } from '../../models/FixedTimeDuration';
import { WorkoutPlan } from '../../models/WorkoutPlan';
import { WorkoutPlanSchedule } from '../../models/WorkoutPlanSchedule';
import { WorkoutPlanAttendee } from '../../models/WorkoutPlanAttendee';
import { AssessmentTest } from '../../models/AssessmentTest';
import { KpiFieldsMaster } from '../../models/KpiFieldsMaster';
import { TestSchedule } from '../../models/TestSchedule';
import { CoachingPlan } from '../../models/CoachingPlan';
import { CoachingSchedule } from '../../models/CoachingSchedule';
import { CoachingDrillAttendee } from '../../models/CoachingDrillAttendee';
import { CertificationResult } from '../../models/CertificationResult';
import { CoachingScheduleAttendee } from '../../models/CoachingScheduleAttendee';
import { MapWorkoutPlanSchedule } from '../../models/MapWorkoutPlanSchedule';
import { StudentTag } from '../../models/StudentTag';
import { MapStudentTag } from '../../models/MapStudentTag';
import { MapWorkoutStudent } from '../../models/MapWorkoutStudent';
import { MapCoachingDrillSchedule } from '../../models/MapCoachingDrillSchedule';
import { MapCoachingScheduleStudent } from '../../models/MapCoachingScheduleStudent';
import { ProcurementItem } from '../../models/ProcurementItem';
import { MapCertificationStudent } from '../../models/MapCertificationStudent';
import { AccountCourse } from '../../models/AccountCourse';
import { AccountBatchtiming } from '../../models/AccountBatchtiming';
import { AppUserToken } from '../../models/AppUserToken';
import { BellNotification } from '../../models/BellNotification';
import { TermsAndConditions } from '../../models/TermsAndConditions';
import { UserRoleScope } from '../../models/UserRoleScope';
import { FeeSetting } from '../../models/FeeSetting';
import { MapAssessmentParameter } from '../../models/MapAssessmentParameter';
import { ScheduleTest } from '../../models/ScheduleTest';
import { ScheduleTestAttendance } from '../../models/ScheduleTestAttendance';
import { MappingScheduleTestAssessment } from '../../models/MappingScheduleTestAssessment';
import { AccountAssessmentParameterResult } from '../../models/AccountAssessmentParameterResult';
import { AssessmentResult } from '../../models/AssessmentResult';
import { CoachEvaluationParameter } from '../../models/CoachEvaluationParameter';
import { WeekAtAGlanceArea } from '../../models/WeekAtAGlanceArea';
import { ActivityRequest } from '../../models/ActivityRequest';
import { CoachGoal } from '../../models/CoachGoal';
import { YearlySchedule } from '../../models/YearlySchedule';
import { SportsPsychologyTracking } from '../../models/SportsPsychologyTracking';
import { MusculoskeletalScreening } from '../../models/MusculoskeletalScreening';
import { FunctionalMovementScreening } from '../../models/FunctionalMovementScreening';
import { SessionPlan } from '../../models/SessionPlan';
import { MesoPlanner } from '../../models/MesoPlanner';
import { NewMesoPlanner } from '../../models/NewMesoPlanner';
import { NewMacroPlanner } from '../../models/NewMacroPlanner';
import { WorkInProgress } from '../../models/WorkInProgress';
import { DisciplineReport } from '../../models/DisciplineReport';
import { MealPlan } from '../../models/MealPlan';
import { AssessmentAssignment } from '../../models/AssessmentAssignment';
import { IndividualMealPlan } from '../../models/IndividualMealPlan';
import { TeamReport } from '../../models/TeamReport';
import { AccountAcademic } from '../../models/AccountAcademic';
import { StudentGoal } from '../../models/StudentGoal';
import { PreWorkoutRoutine } from '../../models/PreWorkoutRoutine';
import { StrengthAndConditioning } from '../../models/StrengthAndConditioning';
import { WellnessReport } from '../../models/WellnessReport';
import { StrengthAndConditioningStudentScore } from '../../models/StrengthAndConditioningStudentScore';
import { MicroPlanner } from '../../models/MicroPlanner';
import { BrandingPreference } from '../../models/BrandingPreference';
import { IndividualMealPlanTiming } from '../../models/IndividualMealPlanTiming';
import { MapWagFileAccount } from '../../models/MapWagFileAccount';
import { SportPsychologicalComponent } from '../../models/SportPsychologicalComponent';
import { SportActivity } from '../../models/SportActivity';
import { TournamentMealPlan } from '../../models/TournamentMealPlan';
import { HydrationTracker } from '../../models/HydrationTracker';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Option: Option,
    AppUser: AppUser,
    AppRole: AppRole,
    AppUserRole: AppUserRole,
    Country: Country,
    State: State,
    City: City,
    Address: Address,
    Account: Account,
    AppFile: AppFile,
    AccountDetail: AccountDetail,
    Organization: Organization,
    Sport: Sport,
    Course: Course,
    Infrastructure: Infrastructure,
    Amenity: Amenity,
    Batch: Batch,
    BulkUpload: BulkUpload,
    Guardian: Guardian,
    CourseInfrastructure: CourseInfrastructure,
    Excercise: Excercise,
    ExcercisesAttendee: ExcercisesAttendee,
    Maintenance: Maintenance,
    Attendance: Attendance,
    Calendar: Calendar,
    Form: Form,
    FormElement: FormElement,
    Event: Event,
    Interest: Interest,
    Post: Post,
    Tag: Tag,
    FormSubmission: FormSubmission,
    Procurement: Procurement,
    Rating: Rating,
    AccountBatch: AccountBatch,
    AccountInfrastructure: AccountInfrastructure,
    Product: Product,
    Cart: Cart,
    FeePlan: FeePlan,
    FeeSubscription: FeeSubscription,
    FeeInstallment: FeeInstallment,
    MapFeeplanCourse: MapFeeplanCourse,
    Order: Order,
    CartItem: CartItem,
    Transaction: Transaction,
    SystematicMethod: SystematicMethod,
    Pricebook: Pricebook,
    Assessment: Assessment,
    AssessmentParameter: AssessmentParameter,
    AssessmentSection: AssessmentSection,
    AssessmentKpi: AssessmentKpi,
    AssessmentSubmission: AssessmentSubmission,
    RankingClassification: RankingClassification,
    Ranking: Ranking,
    BatchTiming: BatchTiming,
    AccountUser: AccountUser,
    ChequeTracker: ChequeTracker,
    UtrTracker: UtrTracker,
    CoachingDrill: CoachingDrill,
    LessonPlan: LessonPlan,
    Dashboard: Dashboard,
    Certification: Certification,
    Injury: Injury,
    Payment: Payment,
    ActivityTracker: ActivityTracker,
    Session: Session,
    AccountSession: AccountSession,
    VideoSession: VideoSession,
    VideoSessionAttendees: VideoSessionAttendees,
    Message: Message,
    Test: Test,
    TestAttendee: TestAttendee,
    CertificationAttendee: CertificationAttendee,
    Recurring: Recurring,
    FixedTimeDuration: FixedTimeDuration,
    WorkoutPlan: WorkoutPlan,
    WorkoutPlanSchedule: WorkoutPlanSchedule,
    WorkoutPlanAttendee: WorkoutPlanAttendee,
    AssessmentTest: AssessmentTest,
    KpiFieldsMaster: KpiFieldsMaster,
    TestSchedule: TestSchedule,
    CoachingPlan: CoachingPlan,
    CoachingSchedule: CoachingSchedule,
    CoachingDrillAttendee: CoachingDrillAttendee,
    CertificationResult: CertificationResult,
    CoachingScheduleAttendee: CoachingScheduleAttendee,
    MapWorkoutPlanSchedule: MapWorkoutPlanSchedule,
    StudentTag: StudentTag,
    MapStudentTag: MapStudentTag,
    MapWorkoutStudent: MapWorkoutStudent,
    MapCoachingDrillSchedule: MapCoachingDrillSchedule,
    MapCoachingScheduleStudent: MapCoachingScheduleStudent,
    ProcurementItem: ProcurementItem,
    MapCertificationStudent: MapCertificationStudent,
    AccountCourse: AccountCourse,
    AccountBatchtiming: AccountBatchtiming,
    AppUserToken: AppUserToken,
    BellNotification: BellNotification,
    TermsAndConditions: TermsAndConditions,
    UserRoleScope: UserRoleScope,
    FeeSetting: FeeSetting,
    MapAssessmentParameter: MapAssessmentParameter,
    ScheduleTest: ScheduleTest,
    ScheduleTestAttendance: ScheduleTestAttendance,
    MappingScheduleTestAssessment: MappingScheduleTestAssessment,
    AccountAssessmentParameterResult: AccountAssessmentParameterResult,
    AssessmentResult: AssessmentResult,
    CoachEvaluationParameter: CoachEvaluationParameter,
    WeekAtAGlanceArea: WeekAtAGlanceArea,
    ActivityRequest: ActivityRequest,
    CoachGoal: CoachGoal,
    YearlySchedule: YearlySchedule,
    SportsPsychologyTracking: SportsPsychologyTracking,
    MusculoskeletalScreening: MusculoskeletalScreening,
    FunctionalMovementScreening: FunctionalMovementScreening,
    SessionPlan: SessionPlan,
    MesoPlanner: MesoPlanner,
    NewMesoPlanner: NewMesoPlanner,
    NewMacroPlanner: NewMacroPlanner,
    WorkInProgress: WorkInProgress,
    DisciplineReport: DisciplineReport,
    MealPlan: MealPlan,
    AssessmentAssignment: AssessmentAssignment,
    IndividualMealPlan: IndividualMealPlan,
    TeamReport: TeamReport,
    AccountAcademic: AccountAcademic,
    StudentGoal: StudentGoal,
    PreWorkoutRoutine: PreWorkoutRoutine,
    StrengthAndConditioning: StrengthAndConditioning,
    WellnessReport: WellnessReport,
    StrengthAndConditioningStudentScore: StrengthAndConditioningStudentScore,
    MicroPlanner: MicroPlanner,
    BrandingPreference: BrandingPreference,
    IndividualMealPlanTiming: IndividualMealPlanTiming,
    MapWagFileAccount: MapWagFileAccount,
    SportPsychologicalComponent: SportPsychologicalComponent,
    SportActivity: SportActivity,
    TournamentMealPlan: TournamentMealPlan,
    HydrationTracker: HydrationTracker,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
