/* tslint:disable */

declare var Object: any;
export interface WellnessReportInterface {
  "date": Date;
  "studentId": number;
  "areYouSick": string;
  "sicknessSymptoms": string;
  "currentInjuries": string;
  "sessionObjective": string;
  "muscleSorenessUpperBody": string;
  "muscleSorenessLowerBody": string;
  "muscleTightness": string;
  "supplements": string;
  "fatigue": number;
  "recovery": number;
  "motivation": number;
  "appetite": number;
  "nutrition": number;
  "hydration": number;
  "energyLevels": number;
  "sleepQuality": number;
  "totalScore": number;
  "additionalComments"?: string;
  "createdBy": number;
  "createdByName": string;
  "updatedBy"?: number;
  "updatedByName"?: string;
  "createdDate"?: Date;
  "updatedDate"?: Date;
  "id"?: string;
  "isActive": boolean;
  "lastModifiedDate": Date;
}

export class WellnessReport implements WellnessReportInterface {
  "date": Date;
  "studentId": number;
  "areYouSick": string;
  "sicknessSymptoms": string;
  "currentInjuries": string;
  "sessionObjective": string;
  "muscleSorenessUpperBody": string;
  "muscleSorenessLowerBody": string;
  "muscleTightness": string;
  "supplements": string;
  "fatigue": number;
  "recovery": number;
  "motivation": number;
  "appetite": number;
  "nutrition": number;
  "hydration": number;
  "energyLevels": number;
  "sleepQuality": number;
  "totalScore": number;
  "additionalComments": string;
  "createdBy": number;
  "createdByName": string;
  "updatedBy": number;
  "updatedByName": string;
  "createdDate": Date;
  "updatedDate": Date;
  "id": string;
  "isActive": boolean;
  "lastModifiedDate": Date;
  constructor(data?: WellnessReportInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WellnessReport`.
   */
  public static getModelName() {
    return "WellnessReport";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WellnessReport for dynamic purposes.
  **/
  public static factory(data: WellnessReportInterface): WellnessReport{
    return new WellnessReport(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WellnessReport',
      plural: 'WellnessReports',
      path: 'WellnessReports',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "studentId": {
          name: 'studentId',
          type: 'number'
        },
        "areYouSick": {
          name: 'areYouSick',
          type: 'string'
        },
        "sicknessSymptoms": {
          name: 'sicknessSymptoms',
          type: 'string'
        },
        "currentInjuries": {
          name: 'currentInjuries',
          type: 'string'
        },
        "sessionObjective": {
          name: 'sessionObjective',
          type: 'string'
        },
        "muscleSorenessUpperBody": {
          name: 'muscleSorenessUpperBody',
          type: 'string'
        },
        "muscleSorenessLowerBody": {
          name: 'muscleSorenessLowerBody',
          type: 'string'
        },
        "muscleTightness": {
          name: 'muscleTightness',
          type: 'string'
        },
        "supplements": {
          name: 'supplements',
          type: 'string'
        },
        "fatigue": {
          name: 'fatigue',
          type: 'number'
        },
        "recovery": {
          name: 'recovery',
          type: 'number'
        },
        "motivation": {
          name: 'motivation',
          type: 'number'
        },
        "appetite": {
          name: 'appetite',
          type: 'number'
        },
        "nutrition": {
          name: 'nutrition',
          type: 'number'
        },
        "hydration": {
          name: 'hydration',
          type: 'number'
        },
        "energyLevels": {
          name: 'energyLevels',
          type: 'number'
        },
        "sleepQuality": {
          name: 'sleepQuality',
          type: 'number'
        },
        "totalScore": {
          name: 'totalScore',
          type: 'number'
        },
        "additionalComments": {
          name: 'additionalComments',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "updatedByName": {
          name: 'updatedByName',
          type: 'string'
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "updatedDate": {
          name: 'updatedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
