/* tslint:disable */
import {
  Amenity,
  AppFile,
  Rating
} from '../index';

declare var Object: any;
export interface MaintenanceInterface {
  "description": string;
  "status": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  amenity?: Amenity;
  maintenancePicture?: AppFile;
  rating?: Rating[];
}

export class Maintenance implements MaintenanceInterface {
  "description": string;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  amenity: Amenity;
  maintenancePicture: AppFile;
  rating: Rating[];
  constructor(data?: MaintenanceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Maintenance`.
   */
  public static getModelName() {
    return "Maintenance";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Maintenance for dynamic purposes.
  **/
  public static factory(data: MaintenanceInterface): Maintenance{
    return new Maintenance(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Maintenance',
      plural: 'Maintenances',
      path: 'Maintenances',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        amenity: {
          name: 'amenity',
          type: 'Amenity',
          model: 'Amenity',
          relationType: 'belongsTo',
                  keyFrom: 'amenity_id',
          keyTo: 'id'
        },
        maintenancePicture: {
          name: 'maintenancePicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        rating: {
          name: 'rating',
          type: 'Rating[]',
          model: 'Rating',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'rating_id'
        },
      }
    }
  }
}
