/* tslint:disable */
import {
  Account,
  Event
} from '../index';

declare var Object: any;
export interface RatingInterface {
  "rating": number;
  "date": Date;
  "feedback": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "staff_id"?: number;
  "rating_id"?: number;
  account?: Account;
  staff?: Account;
  event?: Event;
}

export class Rating implements RatingInterface {
  "rating": number;
  "date": Date;
  "feedback": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "staff_id": number;
  "rating_id": number;
  account: Account;
  staff: Account;
  event: Event;
  constructor(data?: RatingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Rating`.
   */
  public static getModelName() {
    return "Rating";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Rating for dynamic purposes.
  **/
  public static factory(data: RatingInterface): Rating{
    return new Rating(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Rating',
      plural: 'Ratings',
      path: 'Ratings',
      idName: 'id',
      properties: {
        "rating": {
          name: 'rating',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "feedback": {
          name: 'feedback',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "staff_id": {
          name: 'staff_id',
          type: 'number'
        },
        "rating_id": {
          name: 'rating_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        staff: {
          name: 'staff',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'staff_id',
          keyTo: 'id'
        },
        event: {
          name: 'event',
          type: 'Event',
          model: 'Event',
          relationType: 'belongsTo',
                  keyFrom: 'event_id',
          keyTo: 'id'
        },
      }
    }
  }
}
