/* tslint:disable */
import {
  AppFile,
  Account,
  Sport
} from '../index';

declare var Object: any;
export interface WorkoutPlanInterface {
  "name": string;
  "focusArea": string;
  "workoutPlanType"?: string;
  "timing"?: number;
  "setsMin"?: number;
  "setsMax"?: number;
  "repsMin"?: number;
  "repsMax"?: number;
  "equipments": string;
  "fileType"?: string;
  "fileName"?: string;
  "description"?: string;
  "timmingminutes"?: number;
  "notes": string;
  "level": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_file_id"?: number;
  "created_by"?: number;
  "sport_id"?: number;
  workoutPlanPicture?: AppFile;
  createdBy?: Account;
  sport?: Sport;
}

export class WorkoutPlan implements WorkoutPlanInterface {
  "name": string;
  "focusArea": string;
  "workoutPlanType": string;
  "timing": number;
  "setsMin": number;
  "setsMax": number;
  "repsMin": number;
  "repsMax": number;
  "equipments": string;
  "fileType": string;
  "fileName": string;
  "description": string;
  "timmingminutes": number;
  "notes": string;
  "level": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_file_id": number;
  "created_by": number;
  "sport_id": number;
  workoutPlanPicture: AppFile;
  createdBy: Account;
  sport: Sport;
  constructor(data?: WorkoutPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkoutPlan`.
   */
  public static getModelName() {
    return "WorkoutPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkoutPlan for dynamic purposes.
  **/
  public static factory(data: WorkoutPlanInterface): WorkoutPlan{
    return new WorkoutPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkoutPlan',
      plural: 'WorkoutPlans',
      path: 'WorkoutPlans',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "focusArea": {
          name: 'focusArea',
          type: 'string'
        },
        "workoutPlanType": {
          name: 'workoutPlanType',
          type: 'string'
        },
        "timing": {
          name: 'timing',
          type: 'number'
        },
        "setsMin": {
          name: 'setsMin',
          type: 'number'
        },
        "setsMax": {
          name: 'setsMax',
          type: 'number'
        },
        "repsMin": {
          name: 'repsMin',
          type: 'number'
        },
        "repsMax": {
          name: 'repsMax',
          type: 'number'
        },
        "equipments": {
          name: 'equipments',
          type: 'string'
        },
        "fileType": {
          name: 'fileType',
          type: 'string'
        },
        "fileName": {
          name: 'fileName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "timmingminutes": {
          name: 'timmingminutes',
          type: 'number'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
        "created_by": {
          name: 'created_by',
          type: 'number'
        },
        "sport_id": {
          name: 'sport_id',
          type: 'number'
        },
      },
      relations: {
        workoutPlanPicture: {
          name: 'workoutPlanPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by',
          keyTo: 'id'
        },
        sport: {
          name: 'sport',
          type: 'Sport',
          model: 'Sport',
          relationType: 'belongsTo',
                  keyFrom: 'sport_id',
          keyTo: 'id'
        },
      }
    }
  }
}
