/* tslint:disable */
import {
  Account,
  Test
} from '../index';

declare var Object: any;
export interface TestAttendeeInterface {
  "attendeesId"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "test_id"?: number;
  account?: Account;
  test?: Test;
}

export class TestAttendee implements TestAttendeeInterface {
  "attendeesId": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "test_id": number;
  account: Account;
  test: Test;
  constructor(data?: TestAttendeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TestAttendee`.
   */
  public static getModelName() {
    return "TestAttendee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TestAttendee for dynamic purposes.
  **/
  public static factory(data: TestAttendeeInterface): TestAttendee{
    return new TestAttendee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TestAttendee',
      plural: 'TestAttendees',
      path: 'TestAttendees',
      idName: 'id',
      properties: {
        "attendeesId": {
          name: 'attendeesId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "test_id": {
          name: 'test_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        test: {
          name: 'test',
          type: 'Test',
          model: 'Test',
          relationType: 'belongsTo',
                  keyFrom: 'test_id',
          keyTo: 'id'
        },
      }
    }
  }
}
