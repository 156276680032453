/* tslint:disable */
import {
  Recurring,
  FixedTimeDuration,
  CoachingDrill,
  Account,
  MapCoachingDrillSchedule
} from '../index';

declare var Object: any;
export interface CoachingScheduleInterface {
  "status"?: number;
  "scheduleName"?: string;
  "startDate": Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  recurring?: Recurring;
  fixedTimeDuration?: FixedTimeDuration;
  coachingDrill?: CoachingDrill;
  createdBy?: Account;
  coachingDrillSchedule?: MapCoachingDrillSchedule[];
}

export class CoachingSchedule implements CoachingScheduleInterface {
  "status": number;
  "scheduleName": string;
  "startDate": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  recurring: Recurring;
  fixedTimeDuration: FixedTimeDuration;
  coachingDrill: CoachingDrill;
  createdBy: Account;
  coachingDrillSchedule: MapCoachingDrillSchedule[];
  constructor(data?: CoachingScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoachingSchedule`.
   */
  public static getModelName() {
    return "CoachingSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoachingSchedule for dynamic purposes.
  **/
  public static factory(data: CoachingScheduleInterface): CoachingSchedule{
    return new CoachingSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoachingSchedule',
      plural: 'CoachingSchedules',
      path: 'CoachingSchedules',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'number'
        },
        "scheduleName": {
          name: 'scheduleName',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
      },
      relations: {
        recurring: {
          name: 'recurring',
          type: 'Recurring',
          model: 'Recurring',
          relationType: 'belongsTo',
                  keyFrom: 'recurring_id',
          keyTo: 'id'
        },
        fixedTimeDuration: {
          name: 'fixedTimeDuration',
          type: 'FixedTimeDuration',
          model: 'FixedTimeDuration',
          relationType: 'belongsTo',
                  keyFrom: 'fixed_time_duration_id',
          keyTo: 'id'
        },
        coachingDrill: {
          name: 'coachingDrill',
          type: 'CoachingDrill',
          model: 'CoachingDrill',
          relationType: 'belongsTo',
                  keyFrom: 'coaching_drill_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        coachingDrillSchedule: {
          name: 'coachingDrillSchedule',
          type: 'MapCoachingDrillSchedule[]',
          model: 'MapCoachingDrillSchedule',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'coachingScheduleId'
        },
      }
    }
  }
}
