/* tslint:disable */
import {
  Course,
  Infrastructure,
  FeeSubscription,
  FeeInstallment,
  MapFeeplanCourse,
  Order
} from '../index';

declare var Object: any;
export interface FeePlanInterface {
  "name": string;
  "feetype": number;
  "recurringFrequency": number;
  "price": number;
  "hasLateFee"?: boolean;
  "lateFeeDelay"?: number;
  "lateFeeAmount"?: number;
  "hasProrata"?: boolean;
  "feeDescription"?: string;
  "feePeriod"?: string;
  "aging"?: number;
  "isVisibleToRegistration": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  course?: Course;
  center?: Infrastructure;
  feeSubscription?: FeeSubscription[];
  feeInstallment?: FeeInstallment[];
  feePlanCourse?: MapFeeplanCourse[];
  orders?: Order[];
}

export class FeePlan implements FeePlanInterface {
  "name": string;
  "feetype": number;
  "recurringFrequency": number;
  "price": number;
  "hasLateFee": boolean;
  "lateFeeDelay": number;
  "lateFeeAmount": number;
  "hasProrata": boolean;
  "feeDescription": string;
  "feePeriod": string;
  "aging": number;
  "isVisibleToRegistration": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  course: Course;
  center: Infrastructure;
  feeSubscription: FeeSubscription[];
  feeInstallment: FeeInstallment[];
  feePlanCourse: MapFeeplanCourse[];
  orders: Order[];
  constructor(data?: FeePlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FeePlan`.
   */
  public static getModelName() {
    return "FeePlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FeePlan for dynamic purposes.
  **/
  public static factory(data: FeePlanInterface): FeePlan{
    return new FeePlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FeePlan',
      plural: 'FeePlans',
      path: 'FeePlans',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "feetype": {
          name: 'feetype',
          type: 'number'
        },
        "recurringFrequency": {
          name: 'recurringFrequency',
          type: 'number'
        },
        "price": {
          name: 'price',
          type: 'number'
        },
        "hasLateFee": {
          name: 'hasLateFee',
          type: 'boolean',
          default: false
        },
        "lateFeeDelay": {
          name: 'lateFeeDelay',
          type: 'number'
        },
        "lateFeeAmount": {
          name: 'lateFeeAmount',
          type: 'number'
        },
        "hasProrata": {
          name: 'hasProrata',
          type: 'boolean',
          default: false
        },
        "feeDescription": {
          name: 'feeDescription',
          type: 'string'
        },
        "feePeriod": {
          name: 'feePeriod',
          type: 'string'
        },
        "aging": {
          name: 'aging',
          type: 'number'
        },
        "isVisibleToRegistration": {
          name: 'isVisibleToRegistration',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        course: {
          name: 'course',
          type: 'Course',
          model: 'Course',
          relationType: 'belongsTo',
                  keyFrom: 'course_id',
          keyTo: 'id'
        },
        center: {
          name: 'center',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
        feeSubscription: {
          name: 'feeSubscription',
          type: 'FeeSubscription[]',
          model: 'FeeSubscription',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'feePlanId'
        },
        feeInstallment: {
          name: 'feeInstallment',
          type: 'FeeInstallment[]',
          model: 'FeeInstallment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'feePlanId'
        },
        feePlanCourse: {
          name: 'feePlanCourse',
          type: 'MapFeeplanCourse[]',
          model: 'MapFeeplanCourse',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'feePlanId'
        },
        orders: {
          name: 'orders',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'fee_plan_id'
        },
      }
    }
  }
}
