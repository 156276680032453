/* tslint:disable */
import {
  Account,
  AppFile
} from '../index';

declare var Object: any;
export interface CoachingPlanInterface {
  "name": string;
  "objective"?: string;
  "setup"?: string;
  "description"?: string;
  "Points"?: string;
  "fileType": number;
  "fileName"?: string;
  "attendeeType": number;
  "status"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  account?: Account;
  coachingPlanPicture?: AppFile;
}

export class CoachingPlan implements CoachingPlanInterface {
  "name": string;
  "objective": string;
  "setup": string;
  "description": string;
  "Points": string;
  "fileType": number;
  "fileName": string;
  "attendeeType": number;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  account: Account;
  coachingPlanPicture: AppFile;
  constructor(data?: CoachingPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoachingPlan`.
   */
  public static getModelName() {
    return "CoachingPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoachingPlan for dynamic purposes.
  **/
  public static factory(data: CoachingPlanInterface): CoachingPlan{
    return new CoachingPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoachingPlan',
      plural: 'CoachingPlans',
      path: 'CoachingPlans',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "objective": {
          name: 'objective',
          type: 'string'
        },
        "setup": {
          name: 'setup',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "Points": {
          name: 'Points',
          type: 'string'
        },
        "fileType": {
          name: 'fileType',
          type: 'number'
        },
        "fileName": {
          name: 'fileName',
          type: 'string'
        },
        "attendeeType": {
          name: 'attendeeType',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        coachingPlanPicture: {
          name: 'coachingPlanPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
