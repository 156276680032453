/* tslint:disable */

declare var Object: any;
export interface WorkInProgressInterface {
  "date": string;
  "area": string;
  "aspect": string;
  "subAspect"?: string;
  "observation"?: string;
  "workToBeDone": string;
  "students": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy"?: number;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class WorkInProgress implements WorkInProgressInterface {
  "date": string;
  "area": string;
  "aspect": string;
  "subAspect": string;
  "observation": string;
  "workToBeDone": string;
  "students": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy": number;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: WorkInProgressInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkInProgress`.
   */
  public static getModelName() {
    return "WorkInProgress";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkInProgress for dynamic purposes.
  **/
  public static factory(data: WorkInProgressInterface): WorkInProgress{
    return new WorkInProgress(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkInProgress',
      plural: 'WorkInProgress',
      path: 'WorkInProgress',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'string'
        },
        "area": {
          name: 'area',
          type: 'string'
        },
        "aspect": {
          name: 'aspect',
          type: 'string'
        },
        "subAspect": {
          name: 'subAspect',
          type: 'string'
        },
        "observation": {
          name: 'observation',
          type: 'string'
        },
        "workToBeDone": {
          name: 'workToBeDone',
          type: 'string'
        },
        "students": {
          name: 'students',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
