/* tslint:disable */

declare var Object: any;
export interface FeeSettingInterface {
  "enableOnlineFeePayment"?: boolean;
  "allowPartialPaymentFee"?: boolean;
  "taxSettings"?: boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class FeeSetting implements FeeSettingInterface {
  "enableOnlineFeePayment": boolean;
  "allowPartialPaymentFee": boolean;
  "taxSettings": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: FeeSettingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FeeSetting`.
   */
  public static getModelName() {
    return "FeeSetting";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FeeSetting for dynamic purposes.
  **/
  public static factory(data: FeeSettingInterface): FeeSetting{
    return new FeeSetting(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FeeSetting',
      plural: 'FeeSettings',
      path: 'FeeSettings',
      idName: 'id',
      properties: {
        "enableOnlineFeePayment": {
          name: 'enableOnlineFeePayment',
          type: 'boolean',
          default: false
        },
        "allowPartialPaymentFee": {
          name: 'allowPartialPaymentFee',
          type: 'boolean',
          default: false
        },
        "taxSettings": {
          name: 'taxSettings',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
