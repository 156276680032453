/* tslint:disable */
import {
  Product,
  Cart
} from '../index';

declare var Object: any;
export interface CartItemInterface {
  "quantity": number;
  "subTotal": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  product?: Product;
  cart?: Cart;
}

export class CartItem implements CartItemInterface {
  "quantity": number;
  "subTotal": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  product: Product;
  cart: Cart;
  constructor(data?: CartItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CartItem`.
   */
  public static getModelName() {
    return "CartItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CartItem for dynamic purposes.
  **/
  public static factory(data: CartItemInterface): CartItem{
    return new CartItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CartItem',
      plural: 'CartsItems',
      path: 'CartsItems',
      idName: 'id',
      properties: {
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "subTotal": {
          name: 'subTotal',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'product_id',
          keyTo: 'id'
        },
        cart: {
          name: 'cart',
          type: 'Cart',
          model: 'Cart',
          relationType: 'belongsTo',
                  keyFrom: 'cart_id',
          keyTo: 'id'
        },
      }
    }
  }
}
