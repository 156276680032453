import { Component, OnInit, Input, QueryList, ViewChildren, OnChanges, Output, EventEmitter } from '@angular/core';
import { AttendanceApi } from 'src/app/shared/sdk';
// import { CustomAttendanceComponent } from "../custom-attendance/custom-attendance.component";
import { SwitchComponent } from '../switch/switch.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-attendance-table',
  templateUrl: 'attendance-table.component.html',
  styleUrls: ['./attendance-table.component.scss']
})
export class AttendanceTableComponent implements OnInit, OnChanges {
  @Input() inputs: any;
  @ViewChildren(SwitchComponent) switchComponents: QueryList<any>;
  @Output() closeModal = new EventEmitter<boolean>();

  public options: any = [];
  public attendaceArray: any = [];
  public disableAttendance = false;
  public rows: any = [];
  public batchId: any;
  public centerId: any;
  public courseId: any;
  public accountId: any;
  public presentCount: any = 0;
  public existAttendance: any;
  public accountType:any;
  public eventData:any=[];

  // Code for CustomAttendanceComponent
  // @ViewChildren(CustomAttendanceComponent) customAttendanceComponents: QueryList<any>;
  constructor(private activatedRouter: ActivatedRoute, private authService: AuthService, private attendanceApi: AttendanceApi) {}

  ngOnChanges() {
    const today = new Date();
    this.attendaceArray = [];
    this.eventData=[];
    this.inputs.forEach(async (row: any) => {
      console.log("chechk row",row,row.type)
      if(row.type==4){
        this.accountType=4;
        this.attendaceArray.push({ accountId: row.accountId, attendanceType: 3,accountType:row.type});
      }else{
        this.accountType=1;
      await row.batches.map((batches: any) => {            
        this.attendaceArray.push({ accountId: row.accountId, batchId: batches.id, attendanceType: 3});
      });      
    }
    });   
    console.log("hereon ngchange",this.attendaceArray)
    // this.inputs.forEach(async (row: any) => {
    //   await this.attendaceArray.push({ accountId: this.inputs.accountDetail.accountId, batchId: row.id, attendanceType: 2 });
    // });
    // var previoustDay = moment().format('YYYY-MM-DD')===moment().endOf('month').format('YYYY-MM-DD')? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()-1}`:`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;   
    // var nexttDay = moment().format('YYYY-MM-DD')===moment().endOf('month').format('YYYY-MM-DD')? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`:`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()+1}`;   

    // console.log(previoustDay, moment().format('YYYY-MM-DD'),this.batchId,this.centerId)
    // this.attendanceApi
    //   .find({
    //     where: {
    //       isActive: true,
    //       batchId: this.batchId,
    //       centerId: this.centerId,
    //       date: {
    //         between: [
    //           `${previoustDay}`,
    //           `${nexttDay}`
           
    //         ]
    //       }
    //     }
    //   })
    //   .toPromise()
    //   .then((res: any) => {
    //     this.existAttendance = res;
    //     if (res.length !== 0) {
    //       console.log(res)
    //       this.inputs.map((row: any, rowIndex: number) => {
    //         res.map((accountData: any) => {
    //           if(row.accountId===accountData.accountId){
    //             this.inputs[rowIndex].attendanceType = accountData.type;
    //             if(accountData.type===1){
    //               this.inputs[rowIndex].present = true;
    //               this.presentCount++;
    //             }else{
    //               this.inputs[rowIndex].present =false;
    //             }
    //           }
    //         });
            // let result: any = row.filter((accountDetail) => accountDetail.accountId === res.accountId);
            // result = new Set(result);
            // result = Array.from(result);
            // this.inputs[rowIndex].attendanceType = result.type;
            // if (res.type === 1) {
            //   this.inputs[rowIndex].present = true;
            // } else {
            //   this.inputs[rowIndex].present = false;
            // }
            // for(let index=0;index<res.length;index++){
            //   if(row.accountDetail.accountId===res[index].accountId){
            //     this.inputs[rowIndex].attendanceType = res[index].type;
            //     if(res[index].type===1){
            //       this.inputs[rowIndex].present =true;
            //     }else{
            //       this.inputs[rowIndex].present =false;
            //     }
            //     break;
            //   }
            // }
          //});
          // this.disableAttendance =true;
        //}
        // if (res.length === this.presentCount) {
        //   this.disableAttendance =true;
        // }
     // })
      //.then((res: any) => {});
  }
  getAttendance(centerId){
    const today = new Date();
   // var previoustDay = moment().format('YYYY-MM-DD')===moment().endOf('month').format('YYYY-MM-DD')? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()-1}`:`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;   
    var nexttDay = moment().format('YYYY-MM-DD')===moment().endOf('month').format('YYYY-MM-DD')? moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'):`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()+1}`;   

    //console.log(moment().format('YYYY-MM-DD'),this.batchId,this.centerId)
  
    this.attendanceApi
      .find({
        where: {
          isActive: true,        
          centerId: centerId,
          date: {
            between: [
              `${moment().format('YYYY-MM-DD')}`,
              `${moment(nexttDay).format('YYYY-MM-DD')}`           
            ]
          }
        }
      })
      .toPromise()
      .then((res: any) => {
        this.existAttendance = res;
        if (res.length !== 0) {
          console.log(res)
          this.inputs.map((row: any, rowIndex: number) => {
            res.map((accountData: any) => {
              if(row.accountId===accountData.accountId){
                this.inputs[rowIndex].attendanceType = accountData.type;
                if(accountData.type===1){
                  this.inputs[rowIndex].present = true;
                  this.presentCount++;
                }else{
                  this.inputs[rowIndex].present =false;
                }
              }
            });
         
          });         
        }       
      })
      .then((res: any) => {});
  }

  ngOnInit() {
    this.activatedRouter.params.subscribe((params) => {
      this.centerId = params.id;
      this.batchId = params.batchId;
      this.courseId = params.courseId;
    });   
    this.getAttendance(this.centerId);
    this.accountId = this.authService.getCurrentAccountId();
    console.log(this.attendaceArray);
    if(this.accountType==1){
    let filterattendanceArray: any = this.attendaceArray.filter(attendance => attendance.batchId == this.batchId);  
    this.attendaceArray=filterattendanceArray;
    }
    // console.log('filteerd att',this.attendaceArray,this.batchId,this.accountId);

  }
  //CustomAttendanceComponent

  // setCustomAttendance(event){
  //   for(let i:number=0;i<this.attendaceArray.length;i++){
  //     if(this.attendaceArray[i].accountId===event.accountId){
  //       this.attendaceArray[i].attendanceType= parseInt(event.type);
  //       break;
  //     }
  //   }
  //   this.customAttendanceComponents.forEach((customAttendanceComponent:any,index:number) =>{
  //     if(customAttendanceComponent.input.accountId===event.accountId){
  //         customAttendanceComponent.input.selectedValue = event.type;
  //       }
  //   });

  //   this.switchComponents.forEach((switchComponent:any) =>{
  //     if(switchComponent.input.accountId===event.accountId ){
  //       if( parseInt(event.type)===1){
  //         switchComponent.input.present = true;
  //       }else{
  //         switchComponent.input.present = false;
  //       }
  //     }
  //   });
  // }

  absent() {
    this.eventData=[];
    this.switchComponents.forEach((switchComponent: any) => {
      switchComponent.input.present = false;
      // this.switchAttendance(switchComponent);
    });
    this.attendaceArray.map((attendanceData) => {
      attendanceData.attendanceType = 3;
    });
  }
  present() {
    this.eventData=[];
    this.switchComponents.forEach((switchComponent: any) => {
      switchComponent.input.present = true;
      // this.switchAttendance(switchComponent);
    });
    this.attendaceArray.map((attendanceData) => {
      attendanceData.attendanceType = 1;
    });
  }

  switchAttendance(event) {   
    this.eventData.push(event); 
    this.attendaceArray.map((attendanceData) => {
      // if (event.accountId !== undefined) {
        console.log("switch",event)
        if (attendanceData.accountId === event.accountId) {
          attendanceData.attendanceType = event.present ? 1 : 3;
        } else {
          this.existAttendance.map((attendance: any) => {
            if (event.accountId !== attendance.accountId) {
              attendanceData.attendanceType = attendance.type;
            }
          });
          // attendanceData.attendanceType = this.inputs.present ? 1 : 2;
        }
        console.log(this.eventData);
      // }
      //  else {
      //   if (attendanceData.accountId === event.input.accountId) {
      //     attendanceData.attendanceType = event.input.present ? 1 : 2;
      //   }
      // }
    });
    // for(let i=0;i<this.attendaceArray.length;i++){
    //   if(event.accountId !== undefined) {
    //     if(this.attendaceArray[i].accountId===event.accountId){
    //       this.attendaceArray[i].attendanceType=event.present ? 1 : 2;
    //       break;
    //     }
    //   }else {
    //     if(this.attendaceArray[i].accountId===event.input.accountId){
    //       this.attendaceArray[i].attendanceType=event.input.present ? 1 : 2;
    //       break;
    //     }
    //   }
    // }

    this.switchComponents.forEach((switchComponent: any) => {
      // if (event.accountId !== undefined) {
        if (switchComponent.input.accountId === event.accountId) {
          switchComponent.input.present = event.present;
        }
      // }
      // else {
      //   if (switchComponent.input.accountId === event.input.accountId) {
      //     switchComponent.input.present = event.input.present;
      //   }
      // }
    });

    //CustomAttendanceComponent
    // this.customAttendanceComponents.forEach((customAttendanceComponent:any,index:number) =>{
    //   if(customAttendanceComponent.input.accountId===event.accountId){
    //     if(event.present){
    //       customAttendanceComponent.input.selectedValue = 1;
    //     }else{
    //       customAttendanceComponent.input.selectedValue = 2;
    //     }
    //   }
    // });
  }

  markAttendance() { 
    console.log('check center',this.centerId,this.batchId)  
    const promises: any = [];
    const editpromises: any = [];
    console.log('checklist',this.attendaceArray);
    if(this.eventData.length>0){
      let array=[];
      this.eventData.map((item:any)=>{
        console.log(item)
        let filterarray=this.attendaceArray.filter(attendace=>attendace.accountId===item.accountId);      
        array.push({accountId: filterarray[0].accountId, attendanceType: item.present?1:3, accountType: filterarray[0].accountType});              
      })     
      this.attendaceArray=array;    
    }
  
    this.attendaceArray.map((attendace: any) => {
      const accountId = attendace.accountId;
      console.log(accountId);
      this.attendanceApi.find({ where: {accountId: accountId,centerId:this.centerId } }).toPromise().then((attendanceDetails: any) => {
      //  console.log(attendanceDetails,moment().format("YYYY-MM-DD"),attendace.accountId)
        if (attendanceDetails.length > 0) {
              //  console.log(attendanceDetails);
                let check=0;
          attendanceDetails.map((details: any) => {
            if (moment(details.date).format('YYYY-MM-DD') == moment().format("YYYY-MM-DD") && 
              details.accountId == attendace.accountId) {
                check=1;
              const updateAttendanceInfo: any = {};
              updateAttendanceInfo.date = details.date;
              updateAttendanceInfo.type = attendace.attendanceType;
              updateAttendanceInfo.isActive = true;
              updateAttendanceInfo.batchId = attendace.batchId;
              updateAttendanceInfo.centerId = this.centerId;
              updateAttendanceInfo.createdById = this.accountId;
              editpromises.push(
                this.attendanceApi.patchAttributes(details.id, updateAttendanceInfo).toPromise()
              );            
            }
          })
          if(check==0){
              promises.push(        
              this.attendanceApi
                .create({
                  date: new Date(),
                  type: attendace.attendanceType,
                  isActive: true,
                  accountId: attendace.accountId,
                  batchId: this.batchId,
                  centerId:this.centerId,
                  createdById:this.accountId
                })
                .toPromise()           
              ); 
            }                      
          Promise.all(editpromises)
          .then((res: any) => res)
          .then(() => {
            this.closeModal.emit(true);
          });
        } else {
          promises.push(        
          this.attendanceApi
            .create({
              date: new Date(),
              type: attendace.attendanceType,
              isActive: true,
              accountId: attendace.accountId,
              batchId: this.batchId,
              centerId:this.centerId,
              createdById:this.accountId
            })
            .toPromise()           
          );  
          Promise.all(promises)
          .then((res: any) => res)
          .then(() => {
            this.closeModal.emit(true);
          });
        }             
      });      
    });
  }
}
