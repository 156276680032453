/* tslint:disable */
import {
  Certification,
  Account
} from '../index';

declare var Object: any;
export interface MapCertificationStudentInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "certificationId"?: number;
  certification?: Certification;
  account?: Account;
}

export class MapCertificationStudent implements MapCertificationStudentInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "certificationId": number;
  certification: Certification;
  account: Account;
  constructor(data?: MapCertificationStudentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapCertificationStudent`.
   */
  public static getModelName() {
    return "MapCertificationStudent";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapCertificationStudent for dynamic purposes.
  **/
  public static factory(data: MapCertificationStudentInterface): MapCertificationStudent{
    return new MapCertificationStudent(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapCertificationStudent',
      plural: 'MapCertificationStudents',
      path: 'MapCertificationStudents',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "certificationId": {
          name: 'certificationId',
          type: 'number'
        },
      },
      relations: {
        certification: {
          name: 'certification',
          type: 'Certification',
          model: 'Certification',
          relationType: 'belongsTo',
                  keyFrom: 'certification_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
