/* tslint:disable */
import {
  Cart,
  Account,
  FeePlan,
  MapFeeplanCourse,
  ChequeTracker,
  UtrTracker
} from '../index';

declare var Object: any;
export interface OrderInterface {
  "currency": string;
  "status": number;
  "attempts": number;
  "amount": number;
  "amountPaid": number;
  "amountDue": number;
  "acceptPartialPayment"?: boolean;
  "notes"?: string;
  "date": Date;
  "trainingStartDate": Date;
  "dueDate": Date;
  "nextInvoiceDate"?: Date;
  "generateNextInvoice"?: boolean;
  "waveOffAmount"?: number;
  "discountPercent"?: number;
  "isVisibleToStudent": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "fee_plan_id"?: number;
  "map_feeplan_course_id"?: number;
  "accountId"?: number;
  cart?: Cart;
  account?: Account;
  feePlan?: FeePlan;
  feePlanCourse?: MapFeeplanCourse;
  chqTracker?: ChequeTracker[];
  utrTracker?: UtrTracker[];
}

export class Order implements OrderInterface {
  "currency": string;
  "status": number;
  "attempts": number;
  "amount": number;
  "amountPaid": number;
  "amountDue": number;
  "acceptPartialPayment": boolean;
  "notes": string;
  "date": Date;
  "trainingStartDate": Date;
  "dueDate": Date;
  "nextInvoiceDate": Date;
  "generateNextInvoice": boolean;
  "waveOffAmount": number;
  "discountPercent": number;
  "isVisibleToStudent": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "fee_plan_id": number;
  "map_feeplan_course_id": number;
  "accountId": number;
  cart: Cart;
  account: Account;
  feePlan: FeePlan;
  feePlanCourse: MapFeeplanCourse;
  chqTracker: ChequeTracker[];
  utrTracker: UtrTracker[];
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'Orders',
      path: 'Orders',
      idName: 'id',
      properties: {
        "currency": {
          name: 'currency',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "attempts": {
          name: 'attempts',
          type: 'number'
        },
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "amountPaid": {
          name: 'amountPaid',
          type: 'number'
        },
        "amountDue": {
          name: 'amountDue',
          type: 'number'
        },
        "acceptPartialPayment": {
          name: 'acceptPartialPayment',
          type: 'boolean',
          default: false
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "trainingStartDate": {
          name: 'trainingStartDate',
          type: 'Date'
        },
        "dueDate": {
          name: 'dueDate',
          type: 'Date'
        },
        "nextInvoiceDate": {
          name: 'nextInvoiceDate',
          type: 'Date'
        },
        "generateNextInvoice": {
          name: 'generateNextInvoice',
          type: 'boolean',
          default: true
        },
        "waveOffAmount": {
          name: 'waveOffAmount',
          type: 'number'
        },
        "discountPercent": {
          name: 'discountPercent',
          type: 'number'
        },
        "isVisibleToStudent": {
          name: 'isVisibleToStudent',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "fee_plan_id": {
          name: 'fee_plan_id',
          type: 'number'
        },
        "map_feeplan_course_id": {
          name: 'map_feeplan_course_id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        cart: {
          name: 'cart',
          type: 'Cart',
          model: 'Cart',
          relationType: 'belongsTo',
                  keyFrom: 'cart_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        feePlan: {
          name: 'feePlan',
          type: 'FeePlan',
          model: 'FeePlan',
          relationType: 'belongsTo',
                  keyFrom: 'fee_plan_id',
          keyTo: 'id'
        },
        feePlanCourse: {
          name: 'feePlanCourse',
          type: 'MapFeeplanCourse',
          model: 'MapFeeplanCourse',
          relationType: 'belongsTo',
                  keyFrom: 'map_feeplan_course_id',
          keyTo: 'id'
        },
        chqTracker: {
          name: 'chqTracker',
          type: 'ChequeTracker[]',
          model: 'ChequeTracker',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
        utrTracker: {
          name: 'utrTracker',
          type: 'UtrTracker[]',
          model: 'UtrTracker',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
      }
    }
  }
}
