import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AccountApi, OrganizationApi, UserRoleScopeApi, BrandingPreferenceApi } from 'src/app/shared/sdk';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap';

const misc: any = {
  sidebar_mini_active: true
};
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  scopes?: any[];
  showMenu: boolean;
  children?: ChildrenItems[];
  isPremium?: boolean;
  priority?:number;
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
// Menu Items
export let ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'ni-shop text-red',
    showMenu: false,
    scopes: ['read:dashboard', 'read:dashboardCount', 'read:fetchDashboard', 'DEFAULT']
  },
  {
    path: '/fee-plans',
    title: 'Fee Plans',
    type: 'link',
    icontype: 'ni-ui-04 text-red',
    showMenu: false,
    scopes: ['read:fetchFeePlanById', 'read:fetchFeePlanByAccountId']
  },
  {
    path: '/payment-tracker',
    title: 'Payment Tracker',
    type: 'link',
    icontype: 'ni-chart-pie-35 text-red',
    showMenu: false,
    scopes: ['create:addOrder', 'update:editOrder', 'create:createOrder', 'read:fetchPaymentByPayamentId'],
    isPremium: false
  },
  {
    path: '/reports',
    title: 'Reports',
    type: 'link',
    icontype: 'ni-collection text-red',
    showMenu: false,
    scopes: ['update:deRegisterUser', 'update:deActivateAccount']
  },

  {
    path: '/request',
    title: 'Resource Requests',
    type: 'link',
    icontype: 'ni-notification-70 text-red',
    showMenu: false,
    scopes: [
      'read:fetchProcurementsById',
      'update:updateProcurement',
      'update:deleteProcurement',
      'create:addProcurement',
      'update:uploadProcurementPicture'
    ]
  },
  // {
  //   path: "/student",
  //   title: "Students",
  //   type: "link",
  //   icontype: "ni-single-02 text-yellow"
  // },
  // {
  //   path: "/staff",
  //   title: "Staff",
  //   type: "link",
  //   icontype: "ni-single-02 text-danger"
  // },
  // {
  //   path: '/center',
  //   title: 'Centers',
  //   type: 'link',
  //   icontype: 'ni-ungroup text-red',
  //   showMenu: false,
  //   scopes: ['read:coachStudentsCenterhWise', 'update:assignCenterToStaff', 'update:courseInfrastructure', 'read:fetchCoachListOfOtherInfrastructures', 'update:uploadInfrastructurePicture']
  // },
  {
    path: '/attendance',
    title: 'Attendance Tracker',
    type: 'link',
    icontype: 'ni-align-left-2 text-red',
    showMenu: false,
    scopes: [
      'read:fetchAttendanceByAccountId',
      'create:markAttendance',
      'read:viewBatchAttendance',
      'read:userAttendance',
      'read:fetchWorkoutAttendeesCount',
      'create:bulkAttendance',
      'create:sessionAttendance',
      'update:updateBulkAttendance',
      'create:markCoachAttendance'
    ]
  },
  {
    path: '/role',
    title: 'Roles',
    type: 'link',
    showMenu: false,
    icontype: 'ni-archive-2 text-red',
    scopes: ['update:userRole']
  },
  // {
  //   path: "/course",
  //   title: "Courses",
  //   type: "link",
  //   icontype: "ni-bullet-list-67 text-info"
  // },

  {
    path: '/messaging',
    title: 'Messaging',
    type: 'link',
    icontype: 'ni-email-83 text-red',
    showMenu: false,
    scopes: [
      'create:createActivity',
      'read:readActivity',
      'update:updateActivity',
      'delete:deleteActivity',
      'create:sendSMS',
      'create:sendMobileNotification',
      'create:sendMessage',
      'read:firebaseToken',
      'create:sendBothNotifications'
    ]
  },
  {
    path: '/news',
    title: 'News',
    type: 'link',
    icontype: 'ni-box-2 text-red',
    showMenu: false,
    scopes: ['update:updatePost', 'update:uploadPostPicture', 'create:createPost', 'read:fetchPostByAccountId']
  },
  {
    path: '/assessment',
    title: 'Assessments',
    type: 'link',
    icontype: 'ni-album-2 text-red',
    showMenu: false,
    scopes: ['read:getAssessmentResultById']
  },
  // {
  //   path: "/form",
  //   title: "Forms",
  //   type: "link",
  //   icontype: "ni-single-copy-04 text-yellow"
  // },

  // {
  //   path: "/maintenance",
  //   title: "Maintenance",
  //   type: "link",
  //   icontype: "ni-support-16 text-warning"
  // },
  // {
  //   path: "/payments",
  //   title: "Payments",
  //   type: "link",
  //   icontype: "ni-money-coins text-yellow"
  // },
  // {
  //   path: '/chequetracking',
  //   title: 'Cheque Tracking',
  //   type: 'link',
  //   icontype: 'ni-support-16 text-red'
  // },
  // {
  //   path: "/paymentracking",
  //   title: "Payment Tracking",
  //   type: "link",
  //   icontype: "ni-books text-green"
  // },

  {
    path: '/coaching-drills',
    title: 'Coaching Drills',
    type: 'link',
    icontype: 'ni-chart-bar-32 text-red',
    showMenu: false,
    scopes: [
      'update:uploadCoachingDrillImage',
      'update:uploadCoachingDrillVideo',
      'update:AssignDrillToSchedulePlan',
      'read:fetchCoachingDrillAttendeesCount',
      'update:assignCoachingDrillToAccount',
      'update:assignWorkoutPlanToAccount',
      'update:createScheduleSession',
      'update:assignCoachingScheduleToAccount',
      'update:updateInjuryDetails',
      'delete:deleteInjuryDetails',
      'read:fetchCalendarDetails',
      'delete:deleteActivity',
      'read:upcomingSession'
    ]
  },
  // {
  //   path: '/lesson-plans',
  //   title: 'Session Plans',
  //   type: 'link',
  //   icontype: 'ni-compass-04 text-red',
  //   showMenu: false,
  //   scopes: ['update:createLessonPlan']
  // },
  {
    path: '/competition-tracker',
    title: 'Competition Tracker',
    type: 'link',
    icontype: 'ni-user-run text-red',
    showMenu: false,
    scopes: ['update:updateCertification', 'delete:deleteCertification']
  },
  {
    path: '/workout-plans',
    title: 'Workout Plans',
    type: 'link',
    icontype: 'ni-atom text-red',
    showMenu: false,
    scopes: ['DEFAULT']
  },
  {
    path: '/video-session',
    title: 'Video Session',
    type: 'link',
    icontype: 'ni-calendar-grid-58 text-red',
    showMenu: false,
    scopes: ['read:fetchVideoSessionByAccountId', 'read:fetchVideoSessionAttendeesCount', 'update:assignVideoSessionToAccount']
  },
  {
    path: '/team-progress',
    title: 'Team Progress',
    type: 'link',
    icontype: 'ni-chart-bar-32 text-red',
    showMenu: false,
    scopes: ['DEFAULT']
  },
  {
    path: '/tags',
    title: 'Tags',
    type: 'link',
    icontype: 'ni-tag text-red',
    showMenu: false,
    scopes: [
      'create:addStudentTag',
      'create:createStudentStudentTag',
      'delete:deleteStudentTag',
      'update:UntagStudent',
      'read:fetchTaggedStudentsCount'
    ]
  },
  // {
  //   path: "/calendar",
  //   title: "Calendar",
  //   type: "link",
  //   icontype: "ni-calendar-grid-58 text-warning"
  // }

  {
    path: '/publish',
    title: 'Publish',
    type: 'link',
    icontype: 'ni-send text-red',
    showMenu: false,
    scopes: ['DEFAULT']
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menuItems: any = [];
  public isCollapsed = true;
  public appUserId: any = '';
  public userRoleScope: any = [];
  public permittedScopes: any = [];
  public showSideBarMenuItem = false;
  public demoUrl!: SafeUrl;
  public modalRef: any;
 



  constructor(
    private router: Router,
    private http: HttpClient,
    private accountApi: AccountApi,
    private ursApi: UserRoleScopeApi,
    private organizationApi: OrganizationApi,
    private brandingPreferenceApi: BrandingPreferenceApi,
    private authService: AuthService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private mService: BsModalService,

  ) {
    this.demoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.calendly);
  }

  public brandLogo: any;
  bandLogoUrl = environment.apiUrl;
  // constructor(private router: Router, ,) {}

  ngOnInit() {
    this.getOrganizationDetails();
    this.getUserOfAccountId();
    this.changeMenu();
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.menuItems.map((item: any, index: any) => {
      if (item.scopes) this.menuItems[index].showMenu = false;
    });

    this.fetchLogo();
    // console.log('menu item',this.menuItems);
  }

  private changeMenu() {
    if (this.authService.getOrganizationType() == 'freelanceCoach') {
      ROUTES = [
        {
          path: '/dashboard',
          title: 'Dashboard',
          type: 'link',
          icontype: 'ni-shop text-red',
          showMenu: false,
          isPremium: false,
          scopes: ['read:dashboard', 'read:dashboardCount', 'read:fetchDashboard', 'DEFAULT'],
          priority:1
        },
        {
          path: '/fee-plans',
          title: 'Fee Plans',
          type: 'link',
          icontype: 'ni-ui-04 text-red',
          showMenu: false,
          isPremium: false,
          scopes: ['read:fetchFeePlanById', 'read:fetchFeePlanByAccountId'],
          priority:5  
        },
        {
          path: '/payment-tracker',
          title: 'Payment Tracker',
          type: 'link',
          icontype: 'ni-chart-pie-35 text-red',
          showMenu: false,
          scopes: ['create:addOrder', 'update:editOrder', 'create:createOrder', 'read:fetchPaymentByPayamentId'],
          isPremium: false,
          priority:6
        },
        {
          path: '/reports',
          title: 'Reports',
          type: 'link',
          icontype: 'ni-collection text-red',
          showMenu: false,
          scopes: ['update:deRegisterUser', 'update:deActivateAccount'],
          isPremium: true,
          priority:21
        },

        {
          path: '/request',
          title: 'Resource Requests',
          type: 'link',
          icontype: 'ni-notification-70 text-red',
          showMenu: false,
          scopes: [
            'read:fetchProcurementsById',
            'update:updateProcurement',
            'update:deleteProcurement',
            'create:addProcurement',
            'update:uploadProcurementPicture'
          ],
          isPremium: true,
          priority:20
        },
        {
          path: '/student',
          title: 'Students',
          type: 'link',
          icontype: 'fa fa-users text-red',
          isPremium: false,
          scopes: ['DEFAULT'],
          showMenu: false,
          priority:3
        },
        {
          path: '/staff',
          title: 'Staff',
          type: 'link',
          icontype: 'ni-single-02 text-danger',
          isPremium: true,
          showMenu: false,
          scopes: ['DEFAULT'],
          priority:19
        },
        {
          path: '/center',
          title: 'Centers',
          type: 'link',
          icontype: 'ni-ungroup text-red',
          showMenu: false,
          isPremium: false,
          scopes: [
            'read:coachStudentsCenterhWise',
            'update:assignCenterToStaff',
            'update:courseInfrastructure',
            'read:fetchCoachListOfOtherInfrastructures',
            'update:uploadInfrastructurePicture'
          ],
          priority:4
        },
        {
          path: '/attendance',
          title: 'Attendance Tracker',
          type: 'link',
          icontype: 'ni-align-left-2 text-red',
          showMenu: false,
          scopes: [
            'read:fetchAttendanceByAccountId',
            'create:markAttendance',
            'read:viewBatchAttendance',
            'read:userAttendance',
            'read:fetchWorkoutAttendeesCount',
            'create:bulkAttendance',
            'create:sessionAttendance',
            'update:updateBulkAttendance',
            'create:markCoachAttendance'
          ],
          isPremium: true,
          priority:18
        },
        {
          path: '/role',
          title: 'Roles',
          type: 'link',
          showMenu: false,
          icontype: 'ni-archive-2 text-red',
          scopes: ['update:userRole'],
          isPremium: true,
          priority:17

        },
        {
          path: '/course',
          title: 'Courses',
          type: 'link',
          icontype: 'ni-bullet-list-67 text-red',
          isPremium: false,
          showMenu: false,
          scopes: ['DEFAULT'],
          priority:2,
          
        
        },

        {
          path: '/messaging',
          title: 'Messaging',
          type: 'link',
          icontype: 'ni-email-83 text-red',
          showMenu: false,
          scopes: [
            'create:createActivity',
            'read:readActivity',
            'update:updateActivity',
            'delete:deleteActivity',
            'create:sendSMS',
            'create:sendMobileNotification',
            'create:sendMessage',
            'read:firebaseToken',
            'create:sendBothNotifications'
          ],
          isPremium: true,
          priority:16
        },
        {
          path: '/news',
          title: 'News',
          type: 'link',
          icontype: 'ni-box-2 text-red',
          showMenu: false,
          isPremium: true,
          scopes: ['update:updatePost', 'update:uploadPostPicture', 'create:createPost', 'read:fetchPostByAccountId'],
          priority:15
        },
        {
          path: '/assessment',
          title: 'Assessments',
          type: 'link',
          icontype: 'ni-album-2 text-red',
          showMenu: false,
          scopes: ['read:getAssessmentResultById'],
          isPremium: true,
          priority:14
        },
        // {
        //   path: "/form",
        //   title: "Forms",
        //   type: "link",
        //   icontype: "ni-single-copy-04 text-yellow"
        // },

        // {
        //   path: "/maintenance",
        //   title: "Maintenance",
        //   type: "link",
        //   icontype: "ni-support-16 text-warning"
        // },
        // {
        //   path: "/payments",
        //   title: "Payments",
        //   type: "link",
        //   icontype: "ni-money-coins text-yellow"
        // },
        // {
        //   path: '/chequetracking',
        //   title: 'Cheque Tracking',
        //   type: 'link',
        //   icontype: 'ni-support-16 text-red'
        // },
        // {
        //   path: "/paymentracking",
        //   title: "Payment Tracking",
        //   type: "link",
        //   icontype: "ni-books text-green"
        // },

        {
          path: '/coaching-drills',
          title: 'Coaching Drills',
          type: 'link',
          icontype: 'ni-chart-bar-32 text-red',
          showMenu: false,
          scopes: [
            'update:uploadCoachingDrillImage',
            'update:uploadCoachingDrillVideo',
            'update:AssignDrillToSchedulePlan',
            'read:fetchCoachingDrillAttendeesCount',
            'update:assignCoachingDrillToAccount',
            'update:assignWorkoutPlanToAccount',
            'update:createScheduleSession',
            'update:assignCoachingScheduleToAccount',
            'update:updateInjuryDetails',
            'delete:deleteInjuryDetails',
            'read:fetchCalendarDetails',
            'delete:deleteActivity',
            'read:upcomingSession'
          ],
          isPremium: true,
          priority:13
        },
        // {
        //   path: '/lesson-plans',
        //   title: 'Session Plans',
        //   type: 'link',
        //   icontype: 'ni-compass-04 text-red',
        //   showMenu: false,
        //   scopes: ['update:createLessonPlan']
        // },
        {
          path: '/competition-tracker',
          title: 'Competition Tracker',
          type: 'link',
          icontype: 'ni-user-run text-red',
          showMenu: false,
          scopes: ['update:updateCertification', 'delete:deleteCertification'],
          isPremium: true,
          priority:12
        },
        {
          path: '/workout-plans',
          title: 'Workout Plans',
          type: 'link',
          icontype: 'ni-atom text-red',
          showMenu: false,
          scopes: ['DEFAULT'],
          isPremium: true,
          priority:11
        },
        {
          path: '/video-session',
          title: 'Video Session',
          type: 'link',
          icontype: 'ni-calendar-grid-58 text-red',
          showMenu: false,
          isPremium: true,
          scopes: ['read:fetchVideoSessionByAccountId', 'read:fetchVideoSessionAttendeesCount', 'update:assignVideoSessionToAccount'],
          priority:10
        },
        {
          path: '/team-progress',
          title: 'Team Progress',
          type: 'link',
          icontype: 'ni-chart-bar-32 text-red',
          showMenu: false,
          scopes: ['DEFAULT'],
          isPremium: true,
          priority:9
        },
        {
          path: '/tags',
          title: 'Tags',
          type: 'link',
          icontype: 'ni-tag text-red',
          showMenu: false,
          scopes: [
            'create:addStudentTag',
            'create:createStudentStudentTag',
            'delete:deleteStudentTag',
            'update:UntagStudent',
            'read:fetchTaggedStudentsCount'
          ],
          isPremium: true,
          priority:8
        },
        // {
        //   path: "/calendar",
        //   title: "Calendar",
        //   type: "link",
        //   icontype: "ni-calendar-grid-58 text-warning"
        // }

        {
          path: '/publish',
          title: 'Publish',
          type: 'link',
          icontype: 'ni-send text-red',
          showMenu: false,
          scopes: ['DEFAULT'],
          isPremium:false,
          priority:7
        }
      ];
      ROUTES = ROUTES.sort((a: any, b: any) => a.priority - b.priority);
  
    } else {
      ROUTES = [
        {
          path: '/dashboard',
          title: 'Dashboard',
          type: 'link',
          icontype: 'ni-shop text-red',
          showMenu: false,
          scopes: ['read:dashboard', 'read:dashboardCount', 'read:fetchDashboard', 'DEFAULT'],
          isPremium: false
        },
        {
          path: '/fee-plans',
          title: 'Fee Plans',
          type: 'link',
          icontype: 'ni-ui-04 text-red',
          showMenu: false,
          scopes: ['read:fetchFeePlanById', 'read:fetchFeePlanByAccountId'],
          isPremium: false
        },
        {
          path: '/payment-tracker',
          title: 'Payment Tracker',
          type: 'link',
          icontype: 'ni-chart-pie-35 text-red',
          showMenu: false,
          scopes: ['create:addOrder', 'update:editOrder', 'create:createOrder', 'read:fetchPaymentByPayamentId'],
          isPremium: false
          
        },
        {
          path: '/reports',
          title: 'Reports',
          type: 'link',
          icontype: 'ni-collection text-red',
          showMenu: false,
          scopes: ['update:deRegisterUser', 'update:deActivateAccount'],
          isPremium: false
        },

        {
          path: '/request',
          title: 'Resource Requests',
          type: 'link',
          icontype: 'ni-notification-70 text-red',
          showMenu: false,
          scopes: [
            'read:fetchProcurementsById',
            'update:updateProcurement',
            'update:deleteProcurement',
            'create:addProcurement',
            'update:uploadProcurementPicture'
          ],
          isPremium: false
        },
        // {
        //   path: "/student",
        //   title: "Students",
        //   type: "link",
        //   icontype: "ni-single-02 text-yellow"
        // },
        // {
        //   path: "/staff",
        //   title: "Staff",
        //   type: "link",
        //   icontype: "ni-single-02 text-danger"
        // },
        // {
        //   path: '/center',
        //   title: 'Centers',
        //   type: 'link',
        //   icontype: 'ni-ungroup text-red',
        //   showMenu: false,
        //   scopes: ['read:coachStudentsCenterhWise', 'update:assignCenterToStaff', 'update:courseInfrastructure', 'read:fetchCoachListOfOtherInfrastructures', 'update:uploadInfrastructurePicture']
        // },
        {
          path: '/attendance',
          title: 'Attendance Tracker',
          type: 'link',
          icontype: 'ni-align-left-2 text-red',
          showMenu: false,
          scopes: [
            'read:fetchAttendanceByAccountId',
            'create:markAttendance',
            'read:viewBatchAttendance',
            'read:userAttendance',
            'read:fetchWorkoutAttendeesCount',
            'create:bulkAttendance',
            'create:sessionAttendance',
            'update:updateBulkAttendance',
            'create:markCoachAttendance'
          ],
          isPremium: false
        },
        {
          path: '/role',
          title: 'Roles',
          type: 'link',
          showMenu: false,
          icontype: 'ni-archive-2 text-red',
          scopes: ['update:userRole'],
          isPremium: false
        },
        // {
        //   path: "/course",
        //   title: "Courses",
        //   type: "link",
        //   icontype: "ni-bullet-list-67 text-info"
        // },

        {
          path: '/messaging',
          title: 'Messaging',
          type: 'link',
          icontype: 'ni-email-83 text-red',
          showMenu: false,
          scopes: [
            'create:createActivity',
            'read:readActivity',
            'update:updateActivity',
            'delete:deleteActivity',
            'create:sendSMS',
            'create:sendMobileNotification',
            'create:sendMessage',
            'read:firebaseToken',
            'create:sendBothNotifications'
          ],
          isPremium: false
        },
        {
          path: '/news',
          title: 'News',
          type: 'link',
          icontype: 'ni-box-2 text-red',
          showMenu: false,
          isPremium: false,
          scopes: ['update:updatePost', 'update:uploadPostPicture', 'create:createPost', 'read:fetchPostByAccountId']
        },
        {
          path: '/assessment',
          title: 'Assessments',
          type: 'link',
          icontype: 'ni-album-2 text-red',
          showMenu: false,
          scopes: ['read:getAssessmentResultById'],
          isPremium: false
        },
        // {
        //   path: "/form",
        //   title: "Forms",
        //   type: "link",
        //   icontype: "ni-single-copy-04 text-yellow"
        // },

        // {
        //   path: "/maintenance",
        //   title: "Maintenance",
        //   type: "link",
        //   icontype: "ni-support-16 text-warning"
        // },
        // {
        //   path: "/payments",
        //   title: "Payments",
        //   type: "link",
        //   icontype: "ni-money-coins text-yellow"
        // },
        // {
        //   path: '/chequetracking',
        //   title: 'Cheque Tracking',
        //   type: 'link',
        //   icontype: 'ni-support-16 text-red'
        // },
        // {
        //   path: "/paymentracking",
        //   title: "Payment Tracking",
        //   type: "link",
        //   icontype: "ni-books text-green"
        // },

        {
          path: '/coaching-drills',
          title: 'Coaching Drills',
          type: 'link',
          icontype: 'ni-chart-bar-32 text-red',
          showMenu: false,
          scopes: [
            'update:uploadCoachingDrillImage',
            'update:uploadCoachingDrillVideo',
            'update:AssignDrillToSchedulePlan',
            'read:fetchCoachingDrillAttendeesCount',
            'update:assignCoachingDrillToAccount',
            'update:assignWorkoutPlanToAccount',
            'update:createScheduleSession',
            'update:assignCoachingScheduleToAccount',
            'update:updateInjuryDetails',
            'delete:deleteInjuryDetails',
            'read:fetchCalendarDetails',
            'delete:deleteActivity',
            'read:upcomingSession'
          ],
          isPremium: false
        },
        // {
        //   path: '/lesson-plans',
        //   title: 'Session Plans',
        //   type: 'link',
        //   icontype: 'ni-compass-04 text-red',
        //   showMenu: false,
        //   scopes: ['update:createLessonPlan']
        // },
        {
          path: '/competition-tracker',
          title: 'Competition Tracker',
          type: 'link',
          icontype: 'ni-user-run text-red',
          showMenu: false,
          scopes: ['update:updateCertification', 'delete:deleteCertification'],
          isPremium: false
        },
        {
          path: '/workout-plans',
          title: 'Workout Plans',
          type: 'link',
          icontype: 'ni-atom text-red',
          showMenu: false,
          scopes: ['DEFAULT'],
          isPremium: false
        },
        {
          path: '/video-session',
          title: 'Video Session',
          type: 'link',
          icontype: 'ni-calendar-grid-58 text-red',
          showMenu: false,
          isPremium: false,
          scopes: ['read:fetchVideoSessionByAccountId', 'read:fetchVideoSessionAttendeesCount', 'update:assignVideoSessionToAccount']
        },
        {
          path: '/team-progress',
          title: 'Team Progress',
          type: 'link',
          icontype: 'ni-chart-bar-32 text-red',
          showMenu: false,
          isPremium: false,
          scopes: ['DEFAULT']
        },
        {
          path: '/tags',
          title: 'Tags',
          type: 'link',
          icontype: 'ni-tag text-red',
          showMenu: false,
          isPremium: false,
          scopes: [
            'create:addStudentTag',
            'create:createStudentStudentTag',
            'delete:deleteStudentTag',
            'update:UntagStudent',
            'read:fetchTaggedStudentsCount'
          ]
        },
        // {
        //   path: "/calendar",
        //   title: "Calendar",
        //   type: "link",
        //   icontype: "ni-calendar-grid-58 text-warning"
        // }

        {
          path: '/publish',
          title: 'Publish',
          type: 'link',
          icontype: 'ni-send text-red',
          showMenu: false,
          isPremium: false,
          scopes: ['DEFAULT']
        }
      ];
    }
  }

  // getting of the organization api
  private getOrganizationDetails() {
    this.organizationApi
      .find()
      .toPromise()
      .then((org: any) => {
        console.log('org--->',org)
        // condition to hide and show the publish functionality.
        if (org[0]?.isPublished == null || Number(org[0]?.isPublished) !== 1) {
          this.menuItems = this.menuItems.filter((res) => res.title.toLowerCase() !== 'publish');

        }else{
          this.menuItems = this.menuItems
        }
      });
  }

  fetchLogo() {
    // this.http.get(this.bandLogoUrl + '/branding-preference/fetch-logo').subscribe((showResult: any) => {
    //   //  console.log(showResult);
    //   this.brandLogo = showResult.data;
    // });
    this.brandingPreferenceApi
      .find({ where: { isActive: 1 }, include: ['organizationLogoFile'] })
      .toPromise()
      .then((response: any) => {
        // console.log(response);
        this.brandLogo = response;
      });
  }

  getUserOfAccountId() {
    const adminScopes = [];
    this.accountApi
      .find({ where: { id: localStorage.getItem('currentAccountId') } })
      .toPromise()
      .then((accountresp: any) => {
        this.appUserId = accountresp[0].appUserId;
      })
      .then(() => {
        this.ursApi
          .find({ where: { app_user_id: this.appUserId, appUserId: this.appUserId } })
          .toPromise()
          .then((ursResp: any) => {
            Promise.all(
              ursResp[0].scope.map((val: any) => {
                Object.keys(val.scopes).map((scopeValues) => {
                  // this.permittedScopes.push(scopeValues);
                  adminScopes.push(scopeValues);
                });
              })
            ).then(() => {
              this.permittedScopes = adminScopes;
              // console.log('check permitted',this.permittedScopes)
              this.checkSidebarPermissions();
            });
          });
      });
  }

  checkSidebarPermissions() {
    // console.log('menu',this.menuItems)
    this.menuItems.map((item: any, index: any) => {
      if (item.scopes !== undefined) {
        item.scopes.map((scp: any) => {
          // console.log(scp);
          if (this.permittedScopes.includes(scp)) {
            this.menuItems[index].showMenu = true;
          }
        });
      }
    });

    // console.log(this.menuItems, this.permittedScopes);
  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName('sidenav-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('g-sidenav-pinned')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('g-sidenav-pinned');
      body.classList.add('g-sidenav-hidden');
      sidenavToggler.classList.remove('active');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('g-sidenav-pinned');
      body.classList.remove('g-sidenav-hidden');
      sidenavToggler.classList.add('active');
      misc.sidebar_mini_active = true;
    }
  }

  // opening of the subscribe modal
  public openSubscribeModal(modalName: TemplateRef<any>, menuItem: any) {
    if (menuItem.isPremium) {
      this.modalService.open(modalName,{size:'md'});
    } else {
      this.modalService.dismissAll();
    }
  }

  public contactUs(template: TemplateRef<any>){
    this.modalRef = this.mService.show(template, Object.assign({}, { class: 'demo-modal modal-lg' }));
  }
}
