/* tslint:disable */

declare var Object: any;
export interface FunctionalMovementScreeningInterface {
  "studentId": number;
  "coachId": number;
  "scores": string;
  "assessmentYear": string;
  "assessment_quarter": number;
  "totalScore": number;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class FunctionalMovementScreening implements FunctionalMovementScreeningInterface {
  "studentId": number;
  "coachId": number;
  "scores": string;
  "assessmentYear": string;
  "assessment_quarter": number;
  "totalScore": number;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: FunctionalMovementScreeningInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FunctionalMovementScreening`.
   */
  public static getModelName() {
    return "FunctionalMovementScreening";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FunctionalMovementScreening for dynamic purposes.
  **/
  public static factory(data: FunctionalMovementScreeningInterface): FunctionalMovementScreening{
    return new FunctionalMovementScreening(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FunctionalMovementScreening',
      plural: 'FunctionalMovementScreenings',
      path: 'FunctionalMovementScreenings',
      idName: 'id',
      properties: {
        "studentId": {
          name: 'studentId',
          type: 'number'
        },
        "coachId": {
          name: 'coachId',
          type: 'number'
        },
        "scores": {
          name: 'scores',
          type: 'string'
        },
        "assessmentYear": {
          name: 'assessmentYear',
          type: 'string'
        },
        "assessment_quarter": {
          name: 'assessment_quarter',
          type: 'number'
        },
        "totalScore": {
          name: 'totalScore',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
