/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface TestInterface {
  "name": string;
  "attendeesType": number;
  "Date": Date;
  "isFixedTimeDuration": number;
  "startTime"?: Date;
  "endTime"?: Date;
  "isRecurring": number;
  "recurringType"?: number;
  "allowSelfAssessment": number;
  "status"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  createdBy?: Account;
}

export class Test implements TestInterface {
  "name": string;
  "attendeesType": number;
  "Date": Date;
  "isFixedTimeDuration": number;
  "startTime": Date;
  "endTime": Date;
  "isRecurring": number;
  "recurringType": number;
  "allowSelfAssessment": number;
  "status": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  createdBy: Account;
  constructor(data?: TestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Test`.
   */
  public static getModelName() {
    return "Test";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Test for dynamic purposes.
  **/
  public static factory(data: TestInterface): Test{
    return new Test(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Test',
      plural: 'Tests',
      path: 'Tests',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "Date": {
          name: 'Date',
          type: 'Date'
        },
        "isFixedTimeDuration": {
          name: 'isFixedTimeDuration',
          type: 'number'
        },
        "startTime": {
          name: 'startTime',
          type: 'Date'
        },
        "endTime": {
          name: 'endTime',
          type: 'Date'
        },
        "isRecurring": {
          name: 'isRecurring',
          type: 'number'
        },
        "recurringType": {
          name: 'recurringType',
          type: 'number'
        },
        "allowSelfAssessment": {
          name: 'allowSelfAssessment',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
