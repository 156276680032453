/* tslint:disable */
import {
  Infrastructure,
  AppFile
} from '../index';

declare var Object: any;
export interface AmenityInterface {
  "name": string;
  "number": number;
  "category": number;
  "description"?: string;
  "size"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "course_id"?: number;
  infrastructure?: Infrastructure;
  amenityPicture?: AppFile;
}

export class Amenity implements AmenityInterface {
  "name": string;
  "number": number;
  "category": number;
  "description": string;
  "size": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "course_id": number;
  infrastructure: Infrastructure;
  amenityPicture: AppFile;
  constructor(data?: AmenityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Amenity`.
   */
  public static getModelName() {
    return "Amenity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Amenity for dynamic purposes.
  **/
  public static factory(data: AmenityInterface): Amenity{
    return new Amenity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Amenity',
      plural: 'Amenities',
      path: 'Amenities',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "number": {
          name: 'number',
          type: 'number'
        },
        "category": {
          name: 'category',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "size": {
          name: 'size',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "course_id": {
          name: 'course_id',
          type: 'number'
        },
      },
      relations: {
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
        amenityPicture: {
          name: 'amenityPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
