/* tslint:disable */

declare var Object: any;
export interface MusculoskeletalScreeningInterface {
  "scores": string;
  "studentId": number;
  "coachId": number;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class MusculoskeletalScreening implements MusculoskeletalScreeningInterface {
  "scores": string;
  "studentId": number;
  "coachId": number;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: MusculoskeletalScreeningInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MusculoskeletalScreening`.
   */
  public static getModelName() {
    return "MusculoskeletalScreening";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MusculoskeletalScreening for dynamic purposes.
  **/
  public static factory(data: MusculoskeletalScreeningInterface): MusculoskeletalScreening{
    return new MusculoskeletalScreening(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MusculoskeletalScreening',
      plural: 'MusculoskeletalScreenings',
      path: 'MusculoskeletalScreenings',
      idName: 'id',
      properties: {
        "scores": {
          name: 'scores',
          type: 'string'
        },
        "studentId": {
          name: 'studentId',
          type: 'number'
        },
        "coachId": {
          name: 'coachId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
