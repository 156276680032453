/* tslint:disable */
import {
  Account,
  Order,
  Batch,
  Infrastructure
} from '../index';

declare var Object: any;
export interface AccountSessionInterface {
  "noOfSession": number;
  "remainingSession": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "batch_id"?: number;
  "infrastructure_id"?: number;
  "accountId"?: number;
  account?: Account;
  order?: Order;
  batch?: Batch;
  infrastructure?: Infrastructure;
}

export class AccountSession implements AccountSessionInterface {
  "noOfSession": number;
  "remainingSession": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "batch_id": number;
  "infrastructure_id": number;
  "accountId": number;
  account: Account;
  order: Order;
  batch: Batch;
  infrastructure: Infrastructure;
  constructor(data?: AccountSessionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountSession`.
   */
  public static getModelName() {
    return "AccountSession";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountSession for dynamic purposes.
  **/
  public static factory(data: AccountSessionInterface): AccountSession{
    return new AccountSession(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountSession',
      plural: 'AccountSessions',
      path: 'AccountSessions',
      idName: 'id',
      properties: {
        "noOfSession": {
          name: 'noOfSession',
          type: 'number'
        },
        "remainingSession": {
          name: 'remainingSession',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "batch_id": {
          name: 'batch_id',
          type: 'number'
        },
        "infrastructure_id": {
          name: 'infrastructure_id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'order_id',
          keyTo: 'id'
        },
        batch: {
          name: 'batch',
          type: 'Batch',
          model: 'Batch',
          relationType: 'belongsTo',
                  keyFrom: 'batch_id',
          keyTo: 'id'
        },
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
      }
    }
  }
}
