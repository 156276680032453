import { Component, OnInit, Output, AfterViewInit, Input, EventEmitter } from '@angular/core';
import noUiSlider from 'nouislider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewInit {
  @Input() input: any;
  @Output() sliderEmitter: EventEmitter<any> = new EventEmitter<any>();
  value: any;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    const slider = document.getElementById(this.input.id);
    this.value = noUiSlider.create(slider, {
      start: 0,
      connect: [true, false],
      range: {
        min: this.input.min,
        max: this.input.max
      }
    });
    this.value.on('change', () => {
      const percentageString: string = this.value.target.childNodes[0].childNodes[1].innerHTML
        .split('aria-value')[3]
        .split('=')[1]
        .replace('"', '');
      this.sliderEmitter.emit({ value: (parseFloat(percentageString.replace('"', '')) * (this.input.max / 100)).toFixed(2) });
    });
  }
}
