/* tslint:disable */
import {
  Event
} from '../index';

declare var Object: any;
export interface CalendarInterface {
  "day": number;
  "week": number;
  "month": number;
  "year": number;
  "isBusinessDay": boolean;
  "isHoliday": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  events?: Event[];
}

export class Calendar implements CalendarInterface {
  "day": number;
  "week": number;
  "month": number;
  "year": number;
  "isBusinessDay": boolean;
  "isHoliday": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  events: Event[];
  constructor(data?: CalendarInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Calendar`.
   */
  public static getModelName() {
    return "Calendar";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Calendar for dynamic purposes.
  **/
  public static factory(data: CalendarInterface): Calendar{
    return new Calendar(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Calendar',
      plural: 'Calendars',
      path: 'Calendars',
      idName: 'id',
      properties: {
        "day": {
          name: 'day',
          type: 'number'
        },
        "week": {
          name: 'week',
          type: 'number'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "year": {
          name: 'year',
          type: 'number'
        },
        "isBusinessDay": {
          name: 'isBusinessDay',
          type: 'boolean',
          default: false
        },
        "isHoliday": {
          name: 'isHoliday',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        events: {
          name: 'events',
          type: 'Event[]',
          model: 'Event',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'calendar_id'
        },
      }
    }
  }
}
