/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface AppFileInterface {
  "path"?: string;
  "containerName"?: string;
  "checksum"?: string;
  "originalFileName"?: string;
  "name": string;
  "size"?: number;
  "extension"?: string;
  "mimeType"?: string;
  "location"?: string;
  "academicYear"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountId"?: number;
  account?: Account;
}

export class AppFile implements AppFileInterface {
  "path": string;
  "containerName": string;
  "checksum": string;
  "originalFileName": string;
  "name": string;
  "size": number;
  "extension": string;
  "mimeType": string;
  "location": string;
  "academicYear": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountId": number;
  account: Account;
  constructor(data?: AppFileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppFile`.
   */
  public static getModelName() {
    return "AppFile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppFile for dynamic purposes.
  **/
  public static factory(data: AppFileInterface): AppFile{
    return new AppFile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppFile',
      plural: 'AppFiles',
      path: 'AppFiles',
      idName: 'id',
      properties: {
        "path": {
          name: 'path',
          type: 'string'
        },
        "containerName": {
          name: 'containerName',
          type: 'string'
        },
        "checksum": {
          name: 'checksum',
          type: 'string'
        },
        "originalFileName": {
          name: 'originalFileName',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "size": {
          name: 'size',
          type: 'number'
        },
        "extension": {
          name: 'extension',
          type: 'string'
        },
        "mimeType": {
          name: 'mimeType',
          type: 'string'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "academicYear": {
          name: 'academicYear',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
