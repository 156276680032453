/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface TestScheduleInterface {
  "startTime": Date;
  "endTime": Date;
  "testDate": Date;
  "attendeesType": number;
  "isFixedTimeDuration": number;
  "isRecurring": number;
  "recurringType": number;
  "allowSelfAssessment": number;
  "status": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by"?: number;
  createdBy?: Account;
}

export class TestSchedule implements TestScheduleInterface {
  "startTime": Date;
  "endTime": Date;
  "testDate": Date;
  "attendeesType": number;
  "isFixedTimeDuration": number;
  "isRecurring": number;
  "recurringType": number;
  "allowSelfAssessment": number;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by": number;
  createdBy: Account;
  constructor(data?: TestScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TestSchedule`.
   */
  public static getModelName() {
    return "TestSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TestSchedule for dynamic purposes.
  **/
  public static factory(data: TestScheduleInterface): TestSchedule{
    return new TestSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TestSchedule',
      plural: 'TestSchedules',
      path: 'TestSchedules',
      idName: 'id',
      properties: {
        "startTime": {
          name: 'startTime',
          type: 'Date'
        },
        "endTime": {
          name: 'endTime',
          type: 'Date'
        },
        "testDate": {
          name: 'testDate',
          type: 'Date'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "isFixedTimeDuration": {
          name: 'isFixedTimeDuration',
          type: 'number'
        },
        "isRecurring": {
          name: 'isRecurring',
          type: 'number'
        },
        "recurringType": {
          name: 'recurringType',
          type: 'number'
        },
        "allowSelfAssessment": {
          name: 'allowSelfAssessment',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "created_by": {
          name: 'created_by',
          type: 'number'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by',
          keyTo: 'id'
        },
      }
    }
  }
}
