/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface SessionInterface {
  "attendeesType": number;
  "sessionNotes"?: string;
  "sessionDate": Date;
  "status"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id"?: number;
  account?: Account;
  accounts?: Account[];
}

export class Session implements SessionInterface {
  "attendeesType": number;
  "sessionNotes": string;
  "sessionDate": Date;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id": number;
  account: Account;
  accounts: Account[];
  constructor(data?: SessionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Session`.
   */
  public static getModelName() {
    return "Session";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Session for dynamic purposes.
  **/
  public static factory(data: SessionInterface): Session{
    return new Session(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Session',
      plural: 'Sessions',
      path: 'Sessions',
      idName: 'id',
      properties: {
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "sessionNotes": {
          name: 'sessionNotes',
          type: 'string'
        },
        "sessionDate": {
          name: 'sessionDate',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'AccountSession',
          keyThrough: 'account_id',
          keyFrom: 'id',
          keyTo: 'session_id'
        },
      }
    }
  }
}
