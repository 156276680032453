import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/consts/global-constants';
import { Store } from '@ngrx/store';
import { changeLanguage } from '../state/translate.action';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
  languages = GlobalConstants.Languages;
  defaultLanguage: string;
  languageForm: FormGroup;

  public selectedFeature = 'Academy-Managment';
  // tslint:disable-next-line:no-non-null-assertion
  public feature: string = this.route.snapshot.paramMap.get('feature')!;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private store: Store<{ language: { language: string } }>
  ) {
    this.languageForm = this.formBuilder.group({
      selectedLanguage: ['en']
    });

    const selectedLanguage = this.cookieService.get('language');
    console.log('------------------Features Page-----------------------------', selectedLanguage);
    if (selectedLanguage) {
      translate.setDefaultLang(selectedLanguage);
      this.translate.use(selectedLanguage);
      this.languageForm.patchValue({ selectedLanguage: this.cookieService.get('language') });
    } else {
      translate.setDefaultLang('en');
      this.translate.use('en');
      this.cookieService.set('language', 'en');
    }
  }

  ngOnInit(): void {
  }



  showfeature(value: string) {
    // this.router.navigate(['/features', value]); // Assuming 'products' is the route and 'productId' is the parameter name
    this.router.navigate(['/features', value])
      .then(() => {
        window.location.reload();
      });
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    this.cookieService.set('language', language);
  }

}
