/* tslint:disable */
import {
  AccountAssessmentParameterResult
} from '../index';

declare var Object: any;
export interface AssessmentResultInterface {
  "score"?: any;
  "assessmentDate": Date;
  "remarks"?: string;
  "status"?: boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_assessment_parameter_id"?: number;
  accountAssessmentParameter?: AccountAssessmentParameterResult;
}

export class AssessmentResult implements AssessmentResultInterface {
  "score": any;
  "assessmentDate": Date;
  "remarks": string;
  "status": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_assessment_parameter_id": number;
  accountAssessmentParameter: AccountAssessmentParameterResult;
  constructor(data?: AssessmentResultInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentResult`.
   */
  public static getModelName() {
    return "AssessmentResult";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentResult for dynamic purposes.
  **/
  public static factory(data: AssessmentResultInterface): AssessmentResult{
    return new AssessmentResult(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentResult',
      plural: 'AssessmentResults',
      path: 'AssessmentResults',
      idName: 'id',
      properties: {
        "score": {
          name: 'score',
          type: 'any'
        },
        "assessmentDate": {
          name: 'assessmentDate',
          type: 'Date'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_assessment_parameter_id": {
          name: 'account_assessment_parameter_id',
          type: 'number'
        },
      },
      relations: {
        accountAssessmentParameter: {
          name: 'accountAssessmentParameter',
          type: 'AccountAssessmentParameterResult',
          model: 'AccountAssessmentParameterResult',
          relationType: 'belongsTo',
                  keyFrom: 'account_assessment_parameter_id',
          keyTo: 'id'
        },
      }
    }
  }
}
