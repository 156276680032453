/* tslint:disable */
import {
  CoachingDrill,
  CoachingSchedule
} from '../index';

declare var Object: any;
export interface MapCoachingDrillScheduleInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "coaching_drill_id"?: number;
  "coaching_schedule_id"?: number;
  "coachingScheduleId"?: number;
  coachingDrill?: CoachingDrill;
  coachingSchedule?: CoachingSchedule;
}

export class MapCoachingDrillSchedule implements MapCoachingDrillScheduleInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "coaching_drill_id": number;
  "coaching_schedule_id": number;
  "coachingScheduleId": number;
  coachingDrill: CoachingDrill;
  coachingSchedule: CoachingSchedule;
  constructor(data?: MapCoachingDrillScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapCoachingDrillSchedule`.
   */
  public static getModelName() {
    return "MapCoachingDrillSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapCoachingDrillSchedule for dynamic purposes.
  **/
  public static factory(data: MapCoachingDrillScheduleInterface): MapCoachingDrillSchedule{
    return new MapCoachingDrillSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapCoachingDrillSchedule',
      plural: 'MapCoachingDrillSchedules',
      path: 'MapCoachingDrillSchedules',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "coaching_drill_id": {
          name: 'coaching_drill_id',
          type: 'number'
        },
        "coaching_schedule_id": {
          name: 'coaching_schedule_id',
          type: 'number'
        },
        "coachingScheduleId": {
          name: 'coachingScheduleId',
          type: 'number'
        },
      },
      relations: {
        coachingDrill: {
          name: 'coachingDrill',
          type: 'CoachingDrill',
          model: 'CoachingDrill',
          relationType: 'belongsTo',
                  keyFrom: 'coaching_drill_id',
          keyTo: 'id'
        },
        coachingSchedule: {
          name: 'coachingSchedule',
          type: 'CoachingSchedule',
          model: 'CoachingSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'coaching_schedule_id',
          keyTo: 'id'
        },
      }
    }
  }
}
