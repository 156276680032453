export class GlobalConstants {
    public static Languages = [
        {
            id: 100,
            name: 'Select Language',
            value: ''
        },
        {
            id: 1,
            name: 'English (US)',
            value: 'en'
        },
        {
            id: 2,
            name: 'Chinese (PRC)',
            value: 'zh-CN'
        },
        {
            id: 3,
            name: 'Dutch',
            value: 'nl'
        },
        {
            id: 4,
            name: 'French',
            value: 'fr'
        },
        {
            id: 5,
            name: 'German',
            value: 'de'
        },
        {
            id: 6,
            name: 'Hindi',
            value: 'hi'
        },
        {
            id: 7,
            name: 'Italian',
            value: 'it'
        },
        {
            id: 8,
            name: 'Japanese',
            value: 'ja'
        },
        {
            id: 9,
            name: 'Korean',
            value: 'ko'
        },
        {
            id: 10,
            name: 'Russian',
            value: 'ru'
        },
        {
            id: 11,
            name: 'Spanish',
            value: 'es'
        },
        {
            id: 12,
            name: 'Thai',
            value: 'th'
        },
    ];

    public static notificationTypes = [
        {
            type: 'Birthday Notification',
            subtype: 'Birthday wishes message',
            routerLink: 'birthday-notification',
            value: 'birthday',
            parameters: { firstname: 'First Name', lastname: 'Last Name', organization_name: 'Organization Name' },
            hasChildren: false,
        },
        {
            type: 'Payment Reminder',
            subtype: 'Outstanding payments reminder 3 days before due date',
            routerLink: 'paymentreminder-notification',
            value: 'paymentreminder',
            hasChildren: true,
            childValues: ['prepaymentreminder', 'postpaymentreminder'],
            children: [{
                type: 'Pre Payment Due Date Reminder',
                parameters: { days: 'Days', firstname: 'First Name', invoice_date: 'Invoice Date', lastname: 'Last Name', organization_name: 'Organization Name', payment_info: 'Payment Info', total_payment_due: 'Total Payment Due' },
                value: 'prepaymentreminder',
            },
            {
                type: 'Post Payment Due Date Reminder',
                parameters: { days: 'Days', firstname: 'First Name', invoice_date: 'Invoice Date', lastname: 'Last Name', organization_name: 'Organization Name', payment_info: 'Payment Info', total_payment_due: 'Total Payment Due' },
                value: 'postpaymentreminder',
            }],
        },
        {
            type: 'Payment Received',
            subtype: 'Payment received thank you note',
            routerLink: 'paymentreceived-notification',
            value: 'paymentreceived',
            parameters: { cgst: 'CGST', firstname: 'First Name', invoice_date: 'Invoice Date', lastname: 'Last Name', organization_name: 'Organization Name', payment_description: 'Payment Description', payment_info: 'Payment Info', payment_method: 'Payment Method', period: 'Period', sgst: 'SGST', sub_total_amount: 'Sub-Total Amount', total_amount: 'Total Amount', total_amount_in_words: 'Total Amount in words' },
            hasChildren: false
        },
        {
            type: 'Download Invities',
            subtype: 'Invite to download to registered email on new registrations',
            routerLink: 'downloadinvities-notification',
            value: 'downloadinvities',
            parameters: { firstname: 'First Name', lastname: 'Last Name', organization_name: 'Organization Name' },
            hasChildren: false
        },
        {
            type: 'Post Notification',
            subtype: 'post notification messages',
            routerLink: 'post-notification',
            value: 'post',
            parameters: {
                author: 'Author', content: 'Content', date_published_preference: 'Date Published Preference',
                firstname: 'First Name', lastname: 'Last Name', organization_name: 'Organization Name', post_image: 'Post Image', video_url: 'Video URL'
            },
            hasChildren: false
        },
        {
            type: 'Cheque Status Notification',
            subtype: 'cheque status change messages',
            routerLink: 'cheque-notification',
            value: 'cheque',
            parameters: { cheque_no: 'Cheque No.', cheque_received_date: 'Cheque Received Date', cheque_status: 'Cheque Status', firstname: 'First Name', invoice_date: 'Invoice Date', lastname: 'Last Name', organization_name: 'Organization Name', payment_description: 'Payment Description', payment_method: 'Payment Method', period: 'Period' },
            hasChildren: false
        },
        {
            type: 'Attendance',
            subtype: 'Notify user as soon as the members attendance is captured',
            routerLink: 'attendance-notification',
            value: 'attendance',
            parameters: {
                attendance_date: 'Attendance Date', captured_by: 'Captured By',
                firstname: 'First Name', in_time: 'In Time', lastname: 'Last Name', organization_name: 'Organization Name'
            },
            hasChildren: false
        },
        {
            type: 'Calender Tasks',
            subtype: 'Notify members about activies planned on their calender',
            routerLink: 'calendartasks-notification',
            value: 'calendartasks',
            hasChildren: true,
            childValues: ['calendartasksdaily', 'calendartasksweekly'],
            children: [{
                type: 'Daily Notification',
                parameters: {
                    calendar_activity_today: 'Calendar Activity Today', firstname: 'First Name', lastname: 'Last Name',
                    organization_name: 'Organization Name', todays_date: 'Today\'s Date'
                },
                value: 'calendartasksdaily'
            },
            {
                type: 'Weekly Notification',
                parameters: {
                    calendar_activity_today: 'Calendar Activity Today', calendar_activity_week: 'Calendar Activity Week',
                    feedback_time: 'Feedback Time', firstname: 'First Name', lastname: 'Last Name',
                    organization_name: 'Organization Name', todays_date: 'Today\'s Date'
                },
                value: 'calendartasksweekly'
            }],

        },
        {
            type: 'Deregistration',
            subtype: 'Notify users as soon as the member is deregistered from the organization',
            routerLink: 'deregistration-notification',
            value: 'deregistration',
            parameters: {
                academy_center_name: 'Academy Center Name', captured_by: 'Captured By',
                deregistration_date: 'De-Registration Date', deregistration_reason: 'De-Registration Reason',
                firstname: 'First Name', lastname: 'Last Name', organization_name: 'Organization Name'
            },
            hasChildren: false
        },
        {
            type: 'Batch Capacity',
            subtype: 'Notify administrators as soon as a member is registered and the batch capacity is exceeded. This notification is sent only to the registered academy email id.',
            routerLink: 'batchcapacity-notification',
            value: 'batchcapacity',
            parameters: { batch_capacity: 'Batch Capacity', batch_headcount: 'Batch Headcount', batch_name: 'Batch Name', center_name: 'Center Name', firstname: 'First Name', lastname: 'Last Name', organization_name: 'Organization Name', registration_date: 'Registration Date' },
            hasChildren: false
        },
        {
            type: 'Payment Due Date Extension',
            subtype: 'Notify parent that the next payment due date has been changed to a future date due to genuine circumstances',
            routerLink: 'paymentduedateextension-notification',
            value: 'paymentduedateextension',
            parameters: {
                firstname: 'First Name', lastname: 'Last Name',
                next_payment_due: 'Next Payment Due', next_payment_due_extension_reason:
                    'Next Payment Due Extension Reason', organization_name: 'Organization Name'
            },
            hasChildren: false
        },
        {
            type: 'Staff Rating',
            subtype: 'Notify users as soon as a staff member feedback is provided by a member',
            routerLink: 'staffrating-notification',
            value: 'staffrating',
            hasChildren: true,
            childValues: ['staffratingacademy', 'staffratingparents'],
            children: [{
                type: 'Staff Rating : Academy',
                parameters: {
                    feedback_date: 'Feedback Date', feedback_rating: 'Feedback Rating',
                    feedback_text: 'Feedback Text', feedback_time: 'Feedback Time', firstname: 'First Name',
                    lastname: 'Last Name', organization_name: 'Organization Name', stafffirstname_field: 'Staff Firstname',
                    stafflastname_field: 'Staff Last Name'
                },
                value: 'staffratingacademy'
            },
            {
                type: 'Staff Rating : Parents',
                parameters: {
                    feedback_date: 'Feedback Date', firstname: 'First Name', lastname: 'Last Name',
                    organization_name: 'Organization Name', stafffirstname_field: 'Staff Firstname', stafflastname_field: 'Staff Last Name'
                },
                value: 'staffratingparents'
            }],

        },
        {
            type: 'Subscriptions',
            subtype: 'Notify users as soon as a subsrciption expire',
            routerLink: 'subscriptions',
            value: 'subscriptions',
            hasChildren: true,
            childValues: ['beforesubscriptionexpire', 'aftersubscriptionexpire'],
            children: [{
                type: 'Before Subscription Expire Reminder',
                parameters: { days: 'Days', firstname: 'First Name', invoice_date: 'Invoice Date', lastname: 'Last Name', organization_name: 'Organization Name', payment_info: 'Payment Info.', subscription_expiry_date: 'Subscription Expiry Date', total_payment_due: 'Total Payment Due' },
                value: 'beforesubscriptionexpire'
            },
            {
                type: 'After Subscription Expire Reminder',
                parameters: { days: 'Days', firstname: 'First Name', invoice_date: 'Invoice Date', lastname: 'Last Name', organization_name: 'Organization Name', payment_info: 'Payment Info.', subscription_expiry_date: 'Subscription Expiry Date', total_payment_due: 'Total Payment Due' },
                value: 'aftersubscriptionexpire'
            }],

        },
        {
            type: 'Self Registration',
            subtype: 'Notify users as soon as a self registration is done',
            routerLink: 'selfregistration',
            value: 'selfregistration',
            hasChildren: true,
            childValues: ['emailtoadmin', 'mobilenotificationtoadmin', 'emailtoselfregisteredplayers'],
            children: [
                {
                    type: 'Email to Admins',
                    parameters: {
                        firstname: 'First Name', lastname: 'Last Name',
                        organization_name: 'Organization Name', receive_date: 'Receive Date'
                    },
                    value: 'emailtoadmin'
                },
                {
                    type: 'Mobile Notification to Admins',
                    parameters: { firstname: 'First Name', lastname: 'Last Name', receive_date: 'Receive Date' },
                    value: 'mobilenotificationtoadmin'
                },
                {
                    type: 'Email to Self Registered Players',
                    parameters: { firstname: 'First Name', organization_name: 'Organization Name', receive_date: 'Receive Date' },
                    value: 'emailtoselfregisteredplayers'
                }
            ],

        }
    ];
    public static allCountriesCurrency = [
        {
            country: 'Andorra',
            shortName: 'AND',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'French Southern and Antarctic Lands',
            shortName: 'ATF',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Laos',
            shortName: 'LAO',
            currency: 'LAK',
            currencySymbol: '₭'
        },
        {
            country: 'Canada',
            shortName: 'CAN',
            currency: 'CAD',
            currencySymbol: '$'
        },
        {
            country: 'Nigeria',
            shortName: 'NGA',
            currency: 'NGN',
            currencySymbol: '₦'
        },
        {
            country: 'Vanuatu',
            shortName: 'VUT',
            currency: 'VUV',
            currencySymbol: 'Vt'
        },
        {
            country: 'Czechia',
            shortName: 'CZE',
            currency: 'CZK',
            currencySymbol: 'Kč'
        },
        {
            country: 'Malawi',
            shortName: 'MWI',
            currency: 'MWK',
            currencySymbol: 'MK'
        },
        {
            country: 'Mali',
            shortName: 'MLI',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Iceland',
            shortName: 'ISL',
            currency: 'ISK',
            currencySymbol: 'kr'
        },
        {
            country: 'Norway',
            shortName: 'NOR',
            currency: 'NOK',
            currencySymbol: 'kr'
        },
        {
            country: 'Saint Vincent and the Grenadines',
            shortName: 'VCT',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Guadeloupe',
            shortName: 'GLP',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Chile',
            shortName: 'CHL',
            currency: 'CLP',
            currencySymbol: '$'
        },
        {
            country: 'Bermuda',
            shortName: 'BMU',
            currency: 'BMD',
            currencySymbol: '$'
        },
        {
            country: 'Kuwait',
            shortName: 'KWT',
            currency: 'KWD',
            currencySymbol: 'د.ك'
        },
        {
            country: 'Dominica',
            shortName: 'DMA',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Montenegro',
            shortName: 'MNE',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'United States Virgin Islands',
            shortName: 'VIR',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Cameroon',
            shortName: 'CMR',
            currency: 'XAF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Sri Lanka',
            shortName: 'LKA',
            currency: 'LKR',
            currencySymbol: 'Rs  රු'
        },
        {
            country: 'China',
            shortName: 'CHN',
            currency: 'CNY',
            currencySymbol: '¥'
        },
        {
            country: 'Bangladesh',
            shortName: 'BGD',
            currency: 'BDT',
            currencySymbol: '৳'
        },
        {
            country: 'Sweden',
            shortName: 'SWE',
            currency: 'SEK',
            currencySymbol: 'kr'
        },
        {
            country: 'Grenada',
            shortName: 'GRD',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Turkey',
            shortName: 'TUR',
            currency: 'TRY',
            currencySymbol: '₺'
        },
        {
            country: 'Guinea',
            shortName: 'GIN',
            currency: 'GNF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Tanzania',
            shortName: 'TZA',
            currency: 'TZS',
            currencySymbol: 'Sh'
        },
        {
            country: 'Rwanda',
            shortName: 'RWA',
            currency: 'RWF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Singapore',
            shortName: 'SGP',
            currency: 'SGD',
            currencySymbol: '$'
        },
        {
            country: 'Morocco',
            shortName: 'MAR',
            currency: 'MAD',
            currencySymbol: 'د.م.'
        },
        {
            country: 'Saint Barthélemy',
            shortName: 'BLM',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Iraq',
            shortName: 'IRQ',
            currency: 'IQD',
            currencySymbol: 'ع.د'
        },
        {
            country: 'Brunei',
            shortName: 'BRN',
            currency: 'BND',
            currencySymbol: '$'
        },
        {
            country: 'Isle of Man',
            shortName: 'IMN',
            currency: 'GBP',
            currencySymbol: '£'
        },
        {
            country: 'North Korea',
            shortName: 'PRK',
            currency: 'KPW',
            currencySymbol: '₩'
        },
        {
            country: 'Iran',
            shortName: 'IRN',
            currency: 'IRR',
            currencySymbol: '﷼'
        },
        {
            country: 'Curaçao',
            shortName: 'CUW',
            currency: 'ANG',
            currencySymbol: 'ƒ'
        },
        {
            country: 'Paraguay',
            shortName: 'PRY',
            currency: 'PYG',
            currencySymbol: '₲'
        },
        {
            country: 'Albania',
            shortName: 'ALB',
            currency: 'ALL',
            currencySymbol: 'L'
        },
        {
            country: 'Tajikistan',
            shortName: 'TJK',
            currency: 'TJS',
            currencySymbol: 'ЅМ'
        },
        {
            country: 'Bolivia',
            shortName: 'BOL',
            currency: 'BOB',
            currencySymbol: 'Bs.'
        },
        {
            country: 'Austria',
            shortName: 'AUT',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Saint Kitts and Nevis',
            shortName: 'KNA',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'United States Minor Outlying Islands',
            shortName: 'UMI',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Colombia',
            shortName: 'COL',
            currency: 'COP',
            currencySymbol: '$'
        },
        {
            country: 'Kosovo',
            shortName: 'UNK',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Belize',
            shortName: 'BLZ',
            currency: 'BZD',
            currencySymbol: '$'
        },
        {
            country: 'Guinea-Bissau',
            shortName: 'GNB',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Marshall Islands',
            shortName: 'MHL',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Myanmar',
            shortName: 'MMR',
            currency: 'MMK',
            currencySymbol: 'Ks'
        },
        {
            country: 'French Polynesia',
            shortName: 'PYF',
            currency: 'XPF',
            currencySymbol: '₣'
        },
        {
            country: 'Brazil',
            shortName: 'BRA',
            currency: 'BRL',
            currencySymbol: 'R$'
        },
        {
            country: 'Croatia',
            shortName: 'HRV',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Somalia',
            shortName: 'SOM',
            currency: 'SOS',
            currencySymbol: 'Sh'
        },
        {
            country: 'Afghanistan',
            shortName: 'AFG',
            currency: 'AFN',
            currencySymbol: '؋'
        },
        {
            country: 'Anguilla',
            shortName: 'AIA',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Cook Islands',
            shortName: 'COK',
            currency: 'CKD',
            currencySymbol: '$'
        },
        {
            country: 'Western Sahara',
            shortName: 'ESH',
            currency: 'DZD',
            currencySymbol: 'دج'
        },
        {
            country: 'New Zealand',
            shortName: 'NZL',
            currency: 'NZD',
            currencySymbol: '$'
        },
        {
            country: 'Eritrea',
            shortName: 'ERI',
            currency: 'ERN',
            currencySymbol: 'Nfk'
        },
        {
            country: 'Cambodia',
            shortName: 'KHM',
            currency: 'KHR',
            currencySymbol: '៛'
        },
        {
            country: 'Bahamas',
            shortName: 'BHS',
            currency: 'BSD',
            currencySymbol: '$'
        },
        {
            country: 'Belarus',
            shortName: 'BLR',
            currency: 'BYN',
            currencySymbol: 'Br'
        },
        {
            country: 'Norfolk Island',
            shortName: 'NFK',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'Tuvalu',
            shortName: 'TUV',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'South Georgia',
            shortName: 'SGS',
            currency: 'SHP',
            currencySymbol: '£'
        },
        {
            country: 'Mauritania',
            shortName: 'MRT',
            currency: 'MRU',
            currencySymbol: 'UM'
        },
        {
            country: 'New Caledonia',
            shortName: 'NCL',
            currency: 'XPF',
            currencySymbol: '₣'
        },
        {
            country: 'Bulgaria',
            shortName: 'BGR',
            currency: 'BGN',
            currencySymbol: 'лв'
        },
        {
            country: 'Mozambique',
            shortName: 'MOZ',
            currency: 'MZN',
            currencySymbol: 'MT'
        },
        {
            country: 'Niue',
            shortName: 'NIU',
            currency: 'NZD',
            currencySymbol: '$'
        },
        {
            country: 'Estonia',
            shortName: 'EST',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Italy',
            shortName: 'ITA',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Malta',
            shortName: 'MLT',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Slovenia',
            shortName: 'SVN',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'India',
            shortName: 'IND',
            currency: 'INR',
            currencySymbol: '₹'
        },
        {
            country: 'Peru',
            shortName: 'PER',
            currency: 'PEN',
            currencySymbol: 'S/ '
        },
        {
            country: 'Burundi',
            shortName: 'BDI',
            currency: 'BIF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Lithuania',
            shortName: 'LTU',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'United States',
            shortName: 'USA',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Honduras',
            shortName: 'HND',
            currency: 'HNL',
            currencySymbol: 'L'
        },
        {
            country: 'Tonga',
            shortName: 'TON',
            currency: 'TOP',
            currencySymbol: 'T$'
        },
        {
            country: 'Saudi Arabia',
            shortName: 'SAU',
            currency: 'SAR',
            currencySymbol: 'ر.س'
        },
        {
            country: 'Suriname',
            shortName: 'SUR',
            currency: 'SRD',
            currencySymbol: '$'
        },
        {
            country: 'Qatar',
            shortName: 'QAT',
            currency: 'QAR',
            currencySymbol: 'ر.ق'
        },
        {
            country: 'Saint Helena, Ascension and Tristan da Cunha',
            shortName: 'SHN',
            currency: 'GBP',
            currencySymbol: '£'
        },
        {
            country: 'Gibraltar',
            shortName: 'GIB',
            currency: 'GIP',
            currencySymbol: '£'
        },
        {
            country: 'Northern Mariana Islands',
            shortName: 'MNP',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Mauritius',
            shortName: 'MUS',
            currency: 'MUR',
            currencySymbol: '₨'
        },
        {
            country: 'Barbados',
            shortName: 'BRB',
            currency: 'BBD',
            currencySymbol: '$'
        },
        {
            country: 'Réunion',
            shortName: 'REU',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'British Indian Ocean Territory',
            shortName: 'IOT',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Syria',
            shortName: 'SYR',
            currency: 'SYP',
            currencySymbol: '£'
        },
        {
            country: 'Egypt',
            shortName: 'EGY',
            currency: 'EGP',
            currencySymbol: '£'
        },
        {
            country: 'São Tomé and Príncipe',
            shortName: 'STP',
            currency: 'STN',
            currencySymbol: 'Db'
        },
        {
            country: 'Kiribati',
            shortName: 'KIR',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'Timor-Leste',
            shortName: 'TLS',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Lesotho',
            shortName: 'LSO',
            currency: 'LSL',
            currencySymbol: 'L'
        },
        {
            country: 'Solomon Islands',
            shortName: 'SLB',
            currency: 'SBD',
            currencySymbol: '$'
        },
        {
            country: 'Libya',
            shortName: 'LBY',
            currency: 'LYD',
            currencySymbol: 'ل.د'
        },
        {
            country: 'South Korea',
            shortName: 'KOR',
            currency: 'KRW',
            currencySymbol: '₩'
        },
        {
            country: 'Liechtenstein',
            shortName: 'LIE',
            currency: 'CHF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Nicaragua',
            shortName: 'NIC',
            currency: 'NIO',
            currencySymbol: 'C$'
        },
        {
            country: 'Ecuador',
            shortName: 'ECU',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Maldives',
            shortName: 'MDV',
            currency: 'MVR',
            currencySymbol: '.ރ'
        },
        {
            country: 'Algeria',
            shortName: 'DZA',
            currency: 'DZD',
            currencySymbol: 'د.ج'
        },
        {
            country: 'Kyrgyzstan',
            shortName: 'KGZ',
            currency: 'KGS',
            currencySymbol: 'с'
        },
        {
            country: 'Finland',
            shortName: 'FIN',
            currency: 'EUR',
            currencySymbol: '€'
        },
        null,
        {
            country: 'Kenya',
            shortName: 'KEN',
            currency: 'KES',
            currencySymbol: 'Sh'
        },
        {
            country: 'Cuba',
            shortName: 'CUB',
            currency: 'CUC',
            currencySymbol: '$'
        },
        {
            country: 'Montserrat',
            shortName: 'MSR',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Poland',
            shortName: 'POL',
            currency: 'PLN',
            currencySymbol: 'zł'
        },
        {
            country: 'Åland Islands',
            shortName: 'ALA',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Ethiopia',
            shortName: 'ETH',
            currency: 'ETB',
            currencySymbol: 'Br'
        },
        {
            country: 'Togo',
            shortName: 'TGO',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Bosnia and Herzegovina',
            shortName: 'BIH',
            currency: 'BAM'
        },
        {
            country: 'Uruguay',
            shortName: 'URY',
            currency: 'UYU',
            currencySymbol: '$'
        },
        {
            country: 'Guam',
            shortName: 'GUM',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Cape Verde',
            shortName: 'CPV',
            currency: 'CVE',
            currencySymbol: 'Esc'
        },
        {
            country: 'Chad',
            shortName: 'TCD',
            currency: 'XAF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Vatican City',
            shortName: 'VAT',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Palau',
            shortName: 'PLW',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Haiti',
            shortName: 'HTI',
            currency: 'HTG',
            currencySymbol: 'G'
        },
        {
            country: 'Yemen',
            shortName: 'YEM',
            currency: 'YER',
            currencySymbol: '﷼'
        },
        {
            country: 'Eswatini',
            shortName: 'SWZ',
            currency: 'SZL',
            currencySymbol: 'L'
        },
        {
            country: 'Zimbabwe',
            shortName: 'ZWE',
            currency: 'ZWL',
            currencySymbol: '$'
        },
        {
            country: 'Greece',
            shortName: 'GRC',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Israel',
            shortName: 'ISR',
            currency: 'ILS',
            currencySymbol: '₪'
        },
        {
            country: 'Saint Martin',
            shortName: 'MAF',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Antigua and Barbuda',
            shortName: 'ATG',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Cyprus',
            shortName: 'CYP',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Sint Maarten',
            shortName: 'SXM',
            currency: 'ANG',
            currencySymbol: 'ƒ'
        },
        {
            country: 'Monaco',
            shortName: 'MCO',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Fiji',
            shortName: 'FJI',
            currency: 'FJD',
            currencySymbol: '$'
        },
        {
            country: 'Ukraine',
            shortName: 'UKR',
            currency: 'UAH',
            currencySymbol: '₴'
        },
        {
            country: 'Martinique',
            shortName: 'MTQ',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Hong Kong',
            shortName: 'HKG',
            currency: 'HKD',
            currencySymbol: '$'
        },
        {
            country: 'Portugal',
            shortName: 'PRT',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Bhutan',
            shortName: 'BTN',
            currency: 'BTN',
            currencySymbol: 'Nu.'
        },
        {
            country: 'Nepal',
            shortName: 'NPL',
            currency: 'NPR',
            currencySymbol: '₨'
        },
        {
            country: 'France',
            shortName: 'FRA',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Ireland',
            shortName: 'IRL',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'United Arab Emirates',
            shortName: 'ARE',
            currency: 'AED',
            currencySymbol: 'د.إ'
        },
        {
            country: 'Guernsey',
            shortName: 'GGY',
            currency: 'GBP',
            currencySymbol: '£'
        },
        {
            country: 'Saint Lucia',
            shortName: 'LCA',
            currency: 'XCD',
            currencySymbol: '$'
        },
        {
            country: 'Dominican Republic',
            shortName: 'DOM',
            currency: 'DOP',
            currencySymbol: '$'
        },
        {
            country: 'Serbia',
            shortName: 'SRB',
            currency: 'RSD',
            currencySymbol: 'дин.'
        },
        {
            country: 'Botswana',
            shortName: 'BWA',
            currency: 'BWP',
            currencySymbol: 'P'
        },
        {
            country: 'Ivory Coast',
            shortName: 'CIV',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Ghana',
            shortName: 'GHA',
            currency: 'GHS',
            currencySymbol: '₵'
        },
        {
            country: 'Comoros',
            shortName: 'COM',
            currency: 'KMF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Azerbaijan',
            shortName: 'AZE',
            currency: 'AZN',
            currencySymbol: '₼'
        },
        {
            country: 'United Kingdom',
            shortName: 'GBR',
            currency: 'GBP',
            currencySymbol: '£'
        },
        {
            country: 'Central African Republic',
            shortName: 'CAF',
            currency: 'XAF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Palestine',
            shortName: 'PSE',
            currency: 'EGP',
            currencySymbol: 'E£'
        },
        {
            country: 'Caribbean Netherlands',
            shortName: 'BES',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Taiwan',
            shortName: 'TWN',
            currency: 'TWD',
            currencySymbol: '$'
        },
        {
            country: 'Pitcairn Islands',
            shortName: 'PCN',
            currency: 'NZD',
            currencySymbol: '$'
        },
        {
            country: 'San Marino',
            shortName: 'SMR',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Svalbard and Jan Mayen',
            shortName: 'SJM',
            currency: 'NOK',
            currencySymbol: 'kr'
        },
        {
            country: 'Djibouti',
            shortName: 'DJI',
            currency: 'DJF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Wallis and Futuna',
            shortName: 'WLF',
            currency: 'XPF',
            currencySymbol: '₣'
        },
        {
            country: 'Denmark',
            shortName: 'DNK',
            currency: 'DKK',
            currencySymbol: 'kr'
        },
        {
            country: 'Papua New Guinea',
            shortName: 'PNG',
            currency: 'PGK',
            currencySymbol: 'K'
        },
        {
            country: 'Madagascar',
            shortName: 'MDG',
            currency: 'MGA',
            currencySymbol: 'Ar'
        },
        null,
        {
            country: 'Hungary',
            shortName: 'HUN',
            currency: 'HUF',
            currencySymbol: 'Ft'
        },
        {
            country: 'Tokelau',
            shortName: 'TKL',
            currency: 'NZD',
            currencySymbol: '$'
        },
        {
            country: 'Trinidad and Tobago',
            shortName: 'TTO',
            currency: 'TTD',
            currencySymbol: '$'
        },
        {
            country: 'Gambia',
            shortName: 'GMB',
            currency: 'GMD',
            currencySymbol: 'D'
        },
        {
            country: 'Luxembourg',
            shortName: 'LUX',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Cocos (Keeling) Islands',
            shortName: 'CCK',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'Republic of the Congo',
            shortName: 'COG',
            currency: 'XAF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Argentina',
            shortName: 'ARG',
            currency: 'ARS',
            currencySymbol: '$'
        },
        {
            country: 'DR Congo',
            shortName: 'COD',
            currency: 'CDF',
            currencySymbol: 'FC'
        },
        {
            country: 'Greenland',
            shortName: 'GRL',
            currency: 'DKK',
            currencySymbol: 'kr.'
        },
        {
            country: 'Jordan',
            shortName: 'JOR',
            currency: 'JOD',
            currencySymbol: 'د.ا'
        },
        {
            country: 'Belgium',
            shortName: 'BEL',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Switzerland',
            shortName: 'CHE',
            currency: 'CHF',
            currencySymbol: 'Fr.'
        },
        {
            country: 'Indonesia',
            shortName: 'IDN',
            currency: 'IDR',
            currencySymbol: 'Rp'
        },
        {
            country: 'Lebanon',
            shortName: 'LBN',
            currency: 'LBP',
            currencySymbol: 'ل.ل'
        },
        {
            country: 'Malaysia',
            shortName: 'MYS',
            currency: 'MYR',
            currencySymbol: 'RM'
        },
        {
            country: 'Cayman Islands',
            shortName: 'CYM',
            currency: 'KYD',
            currencySymbol: '$'
        },
        {
            country: 'Slovakia',
            shortName: 'SVK',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Armenia',
            shortName: 'ARM',
            currency: 'AMD',
            currencySymbol: '֏'
        },
        {
            country: 'Christmas Island',
            shortName: 'CXR',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'Mongolia',
            shortName: 'MNG',
            currency: 'MNT',
            currencySymbol: '₮'
        },
        {
            country: 'Saint Pierre and Miquelon',
            shortName: 'SPM',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Japan',
            shortName: 'JPN',
            currency: 'JPY',
            currencySymbol: '¥'
        },
        {
            country: 'South Africa',
            shortName: 'ZAF',
            currency: 'ZAR',
            currencySymbol: 'R'
        },
        {
            country: 'Philippines',
            shortName: 'PHL',
            currency: 'PHP',
            currencySymbol: '₱'
        },
        {
            country: 'Micronesia',
            shortName: 'FSM',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Germany',
            shortName: 'DEU',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Latvia',
            shortName: 'LVA',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Jamaica',
            shortName: 'JAM',
            currency: 'JMD',
            currencySymbol: '$'
        },
        {
            country: 'Macau',
            shortName: 'MAC',
            currency: 'MOP',
            currencySymbol: 'P'
        },
        {
            country: 'Nauru',
            shortName: 'NRU',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'Faroe Islands',
            shortName: 'FRO',
            currency: 'DKK',
            currencySymbol: 'kr'
        },
        {
            country: 'Guyana',
            shortName: 'GUY',
            currency: 'GYD',
            currencySymbol: '$'
        },
        {
            country: 'Burkina Faso',
            shortName: 'BFA',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Sudan',
            shortName: 'SDN',
            currency: 'SDG'
        },
        {
            country: 'Russia',
            shortName: 'RUS',
            currency: 'RUB',
            currencySymbol: '₽'
        },
        {
            country: 'Mayotte',
            shortName: 'MYT',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Australia',
            shortName: 'AUS',
            currency: 'AUD',
            currencySymbol: '$'
        },
        {
            country: 'Liberia',
            shortName: 'LBR',
            currency: 'LRD',
            currencySymbol: '$'
        },
        {
            country: 'Mexico',
            shortName: 'MEX',
            currency: 'MXN',
            currencySymbol: '$'
        },
        {
            country: 'Tunisia',
            shortName: 'TUN',
            currency: 'TND',
            currencySymbol: 'د.ت'
        },
        {
            country: 'Aruba',
            shortName: 'ABW',
            currency: 'AWG',
            currencySymbol: 'ƒ'
        },
        {
            country: 'Kazakhstan',
            shortName: 'KAZ',
            currency: 'KZT',
            currencySymbol: '₸'
        },
        {
            country: 'Oman',
            shortName: 'OMN',
            currency: 'OMR',
            currencySymbol: 'ر.ع.'
        },
        {
            country: 'French Guiana',
            shortName: 'GUF',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Niger',
            shortName: 'NER',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Turkmenistan',
            shortName: 'TKM',
            currency: 'TMT',
            currencySymbol: 'm'
        },
        {
            country: 'Sierra Leone',
            shortName: 'SLE',
            currency: 'SLL',
            currencySymbol: 'Le'
        },
        {
            country: 'Samoa',
            shortName: 'WSM',
            currency: 'WST',
            currencySymbol: 'T'
        },
        {
            country: 'Senegal',
            shortName: 'SEN',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Georgia',
            shortName: 'GEO',
            currency: 'GEL',
            currencySymbol: '₾'
        },
        {
            country: 'Namibia',
            shortName: 'NAM',
            currency: 'NAD',
            currencySymbol: '$'
        },
        {
            country: 'South Sudan',
            shortName: 'SSD',
            currency: 'SSP',
            currencySymbol: '£'
        },
        {
            country: 'Thailand',
            shortName: 'THA',
            currency: 'THB',
            currencySymbol: '฿'
        },
        {
            country: 'Bahrain',
            shortName: 'BHR',
            currency: 'BHD',
            currencySymbol: '.د.ب'
        },
        null,
        {
            country: 'Falkland Islands',
            shortName: 'FLK',
            currency: 'FKP',
            currencySymbol: '£'
        },
        {
            country: 'Jersey',
            shortName: 'JEY',
            currency: 'GBP',
            currencySymbol: '£'
        },
        {
            country: 'Vietnam',
            shortName: 'VNM',
            currency: 'VND',
            currencySymbol: '₫'
        },
        {
            country: 'Guatemala',
            shortName: 'GTM',
            currency: 'GTQ',
            currencySymbol: 'Q'
        },
        {
            country: 'Moldova',
            shortName: 'MDA',
            currency: 'MDL',
            currencySymbol: 'L'
        },
        {
            country: 'North Macedonia',
            shortName: 'MKD',
            currency: 'MKD',
            currencySymbol: 'den'
        },
        {
            country: 'Uzbekistan',
            shortName: 'UZB',
            currency: 'UZS',
            currencySymbol: 'so\'m'
        },
        {
            country: 'Romania',
            shortName: 'ROU',
            currency: 'RON',
            currencySymbol: 'lei'
        },
        {
            country: 'Uganda',
            shortName: 'UGA',
            currency: 'UGX',
            currencySymbol: 'Sh'
        },
        {
            country: 'El Salvador',
            shortName: 'SLV',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Zambia',
            shortName: 'ZMB',
            currency: 'ZMW',
            currencySymbol: 'ZK'
        },
        {
            country: 'Gabon',
            shortName: 'GAB',
            currency: 'XAF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Equatorial Guinea',
            shortName: 'GNQ',
            currency: 'XAF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Spain',
            shortName: 'ESP',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'Netherlands',
            shortName: 'NLD',
            currency: 'EUR',
            currencySymbol: '€'
        },
        {
            country: 'British Virgin Islands',
            shortName: 'VGB',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Benin',
            shortName: 'BEN',
            currency: 'XOF',
            currencySymbol: 'Fr'
        },
        {
            country: 'Pakistan',
            shortName: 'PAK',
            currency: 'PKR',
            currencySymbol: '₨'
        },
        {
            country: 'Panama',
            shortName: 'PAN',
            currency: 'PAB',
            currencySymbol: 'B/.'
        },
        {
            country: 'Turks and Caicos Islands',
            shortName: 'TCA',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Angola',
            shortName: 'AGO',
            currency: 'AOA',
            currencySymbol: 'Kz'
        },
        {
            country: 'American Samoa',
            shortName: 'ASM',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Venezuela',
            shortName: 'VEN',
            currency: 'VES',
            currencySymbol: 'Bs.S.'
        },
        {
            country: 'Costa Rica',
            shortName: 'CRI',
            currency: 'CRC',
            currencySymbol: '₡'
        },
        {
            country: 'Puerto Rico',
            shortName: 'PRI',
            currency: 'USD',
            currencySymbol: '$'
        },
        {
            country: 'Seychelles',
            shortName: 'SYC',
            currency: 'SCR',
            currencySymbol: '₨'
        }
    ];
}
