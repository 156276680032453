/* tslint:disable */

declare var Object: any;
export interface YearlyScheduleInterface {
  "year": string;
  "weekDay": string;
  "fromTime"?: string;
  "toTime"?: string;
  "session": number;
  "contents": string;
  "sport": number;
  "sportName": string;
  "otherSport"?: string;
  "level"?: string;
  "coaches"?: string;
  "students"?: string;
  "createdBy": number;
  "modifiedBy"?: number;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class YearlySchedule implements YearlyScheduleInterface {
  "year": string;
  "weekDay": string;
  "fromTime": string;
  "toTime": string;
  "session": number;
  "contents": string;
  "sport": number;
  "sportName": string;
  "otherSport": string;
  "level": string;
  "coaches": string;
  "students": string;
  "createdBy": number;
  "modifiedBy": number;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: YearlyScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `YearlySchedule`.
   */
  public static getModelName() {
    return "YearlySchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of YearlySchedule for dynamic purposes.
  **/
  public static factory(data: YearlyScheduleInterface): YearlySchedule{
    return new YearlySchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'YearlySchedule',
      plural: 'YearlySchedules',
      path: 'YearlySchedules',
      idName: 'id',
      properties: {
        "year": {
          name: 'year',
          type: 'string'
        },
        "weekDay": {
          name: 'weekDay',
          type: 'string'
        },
        "fromTime": {
          name: 'fromTime',
          type: 'string'
        },
        "toTime": {
          name: 'toTime',
          type: 'string'
        },
        "session": {
          name: 'session',
          type: 'number'
        },
        "contents": {
          name: 'contents',
          type: 'string'
        },
        "sport": {
          name: 'sport',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "otherSport": {
          name: 'otherSport',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "coaches": {
          name: 'coaches',
          type: 'string'
        },
        "students": {
          name: 'students',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
