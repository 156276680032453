/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface BellNotificationInterface {
  "notificationType": string;
  "notificationTitle": string;
  "notificationDescription"?: string;
  "notificationStatus": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  sendByAccount?: Account;
}

export class BellNotification implements BellNotificationInterface {
  "notificationType": string;
  "notificationTitle": string;
  "notificationDescription": string;
  "notificationStatus": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  sendByAccount: Account;
  constructor(data?: BellNotificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BellNotification`.
   */
  public static getModelName() {
    return "BellNotification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BellNotification for dynamic purposes.
  **/
  public static factory(data: BellNotificationInterface): BellNotification{
    return new BellNotification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BellNotification',
      plural: 'BellNotifications',
      path: 'BellNotifications',
      idName: 'id',
      properties: {
        "notificationType": {
          name: 'notificationType',
          type: 'string'
        },
        "notificationTitle": {
          name: 'notificationTitle',
          type: 'string'
        },
        "notificationDescription": {
          name: 'notificationDescription',
          type: 'string'
        },
        "notificationStatus": {
          name: 'notificationStatus',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        sendByAccount: {
          name: 'sendByAccount',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
