/* tslint:disable */

declare var Object: any;
export interface NewMacroPlannerInterface {
  "sport": number;
  "sportName": string;
  "level": string;
  "year": string;
  "month": number;
  "monthName": string;
  "assessmentWeek"?: string;
  "tournamentWeek"?: string;
  "assessmentDate"?: string;
  "termExamFromDate"?: string;
  "termExamToDate"?: string;
  "skills"?: string;
  "tactics"?: string;
  "strengthAndConditioning"?: string;
  "isDeleted"?: number;
  "coaches"?: string;
  "students"?: string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy"?: number;
  "modifiedByName"?: string;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class NewMacroPlanner implements NewMacroPlannerInterface {
  "sport": number;
  "sportName": string;
  "level": string;
  "year": string;
  "month": number;
  "monthName": string;
  "assessmentWeek": string;
  "tournamentWeek": string;
  "assessmentDate": string;
  "termExamFromDate": string;
  "termExamToDate": string;
  "skills": string;
  "tactics": string;
  "strengthAndConditioning": string;
  "isDeleted": number;
  "coaches": string;
  "students": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy": number;
  "modifiedByName": string;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: NewMacroPlannerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `NewMacroPlanner`.
   */
  public static getModelName() {
    return "NewMacroPlanner";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of NewMacroPlanner for dynamic purposes.
  **/
  public static factory(data: NewMacroPlannerInterface): NewMacroPlanner{
    return new NewMacroPlanner(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'NewMacroPlanner',
      plural: 'NewMacroPlanners',
      path: 'NewMacroPlanners',
      idName: 'id',
      properties: {
        "sport": {
          name: 'sport',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "year": {
          name: 'year',
          type: 'string'
        },
        "month": {
          name: 'month',
          type: 'number'
        },
        "monthName": {
          name: 'monthName',
          type: 'string'
        },
        "assessmentWeek": {
          name: 'assessmentWeek',
          type: 'string'
        },
        "tournamentWeek": {
          name: 'tournamentWeek',
          type: 'string'
        },
        "assessmentDate": {
          name: 'assessmentDate',
          type: 'string'
        },
        "termExamFromDate": {
          name: 'termExamFromDate',
          type: 'string'
        },
        "termExamToDate": {
          name: 'termExamToDate',
          type: 'string'
        },
        "skills": {
          name: 'skills',
          type: 'string'
        },
        "tactics": {
          name: 'tactics',
          type: 'string'
        },
        "strengthAndConditioning": {
          name: 'strengthAndConditioning',
          type: 'string'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'number',
          default: 0
        },
        "coaches": {
          name: 'coaches',
          type: 'string'
        },
        "students": {
          name: 'students',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "modifiedByName": {
          name: 'modifiedByName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
