/* tslint:disable */
import {
  CoachingDrill
} from '../index';

declare var Object: any;
export interface LessonPlanInterface {
  "name": string;
  "description": string;
  "suitableFor": number;
  "duration": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "coachingDrillIds"?: Array<any>;
  coachingDrills?: CoachingDrill[];
}

export class LessonPlan implements LessonPlanInterface {
  "name": string;
  "description": string;
  "suitableFor": number;
  "duration": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "coachingDrillIds": Array<any>;
  coachingDrills: CoachingDrill[];
  constructor(data?: LessonPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LessonPlan`.
   */
  public static getModelName() {
    return "LessonPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LessonPlan for dynamic purposes.
  **/
  public static factory(data: LessonPlanInterface): LessonPlan{
    return new LessonPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LessonPlan',
      plural: 'LessonPlans',
      path: 'LessonPlans',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "suitableFor": {
          name: 'suitableFor',
          type: 'number'
        },
        "duration": {
          name: 'duration',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "coachingDrillIds": {
          name: 'coachingDrillIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        coachingDrills: {
          name: 'coachingDrills',
          type: 'CoachingDrill[]',
          model: 'CoachingDrill',
          relationType: 'referencesMany',
                  keyFrom: 'coachingDrillIds',
          keyTo: 'id'
        },
      }
    }
  }
}
