import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: 'switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit, OnChanges {
  @Input() input: any;
  @Output() attendanceEmitter = new EventEmitter<any>();
  public disable = false;
  constructor() {}
  ngOnInit() {}
  ngOnChanges() {
    this.disable = this.input.disableAttribute;
  }

  switch(event, accountId) {
    this.attendanceEmitter.emit({ present: event.target.checked, accountId });
  }
}
