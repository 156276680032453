/* tslint:disable */
import {
  FormElement,
  Account
} from '../index';

declare var Object: any;
export interface FormSubmissionInterface {
  "value": string;
  "pointsScored"?: number;
  "submittedDate": Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  formElement?: FormElement;
  account?: Account;
}

export class FormSubmission implements FormSubmissionInterface {
  "value": string;
  "pointsScored": number;
  "submittedDate": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  formElement: FormElement;
  account: Account;
  constructor(data?: FormSubmissionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FormSubmission`.
   */
  public static getModelName() {
    return "FormSubmission";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FormSubmission for dynamic purposes.
  **/
  public static factory(data: FormSubmissionInterface): FormSubmission{
    return new FormSubmission(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FormSubmission',
      plural: 'FormSubmissions',
      path: 'FormSubmissions',
      idName: 'id',
      properties: {
        "value": {
          name: 'value',
          type: 'string'
        },
        "pointsScored": {
          name: 'pointsScored',
          type: 'number'
        },
        "submittedDate": {
          name: 'submittedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        formElement: {
          name: 'formElement',
          type: 'FormElement',
          model: 'FormElement',
          relationType: 'belongsTo',
                  keyFrom: 'form_element_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
