import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ImageCropperModule } from 'ngx-image-cropper';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { VectorMapComponent } from './vector-map/vector-map.component';

import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DxVectorMapModule } from 'devextreme-angular';
import { BsDropdownModule, ModalModule } from 'ngx-bootstrap';
import { StudentSidebarComponent } from './student-sidebar/student-sidebar.component';
import { MultipleDropZoneComponent } from './dropzone/mutipleDropzone/mutipleDropzone.component';
import { SingleDropZoneComponent } from './dropzone/singleDropzone/singleDropzone.component';
import { EditDynamicFormComponent } from './edit-dynamic-form/edit-dynamic-form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextBoxModalComponent } from './text-box-modal/text-box-modal';
import { CoachSidebarComponent } from './coach-sidebar/coach-sidebar.component';
import { StaffSidebarComponent } from './staff-sidebar/staff-sidebar.component';
import { AuthService } from '../services/auth.service';
import { AttedanceGridComponent } from './attendance-tracker-grid/attendance-grid.component';
import { PresentComponent } from './present/present.component';
import { AbsentComponent } from './absent/absent.component';
import { WorkFromIconComponent } from './work-from-home-icon/work-from-home-icon.component';
import { LeaveIconComponent } from './leave-icon/leave-icon.component';
import { HalfDayIconComponent } from './half-day-icon/half-day-icon.component';
import { SwitchComponent } from './switch/switch.component';
import { AttendanceTableComponent } from './attendance-table/attendance-table.component';
import { CustomAttendanceComponent } from './custom-attendance/custom-attendance.component';
import { SliderComponent } from './slider/slider.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { SingleFormComponent } from './student-stepper/single-form/single-form.component';
import { MyStepperComponent } from './student-stepper/stepper/my-stepper.component';
import { AccountService } from '../services/account.service';
import { FileUploadService } from '../services/fileUpload.service';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { DatePipe } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AddCourseComponent } from './add-course/add-course.component';
import { AddCenterComponent } from './add-center/add-center.component';
import { AddFeePlansComponent } from './add-fee-plans/add-fee-plans.component';
import { AddBatchComponent } from './add-batch/add-batch.component';
import { AddCoachComponent } from './add-coach/add-coach.component';
import { AddStudentComponent } from './add-student/add-student.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddRoleComponent } from './add-role/add-role.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { FreeLanceCoachSidebarComponent } from './free-lance-coach-sidebar/free-lance-coach-sidebar.component';
import { FDashboardWelcomeComponent } from './f-dashboard-welcome/f-dashboard-welcome.component';


@NgModule({
  imports: [
    CommonModule,
    CdkStepperModule,
    RouterModule,
    CollapseModule.forRoot(),
    DxVectorMapModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxSpinnerModule,
  ],
  declarations: [
    FooterComponent,
    VectorMapComponent,
    NavbarComponent,
    SidebarComponent,
    StudentSidebarComponent,
    CoachSidebarComponent,
    StaffSidebarComponent,
    MultipleDropZoneComponent,
    SingleDropZoneComponent,
    EditDynamicFormComponent,
    TextBoxModalComponent,
    AttedanceGridComponent,
    PresentComponent,
    AbsentComponent,
    WorkFromIconComponent,
    LeaveIconComponent,
    HalfDayIconComponent,
    SwitchComponent,
    AttendanceTableComponent,
    CustomAttendanceComponent,
    SliderComponent,
    SingleFormComponent,
    MyStepperComponent,
    MediaUploadComponent,
    AddCourseComponent,
    AddCenterComponent,
    AddFeePlansComponent,
    AddBatchComponent,
    AddCoachComponent,
    AddStudentComponent,
    AddRoleComponent,
    WelcomeMessageComponent,
    FreeLanceCoachSidebarComponent,
    FDashboardWelcomeComponent,
  ],
  exports: [
    FooterComponent,
    VectorMapComponent,
    NavbarComponent,
    SidebarComponent,
    StudentSidebarComponent,
    CoachSidebarComponent,
    StaffSidebarComponent,
    MultipleDropZoneComponent,
    SingleDropZoneComponent,
    EditDynamicFormComponent,
    TextBoxModalComponent,
    AttedanceGridComponent,
    SwitchComponent,
    AttendanceTableComponent,
    CustomAttendanceComponent,
    SliderComponent,
    SingleFormComponent,
    MyStepperComponent,
    MediaUploadComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    AuthService,
    AccountService,
    FileUploadService,    
    DatePipe
  ]
})
export class ComponentsModule { }