/* tslint:disable */
import {
  Product
} from '../index';

declare var Object: any;
export interface PricebookInterface {
  "price": string;
  "applicableto": string;
  "applicablefrom": string;
  "type": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  product?: Product;
}

export class Pricebook implements PricebookInterface {
  "price": string;
  "applicableto": string;
  "applicablefrom": string;
  "type": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  product: Product;
  constructor(data?: PricebookInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Pricebook`.
   */
  public static getModelName() {
    return "Pricebook";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Pricebook for dynamic purposes.
  **/
  public static factory(data: PricebookInterface): Pricebook{
    return new Pricebook(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Pricebook',
      plural: 'Pricebooks',
      path: 'Pricebooks',
      idName: 'id',
      properties: {
        "price": {
          name: 'price',
          type: 'string'
        },
        "applicableto": {
          name: 'applicableto',
          type: 'string'
        },
        "applicablefrom": {
          name: 'applicablefrom',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        product: {
          name: 'product',
          type: 'Product',
          model: 'Product',
          relationType: 'belongsTo',
                  keyFrom: 'product_id',
          keyTo: 'id'
        },
      }
    }
  }
}
