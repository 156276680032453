import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../class/constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      // if (this.authService.currentRole.name === 'PLAYER' && (this.authService.currentUser.appUserStatus === 2 ||
      // this.authService.currentUser.appUserStatus === 1)) {
      //   return false;
      // }
      return true;
    } else {
      this.router.navigate([Constants.PATHS.LOGIN]);
      return false;
    }
    //   } else if (!this.authService.isAuthenticated()) {
    //     if ((window.location.href.indexOf('ops') !== -1 || (window.location.href.indexOf('system-admin') !== -1
    // || (window.location.href.indexOf('ops') !== -1) ||
    //       (window.location.href.indexOf('one-admin') !== -1) || (window.location.href.indexOf('business-admin') !== -1)))) {
    //       this.router.navigate(['ops/login'], { queryParams: { returnUrl: state.url } });
    //     } else if (state.url === '/logout') {
    //       this.router.navigate(['login']);
    //     } else {
    //       this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    //     }
    //   }
    // }
  }
}
