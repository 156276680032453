/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface CoachGoalInterface {
  "assessmentYear": number;
  "assessmentQuarter"?: string;
  "shortTermGoal": string;
  "longTermGoal": string;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountId"?: number;
  coach?: Account;
}

export class CoachGoal implements CoachGoalInterface {
  "assessmentYear": number;
  "assessmentQuarter": string;
  "shortTermGoal": string;
  "longTermGoal": string;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountId": number;
  coach: Account;
  constructor(data?: CoachGoalInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoachGoal`.
   */
  public static getModelName() {
    return "CoachGoal";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoachGoal for dynamic purposes.
  **/
  public static factory(data: CoachGoalInterface): CoachGoal{
    return new CoachGoal(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoachGoal',
      plural: 'CoachGoals',
      path: 'CoachGoals',
      idName: 'id',
      properties: {
        "assessmentYear": {
          name: 'assessmentYear',
          type: 'number'
        },
        "assessmentQuarter": {
          name: 'assessmentQuarter',
          type: 'string'
        },
        "shortTermGoal": {
          name: 'shortTermGoal',
          type: 'string'
        },
        "longTermGoal": {
          name: 'longTermGoal',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        coach: {
          name: 'coach',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'accountId',
          keyTo: 'id'
        },
      }
    }
  }
}
