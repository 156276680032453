/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface ScheduleTestInterface {
  "name": string;
  "startDate": Date;
  "endDate"?: Date;
  "testTime"?: any;
  "testDuration"?: string;
  "isAllowSelfAssessment": boolean;
  "isFixedTimeDuration": boolean;
  "isRecurringActivity": boolean;
  "frequencyType"?: string;
  "weekDays"?: string;
  "noWeek"?: number;
  "dayOfMonth"?: number;
  "noMonth"?: number;
  "noYear"?: number;
  "onMonth"?: number;
  "status": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id"?: number;
  createdBy?: Account;
}

export class ScheduleTest implements ScheduleTestInterface {
  "name": string;
  "startDate": Date;
  "endDate": Date;
  "testTime": any;
  "testDuration": string;
  "isAllowSelfAssessment": boolean;
  "isFixedTimeDuration": boolean;
  "isRecurringActivity": boolean;
  "frequencyType": string;
  "weekDays": string;
  "noWeek": number;
  "dayOfMonth": number;
  "noMonth": number;
  "noYear": number;
  "onMonth": number;
  "status": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id": number;
  createdBy: Account;
  constructor(data?: ScheduleTestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ScheduleTest`.
   */
  public static getModelName() {
    return "ScheduleTest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ScheduleTest for dynamic purposes.
  **/
  public static factory(data: ScheduleTestInterface): ScheduleTest{
    return new ScheduleTest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ScheduleTest',
      plural: 'ScheduleTests',
      path: 'ScheduleTests',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "testTime": {
          name: 'testTime',
          type: 'any'
        },
        "testDuration": {
          name: 'testDuration',
          type: 'string'
        },
        "isAllowSelfAssessment": {
          name: 'isAllowSelfAssessment',
          type: 'boolean',
          default: false
        },
        "isFixedTimeDuration": {
          name: 'isFixedTimeDuration',
          type: 'boolean',
          default: false
        },
        "isRecurringActivity": {
          name: 'isRecurringActivity',
          type: 'boolean',
          default: false
        },
        "frequencyType": {
          name: 'frequencyType',
          type: 'string'
        },
        "weekDays": {
          name: 'weekDays',
          type: 'string'
        },
        "noWeek": {
          name: 'noWeek',
          type: 'number'
        },
        "dayOfMonth": {
          name: 'dayOfMonth',
          type: 'number'
        },
        "noMonth": {
          name: 'noMonth',
          type: 'number'
        },
        "noYear": {
          name: 'noYear',
          type: 'number'
        },
        "onMonth": {
          name: 'onMonth',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
