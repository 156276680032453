/* tslint:disable */
import {
  AppUser,
  Account,
  AppFile,
  Tag
} from '../index';

declare var Object: any;
export interface PostInterface {
  "title": string;
  "publishDate": Date;
  "status": number;
  "content": string;
  "isPublic": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountIds"?: Array<any>;
  author?: AppUser;
  accounts?: Account[];
  postPicture?: AppFile;
  tag?: Tag[];
}

export class Post implements PostInterface {
  "title": string;
  "publishDate": Date;
  "status": number;
  "content": string;
  "isPublic": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountIds": Array<any>;
  author: AppUser;
  accounts: Account[];
  postPicture: AppFile;
  tag: Tag[];
  constructor(data?: PostInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Post`.
   */
  public static getModelName() {
    return "Post";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Post for dynamic purposes.
  **/
  public static factory(data: PostInterface): Post{
    return new Post(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Post',
      plural: 'Posts',
      path: 'Posts',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "publishDate": {
          name: 'publishDate',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "isPublic": {
          name: 'isPublic',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "accountIds": {
          name: 'accountIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        author: {
          name: 'author',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'referencesMany',
                  keyFrom: 'accountIds',
          keyTo: 'id'
        },
        postPicture: {
          name: 'postPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        tag: {
          name: 'tag',
          type: 'Tag[]',
          model: 'Tag',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'tag_id'
        },
      }
    }
  }
}
