import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(amount: number, countryCode: string): string {
    let currencySymbol = '';

    // Map country codes to currency symbols
    switch (countryCode) {
      case 'IND': // India
        currencySymbol = '₹';
        break;
      case 'USA': // United States
        currencySymbol = '$';
        break;
      case 'AUS': // Australia
        currencySymbol = 'AU$';
        break;
      // Add more cases for other countries as needed
      default:
        currencySymbol = '₹'; // Default to rupee symbol for unknown countries
        break;
    }

    return currencySymbol + ' ' + amount; // Add currency symbol to the amount
  }

  // Method 2: get Currency of given country 
  getCurrency(countryCode: string): string {
    let currency = '';

    // Map country codes to currency symbols
    switch (countryCode) {
      case 'IN': // India
        currency = 'INR';
        break;
      case 'US': // United States
        currency = 'USD';
        break;
      case 'AUS': // Australia
        currency = 'AUD';
        break;
      // Add more cases for other countries as needed
      default:
        currency = 'INR'; // Default to rupee symbol for unknown countries
        break;
    }

    return currency; // Add currency symbol to the amount
  }
}