/* tslint:disable */
import {
  FormElement
} from '../index';

declare var Object: any;
export interface FormInterface {
  "description"?: string;
  "name": string;
  "type": number;
  "expiry": string;
  "isScoringEnabled"?: boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  elements?: FormElement[];
}

export class Form implements FormInterface {
  "description": string;
  "name": string;
  "type": number;
  "expiry": string;
  "isScoringEnabled": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  elements: FormElement[];
  constructor(data?: FormInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Form`.
   */
  public static getModelName() {
    return "Form";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Form for dynamic purposes.
  **/
  public static factory(data: FormInterface): Form{
    return new Form(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Form',
      plural: 'Forms',
      path: 'Forms',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "expiry": {
          name: 'expiry',
          type: 'string'
        },
        "isScoringEnabled": {
          name: 'isScoringEnabled',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        elements: {
          name: 'elements',
          type: 'FormElement[]',
          model: 'FormElement',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'form_id'
        },
      }
    }
  }
}
