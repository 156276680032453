import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-icon',
  templateUrl: 'leave-icon.component.html',
  styleUrls: ['./leave-icon.component.scss']
})
export class LeaveIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
