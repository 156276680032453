/* tslint:disable */
import {
  Infrastructure,
  Course,
  Account,
  BatchTiming
} from '../index';

declare var Object: any;
export interface BatchInterface {
  "name": string;
  "description"?: string;
  "startDate"?: Date;
  "endDate"?: Date;
  "limitedSessions"?: boolean;
  "numberOfSessions"?: number;
  "batchCapacity"?: number;
  "isActive"?: boolean;
  "lastRegistrationDate"?: Date;
  "id"?: number;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "infrastructure_id"?: number;
  infrastructure?: Infrastructure;
  course?: Course;
  accounts?: Account[];
  batchTimings?: BatchTiming[];
}

export class Batch implements BatchInterface {
  "name": string;
  "description": string;
  "startDate": Date;
  "endDate": Date;
  "limitedSessions": boolean;
  "numberOfSessions": number;
  "batchCapacity": number;
  "isActive": boolean;
  "lastRegistrationDate": Date;
  "id": number;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "infrastructure_id": number;
  infrastructure: Infrastructure;
  course: Course;
  accounts: Account[];
  batchTimings: BatchTiming[];
  constructor(data?: BatchInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Batch`.
   */
  public static getModelName() {
    return "Batch";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Batch for dynamic purposes.
  **/
  public static factory(data: BatchInterface): Batch{
    return new Batch(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Batch',
      plural: 'Batches',
      path: 'Batches',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "limitedSessions": {
          name: 'limitedSessions',
          type: 'boolean'
        },
        "numberOfSessions": {
          name: 'numberOfSessions',
          type: 'number'
        },
        "batchCapacity": {
          name: 'batchCapacity',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean'
        },
        "lastRegistrationDate": {
          name: 'lastRegistrationDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "infrastructure_id": {
          name: 'infrastructure_id',
          type: 'number'
        },
      },
      relations: {
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
        course: {
          name: 'course',
          type: 'Course',
          model: 'Course',
          relationType: 'belongsTo',
                  keyFrom: 'course_id',
          keyTo: 'id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'AccountBatch',
          keyThrough: 'accountId',
          keyFrom: 'id',
          keyTo: 'batchId'
        },
        batchTimings: {
          name: 'batchTimings',
          type: 'BatchTiming[]',
          model: 'BatchTiming',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'batch_id'
        },
      }
    }
  }
}
