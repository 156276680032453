/* tslint:disable */
import {
  AppFile,
  Infrastructure
} from '../index';

declare var Object: any;
export interface OrganizationInterface {
  "name": string;
  "description"?: string;
  "webURL"?: string;
  "email"?: string;
  "phone": string;
  "isPublished": boolean;
  "academyCode"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  logo?: AppFile;
  infrastructures?: Infrastructure[];
}

export class Organization implements OrganizationInterface {
  "name": string;
  "description": string;
  "webURL": string;
  "email": string;
  "phone": string;
  "isPublished": boolean;
  "academyCode": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  logo: AppFile;
  infrastructures: Infrastructure[];
  constructor(data?: OrganizationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Organization`.
   */
  public static getModelName() {
    return "Organization";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Organization for dynamic purposes.
  **/
  public static factory(data: OrganizationInterface): Organization{
    return new Organization(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Organization',
      plural: 'Organizations',
      path: 'Organizations',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "webURL": {
          name: 'webURL',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "isPublished": {
          name: 'isPublished',
          type: 'boolean'
        },
        "academyCode": {
          name: 'academyCode',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        logo: {
          name: 'logo',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        infrastructures: {
          name: 'infrastructures',
          type: 'Infrastructure[]',
          model: 'Infrastructure',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'organization_id'
        },
      }
    }
  }
}
