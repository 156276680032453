/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { OptionApi } from './services/custom/Option';
import { AppUserApi } from './services/custom/AppUser';
import { AppRoleApi } from './services/custom/AppRole';
import { AppUserRoleApi } from './services/custom/AppUserRole';
import { CountryApi } from './services/custom/Country';
import { StateApi } from './services/custom/State';
import { CityApi } from './services/custom/City';
import { AddressApi } from './services/custom/Address';
import { AccountApi } from './services/custom/Account';
import { AppFileApi } from './services/custom/AppFile';
import { AccountDetailApi } from './services/custom/AccountDetail';
import { OrganizationApi } from './services/custom/Organization';
import { SportApi } from './services/custom/Sport';
import { CourseApi } from './services/custom/Course';
import { InfrastructureApi } from './services/custom/Infrastructure';
import { AmenityApi } from './services/custom/Amenity';
import { BatchApi } from './services/custom/Batch';
import { BulkUploadApi } from './services/custom/BulkUpload';
import { GuardianApi } from './services/custom/Guardian';
import { CourseInfrastructureApi } from './services/custom/CourseInfrastructure';
import { ExcerciseApi } from './services/custom/Excercise';
import { ExcercisesAttendeeApi } from './services/custom/ExcercisesAttendee';
import { MaintenanceApi } from './services/custom/Maintenance';
import { AttendanceApi } from './services/custom/Attendance';
import { CalendarApi } from './services/custom/Calendar';
import { FormApi } from './services/custom/Form';
import { FormElementApi } from './services/custom/FormElement';
import { EventApi } from './services/custom/Event';
import { InterestApi } from './services/custom/Interest';
import { PostApi } from './services/custom/Post';
import { TagApi } from './services/custom/Tag';
import { FormSubmissionApi } from './services/custom/FormSubmission';
import { ProcurementApi } from './services/custom/Procurement';
import { RatingApi } from './services/custom/Rating';
import { AccountBatchApi } from './services/custom/AccountBatch';
import { AccountInfrastructureApi } from './services/custom/AccountInfrastructure';
import { ProductApi } from './services/custom/Product';
import { CartApi } from './services/custom/Cart';
import { FeePlanApi } from './services/custom/FeePlan';
import { FeeSubscriptionApi } from './services/custom/FeeSubscription';
import { FeeInstallmentApi } from './services/custom/FeeInstallment';
import { MapFeeplanCourseApi } from './services/custom/MapFeeplanCourse';
import { OrderApi } from './services/custom/Order';
import { CartItemApi } from './services/custom/CartItem';
import { TransactionApi } from './services/custom/Transaction';
import { SystematicMethodApi } from './services/custom/SystematicMethod';
import { PricebookApi } from './services/custom/Pricebook';
import { AssessmentApi } from './services/custom/Assessment';
import { AssessmentParameterApi } from './services/custom/AssessmentParameter';
import { AssessmentSectionApi } from './services/custom/AssessmentSection';
import { AssessmentKpiApi } from './services/custom/AssessmentKpi';
import { AssessmentSubmissionApi } from './services/custom/AssessmentSubmission';
import { RankingClassificationApi } from './services/custom/RankingClassification';
import { RankingApi } from './services/custom/Ranking';
import { BatchTimingApi } from './services/custom/BatchTiming';
import { AccountUserApi } from './services/custom/AccountUser';
import { ChequeTrackerApi } from './services/custom/ChequeTracker';
import { UtrTrackerApi } from './services/custom/UtrTracker';
import { CoachingDrillApi } from './services/custom/CoachingDrill';
import { LessonPlanApi } from './services/custom/LessonPlan';
import { DashboardApi } from './services/custom/Dashboard';
import { CertificationApi } from './services/custom/Certification';
import { InjuryApi } from './services/custom/Injury';
import { PaymentApi } from './services/custom/Payment';
import { ActivityTrackerApi } from './services/custom/ActivityTracker';
import { SessionApi } from './services/custom/Session';
import { AccountSessionApi } from './services/custom/AccountSession';
import { VideoSessionApi } from './services/custom/VideoSession';
import { VideoSessionAttendeesApi } from './services/custom/VideoSessionAttendees';
import { MessageApi } from './services/custom/Message';
import { TestApi } from './services/custom/Test';
import { TestAttendeeApi } from './services/custom/TestAttendee';
import { CertificationAttendeeApi } from './services/custom/CertificationAttendee';
import { RecurringApi } from './services/custom/Recurring';
import { FixedTimeDurationApi } from './services/custom/FixedTimeDuration';
import { WorkoutPlanApi } from './services/custom/WorkoutPlan';
import { WorkoutPlanScheduleApi } from './services/custom/WorkoutPlanSchedule';
import { WorkoutPlanAttendeeApi } from './services/custom/WorkoutPlanAttendee';
import { AssessmentTestApi } from './services/custom/AssessmentTest';
import { KpiFieldsMasterApi } from './services/custom/KpiFieldsMaster';
import { TestScheduleApi } from './services/custom/TestSchedule';
import { CoachingPlanApi } from './services/custom/CoachingPlan';
import { CoachingScheduleApi } from './services/custom/CoachingSchedule';
import { CoachingDrillAttendeeApi } from './services/custom/CoachingDrillAttendee';
import { CertificationResultApi } from './services/custom/CertificationResult';
import { CoachingScheduleAttendeeApi } from './services/custom/CoachingScheduleAttendee';
import { MapWorkoutPlanScheduleApi } from './services/custom/MapWorkoutPlanSchedule';
import { StudentTagApi } from './services/custom/StudentTag';
import { MapStudentTagApi } from './services/custom/MapStudentTag';
import { MapWorkoutStudentApi } from './services/custom/MapWorkoutStudent';
import { MapCoachingDrillScheduleApi } from './services/custom/MapCoachingDrillSchedule';
import { MapCoachingScheduleStudentApi } from './services/custom/MapCoachingScheduleStudent';
import { ProcurementItemApi } from './services/custom/ProcurementItem';
import { MapCertificationStudentApi } from './services/custom/MapCertificationStudent';
import { AccountCourseApi } from './services/custom/AccountCourse';
import { AccountBatchtimingApi } from './services/custom/AccountBatchtiming';
import { AppUserTokenApi } from './services/custom/AppUserToken';
import { BellNotificationApi } from './services/custom/BellNotification';
import { TermsAndConditionsApi } from './services/custom/TermsAndConditions';
import { UserRoleScopeApi } from './services/custom/UserRoleScope';
import { FeeSettingApi } from './services/custom/FeeSetting';
import { MapAssessmentParameterApi } from './services/custom/MapAssessmentParameter';
import { ScheduleTestApi } from './services/custom/ScheduleTest';
import { ScheduleTestAttendanceApi } from './services/custom/ScheduleTestAttendance';
import { MappingScheduleTestAssessmentApi } from './services/custom/MappingScheduleTestAssessment';
import { AccountAssessmentParameterResultApi } from './services/custom/AccountAssessmentParameterResult';
import { AssessmentResultApi } from './services/custom/AssessmentResult';
import { CoachEvaluationParameterApi } from './services/custom/CoachEvaluationParameter';
import { WeekAtAGlanceAreaApi } from './services/custom/WeekAtAGlanceArea';
import { ActivityRequestApi } from './services/custom/ActivityRequest';
import { CoachGoalApi } from './services/custom/CoachGoal';
import { YearlyScheduleApi } from './services/custom/YearlySchedule';
import { SportsPsychologyTrackingApi } from './services/custom/SportsPsychologyTracking';
import { MusculoskeletalScreeningApi } from './services/custom/MusculoskeletalScreening';
import { FunctionalMovementScreeningApi } from './services/custom/FunctionalMovementScreening';
import { SessionPlanApi } from './services/custom/SessionPlan';
import { MesoPlannerApi } from './services/custom/MesoPlanner';
import { NewMesoPlannerApi } from './services/custom/NewMesoPlanner';
import { NewMacroPlannerApi } from './services/custom/NewMacroPlanner';
import { WorkInProgressApi } from './services/custom/WorkInProgress';
import { DisciplineReportApi } from './services/custom/DisciplineReport';
import { MealPlanApi } from './services/custom/MealPlan';
import { AssessmentAssignmentApi } from './services/custom/AssessmentAssignment';
import { IndividualMealPlanApi } from './services/custom/IndividualMealPlan';
import { TeamReportApi } from './services/custom/TeamReport';
import { AccountAcademicApi } from './services/custom/AccountAcademic';
import { StudentGoalApi } from './services/custom/StudentGoal';
import { PreWorkoutRoutineApi } from './services/custom/PreWorkoutRoutine';
import { StrengthAndConditioningApi } from './services/custom/StrengthAndConditioning';
import { WellnessReportApi } from './services/custom/WellnessReport';
import { StrengthAndConditioningStudentScoreApi } from './services/custom/StrengthAndConditioningStudentScore';
import { MicroPlannerApi } from './services/custom/MicroPlanner';
import { BrandingPreferenceApi } from './services/custom/BrandingPreference';
import { IndividualMealPlanTimingApi } from './services/custom/IndividualMealPlanTiming';
import { MapWagFileAccountApi } from './services/custom/MapWagFileAccount';
import { SportPsychologicalComponentApi } from './services/custom/SportPsychologicalComponent';
import { SportActivityApi } from './services/custom/SportActivity';
import { TournamentMealPlanApi } from './services/custom/TournamentMealPlan';
import { HydrationTrackerApi } from './services/custom/HydrationTracker';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        OptionApi,
        AppUserApi,
        AppRoleApi,
        AppUserRoleApi,
        CountryApi,
        StateApi,
        CityApi,
        AddressApi,
        AccountApi,
        AppFileApi,
        AccountDetailApi,
        OrganizationApi,
        SportApi,
        CourseApi,
        InfrastructureApi,
        AmenityApi,
        BatchApi,
        BulkUploadApi,
        GuardianApi,
        CourseInfrastructureApi,
        ExcerciseApi,
        ExcercisesAttendeeApi,
        MaintenanceApi,
        AttendanceApi,
        CalendarApi,
        FormApi,
        FormElementApi,
        EventApi,
        InterestApi,
        PostApi,
        TagApi,
        FormSubmissionApi,
        ProcurementApi,
        RatingApi,
        AccountBatchApi,
        AccountInfrastructureApi,
        ProductApi,
        CartApi,
        FeePlanApi,
        FeeSubscriptionApi,
        FeeInstallmentApi,
        MapFeeplanCourseApi,
        OrderApi,
        CartItemApi,
        TransactionApi,
        SystematicMethodApi,
        PricebookApi,
        AssessmentApi,
        AssessmentParameterApi,
        AssessmentSectionApi,
        AssessmentKpiApi,
        AssessmentSubmissionApi,
        RankingClassificationApi,
        RankingApi,
        BatchTimingApi,
        AccountUserApi,
        ChequeTrackerApi,
        UtrTrackerApi,
        CoachingDrillApi,
        LessonPlanApi,
        DashboardApi,
        CertificationApi,
        InjuryApi,
        PaymentApi,
        ActivityTrackerApi,
        SessionApi,
        AccountSessionApi,
        VideoSessionApi,
        VideoSessionAttendeesApi,
        MessageApi,
        TestApi,
        TestAttendeeApi,
        CertificationAttendeeApi,
        RecurringApi,
        FixedTimeDurationApi,
        WorkoutPlanApi,
        WorkoutPlanScheduleApi,
        WorkoutPlanAttendeeApi,
        AssessmentTestApi,
        KpiFieldsMasterApi,
        TestScheduleApi,
        CoachingPlanApi,
        CoachingScheduleApi,
        CoachingDrillAttendeeApi,
        CertificationResultApi,
        CoachingScheduleAttendeeApi,
        MapWorkoutPlanScheduleApi,
        StudentTagApi,
        MapStudentTagApi,
        MapWorkoutStudentApi,
        MapCoachingDrillScheduleApi,
        MapCoachingScheduleStudentApi,
        ProcurementItemApi,
        MapCertificationStudentApi,
        AccountCourseApi,
        AccountBatchtimingApi,
        AppUserTokenApi,
        BellNotificationApi,
        TermsAndConditionsApi,
        UserRoleScopeApi,
        FeeSettingApi,
        MapAssessmentParameterApi,
        ScheduleTestApi,
        ScheduleTestAttendanceApi,
        MappingScheduleTestAssessmentApi,
        AccountAssessmentParameterResultApi,
        AssessmentResultApi,
        CoachEvaluationParameterApi,
        WeekAtAGlanceAreaApi,
        ActivityRequestApi,
        CoachGoalApi,
        YearlyScheduleApi,
        SportsPsychologyTrackingApi,
        MusculoskeletalScreeningApi,
        FunctionalMovementScreeningApi,
        SessionPlanApi,
        MesoPlannerApi,
        NewMesoPlannerApi,
        NewMacroPlannerApi,
        WorkInProgressApi,
        DisciplineReportApi,
        MealPlanApi,
        AssessmentAssignmentApi,
        IndividualMealPlanApi,
        TeamReportApi,
        AccountAcademicApi,
        StudentGoalApi,
        PreWorkoutRoutineApi,
        StrengthAndConditioningApi,
        WellnessReportApi,
        StrengthAndConditioningStudentScoreApi,
        MicroPlannerApi,
        BrandingPreferenceApi,
        IndividualMealPlanTimingApi,
        MapWagFileAccountApi,
        SportPsychologicalComponentApi,
        SportActivityApi,
        TournamentMealPlanApi,
        HydrationTrackerApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

