/* tslint:disable */
import {
  AppFile,
  Account
} from '../index';

declare var Object: any;
export interface BrandingPreferenceInterface {
  "currency"?: string;
  "dateFormat"?: string;
  "centerNameTitle"?: string;
  "registrationPageBgColor"?: string;
  "registrationPageTextColor"?: string;
  "registrationPageHintTextColor"?: string;
  "sidenavHeaderBgColor"?: string;
  "sidenavHeaderFontColor"?: string;
  "sidenavHeaderMenuColor"?: string;
  "sidenavBgColor"?: string;
  "mainpageBgColor"?: string;
  "mainpageTextColor"?: string;
  "mainpageHeaderBgColor"?: string;
  "mainpageHeaderTextColor"?: string;
  "mainpageCardHeaderBgColor"?: string;
  "mainpageCardHeaderTextColor"?: string;
  "mainpageButtonBgColor"?: string;
  "mainpageButtonTextColor"?: string;
  "mainpageSelectedTabUnderlineColor"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "organization_logo_file_id"?: number;
  "created_by_id"?: number;
  "updated_by_id"?: number;
  organizationLogoFile?: AppFile;
  createdBy?: Account;
  updatedBy?: Account;
}

export class BrandingPreference implements BrandingPreferenceInterface {
  "currency": string;
  "dateFormat": string;
  "centerNameTitle": string;
  "registrationPageBgColor": string;
  "registrationPageTextColor": string;
  "registrationPageHintTextColor": string;
  "sidenavHeaderBgColor": string;
  "sidenavHeaderFontColor": string;
  "sidenavHeaderMenuColor": string;
  "sidenavBgColor": string;
  "mainpageBgColor": string;
  "mainpageTextColor": string;
  "mainpageHeaderBgColor": string;
  "mainpageHeaderTextColor": string;
  "mainpageCardHeaderBgColor": string;
  "mainpageCardHeaderTextColor": string;
  "mainpageButtonBgColor": string;
  "mainpageButtonTextColor": string;
  "mainpageSelectedTabUnderlineColor": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "organization_logo_file_id": number;
  "created_by_id": number;
  "updated_by_id": number;
  organizationLogoFile: AppFile;
  createdBy: Account;
  updatedBy: Account;
  constructor(data?: BrandingPreferenceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BrandingPreference`.
   */
  public static getModelName() {
    return "BrandingPreference";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BrandingPreference for dynamic purposes.
  **/
  public static factory(data: BrandingPreferenceInterface): BrandingPreference{
    return new BrandingPreference(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BrandingPreference',
      plural: 'BrandingPreferences',
      path: 'BrandingPreferences',
      idName: 'id',
      properties: {
        "currency": {
          name: 'currency',
          type: 'string'
        },
        "dateFormat": {
          name: 'dateFormat',
          type: 'string'
        },
        "centerNameTitle": {
          name: 'centerNameTitle',
          type: 'string'
        },
        "registrationPageBgColor": {
          name: 'registrationPageBgColor',
          type: 'string'
        },
        "registrationPageTextColor": {
          name: 'registrationPageTextColor',
          type: 'string'
        },
        "registrationPageHintTextColor": {
          name: 'registrationPageHintTextColor',
          type: 'string'
        },
        "sidenavHeaderBgColor": {
          name: 'sidenavHeaderBgColor',
          type: 'string'
        },
        "sidenavHeaderFontColor": {
          name: 'sidenavHeaderFontColor',
          type: 'string'
        },
        "sidenavHeaderMenuColor": {
          name: 'sidenavHeaderMenuColor',
          type: 'string'
        },
        "sidenavBgColor": {
          name: 'sidenavBgColor',
          type: 'string'
        },
        "mainpageBgColor": {
          name: 'mainpageBgColor',
          type: 'string'
        },
        "mainpageTextColor": {
          name: 'mainpageTextColor',
          type: 'string'
        },
        "mainpageHeaderBgColor": {
          name: 'mainpageHeaderBgColor',
          type: 'string'
        },
        "mainpageHeaderTextColor": {
          name: 'mainpageHeaderTextColor',
          type: 'string'
        },
        "mainpageCardHeaderBgColor": {
          name: 'mainpageCardHeaderBgColor',
          type: 'string'
        },
        "mainpageCardHeaderTextColor": {
          name: 'mainpageCardHeaderTextColor',
          type: 'string'
        },
        "mainpageButtonBgColor": {
          name: 'mainpageButtonBgColor',
          type: 'string'
        },
        "mainpageButtonTextColor": {
          name: 'mainpageButtonTextColor',
          type: 'string'
        },
        "mainpageSelectedTabUnderlineColor": {
          name: 'mainpageSelectedTabUnderlineColor',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "organization_logo_file_id": {
          name: 'organization_logo_file_id',
          type: 'number'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
        "updated_by_id": {
          name: 'updated_by_id',
          type: 'number'
        },
      },
      relations: {
        organizationLogoFile: {
          name: 'organizationLogoFile',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'organization_logo_file_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'updated_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
