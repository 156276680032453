/* tslint:disable */
import {
  Account,
  BatchTiming
} from '../index';

declare var Object: any;
export interface AccountBatchtimingInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account?: Account;
  batchtiming?: BatchTiming;
}

export class AccountBatchtiming implements AccountBatchtimingInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account: Account;
  batchtiming: BatchTiming;
  constructor(data?: AccountBatchtimingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountBatchtiming`.
   */
  public static getModelName() {
    return "AccountBatchtiming";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountBatchtiming for dynamic purposes.
  **/
  public static factory(data: AccountBatchtimingInterface): AccountBatchtiming{
    return new AccountBatchtiming(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountBatchtiming',
      plural: 'AccountBatchtimings',
      path: 'AccountBatchtimings',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        batchtiming: {
          name: 'batchtiming',
          type: 'BatchTiming',
          model: 'BatchTiming',
          relationType: 'belongsTo',
                  keyFrom: 'batchtiming_id',
          keyTo: 'id'
        },
      }
    }
  }
}
