import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/class/constants';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  AccountApi,
  AccountBatchApi,
  AccountDetailApi,
  AccountUserApi,
  AccountInfrastructureApi,
  AddressApi,
  AppUserApi,
  AppFileApi,
  AppUserRoleApi,
  BatchApi,
  BatchTimingApi,
  AccountBatchtimingApi,
  CityApi,
  CountryApi,
  FeePlanApi,
  GuardianApi,
  InfrastructureApi,
  OptionApi,
  StateApi,
  OrderApi,
  CourseInfrastructureApi,
  MapFeeplanCourseApi,
} from 'src/app/shared/sdk';
import swal from 'sweetalert2';
import * as _moment from 'moment';
import { FileUploadService } from 'src/app/services/fileUpload.service';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import { CurrencySymbolPipe } from 'src/app/CurrencySymbolPipe';


@Component({
  selector: 'app-single-form',
  templateUrl: './single-form.component.html',
  styleUrls: ['./single-form.component.scss'],
})

export class SingleFormComponent implements OnInit {
  @Input() isLinear = true;
  @Input() isEditable = true;
  @Input() inputs: any;

  @Output() closeModal = new EventEmitter<boolean>();

  public maxDate = new Date();

  frmValues: any = {};
  frmStepper: FormGroup;

  //Dropdown Variables
  public bloodGroups: any = [];
  public dominantSides: any = [];
  public genders: any = [];

  public countries: any = [];
  public countryId = 0;
  public countryMessage = '';

  public nameMessage = '';

  public states: any = [];
  public stateId = 0;
  public stateMessage = '';

  public cities: any = [];
  public cityId = 0;
  public cityMessage = '';

  public centers: any = [];
  public centerId = 0;
  public centerMessage = '';


  public courses: any = [];
  public courseId = 0;

  public batches: any = [];
  public batchId = 0;
  public batchMessage = '';

  public gender = 0;
  public genderMessage = '';

  public bloodGroup = 1;
  public dominantSide = 1;

  public message = '';

  public currentBatchId = 0;
  public currentCenterId = 0;
  public centerType: any;
  public centerReadonly = false;
  public isReadOnly:boolean=true;

  accountType = this.authService.getCurrentAccountType();
  feeTypes: any;
  feePlans: any;
  paymentFrequency: any;
  fees: any;
  playerDetails: any;
  gaurdianDetails: any;
  feeSetup: any;
  addressId: any;
  countryCode: any=0;
  relationF: any;
  relationM: any;
  accountId: any;
  accountDetailId: any;
  countrySym:any;
  orderId: any;
  public accountBatches: any[] = [];
  public isReadonly = false;
  public feeTypeSelected: any;
  public feePlanSelected: any;
  public paymentFrequencySelected: any;
  public isFeeTypeSelected = false;
  public ispaymentFrequency = false;
  public notFree = true;
  public today;
  public date;
  public motherId: any;
  public fatherId: any;
  public file: File | null = null;
  profilePicError: any;
  profilePictureUrl: any;
  addoreditProfilePic: boolean = true;
  proratedFee=0;
  feesValue:any;
  hasProrata:boolean=false;
  batchDays:any=[];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private appUserApi: AppUserApi,
    private accountUserApi: AccountUserApi,
    private orderApi: OrderApi,
    private countryAPi: CountryApi,
    private cityApi: CityApi,
    private optionAPi: OptionApi,
    private accountApi: AccountApi,
    private accountDetailApi: AccountDetailApi,
    private accountInfrastructureApi: AccountInfrastructureApi,
    private accountBatchApi: AccountBatchApi,
    private batchTimingApi: BatchTimingApi,
    private accountbatchTimingApi: AccountBatchtimingApi,
    private addressApi: AddressApi,
    private stateApi: StateApi,
    private infrastructureApi: InfrastructureApi,
    private batchApi: BatchApi,
    private feePlanApi: FeePlanApi,
    private appUserRoleApi: AppUserRoleApi,
    private guardianApi: GuardianApi,
    public fileUploadService: FileUploadService,
    private courseInfraApi: CourseInfrastructureApi,
    private mapFeeplanCourseApi: MapFeeplanCourseApi,
    private activatedRouter: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private appFileApi: AppFileApi,
    private currencySymbolPipe: CurrencySymbolPipe

  ) { }

  ngOnInit(): void {
    this.countrySym=this.authService.getCountryCode();
    this.centerId=0;
    this.courseId=0;
    this.frmStepper = this.fb.group({
      steps: this.fb.array([
        this.fb.group({
          fullName: ['', Validators.compose([Validators.required, Validators.pattern('^(?![0-9 ]*$)[a-zA-Z0-9 ]+$')])],
          email: ['', Validators.compose([Validators.required, Validators.email])],
          center: [0, Validators.compose([Validators.required])],
          batch: [0],
          course: [0, Validators.compose([Validators.required])],
          dateOfBirth: ['', Validators.compose([Validators.required])],
          mobile: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
          bloodGroup: [0],
          dominantSide: [0],
          medicalCondition: ['', Validators.compose([Validators.required])],
          gender: [0, Validators.compose([Validators.required])],
          grade: [''],
          isOptedTransport: [''],
          profile: ['', Validators.compose([Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$')])],
          qualification: ['', Validators.compose([Validators.pattern('^[^!@#$%^&*()]+[a-zA-Z0-9. ]+$')])],          
          country: ['',Validators.compose([Validators.required])],
          state: ['',Validators.compose([Validators.required])],
          city: ['',Validators.compose([Validators.required])],
          pincode: ['', Validators.compose([Validators.required, Validators.pattern('^[.]?[0-9]+[.]?[0-9]*$')])],
          address: [''],
        }),
        this.fb.group({
          fatherName: ['', Validators.compose([Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')])],
          fatherMobile: ['', Validators.compose([Validators.pattern('^[0-9]{10}$')])],
          //fatherOccupation: ['', [Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')]],
          fatherOccupation: ['', [Validators.pattern('^[a-zA-Z 0-9._-]+$')]],
          fatherEmail: ['', Validators.compose([Validators.email,
          Validators.pattern('^[[a-zA-z][a-zA-z0-9_.+-]+@[a-zA-z0-9-]+.[a-zA-z0-9-.]+$')])],

          motherName: ['', Validators.compose([Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')])],
          motherMobile: ['', Validators.compose([Validators.pattern('^[0-9]{10}$')])],
          //motherOccupation: ['', [Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')]],
          motherOccupation: ['', [Validators.pattern('^[a-zA-Z 0-9._-]+$')]],
          motherEmail: ['', Validators.compose([Validators.email,
          Validators.pattern('^[[a-zA-z][a-zA-z0-9_.+-]+@[a-zA-z0-9-]+.[a-zA-z0-9-.]+$')])],
        }),
        this.fb.group({
          feeType: ['', Validators.compose([Validators.required])],
          feePlan: ['',Validators.compose([Validators.required,this.validateNotZero])],
          paymentFrequency: [''],
          trainingStartDate: ['',Validators.compose([Validators.required])],
          paymentDueDate: ['',Validators.compose([Validators.required])],
          currency: [''],
          status: [''],
          attempts: [''],
          amountPaid: [''],
          amountDue: [''],
          date: [''],
          appUserId: [''],
          accountId: [''],
          accountDetailId: ['']
        }),
      ]),
    });
    this.loadDropDownValues();
    this.date = new Date();
    this.today = moment(this.date).format('YYYY-MM-DD');
   
    //date set to choose from past 5years from today's date for date of birth field
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 5);
    if (this.isEditable) {
      this.patchValues(this.inputs.rowId);
    }
  }

  get formArray(): AbstractControl {
    return this.frmStepper.get('steps');
  }

  setImage(files: FileList) {
    this.file = files.item(0);
    if (files.item(0).type !== 'image/png' && files.item(0).type !== 'image/jpeg' && files.item(0).type !== 'image/jpg') {
      this.profilePicError = 'Only png, jpg and jpeg files are allowed';
    } else {
      this.profilePicError = '';
    }
  }

  loadDropDownValues() {
    this.activatedRouter.params.subscribe((params) => {     
      this.currentCenterId = params.id?params.id:0
      this.currentBatchId = params.batchId?parseInt(params.batchId, 10):0;
      this.courseId =params.courseId? parseInt(params.courseId):0
    });
    // console.log('loaddd',this.currentCenterId,this.currentBatchId,this.courseId)
    this.optionAPi
      .find({
        where: {
          identifier: Constants.OPTIONS.booldGroup
        }
      })
      .toPromise()
      .then((res) => {
        this.bloodGroups = res;
      })
      .then(() => {
        this.optionAPi
          .find({
            where: {
              identifier: Constants.OPTIONS.dominantSide
            }
          })
          .toPromise()
          .then((res1) => {
            this.dominantSides = res1;
          })
          .then(() => {
            this.optionAPi
              .find({
                where: {
                  identifier: Constants.OPTIONS.gender
                }
              })
              .toPromise()
              .then((res2: any) => {
                this.genders = res2;
                this.infrastructureApi
                  .find({
                    where: {
                      isActive: true
                    }
                  })
                  .toPromise()
                  .then((centers) => {
                    this.centers = centers;
                  });
              });
          }).then(() => {
            this.loadCourseWithoutEvent();                
          })

      });

    this.countryAPi.find().toPromise()
      .then((response) => {
        this.countries = response;
      });
    //patch center and batch
    if (this.currentBatchId !== undefined && this.currentBatchId > 0 && this.currentBatchId !== null
      && this.courseId !== undefined && this.courseId > 0 && this.courseId !== null) {
      console.log("h1")
      this.centerId = this.currentCenterId;
      this.loadFeeTypes();
      this.frmStepper.controls.steps['controls'][0].controls.center.patchValue(this.centerId);
      this.frmStepper.controls.steps['controls'][0].controls.course.patchValue(this.courseId);
      this.frmStepper.controls.steps['controls'][0].controls.batch.patchValue(this.currentBatchId);
    } else {
      this.isReadonly = false;
      if (this.currentCenterId !== undefined && this.currentCenterId > 0 && this.currentCenterId !== null) {
        console.log("h2")
        this.centerId = this.currentCenterId;
        this.loadFeeTypes();
        this.frmStepper.controls.steps['controls'][0].controls.center.patchValue(this.centerId);
        this.centerReadonly = true;
      } else {
        console.log("h3")
        this.centerReadonly = false;
      }
    }
  }

  loadCourseWithoutEvent() {
    console.log("loading", this.centerId);
    this.courses = [];
    if (this.centerId != null && this.centerId != 0) {
      this.courseInfraApi.find({
        where: { 'isActive': 1, 'infrastructureId': this.centerId },
        include: ['course']
      }).toPromise()
        .then((courses: any) => {
          this.courses = courses;
          this.loadBatchesWithoutEvent();        
          // console.log(this.courses);
        });
    } else {
      this.courseInfraApi.find({
        where: { 'isActive': 1 },
        include: ['course']
      }).toPromise()
        .then((courses: any) => {
          this.courses = courses;
          this.loadBatchesWithoutEvent();        
          //console.log(this.courses);
        });

    }
    this.loadFeeTypes();
  }
  loadBatchesWithoutEvent() {  
    console.log('iii',this.courseId);
    this.batchApi
      .find({ where: { 'courseId': this.courseId, 'infrastructureId': this.centerId } })
      .toPromise()
      .then((response) => {
        this.batches = response;
      });
    const feeTypesArray = [];
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] }).toPromise()
      .then((feeTypes: any) => {
        feeTypes.map((feeTypeData: any) => {
          feeTypesArray.push({ id: feeTypeData.feetype, label: feeTypeData.feetypeLabel });
        });
        this.feeTypes = feeTypesArray.filter((thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i);
      });
    this.loadFeeTypes();
  }

  loadCourses($event: any) {
    this.centerType = '';
    console.log("i m here");
    if (this.currentCenterId !== undefined && this.currentCenterId > 0 && this.currentCenterId !== null) {
      this.centerId = this.currentCenterId;
      this.infrastructureApi.findOne({ where: { id: this.centerId } }).toPromise().then((data: any) => {
        this.centerType = data.typeLabel;
      }).then(() => {
        console.log("loadcourse", this.centerId, this.centerType);
      })
    } else {
      this.centerId = parseInt($event.target.value, 10);
      this.infrastructureApi.findOne({ where: { id: this.centerId } }).toPromise().then((data: any) => {
        this.centerType = data.typeLabel;
      }).then(() => {
        console.log("load", this.centerId, this.centerType);
      })
    }
    this.courseInfraApi.find({
      where: { infrastructureId: this.centerId },
      include: ['course']
    }).toPromise()
      .then((courses: any) => {
        this.courses = courses;
      });
    // this.loadFeeTypes();
  }

  loadFeeTypes() {
    console.log(this.centerId, this.courseId,)
    const feeTypesArray = [];
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] }).toPromise()
      .then((feeTypes: any) => {
        Promise.all(feeTypes.map((feeTypeData: any) => {
          if (feeTypeData.feePlanCourse) {
            feeTypeData.feePlanCourse.map((i: any) => {
              if (i.courseId == this.courseId)
                feeTypesArray.push({ id: feeTypeData.feetype, label: feeTypeData.feetypeLabel });
            })
          }
        })).then(() => {
          this.feeTypes = feeTypesArray.filter((thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i);
        })
      });
  }

  loadBatches($event: any) {
    this.courseId = parseInt($event.target.value, 10);
    console.log(this.courseId);
    this.batchApi
      .find({ where: { 'courseId': this.courseId, 'infrastructureId': this.centerId } })
      .toPromise()
      .then((response) => {
        this.batches = response;
      });
    const feeTypesArray = [];
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] }).toPromise()
      .then((feeTypes: any) => {
        feeTypes.map((feeTypeData: any) => {
          feeTypesArray.push({ id: feeTypeData.feetype, label: feeTypeData.feetypeLabel });
        });
        this.feeTypes = feeTypesArray.filter((thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i);
      });
    this.loadFeeTypes();
  }

  loadStates($event:any) {
    this.countryId = parseInt($event.target.value, 10);
    this.countryCode=$event.target.value;
    this.stateApi
      .find({ where: { countryId: this.countryId } })
      .toPromise()
      .then((response) => {
        this.states = response;
      });
  }

  loadCities($event:any) {
    this.stateId = parseInt($event.target.value, 10);
    this.cityApi
      .find({ where: { stateId: this.stateId } })
      .toPromise()
      .then((response) => {
        this.cities = response;
      });
  }
  loadFeePlans($event) {
    this.feeTypeSelected = parseInt($event.target.value, 10);
    if (this.feeTypeSelected !== undefined && this.feeTypeSelected !== '' && this.feeTypeSelected !== null && this.feeTypeSelected !== 0) {
      this.isFeeTypeSelected = true;
    } else {
      this.isFeeTypeSelected = false;
    }
    this.frmStepper.controls.steps['controls'][2].controls.feePlan.patchValue(0);
    this.feePlanFun(this.feeTypeSelected);
  }
  feePlanFun(feetype: any) {
    console.log(this.centerId, this.courseId)
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId, feetype }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] }).toPromise()
      .then((feePlans: any) => {
        let fps = [];
        Promise.all(feePlans.map((fp: any) => {
          Promise.all(fp.feePlanCourse.map((f: any) => {
            if (fp.feePlanCourse && f.courseId == this.courseId) {
              fps.push(fp);
            }
          }));
        })).then(() => {
          this.feePlans = fps;
          this.fees = [];
          this.paymentFrequency = [];
          console.log(this.feePlans)
        })

      });
    if (this.feeTypeSelected === 5) {
      this.notFree = false;
      this.frmStepper.controls.steps['controls'][2].controls.paymentDueDate.patchValue(this.today);
    } else {
      this.notFree = true;
    }
  }
  loadPaymentFrequency($event: any) {
    this.feePlanSelected = parseInt($event.target.value, 10);
    console.log("selectedplan", this.feePlanSelected)
    this.feePlanApi.findOne({where:{id:this.feePlanSelected}}).toPromise().then((data:any)=>{
      this.paymentFrequencyFun(data.feetype);
    })
    
  }
  paymentFrequencyFun(feeTypeId:any) {
    //console.log("in paymentfre",feeTypeId,this.courseId,this.centerId)
    console.log('here', feeTypeId)
    if (feeTypeId === 2) {
      this.ispaymentFrequency = true;
      this.feePlanApi.find({
        where: { isActive: true, centerId: this.centerId, id: this.feePlanSelected, },
        include: ['center', 'feeInstallment', 'feeSubscription', 'feePlanCourse']
      }).toPromise()
        .then((paymentFrequency: any) => {
          paymentFrequency.map((pf: any) => {
            console.log(pf, this.courseId);
            Promise.all(pf.feePlanCourse.map((i: any) => {
              if (i.courseId == this.courseId)
                this.paymentFrequency = paymentFrequency;
                this.hasProrata=pf.hasProrata;
            })).then(() => {
              // this.ispaymentFrequency = true;
              console.log('freq', this.paymentFrequency);
            })
          })
        });
      this.feePlanApi.find({
        where: { isActive: true, centerId: this.centerId, id: this.feePlanSelected },
        include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription']
      }).toPromise()
        .then((fees: any) => {
          fees.map((f: any) => {
            f.feePlanCourse.map((i: any) => {
              if (i.courseId == this.courseId)
                this.fees = fees[0];
            })
          })

        });
    } else {
      // console.log("elseeee")
      this.ispaymentFrequency = false;
      this.feePlanApi.find({
        where: { isActive: true, centerId: this.centerId, id: this.feePlanSelected },
        include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription']
      }).toPromise()
        .then((fees: any) => {
          fees.map((f: any) => {
            Promise.all(f.feePlanCourse.map((i: any) => {
              //  console.log("pi",i)
              if (i.courseId == this.courseId)
                this.fees = fees[0];
            })).then(() => {
              //  console.log("feeee",this.fees)
            })
          })

        });
    }
    //console.log("ppfrew",this.paymentFrequency,this.ispaymentFrequency)
  }

  loadFees($event: any) {
    console.log("ppp", $event.target.value)
    this.paymentFrequencySelected = parseInt($event.target.value, 10);
    this.feesFun(this.paymentFrequencySelected);
  }
  feesFun(payFreq: any) {
    this.feePlanApi.find({
      where: { id: this.feePlanSelected, isActive: true, centerId: this.centerId, recurringFrequency: payFreq },
      include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription']
    }).toPromise()
      .then((fees: any) => {
        this.fees = fees[0];
      });
  }

  setCity($event:any) {
    this.cityId = parseInt($event.target.value, 10);
  }

  setState($event:any) {
    this.stateId = parseInt($event.target.value, 10);
  }
  setAccountUserId(usrId: any) {
    // console.log(this.accountId)
    this.accountUserApi.find({ where: { accountId: this.accountId } }).toPromise().then((data: any) => {
      // console.log('no userdata',data);
      if (data.length == 0) {
        this.accountUserApi.create({ accountId: this.accountId, appUserId: usrId }).toPromise();
      }
    })
  }

  setBatchId($event: any) {
    this.batchId = parseInt($event.target.value, 10);
    this.accountBatchApi.find({ where: { batchId: this.batchId,status:1 }, include: [{ relation: 'account', scope: { where: { type: 1, accountStatus:2 } } }, 'batch'] }).toPromise().then((data:any) => {
      // console.log(this.batchId)
      var count = 0;
      var capacity = 0;
      return Promise.all(data.map((item: any) => {
        capacity = item.batch.batchCapacity;
        if (item.account) {
          count++;
          // console.log(item.account);
        }
      })).then(() => {
        if (count > 0 && count >= capacity) {
          swal.fire({
            text: "This batch is already full!",
            type: 'info',
            confirmButtonClass: 'btn btn-primary',
            buttonsStyling: false
          })
          this.batchId = 0;
          this.frmStepper.controls.steps['controls'][0].controls.batch.patchValue(this.batchId);
         
        }
        console.log(count, capacity, this.batchId);
      });

    })
  }

  onGenderChange($event) {
    this.gender = parseInt($event.target.value, 10);
  }

  setBloodGroup($event) {
    this.bloodGroup = parseInt($event.target.value, 10);
  }

  setDominentSide($event) {
    this.dominantSide = parseInt($event.target.value, 10);
  }

  submit(): void {
    if (this.isEditable) {
      this.updateStudent();
    } else {
      this.addStudent();
    }
  }
 
  addStudent() {   
    let batchDays:any=[];
    console.log('country code',this.countryId,this.countryCode)
    this.frmValues = this.frmStepper.value;
    this.playerDetails = this.frmStepper.value.steps[0];
    this.gaurdianDetails = this.frmStepper.value.steps[1];
    this.feeSetup = this.frmStepper.value.steps[2];

    // if (this.file === undefined || this.file === null) {
    //   this.profilePicError = 'Please Upload Profile Picture';
    //   return false;
    // } else {
    //   this.profilePicError = '';
    // }
    console.log(this.playerDetails)

    if (this.frmStepper.valid) {
      if(this.countryCode==0){
        this.countryMessage='Please select country.';
        return;
      }
      if(this.stateId==0){
        this.stateMessage='Please select state.';
        return;
      }
      if(this.cityId==0){
        this.cityMessage='Please select city.';
        return;
      }
      this.spinner.show();
      this.addressApi.create({
        addressLine1: this.playerDetails.address,
        addressLine2: this.playerDetails.address,
        pincode: parseInt(this.playerDetails.pincode, 10),
        cityId: this.cityId,
        addressType: 4,
        isActive: true,
      }).toPromise().then((address: any) => {
        this.addressId = parseInt(address.id, 10);
        this.appUserApi.addStudent(
          this.playerDetails.fullName,
          parseInt(this.playerDetails.gender, 10),
          this.playerDetails.mobile,
          this.playerDetails.email,
          parseInt(this.playerDetails.batch, 10),
          parseInt(this.playerDetails.center, 10),
          this.addressId,
          this.playerDetails.dateOfBirth,
          parseInt(this.playerDetails.course, 10)
        ).toPromise().then((accountData: any) => {
          console.log("check", accountData)
          if (this.file !== undefined && this.file !== null) {
            this.uploadProfilePic(accountData.accountId);
          }
          this.frmStepper.controls.steps['controls'][2].controls.accountDetailId.patchValue(accountData.id);
          this.frmStepper.controls.steps['controls'][2].controls.accountId.patchValue(accountData.accountId);
          this.frmStepper.controls.steps['controls'][2].controls.appUserId.patchValue(accountData.appUserId);
          this.accountDetailApi.patchAttributes(accountData.id, {
            bloodGroup: parseInt(this.playerDetails.bloodGroup, 10),
            dominantSide: parseInt(this.playerDetails.dominantSide, 10),
            medicalCondition: this.playerDetails.medicalCondition,
            profile: this.playerDetails.profile,
            qualifications: this.playerDetails.qualification,
            dateOfJoining:this.feeSetup.trainingStartDate
          }).toPromise().then((accountDetail: any) => {
            this.countryCode = '1';
            this.relationF = 'Father';
            this.relationM = 'Mother';
            if (this.gaurdianDetails.fatherName != '') {
              this.guardianApi.createGuardian(
                this.gaurdianDetails.fatherName,
                this.gaurdianDetails.fatherEmail,
                this.gaurdianDetails.fatherMobile,
                this.relationF,
                this.gaurdianDetails.fatherOccupation,
                this.countryCode,
                accountDetail.accountId
              ).toPromise().then((guardianDetails: any) => {
                const guardianData = guardianDetails;
              }).catch((error) => {
                this.message = error.message;
              });
            }
            if (this.gaurdianDetails.motherName != '') {
              this.guardianApi.createGuardian(
                this.gaurdianDetails.motherName,
                this.gaurdianDetails.motherEmail,
                this.gaurdianDetails.motherMobile,
                this.relationM,
                this.gaurdianDetails.motherOccupation,
                this.countryCode,
                accountDetail.accountId
              )
                .toPromise().then((guardianDetails: any) => {
                  const guardianData = guardianDetails;
                }).catch((error) => {
                  this.message = error.message;
                });
            }
            console.log(this.feeSetup);
            const feeSetupData: any = {};
            feeSetupData.amount = this.fees.price;
            feeSetupData.accountId = accountDetail.accountId;
            feeSetupData.feePlanId = parseInt(this.feeSetup.feePlan, 10);
            feeSetupData.trainingStartDate = this.feeSetup.trainingStartDate !== "" ? this.feeSetup.trainingStartDate : moment().format('YYYY-MM-DD');
            feeSetupData.dueDate = this.feeSetup.paymentDueDate !== "" ? this.feeSetup.paymentDueDate : moment().format('YYYY-MM-DD');
            feeSetupData.currency = this.currencySymbolPipe.getCurrency(this.countrySym);
            feeSetupData.attempts = this.feeSetup.attempts !== undefined && this.feeSetup.attempts !== "" ? this.feeSetup.attempts : 0;
            feeSetupData.amountPaid = (this.feeSetup.amountPaid !== undefined && this.feeSetup.amountPaid !== "") ? this.feeSetup.amountPaid : 0;
            feeSetupData.amountDue = this.feeSetup.amountDue !== undefined && this.feeSetup.amountDue !== '' ? this.feeSetup.amountDue : this.fees.price;
            feeSetupData.status = feeSetupData.amountDue != 0 ? 1 : 3;
            feeSetupData.date = this.feeSetup.paymentDueDate !== "" ? this.feeSetup.paymentDueDate : moment().format('YYYY-MM-DD');
            if(this.hasProrata){
            this.accountbatchTimingApi.find({where:{accountId:accountDetail.accountId},include:['batchtiming']}).toPromise().then((abtData:any)=>{
              abtData.map((item:any)=>{
                batchDays.push(item.batchtiming.day)
              })
              this.calculateProratedFee(batchDays,feeSetupData.trainingStartDate,parseInt(this.feeSetup.paymentFrequency));
            })
          }
            return this.mapFeeplanCourseApi.findOne({ where: { feePlanId: parseInt(this.feeSetup.feePlan, 10), courseId: parseInt(this.playerDetails.course, 10) } }).toPromise().then((fpc: any) => {
              feeSetupData.feePlanCourseId = fpc.id;
            }).then(() => {
              feeSetupData.amount=this.proratedFee != 0 ? this.proratedFee :  feeSetupData.amount;
              feeSetupData.amountDue=this.proratedFee != 0 ? this.proratedFee : feeSetupData.amountDue;
              this.orderApi.addOrder(feeSetupData.accountId, feeSetupData.amount, feeSetupData.amountDue, feeSetupData.amountPaid, feeSetupData.attempts, feeSetupData.currency, feeSetupData.date, feeSetupData.dueDate, feeSetupData.feePlanId, feeSetupData.status, feeSetupData.trainingStartDate, parseInt(this.playerDetails.course, 10), 0, 0).toPromise().then((feeSetupDetails: any) => {
                const feeSetupInfo = feeSetupDetails;
                // this.orderApi.create(feeSetupData).toPromise().then((feeSetupDetails: any) => {
                //   const feeSetupInfo = feeSetupDetails;
              }).catch((error) => {
                this.message = error.message;
                swal.fire({
                  text: this.message,
                  type: 'error',
                })
              });
            })
          }).then(() => {
            this.spinner.hide();
            swal.fire({
              title: 'Saved!',
              text: 'Student has been Added successfully.',
              type: 'success',
              confirmButtonClass: 'btn btn-primary',
              buttonsStyling: false
            }).then((result) => {
              if (result) {
                this.closeModal.emit(true);
              }
            });
          }).catch((error) => {
            this.spinner.hide();
            this.message = error.message;
            swal.fire({
              text: this.message,
              type: 'error',
            })
          });
        }).catch((error) => {
          this.spinner.hide();
          this.message = error.message;
          swal.fire({
            text: this.message,
            type: 'error',
          })
        });
      });
    }
  }
  updateStudent() {   
    console.log(this.accountId,this.accountDetailId,this.batchDays);
    this.spinner.show();
    this.frmValues = this.frmStepper.value;
    this.playerDetails = this.frmStepper.value.steps[0];
    this.gaurdianDetails = this.frmStepper.value.steps[1];
    this.feeSetup = this.frmStepper.value.steps[2];
    if (this.frmStepper.valid) {
      // this.addressApi.patchAttributes(this.addressId,{
      //   addressLine1: this.playerDetails.address,
      //   addressLine2: this.playerDetails.address,
      //   pincode: parseInt(this.playerDetails.pincode),
      //   cityId: this.cityId,
      //   addressType: 4,
      //   isActive: true,
      // }).toPromise().then(() => {
      console.log(this.feeSetup,this.accountId)
      this.accountInfrastructureApi.find({ where: { accountId: this.accountId } }).toPromise().then((item: any) => {
        if (item.length>0 && item[0].infrastructureId != this.centerId)
          this.accountInfrastructureApi.patchAttributes(item[0].id, { infrastructureId: this.centerId }).toPromise();
        else if(item.length>0 && item[0].infrastructureId == this.centerId){
          console.log("center is already assigned");
        }else{
        this.accountInfrastructureApi.create({ infrastructureId: this.centerId,accountId:this.accountId }).toPromise();
        }
      }).then(()=>{
        if(this.accountDetailId){
          this.accountDetailApi.patchAttributes(this.accountDetailId, {
            bloodGroup: parseInt(this.playerDetails.bloodGroup, 10),
            dominantSide: parseInt(this.playerDetails.dominantSide, 10),
            medicalCondition: this.playerDetails.medicalCondition,
            dateOfBirth: this.playerDetails.dateOfBirth,
            gender: parseInt(this.playerDetails.gender, 10),
            mobile: this.playerDetails.mobile,
            email: this.playerDetails.email,
            profile: this.playerDetails.profile,
            qualifications: this.playerDetails.qualification,
          }).toPromise().then((accountData: any) => {
            if (this.file !== undefined && this.file !== null) {
              this.uploadProfilePic(accountData.accountId);
            }
          });
        }else{
          this.accountDetailApi.create({
            bloodGroup: parseInt(this.playerDetails.bloodGroup, 10),
            dominantSide: parseInt(this.playerDetails.dominantSide, 10),
            medicalCondition: this.playerDetails.medicalCondition,
            dateOfBirth: this.playerDetails.dateOfBirth,
            gender: parseInt(this.playerDetails.gender, 10),
            mobile: this.playerDetails.mobile,
            email: this.playerDetails.email,
            profile: this.playerDetails.profile,
            qualifications: this.playerDetails.qualification,
            accountId:this.accountId
          }).toPromise().then((accountData: any)=>{
            if (this.file !== undefined && this.file !== null) {
              this.uploadProfilePic(accountData.accountId);
            }
          });
        }
      }).then(() => {
        console.log('im in')
          this.accountApi
          .patchAttributes(this.accountId, {
            name: this.playerDetails.fullName,
            accountStatus: 2
          }).toPromise().then((account: any) => {
            console.log('account.appuserid',account.appUserId)
            this.appUserApi.patchAttributes(account.appUserId, { appUserStatus: 1,mobileNumber: this.playerDetails.mobile,
              email: this.playerDetails.email }).toPromise();
            this.setAccountUserId(account.appUserId);
            const motherDetails: any = {};
            motherDetails.name = this.gaurdianDetails.motherName;
            motherDetails.email = this.gaurdianDetails.motherEmail;
            motherDetails.mobileNumber = this.gaurdianDetails.motherMobile;
            motherDetails.relation = 'Mother';
            motherDetails.occupation = this.gaurdianDetails.motherOccupation;
            motherDetails.countryCode = '1';
            const fatherDetails: any = {};
            fatherDetails.name = this.gaurdianDetails.fatherName;
            fatherDetails.email = this.gaurdianDetails.fatherEmail;
            fatherDetails.mobileNumber = this.gaurdianDetails.fatherMobile;
            fatherDetails.relation = 'Father';
            fatherDetails.occupation = this.gaurdianDetails.fatherOccupation;
            fatherDetails.countryCode = '1';
            if (this.motherId !== undefined) {
              this.guardianApi.patchAttributes(this.motherId, motherDetails).toPromise().then((gaurdianM) => {
                const gaurdianMData: any = gaurdianM;
              }).catch((error) => {
                console.log(error);
              });
            } else {
              this.countryCode = '1';
              this.relationM = 'Mother';
              this.guardianApi.createGuardian(
                this.gaurdianDetails.motherName,
                this.gaurdianDetails.motherEmail,
                this.gaurdianDetails.motherMobile,
                this.relationM,
                this.gaurdianDetails.motherOccupation,
                this.countryCode,
                this.accountId
              )
                .toPromise().then((guardianDetails: any) => {
                  const guardianData = guardianDetails;
                }).catch((error) => {
                  this.message = error.message;
                });
            }
            if (this.fatherId !== undefined) {
              this.guardianApi.patchAttributes(this.fatherId, fatherDetails).toPromise().then((gaurdianF) => {
                const gaurdianFData: any = gaurdianF;
              }).catch((error) => {
                console.log(error);
              });
            } else {
              this.countryCode = '1';
              this.relationF = 'Father';
              this.guardianApi.createGuardian(
                this.gaurdianDetails.fatherName,
                this.gaurdianDetails.fatherEmail,
                this.gaurdianDetails.fatherMobile,
                this.relationF,
                this.gaurdianDetails.fatherOccupation,
                this.countryCode,
                this.accountId
              ).toPromise().then((guardianDetails: any) => {
                const guardianData = guardianDetails;
              }).catch((error) => {
                this.message = error.message;
              });
            }
            console.log('account', account);
            if (account.addressId == null) {
              this.addressApi.create({
                addressLine1: this.playerDetails.address,
                addressLine2: this.playerDetails.address,
                pincode: parseInt(this.playerDetails.pincode),
                cityId: this.cityId,
                addressType: 4,
                isActive: true,
              }).toPromise().then((addressDetail: any) => {
                console.log("add", addressDetail.id)
                this.accountApi
                  .patchAttributes(this.accountId, {
                    addressId: addressDetail.id,
                  }).toPromise()
              });
            } else {
              this.addressApi.patchAttributes(this.addressId, {
                addressLine1: this.playerDetails.address,
                addressLine2: this.playerDetails.address,
                pincode: parseInt(this.playerDetails.pincode),
                cityId: this.playerDetails.city,
                addressType: 4,
                isActive: true,
              }).toPromise();
            }
          
            const feeSetupData: any = {};
            feeSetupData.amount = this.fees.price;
            feeSetupData.accountId = account.id;
            feeSetupData.feePlanId = parseInt(this.feeSetup.feePlan, 10);
            feeSetupData.trainingStartDate = this.feeSetup.trainingStartDate;
            feeSetupData.dueDate = this.feeSetup.paymentDueDate;
            feeSetupData.currency = this.currencySymbolPipe.getCurrency(this.countrySym); //this.feeSetup.currency !== undefined ? this.feeSetup.currency : null;
            feeSetupData.status = this.feeSetup.status !== "" ? this.feeSetup.status : 1;
            feeSetupData.attempts = this.feeSetup.attempts !== undefined && this.feeSetup.attempts !== "" ? this.feeSetup.attempts : 0;
            feeSetupData.amountPaid = this.feeSetup.amountPaid !== undefined && this.feeSetup.amountPaid !== "" ? this.feeSetup.amountPaid : 0;
            feeSetupData.amountDue = this.feeSetup.amountDue !== undefined && this.feeSetup.amountDue !== '' ? this.feeSetup.amountDue : this.fees.price;
            feeSetupData.date = this.feeSetup.paymentDueDate; //this.feeSetup.date !== undefined ? this.feeSetup.date : null;
            console.log("batch", this.accountBatches, this.accountBatches[0], this.accountBatches.length)
            if (this.accountBatches && this.accountBatches[0] && this.accountBatches.length < 2) {
              console.log('accountBatch', this.accountBatches, this.batchId, this.courseId);
              this.accountBatchApi.patchAttributes(this.accountBatches[0].id, { courseId: this.courseId, batchId: this.batchId,status:1 }).toPromise();
              if(this.hasProrata){
                this.calculateProratedFee(this.batchDays,feeSetupData.trainingStartDate,parseInt(this.feeSetup.paymentFrequency));
                }
                return this.mapFeeplanCourseApi.findOne({ where: { feePlanId: parseInt(this.feeSetup.feePlan, 10), courseId: parseInt(this.playerDetails.course, 10) } }).toPromise().then((fpc: any) => {
                  feeSetupData.feePlanCourseId = fpc.id;
                }).then(() => {
                  feeSetupData.amount=this.proratedFee != 0 ? this.proratedFee :  feeSetupData.amount;
                  feeSetupData.amountDue=this.proratedFee != 0 ? this.proratedFee : feeSetupData.amountDue;              
                  if (this.orderId && this.orderId !== undefined) {
                    console.log("orderid", this.orderId,this.proratedFee,this.hasProrata)
                    this.orderApi.patchAttributes(this.orderId, feeSetupData).toPromise().then((orderDetails) => {
                      const orderData = orderDetails;
                    }).then(() => {
                      this.spinner.hide();
                      swal.fire({
                        title: 'Saved!',
                        text: 'Student details has been updated successfully.',
                        type: 'success',
                        confirmButtonClass: 'btn btn-primary',
                        buttonsStyling: false
                      }).then((result) => {
                        if (result) {
                          this.closeModal.emit(true);
                        }
                      });
                    });
                  } else {
                    console.log("in orderapi pathchOrcreate", feeSetupData)
                    this.orderApi.addOrder(feeSetupData.accountId, feeSetupData.amount, feeSetupData.amountDue, feeSetupData.amountPaid, feeSetupData.attempts, feeSetupData.currency, feeSetupData.date, feeSetupData.dueDate, feeSetupData.feePlanId, feeSetupData.status, feeSetupData.trainingStartDate, parseInt(this.playerDetails.course, 10), 0, 0).toPromise().then((feeSetupDetails: any) => {
                      const feeSetupInfo = feeSetupDetails;
                    }).then(() => {                 
                      this.spinner.hide();
                      swal.fire({
                        title: 'Saved!',
                        text: 'Student details has been updated successfully.',
                        type: 'success',
                        confirmButtonClass: 'btn btn-primary',
                        buttonsStyling: false
                      }).then((result) => {
                        if (result) {
                          this.closeModal.emit(true);
                        }
                      });
                    });
                  }
                })

            } else {
              console.log('no accountbatch is here',this.courseId,this.batchId);
              this.batchDays=[];
              if(this.courseId!=0 && this.batchId!=0){
              this.accountBatchApi.create({ accountId: this.accountId, batchId: this.batchId, courseId: this.courseId, status: 1 }).toPromise().then(() => {
                this.batchTimingApi.find({ where: { batchId: this.batchId } }).toPromise().then((btData: any) => {
                  if (btData.length > 0) {
                    return Promise.all(btData.map((item: any) => {
                      this.batchDays.push(item.day);
                      this.accountbatchTimingApi.create({
                        accountId: this.accountId,
                        batchtimingId: item.id,
                      }).toPromise();
                    })).then(()=>{
                      if(this.hasProrata){
                        this.calculateProratedFee(this.batchDays,feeSetupData.trainingStartDate,parseInt(this.feeSetup.paymentFrequency));
                        }
                        return this.mapFeeplanCourseApi.findOne({ where: { feePlanId: parseInt(this.feeSetup.feePlan, 10), courseId: parseInt(this.playerDetails.course, 10) } }).toPromise().then((fpc: any) => {
                          feeSetupData.feePlanCourseId = fpc.id;
                        }).then(() => {
                          feeSetupData.amount=this.proratedFee != 0 ? this.proratedFee :  feeSetupData.amount;
                          feeSetupData.amountDue=this.proratedFee != 0 ? this.proratedFee : feeSetupData.amountDue;              
                          if (this.orderId && this.orderId !== undefined) {
                            console.log("orderid", this.orderId,this.proratedFee,this.hasProrata)
                            this.orderApi.patchAttributes(this.orderId, feeSetupData).toPromise().then((orderDetails) => {
                              const orderData = orderDetails;
                            }).then(() => {
                              this.spinner.hide();
                              swal.fire({
                                title: 'Saved!',
                                text: 'Student details has been updated successfully.',
                                type: 'success',
                                confirmButtonClass: 'btn btn-primary',
                                buttonsStyling: false
                              }).then((result) => {
                                if (result) {
                                  this.closeModal.emit(true);
                                }
                              });
                            });
                          } else {
                            console.log("in orderapi pathchOrcreate", feeSetupData)            
                            this.orderApi.addOrder(feeSetupData.accountId, feeSetupData.amount, feeSetupData.amountDue, feeSetupData.amountPaid, feeSetupData.attempts, feeSetupData.currency, feeSetupData.date, feeSetupData.dueDate, feeSetupData.feePlanId, feeSetupData.status, feeSetupData.trainingStartDate, parseInt(this.playerDetails.course, 10), 0, 0).toPromise().then((feeSetupDetails: any) => {
                              const feeSetupInfo = feeSetupDetails;
                            }).then(() => {                 
                              this.spinner.hide();
                              swal.fire({
                                title: 'Saved!',
                                text: 'Student details has been updated successfully.',
                                type: 'success',
                                confirmButtonClass: 'btn btn-primary',
                                buttonsStyling: false
                              }).then((result) => {
                                if (result) {
                                  this.closeModal.emit(true);
                                }
                              });
                            });
                          }
                        })
                    })
                  }
                });
              });
            }
          }                           

          });
      }).catch((error) => {
        console.log(error);
        this.spinner.hide();
    });
      // })
    }else{
      console.log(this.frmStepper)
      this.spinner.hide();
    }
  }
  patchValues(id: any) {
    this.batchDays=[];
    this.accountId = id;
    this.frmStepper.controls.steps['controls'][2].controls.accountDetailId.patchValue(id);
    this.frmStepper.controls.steps['controls'][2].controls.accountId.patchValue(id);
    console.log("accid", this.accountId, "course", this.courseId, "currentBatch", this.batchId,'cb',this.currentBatchId,'cc',this.currentCenterId)
    this.accountInfrastructureApi.find({ where: { accountId: this.accountId } }).toPromise().then((infrastructures: any) => {
      if(infrastructures.length>0 && this.currentCenterId==0){
        this.isReadonly = false;
      }else{
        this.isReadonly = true;
      }
    })
    this.accountBatchApi.find({
      where: { accountId: this.accountId },
      include: { batch: 'infrastructure' }
    }).toPromise().then((accountBatchs: any) => {
     // console.log('from dashboard',accountBatchs[0].batchId,accountBatchs[0].courseId)
      this.accountBatches = accountBatchs;
      // if (accountBatchs.length==1) {
      //   this.isReadonly = true;
      // } else {
      //   this.isReadonly = false;
      // }
    }).then(() => {
      this.accountApi.findById(this.accountId, {
        include: ['appUser', 'accountDetail', 'profilePicture', 'infrastructures', 'batches', 'courses', { address: { city: { state: 'country' } } },'accountBatchtiming']
      }).toPromise()
        .then(async (studentData: any) => {
          console.log('pp',studentData);
          if (studentData.profilePicture !== undefined && studentData.profilePicture.location !== '' && studentData.profilePicture.location !== undefined
            && studentData.profilePicture.location !== null) {
            const res = await this.appFileApi.readFileFromContainer(encodeURIComponent(studentData.profilePicture.containerName), studentData.profilePicture.name).toPromise();
            this.profilePictureUrl = res.data;
            this.addoreditProfilePic = false;
          } else {
            this.addoreditProfilePic = true;
          }
          this.frmStepper.controls.steps['controls'][2].controls.appUserId.patchValue(studentData.appUserId);
          this.accountDetailId = studentData.accountDetail?studentData.accountDetail.id:null;
          if(this.currentCenterId==0 && this.currentBatchId==0 ){
            console.log(1)
          this.centerId = studentData.infrastructures.length > 0 ? studentData.infrastructures[0].id : 0;          
          this.courseId = studentData.courses.length > 0 ? studentData.courses[0].id : 0;
          if (studentData.batches.length>0) {
            this.batchApi.find({ where: { infrastructureId: this.centerId,courseId: this.courseId } }).toPromise()
              .then((response) => {
                this.batches = response;
              });
            this.batchId = studentData.batches[0].id;
          }
         
          }else if(this.currentCenterId!=0 && this.currentBatchId==0){ 
            console.log(2)
            this.centerId =this.currentCenterId;
            this.courseId = studentData.courses.length > 0 ? studentData.courses[0].id : 0;                
            if (studentData.batches.length>0) {
              this.batchApi.find({ where: { infrastructureId: this.centerId,courseId: this.courseId } }).toPromise()
                .then((response) => {
                  this.batches = response;
                });
              this.batchId = studentData.batches[0].id;
            }
        
          }else{
            console.log(3)
            this.centerId=this.currentCenterId;
            this.batchId=this.currentBatchId
            this.loadBatchesWithoutEvent();
          }
          console.log("sc",this.centerId,this.batchId,this.courseId,this.courses);
          if(this.batchId==0 && this.courseId==0)
            this.loadCourseWithoutEvent();

        
          studentData.accountBatchtiming.map((bt:any)=>{
            this.batchDays.push(bt.day);
          })         
          this.frmStepper.controls.steps['controls'][0].controls.fullName.patchValue(studentData.name);
          this.frmStepper.controls.steps['controls'][0].controls.center.patchValue(this.centerId);        
          //console.log('course-batch-center',this.courseId,this.batchId,this.centerId,this.courses,this.centers,this.feeTypes)
          this.frmStepper.controls.steps['controls'][0].controls.course.patchValue(this.courseId);
          this.frmStepper.controls.steps['controls'][0].controls.batch.patchValue(this.batchId);
          this.frmStepper.controls.steps['controls'][0].controls.gender.patchValue(studentData.accountDetail? studentData.accountDetail.gender : 0);
          this.frmStepper.controls.steps['controls'][0].controls.email.patchValue(studentData.appUser.email);
          this.frmStepper.controls.steps['controls'][0].controls.dateOfBirth.patchValue(moment(studentData.accountDetail?studentData.accountDetail.dateOfBirth:'').
            format('YYYY-MM-DD'));
          this.frmStepper.controls.steps['controls'][0].controls.mobile.patchValue(studentData.appUser.mobileNumber);
          this.frmStepper.controls.steps['controls'][0].controls.medicalCondition.patchValue(studentData.accountDetail?studentData.accountDetail.medicalCondition:'');
          this.frmStepper.controls.steps['controls'][0].controls.qualification.patchValue(studentData.accountDetail?studentData.accountDetail.qualifications:'');
          this.frmStepper.controls.steps['controls'][0].controls.profile.patchValue(studentData.accountDetail?studentData.accountDetail.profile:'');
          this.frmStepper.controls.steps['controls'][0].controls.bloodGroup.patchValue(studentData.accountDetail && studentData.accountDetail.bloodGroup !=null? studentData.accountDetail.bloodGroup : 0);
          this.frmStepper.controls.steps['controls'][0].controls.dominantSide.patchValue(studentData.accountDetail && studentData.accountDetail.dominantSide!=null? studentData.accountDetail.dominantSide : 0);
          this.frmStepper.controls.steps['controls'][0].controls.country.patchValue(studentData.address ? studentData.address.city.state.countryId : "");
          let filter: any = '';
          if (studentData.address) {
            filter = { where: { country_id: studentData.address.city.state.countryId } };
          }
          this.stateApi
            .find(filter)
            .toPromise()
            .then((response) => {
              this.states = response;
            });
          this.frmStepper.controls.steps['controls'][0].controls.state.setValue(studentData.address ? studentData.address.city.state.id : "");
          if (studentData.address) {
            filter = { where: { stateId: studentData.address.city.state.id } };
          }
          this.cityApi
            .find(filter)
            .toPromise()
            .then((response) => {
              this.cities = response;
            });
          this.frmStepper.controls.steps['controls'][0].controls.city.setValue(studentData.address ? studentData.address.city.id : "");
          this.frmStepper.controls.steps['controls'][0].controls.pincode.patchValue(studentData.address ? studentData.address.pincode : '');
          this.frmStepper.controls.steps['controls'][0].controls.address.patchValue(studentData.address ? studentData.address.addressLine1 : '');
          this.addressId = studentData.addressId;
          //Guardian Details -- Step2
          this.guardianApi.find({ where: { accountId: this.accountId } }).toPromise().then((guardianData: any) => {
            console.log("gaurdian", guardianData);
            if (guardianData.length > 0) {
              if (guardianData[0] && guardianData[0].relation === 'Mother') {
                this.motherId = guardianData[0].id;
              } else if (guardianData[0] && guardianData[0].relation === 'Father') {
                this.fatherId = guardianData[0].id;
              }
              if (guardianData[1] && guardianData[1].relation === 'Father') {
                this.fatherId = guardianData[1].id;
              } else if (guardianData[1] && guardianData[1].relation === 'Mother') {
                this.motherId = guardianData[1].id;
              }
              if (guardianData.length > 1) {
                this.frmStepper.controls.steps['controls'][1].controls.fatherName.patchValue(guardianData[0].name);
                this.frmStepper.controls.steps['controls'][1].controls.fatherMobile.patchValue(guardianData[0].mobileNumber);
                this.frmStepper.controls.steps['controls'][1].controls.fatherEmail.patchValue(guardianData[0].email);
                this.frmStepper.controls.steps['controls'][1].controls.fatherOccupation.patchValue(guardianData[0].occupation);


                this.frmStepper.controls.steps['controls'][1].controls.motherName.patchValue(guardianData[1].name);
                this.frmStepper.controls.steps['controls'][1].controls.motherMobile.patchValue(guardianData[1].mobileNumber);
                this.frmStepper.controls.steps['controls'][1].controls.motherEmail.patchValue(guardianData[1].email);
                this.frmStepper.controls.steps['controls'][1].controls.motherOccupation.patchValue(guardianData[1].occupation);
              }
              if (guardianData.length == 1) {
                if (guardianData[0] && guardianData[0].relation === 'Mother') {
                  this.frmStepper.controls.steps['controls'][1].controls.motherName.patchValue(guardianData[0].name);
                  this.frmStepper.controls.steps['controls'][1].controls.motherMobile.patchValue(guardianData[0].mobileNumber);
                  this.frmStepper.controls.steps['controls'][1].controls.motherEmail.patchValue(guardianData[0].email);
                  this.frmStepper.controls.steps['controls'][1].controls.motherOccupation.patchValue(guardianData[0].occupation);
                }
                if (guardianData[1] && guardianData[0].relation === 'Father') {
                  this.frmStepper.controls.steps['controls'][1].controls.fatherName.patchValue(guardianData[0].name);
                  this.frmStepper.controls.steps['controls'][1].controls.fatherMobile.patchValue(guardianData[0].mobileNumber);
                  this.frmStepper.controls.steps['controls'][1].controls.fatherEmail.patchValue(guardianData[0].email);
                  this.frmStepper.controls.steps['controls'][1].controls.fatherOccupation.patchValue(guardianData[0].occupation);

                }
              }
            }
          });
          // Fee set Up -- Step 3
          const feeTypesArray = [];
          this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId }, include: ['center', 'course'] }).toPromise()
            .then((feeTypes: any) => {
              feeTypes.map((feeTypeData: any) => {
                feeTypesArray.push({ id: feeTypeData.feetype, label: feeTypeData.feetypeLabel });
              });
              console.log('feetypes',feeTypesArray,feeTypes)
              this.feeTypes = feeTypesArray.filter((thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i);           
              this.orderApi.find({ where: { isActive: true, accountId: id, status: 1 }, include: ['feePlan'] }).
                toPromise().then((feeSetUpResponse: any) => {
                  this.orderId = feeSetUpResponse[0] ? feeSetUpResponse[0].id : '';
                  this.frmStepper.controls.steps['controls'][2].controls.trainingStartDate.patchValue(feeSetUpResponse[0] && feeSetUpResponse[0].trainingStartDate ?
                    moment(feeSetUpResponse[0].trainingStartDate).format('YYYY-MM-DD') : this.today);
                  this.frmStepper.controls.steps['controls'][2].controls.paymentDueDate.patchValue(feeSetUpResponse[0] && feeSetUpResponse[0].dueDate ?
                    moment(feeSetUpResponse[0].dueDate).format('YYYY-MM-DD') : this.today);
                 // console.log('cc', feeSetUpResponse[0], parseInt(feeSetUpResponse[0].feePlan.feetype, 10),this.feeTypes);
                  this.frmStepper.controls.steps['controls'][2].controls.feeType.patchValue(feeSetUpResponse[0] && feeSetUpResponse[0].feePlan ?
                    parseInt(feeSetUpResponse[0].feePlan.feetype, 10) : '');
                  if (feeSetUpResponse[0] && feeSetUpResponse[0].feePlan.feetype !== 0 && feeSetUpResponse[0].feePlan.feetype !== undefined
                    && feeSetUpResponse[0].feePlan.feetype !== null) {
                    this.isFeeTypeSelected = true;
                    this.feePlanSelected = feeSetUpResponse[0] ? feeSetUpResponse[0].feePlan.id : null;
                    this.feePlanFun(parseInt(feeSetUpResponse[0] ? feeSetUpResponse[0].feePlan.feetype : '', 10));
                  } else {
                    this.isFeeTypeSelected = false;
                  }
                  this.frmStepper.controls.steps['controls'][2].controls.feePlan.patchValue(feeSetUpResponse[0] && feeSetUpResponse[0].feePlanId ?
                    parseInt(feeSetUpResponse[0].feePlanId, 10) : '');
                  this.paymentFrequencyFun(parseInt(feeSetUpResponse[0] ? feeSetUpResponse[0].feePlan.feetype : '', 10));
                  this.frmStepper.controls.steps['controls'][2].controls.paymentFrequency.patchValue(feeSetUpResponse[0] && feeSetUpResponse[0].feePlan.recurringFrequency ?
                    parseInt(feeSetUpResponse[0].feePlan.recurringFrequency, 10) : 0);
                  this.feesFun(parseInt(feeSetUpResponse[0] ? feeSetUpResponse[0].feePlan.recurringFrequency : '', 10));
                });
              
            });
       
        });
    }).then(() => {
      console.log('check control', this.frmStepper.controls.steps['controls']);
    })
  }
  uploadProfilePic(accountId: number) {
    this.fileUploadService.uploadPicture(accountId, "Accounts_uploadProfilePicture", this.file,).then((result: any) => {
      console.log(result);
      // swal.fire({
      //   title: "Saved!",
      //   text:  message,
      //   type: "success",
      //   confirmButtonClass: "btn btn-primary",
      //   buttonsStyling: false
      // })
      //   .then(result => {
      //     console.log(result);
      // });
    })
  }
  showFileDiv(value: any) {
    if (value === false) {
      this.addoreditProfilePic = true;
    } else {
      this.addoreditProfilePic = false;
    }
  }
  reset(){
    console.log('i m in ');
    this.frmStepper.reset();
    this.frmStepper.controls.steps['controls'][0].controls.center.setValue(0);
    this.frmStepper.controls.steps['controls'][0].controls.course.setValue(0);
    this.frmStepper.controls.steps['controls'][0].controls.batch.setValue(0);
    this.frmStepper.controls.steps['controls'][0].controls.country.setValue("");
    this.frmStepper.controls.steps['controls'][0].controls.city.setValue("");
    this.frmStepper.controls.steps['controls'][0].controls.state.setValue("");
    this.frmStepper.controls.steps['controls'][0].controls.gender.setValue(0);
    this.frmStepper.controls.steps['controls'][0].controls.bloodGroup.setValue(0);
    this.frmStepper.controls.steps['controls'][0].controls.dominantSide.setValue(0);
    this.frmStepper.controls.steps['controls'][2].controls.paymentFrequency.setValue(0);
    this.frmStepper.controls.steps['controls'][2].controls.feeType.setValue(0);
    this.frmStepper.controls.steps['controls'][2].controls.feePlan.setValue(0);
  }
  // Custom validator function to check if the value is not zero
  validateNotZero(control: FormControl) {
    const value = control.value;
    if (value === 0 || value === '0') {
      return { notZero: true };
    }
    return null;
  }
  onDateInput(event: any) {
    const input = event.target.value;
    console.log('dateinput',input)
    // Restrict input to the format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(input)) {
      // If the input doesn't match the format, reset the value
      event.target.value = '';
    }
    // Limit the number of digits for the year
    if (input.length > 10) {
      event.target.value = input.slice(0, 10);
    }
  }
  onFocus(event: any) {
    this.isReadOnly = false;
    event.target.type = 'date';
    event.target.focus();
  }

  onBlur(event: any) {
    this.isReadOnly = true;
    event.target.type = 'text';
  }
  calculateProratedFee(batchDays: any, start_date: any,frequency:any) {
    let fee:any;
    let start_of_curr_month = moment(start_date).startOf('month').add(7, 'd').format('YYYY-MM-DD');    
    let end_of_curr_month = moment(start_date).endOf('month').format('YYYY-MM-DD');
    if(frequency==2){             //bi-monthly
      fee=Math.round(parseInt(this.fees.price)/2);
    }
    if(frequency==3){             //monthly
      fee=parseInt(this.fees.price) ;
    }
    if(frequency==4){             //quarterly
      fee=Math.round(parseInt(this.fees.price)/3) ;
    }
    if(frequency==5){             //halfyearly
      fee=Math.round(parseInt(this.fees.price)/6) ;
    }
    if(frequency==6){             //yearly
      fee=Math.round(parseInt(this.fees.price)/12);
    }
      
    console.log(start_of_curr_month, end_of_curr_month, moment(start_date).format('YYYY-MM-DD'),frequency);
    if (moment(start_date).format('YYYY-MM-DD') > start_of_curr_month) {
      let no_of_days_in_a_month = 4 * batchDays.length;
      let onesession_cost = fee / no_of_days_in_a_month;
      //  console.log('pay',Math.round(onesession_cost),moment(start_date).day(),moment(end_of_curr_month).day());
      let count = 0;
      while (moment(start_date) <= moment(end_of_curr_month)) {
        // console.log(start_date,moment(start_date).day());
        let day = moment(start_date).day();
        if (batchDays.includes(day + 1)) {
          count = count + 1;
        }
        start_date = moment(start_date).add(1, 'd').format('YYYY-MM-DD');
      }
      console.log('day', count)
      if(frequency==3)
      this.proratedFee = Math.round(onesession_cost * count);
      if(frequency==2){
        this.proratedFee = Math.round(onesession_cost * count)+fee;
      }
      if(frequency==4){
        this.proratedFee = Math.round(onesession_cost * count)+2*fee;
      }
      if(frequency==5){
        this.proratedFee = Math.round(onesession_cost * count)+5*fee;
      }
      if(frequency==6){
        this.proratedFee = Math.round(onesession_cost * count)+11*fee;
      }
      this.feesValue = this.proratedFee;

    }
  }
}
