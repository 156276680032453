import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from 'src/app/consts/global-constants';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { changeLanguage } from '../state/translate.action';
import { Title, Meta, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateAppService } from 'src/app/services/translate.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  public isCollapsed: any;
  // Seo meta tags
  title = 'Home-1Sports';
  description = '1Sports for inquiries, feedback, and support. We are here to assist you with any questions you may have.';
  keywords = '1Sports, contact, inquiries, feedback, support';
  url = 'https://1sports.app/home-page';
  image = 'https://1sports.app/assets/img/brand/s1sLogo.png';
  // End of seo meta tags
  public imagesUrl:any;
  public languages = GlobalConstants.Languages;
  public selectedFeature = 'am';
  public languageForm: FormGroup;
  public demoExpand = false;
  public registerExpand = false;
  public demoUrl!: SafeUrl;
  public modalRef?: BsModalRef;

  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private metaService: Meta,
    private store: Store<{ language: { language: string } }>,
    private translateService: TranslateAppService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private cookieService: CookieService
  ) {
    this.demoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.calendly);
    this.languageForm = this.formBuilder.group({
      selectedLanguage: ['en']
    });

    // const selectedLanguage = localStorage.getItem('selectedLanguage');

    // if (selectedLanguage) {
    //   translate.setDefaultLang(selectedLanguage);
    //   this.translate.use(selectedLanguage);
    //   this.languageForm.patchValue({ selectedLanguage: localStorage.getItem('selectedLanguage') });
    //   // this.languageForm.patchValue({ selectedLanguage });
    // } else {
    //   console.log('-----------------------------------------------');
    //   translate.setDefaultLang('en');
    //   this.languageForm.patchValue({ selectedLanguage: 'en' });
    // }

    const selectedLanguage = this.cookieService.get('language');
    console.log('-----------------Home Page------------------------------', selectedLanguage);
    if (selectedLanguage) {
      translate.setDefaultLang(selectedLanguage);
      this.translate.use(selectedLanguage);
      this.languageForm.patchValue({ selectedLanguage: this.cookieService.get('language') });
    } else {
      translate.setDefaultLang('en');
      this.translate.use('en');
      this.cookieService.set('language', 'en');
    }
  }

  testimonials = [
    {
      message: this.translateService.translateKey('HOME.TMESSAGE'),
      name: this.translateService.translateKey('HOME.TMESSAGE_NAME'),
      image: 'assets/img/theme/User_Circle_Icon.jpg'
    },
    {
      message: this.translateService.translateKey('HOME.TMESSAGE1'),
      name: this.translateService.translateKey('HOME.TMESSAGE_NAME1'),
      image: 'assets/img/theme/User_Circle_Icon.jpg'
    },
    {
      message: this.translateService.translateKey('HOME.TMESSAGE2'),
      name: this.translateService.translateKey('HOME.TMESSAGE_NAME2'),
      image: 'assets/img/theme/User_Circle_Icon.jpg'
    },
    {
      message: this.translateService.translateKey('HOME.TMESSAGE3'),
      name: this.translateService.translateKey('HOME.TMESSAGE_NAME3'),
      image: 'assets/img/theme/User_Circle_Icon.jpg'
    }
  ];
  shuffleFeatures(value: string) {
    this.selectedFeature = value;
  }
  ngAfterViewInit() {
    $('.slick-carousel').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }
  ngOnInit(): void {
    // seo
    this.titleService.setTitle(this.title);
    this.metaService.updateTag({ name: 'description', content: this.description });
    this.metaService.updateTag({ name: 'keywords', content: this.keywords });
    this.metaService.updateTag({ rel: 'canonical', href: this.url });
    // logo slides
    this.imagesUrl = ['assets/img/slides/reeds.png', 'assets/img/slides/snsa.png', 'assets/img/slides/sdfc.png'];
  }

  getTranslatedAddress(key: string) {
    this.translate.get(key).subscribe((translation) => {
      return translation;
    });
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    // localStorage.setItem('selectedLanguage', language);
    // this.store.dispatch(changeLanguage(language));
    this.cookieService.set('language', language);
  }

  // booking of the demo
  public bookDemo(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'demo-modal modal-lg' }));
  }

  // open the registration of the model
  public openRegistrationForm() {
    this.router.navigate(['/register']);
  }

  // route to the courseFinder
  public routeToCourseFinder() {
    window.open(environment.courseFinder_home, '_blank');
  }
}
