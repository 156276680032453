import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-from-home-icon',
  templateUrl: 'work-from-home-icon.component.html',
  styleUrls: ['./work-from-home-icon.component.scss']
})
export class WorkFromIconComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
