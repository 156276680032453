/* tslint:disable */
import {
  Account,
  Certification,
  AppFile
} from '../index';

declare var Object: any;
export interface CertificationResultInterface {
  "name"?: string;
  "eventOrCategory": string;
  "startDate"?: Date;
  "endDate"?: Date;
  "position"?: number;
  "points"?: number;
  "rank"?: number;
  "timing"?: string;
  "accountType"?: string;
  "notes"?: string;
  "customUrl"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_file_id"?: number;
  "certificationId"?: number;
  account?: Account;
  certification?: Certification;
  certificationResultPicture?: AppFile;
}

export class CertificationResult implements CertificationResultInterface {
  "name": string;
  "eventOrCategory": string;
  "startDate": Date;
  "endDate": Date;
  "position": number;
  "points": number;
  "rank": number;
  "timing": string;
  "accountType": string;
  "notes": string;
  "customUrl": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_file_id": number;
  "certificationId": number;
  account: Account;
  certification: Certification;
  certificationResultPicture: AppFile;
  constructor(data?: CertificationResultInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CertificationResult`.
   */
  public static getModelName() {
    return "CertificationResult";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CertificationResult for dynamic purposes.
  **/
  public static factory(data: CertificationResultInterface): CertificationResult{
    return new CertificationResult(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CertificationResult',
      plural: 'CertificationResults',
      path: 'CertificationResults',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "eventOrCategory": {
          name: 'eventOrCategory',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "position": {
          name: 'position',
          type: 'number'
        },
        "points": {
          name: 'points',
          type: 'number'
        },
        "rank": {
          name: 'rank',
          type: 'number'
        },
        "timing": {
          name: 'timing',
          type: 'string'
        },
        "accountType": {
          name: 'accountType',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "customUrl": {
          name: 'customUrl',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
        "certificationId": {
          name: 'certificationId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        certification: {
          name: 'certification',
          type: 'Certification',
          model: 'Certification',
          relationType: 'belongsTo',
                  keyFrom: 'certification_id',
          keyTo: 'id'
        },
        certificationResultPicture: {
          name: 'certificationResultPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
