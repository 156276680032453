/* tslint:disable */
import {
  ScheduleTest,
  Account,
  Batch,
  Infrastructure
} from '../index';

declare var Object: any;
export interface ScheduleTestAttendanceInterface {
  "type": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  scheduleTest?: ScheduleTest;
  account?: Account;
  batch?: Batch;
  infrastructure?: Infrastructure;
}

export class ScheduleTestAttendance implements ScheduleTestAttendanceInterface {
  "type": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  scheduleTest: ScheduleTest;
  account: Account;
  batch: Batch;
  infrastructure: Infrastructure;
  constructor(data?: ScheduleTestAttendanceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ScheduleTestAttendance`.
   */
  public static getModelName() {
    return "ScheduleTestAttendance";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ScheduleTestAttendance for dynamic purposes.
  **/
  public static factory(data: ScheduleTestAttendanceInterface): ScheduleTestAttendance{
    return new ScheduleTestAttendance(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ScheduleTestAttendance',
      plural: 'ScheduleTestAttendances',
      path: 'ScheduleTestAttendances',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        scheduleTest: {
          name: 'scheduleTest',
          type: 'ScheduleTest',
          model: 'ScheduleTest',
          relationType: 'belongsTo',
                  keyFrom: 'schedule_test_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        batch: {
          name: 'batch',
          type: 'Batch',
          model: 'Batch',
          relationType: 'belongsTo',
                  keyFrom: 'batch_id',
          keyTo: 'id'
        },
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
      }
    }
  }
}
