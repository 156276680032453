import { Injectable } from '@angular/core';
import { AppFileApi } from '../shared/sdk/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Constants } from '../class/constants';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { config } from 'src/config/config';
import { fileURLToPath } from 'url';
import { AnyCnameRecord } from 'dns';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'my-auth-token'
  })
};
@Injectable()
export class FileUploadService {
  SERVER_URL = environment.baseUrl + '/api/AppFiles';
  url = environment.baseUrl + '/api/';
  constructor(private appFileApi: AppFileApi, private httpClient: HttpClient, private authService: AuthService) { }

  updateHttpHeader() {
    httpOptions.headers = httpOptions.headers.set('Authorization', this.authService.getAccessToken());
    httpOptions.headers = httpOptions.headers.set('source', config.source);
    httpOptions.headers = httpOptions.headers.set('version', config.version);
    httpOptions.headers = httpOptions.headers.set('x-sub-domain', this.authService.getSubDomain());
  }
  uploadFile(file: File, bucketName: any, accountId: any) {
    const promise = new Promise((resolve, reject) => {
      this.updateHttpHeader();
      const formData = new FormData();
      formData.append('file', file);

      this.httpClient.post<any>(this.SERVER_URL + '/uploadFile/' + bucketName + '/' + accountId, formData, httpOptions).toPromise().then(resolve).catch(reject);
    });
    return promise;
  }

  parseUrl(serviceName: string) {
    return serviceName.split('_');
  }

  uploadPicture(id: any, serviceName: any, file: File) {
    const urlEndPoints = this.parseUrl(serviceName);
    const url = `${this.url}${urlEndPoints[0]}/${id}/${urlEndPoints[1]}`;
    const promise = new Promise((resolve, reject) => {
      this.updateHttpHeader();
      const formData = new FormData();
      formData.append('file', file);
      this.httpClient.post<any>(url, formData, httpOptions).toPromise().then(resolve).catch(reject);
    });
    return promise;
  }
  deleteFile(file: {}) {
    const promise = new Promise((resolve, reject) => {
      this.updateHttpHeader();
      console.log(JSON.stringify(file));
      this.httpClient
        .delete<any>(this.SERVER_URL + '/deleteFile/' + JSON.stringify(file), httpOptions).subscribe(resolve);
    });
    return promise;
  }
  readFile(param:any) {
    const promise = new Promise((resolve, reject) => {
      this.updateHttpHeader();
      let containerName=param.containerName;
      let fileName=param.fileName;
      let mimeType=param.mimeType;
      console.log(containerName,fileName,mimeType)
      this.httpClient
      .get<any>(this.SERVER_URL + '/' + encodeURIComponent(containerName)+'/'+fileName+'/readFileFromContainer', httpOptions).subscribe(resolve);
      // this.httpClient
      //   .get<any>(this.SERVER_URL + '/' + encodeURIComponent(containerName)+'/'+fileName+'/'+ encodeURIComponent(mimeType)+'/readFileFromContainer', httpOptions).subscribe(resolve);
    });
    return promise;
  }
  readExcelFile(param:any) {
    const promise = new Promise((resolve, reject) => {
      this.updateHttpHeader();
      let containerName=param.containerName;
      let fileName=param.fileName;
      let mimeType=param.mimeType;
      console.log(containerName,fileName,mimeType)
      this.httpClient
      .get<any>(this.SERVER_URL + '/' + encodeURIComponent(containerName)+'/'+fileName+'/readExcelFileFromContainer', httpOptions).subscribe(resolve);
      // this.httpClient
      //   .get<any>(this.SERVER_URL + '/' + encodeURIComponent(containerName)+'/'+fileName+'/'+ encodeURIComponent(mimeType)+'/readFileFromContainer', httpOptions).subscribe(resolve);
    });
    return promise;
  }
}
