/* tslint:disable */
import {
  Account,
  AppFile
} from '../index';

declare var Object: any;
export interface VideoSessionInterface {
  "name": string;
  "sessionNotes": string;
  "sessionDate": Date;
  "isFixedTimeDuration": number;
  "startTime": Date;
  "endTime": Date;
  "duration"?: string;
  "isRecurring"?: number;
  "recurringType": number;
  "noOfWeeks"?: number;
  "weekDays"?: any;
  "noOfMonths"?: number;
  "monthDays"?: any;
  "noOfYear"?: number;
  "yearMonths"?: any;
  "yearMonthDays"?: any;
  "image"?: string;
  "status": number;
  "videoSessionId"?: any;
  "meetingType"?: any;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "app_file_id"?: number;
  createdBy?: Account;
  videosessionPicture?: AppFile;
  accounts?: Account[];
}

export class VideoSession implements VideoSessionInterface {
  "name": string;
  "sessionNotes": string;
  "sessionDate": Date;
  "isFixedTimeDuration": number;
  "startTime": Date;
  "endTime": Date;
  "duration": string;
  "isRecurring": number;
  "recurringType": number;
  "noOfWeeks": number;
  "weekDays": any;
  "noOfMonths": number;
  "monthDays": any;
  "noOfYear": number;
  "yearMonths": any;
  "yearMonthDays": any;
  "image": string;
  "status": number;
  "videoSessionId": any;
  "meetingType": any;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "app_file_id": number;
  createdBy: Account;
  videosessionPicture: AppFile;
  accounts: Account[];
  constructor(data?: VideoSessionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VideoSession`.
   */
  public static getModelName() {
    return "VideoSession";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VideoSession for dynamic purposes.
  **/
  public static factory(data: VideoSessionInterface): VideoSession{
    return new VideoSession(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VideoSession',
      plural: 'VideoSessions',
      path: 'VideoSessions',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "sessionNotes": {
          name: 'sessionNotes',
          type: 'string'
        },
        "sessionDate": {
          name: 'sessionDate',
          type: 'Date'
        },
        "isFixedTimeDuration": {
          name: 'isFixedTimeDuration',
          type: 'number'
        },
        "startTime": {
          name: 'startTime',
          type: 'Date'
        },
        "endTime": {
          name: 'endTime',
          type: 'Date'
        },
        "duration": {
          name: 'duration',
          type: 'string'
        },
        "isRecurring": {
          name: 'isRecurring',
          type: 'number'
        },
        "recurringType": {
          name: 'recurringType',
          type: 'number'
        },
        "noOfWeeks": {
          name: 'noOfWeeks',
          type: 'number'
        },
        "weekDays": {
          name: 'weekDays',
          type: 'any'
        },
        "noOfMonths": {
          name: 'noOfMonths',
          type: 'number'
        },
        "monthDays": {
          name: 'monthDays',
          type: 'any'
        },
        "noOfYear": {
          name: 'noOfYear',
          type: 'number'
        },
        "yearMonths": {
          name: 'yearMonths',
          type: 'any'
        },
        "yearMonthDays": {
          name: 'yearMonthDays',
          type: 'any'
        },
        "image": {
          name: 'image',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "videoSessionId": {
          name: 'videoSessionId',
          type: 'any'
        },
        "meetingType": {
          name: 'meetingType',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        videosessionPicture: {
          name: 'videosessionPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'VideoSessionAttendees',
          keyThrough: 'video_session_id',
          keyFrom: 'id',
          keyTo: 'videoSessionId'
        },
      }
    }
  }
}
