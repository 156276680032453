/* tslint:disable */
import {
  Account,
  Order
} from '../index';

declare var Object: any;
export interface PaymentInterface {
  "amount": number;
  "currency": string;
  "status": number;
  "baseAmount": number;
  "baseCurrency": string;
  "method": number;
  "description"?: string;
  "isInternational"?: boolean;
  "date": Date;
  "gatewayRefundId"?: string;
  "refundStatus"?: string;
  "amountRefunded"?: number;
  "refundDate"?: Date;
  "isCaptured"?: boolean;
  "gatewayPaymentId": string;
  "gatewayOrderId": string;
  "gatewaySignature"?: string;
  "email": string;
  "contact": string;
  "errorCode"?: string;
  "errorDescription"?: string;
  "errorSource"?: string;
  "errorStep"?: string;
  "errorReason"?: string;
  "notes"?: any;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "order_id"?: number;
  "received_by_id"?: number;
  "accountId"?: number;
  account?: Account;
  order?: Order;
  receivedBy?: Account;
}

export class Payment implements PaymentInterface {
  "amount": number;
  "currency": string;
  "status": number;
  "baseAmount": number;
  "baseCurrency": string;
  "method": number;
  "description": string;
  "isInternational": boolean;
  "date": Date;
  "gatewayRefundId": string;
  "refundStatus": string;
  "amountRefunded": number;
  "refundDate": Date;
  "isCaptured": boolean;
  "gatewayPaymentId": string;
  "gatewayOrderId": string;
  "gatewaySignature": string;
  "email": string;
  "contact": string;
  "errorCode": string;
  "errorDescription": string;
  "errorSource": string;
  "errorStep": string;
  "errorReason": string;
  "notes": any;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "order_id": number;
  "received_by_id": number;
  "accountId": number;
  account: Account;
  order: Order;
  receivedBy: Account;
  constructor(data?: PaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Payment`.
   */
  public static getModelName() {
    return "Payment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Payment for dynamic purposes.
  **/
  public static factory(data: PaymentInterface): Payment{
    return new Payment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Payment',
      plural: 'Payments',
      path: 'Payments',
      idName: 'id',
      properties: {
        "amount": {
          name: 'amount',
          type: 'number'
        },
        "currency": {
          name: 'currency',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "baseAmount": {
          name: 'baseAmount',
          type: 'number'
        },
        "baseCurrency": {
          name: 'baseCurrency',
          type: 'string'
        },
        "method": {
          name: 'method',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "isInternational": {
          name: 'isInternational',
          type: 'boolean',
          default: false
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "gatewayRefundId": {
          name: 'gatewayRefundId',
          type: 'string'
        },
        "refundStatus": {
          name: 'refundStatus',
          type: 'string'
        },
        "amountRefunded": {
          name: 'amountRefunded',
          type: 'number'
        },
        "refundDate": {
          name: 'refundDate',
          type: 'Date'
        },
        "isCaptured": {
          name: 'isCaptured',
          type: 'boolean',
          default: false
        },
        "gatewayPaymentId": {
          name: 'gatewayPaymentId',
          type: 'string'
        },
        "gatewayOrderId": {
          name: 'gatewayOrderId',
          type: 'string'
        },
        "gatewaySignature": {
          name: 'gatewaySignature',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "contact": {
          name: 'contact',
          type: 'string'
        },
        "errorCode": {
          name: 'errorCode',
          type: 'string'
        },
        "errorDescription": {
          name: 'errorDescription',
          type: 'string'
        },
        "errorSource": {
          name: 'errorSource',
          type: 'string'
        },
        "errorStep": {
          name: 'errorStep',
          type: 'string'
        },
        "errorReason": {
          name: 'errorReason',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "order_id": {
          name: 'order_id',
          type: 'number'
        },
        "received_by_id": {
          name: 'received_by_id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'order_id',
          keyTo: 'id'
        },
        receivedBy: {
          name: 'receivedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'received_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
