/* tslint:disable */
import {
  Address,
  AppUser,
  AppFile,
  AccountDetail,
  Guardian,
  Attendance,
  Rating,
  Batch,
  Course,
  Infrastructure,
  Order,
  Assessment,
  Payment,
  AccountSession,
  StudentTag,
  MapStudentTag,
  BatchTiming
} from '../index';

declare var Object: any;
export interface AccountInterface {
  "accountStatus": number;
  "activationDate"?: Date;
  "remarks"?: string;
  "reasonForDeRegistration"?: string;
  "effectiveDate"?: Date;
  "name"?: string;
  "type"?: number;
  "roleLabel"?: string;
  "isOptedTransport"?: boolean;
  "isDeleted": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "address_id"?: number;
  "deregistered_by_id"?: number;
  address?: Address;
  appUser?: AppUser;
  deregisteredBy?: Account;
  appFiles?: AppFile[];
  profilePicture?: AppFile;
  accountDetail?: AccountDetail;
  guardians?: Guardian[];
  attendance?: Attendance[];
  rating?: Rating[];
  batches?: Batch[];
  courses?: Course[];
  infrastructures?: Infrastructure[];
  orders?: Order[];
  assessment?: Assessment[];
  appUsers?: AppUser[];
  payments?: Payment[];
  accountSessions?: AccountSession[];
  studentTags?: StudentTag[];
  mapStudentTags?: MapStudentTag[];
  coachSports?: Course[];
  accountBatchtiming?: BatchTiming[];
}

export class Account implements AccountInterface {
  "accountStatus": number;
  "activationDate": Date;
  "remarks": string;
  "reasonForDeRegistration": string;
  "effectiveDate": Date;
  "name": string;
  "type": number;
  "roleLabel": string;
  "isOptedTransport": boolean;
  "isDeleted": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "address_id": number;
  "deregistered_by_id": number;
  address: Address;
  appUser: AppUser;
  deregisteredBy: Account;
  appFiles: AppFile[];
  profilePicture: AppFile;
  accountDetail: AccountDetail;
  guardians: Guardian[];
  attendance: Attendance[];
  rating: Rating[];
  batches: Batch[];
  courses: Course[];
  infrastructures: Infrastructure[];
  orders: Order[];
  assessment: Assessment[];
  appUsers: AppUser[];
  payments: Payment[];
  accountSessions: AccountSession[];
  studentTags: StudentTag[];
  mapStudentTags: MapStudentTag[];
  coachSports: Course[];
  accountBatchtiming: BatchTiming[];
  constructor(data?: AccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Account`.
   */
  public static getModelName() {
    return "Account";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Account for dynamic purposes.
  **/
  public static factory(data: AccountInterface): Account{
    return new Account(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Account',
      plural: 'Accounts',
      path: 'Accounts',
      idName: 'id',
      properties: {
        "accountStatus": {
          name: 'accountStatus',
          type: 'number'
        },
        "activationDate": {
          name: 'activationDate',
          type: 'Date'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "reasonForDeRegistration": {
          name: 'reasonForDeRegistration',
          type: 'string'
        },
        "effectiveDate": {
          name: 'effectiveDate',
          type: 'Date'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "roleLabel": {
          name: 'roleLabel',
          type: 'string'
        },
        "isOptedTransport": {
          name: 'isOptedTransport',
          type: 'boolean'
        },
        "isDeleted": {
          name: 'isDeleted',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "address_id": {
          name: 'address_id',
          type: 'number'
        },
        "deregistered_by_id": {
          name: 'deregistered_by_id',
          type: 'number'
        },
      },
      relations: {
        address: {
          name: 'address',
          type: 'Address',
          model: 'Address',
          relationType: 'belongsTo',
                  keyFrom: 'address_id',
          keyTo: 'id'
        },
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
        deregisteredBy: {
          name: 'deregisteredBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'deregistered_by_id',
          keyTo: 'id'
        },
        appFiles: {
          name: 'appFiles',
          type: 'AppFile[]',
          model: 'AppFile',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        profilePicture: {
          name: 'profilePicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        accountDetail: {
          name: 'accountDetail',
          type: 'AccountDetail',
          model: 'AccountDetail',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'account_id'
        },
        guardians: {
          name: 'guardians',
          type: 'Guardian[]',
          model: 'Guardian',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'account_id'
        },
        attendance: {
          name: 'attendance',
          type: 'Attendance[]',
          model: 'Attendance',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        rating: {
          name: 'rating',
          type: 'Rating[]',
          model: 'Rating',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'staff_id'
        },
        batches: {
          name: 'batches',
          type: 'Batch[]',
          model: 'Batch',
          relationType: 'hasMany',
          modelThrough: 'AccountBatch',
          keyThrough: 'batch_id',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
        courses: {
          name: 'courses',
          type: 'Course[]',
          model: 'Course',
          relationType: 'hasMany',
          modelThrough: 'AccountBatch',
          keyThrough: 'course_id',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
        infrastructures: {
          name: 'infrastructures',
          type: 'Infrastructure[]',
          model: 'Infrastructure',
          relationType: 'hasMany',
          modelThrough: 'AccountInfrastructure',
          keyThrough: 'infrastructure_id',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
        orders: {
          name: 'orders',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        assessment: {
          name: 'assessment',
          type: 'Assessment[]',
          model: 'Assessment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'account_id'
        },
        appUsers: {
          name: 'appUsers',
          type: 'AppUser[]',
          model: 'AppUser',
          relationType: 'hasMany',
          modelThrough: 'AccountUser',
          keyThrough: 'app_user_id',
          keyFrom: 'id',
          keyTo: 'account_id'
        },
        payments: {
          name: 'payments',
          type: 'Payment[]',
          model: 'Payment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        accountSessions: {
          name: 'accountSessions',
          type: 'AccountSession[]',
          model: 'AccountSession',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        studentTags: {
          name: 'studentTags',
          type: 'StudentTag[]',
          model: 'StudentTag',
          relationType: 'hasMany',
          modelThrough: 'MapStudentTag',
          keyThrough: 'studentTagId',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
        mapStudentTags: {
          name: 'mapStudentTags',
          type: 'MapStudentTag[]',
          model: 'MapStudentTag',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'accountId'
        },
        coachSports: {
          name: 'coachSports',
          type: 'Course[]',
          model: 'Course',
          relationType: 'hasMany',
          modelThrough: 'AccountCourse',
          keyThrough: 'course_id',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
        accountBatchtiming: {
          name: 'accountBatchtiming',
          type: 'BatchTiming[]',
          model: 'BatchTiming',
          relationType: 'hasMany',
          modelThrough: 'AccountBatchtiming',
          keyThrough: 'batchtiming_id',
          keyFrom: 'id',
          keyTo: 'accountId'
        },
      }
    }
  }
}
