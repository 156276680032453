import { Injectable } from '@angular/core';
import { AppUserApi, AppUser, AppRole, AccountBatchApi, AccountApi, BatchApi, AccountInfrastructureApi,AppFileApi } from '../shared/sdk/index';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Constants } from '../class/constants';
import { AuthService } from './auth.service';

@Injectable()
export class AccountService {
  constructor(
    private appUserApi: AppUserApi,
    private router: Router,
    private batchApi: BatchApi,
    private accountApi: AccountApi,
    private appFileApi: AppFileApi,
    private authService: AuthService,
    private accountBatchApi: AccountBatchApi,
    private accountInfrastructureApi: AccountInfrastructureApi
  ) { }

  parseStudentsData(response: any, courseId) {
    const result: any = [];
    if (response) {
      response.map((player: any, index) => {
        const playerObj: any = {};
        if (player.accountData.infrastructure !== undefined) {
          playerObj.centerName = player.accountData.infrastructure.name;
        }
        if (player.accountData.account.appUser !== undefined) {
          playerObj.email = player.accountData.account.appUser.email;
          playerObj.phone = player.accountData.account.appUser.mobileNumber;

          playerObj.appUserId = player.accountData.account.appUser.id;
        }
        playerObj.accountId = player.accountData.account.id;
        playerObj.paymentDueDate = '';
        playerObj.accountDetailId = player.accountData.account.accountDetail.id;
        playerObj.gender = player.accountData.account.accountDetail.genderLabel;
        // playerObj.schoolName = player.accountData.account.accountDetail.schoolName;
        if (courseId !== 0) {
          if (this.authService.getCurrentAccountType() === 'Admin') {
            playerObj.studentName = player.accountData.account.profilePicture
              ? `<a class="font-weight-bold text-blue" href="/student_profile?accountId=` +
              `${player.accountData.account.id}&courseId=${courseId}" target="_self">` +
              `<img src="${player.accountData.account.profilePicture.location}" ` +
              `class="rounded-circle mr-2" height="40" width="40"> ${player.accountData.account.name}</a>`
              : `<a class="font-weight-bold text-blue" href="/student_profile?accountId=${player.accountData.account.id}` +
              `&courseId=${courseId}" target="_self">` +
              `<img src="assets/img/theme/team-6.png" class="rounded-circle mr-2" height="40" width="40">` +
              ` ${player.accountData.account.name}</a>`;
          } else if (this.authService.getCurrentAccountType() === 'Coach') {
            playerObj.studentName = `${player.accountData.account.name}`;
            playerObj.href = `/coach/student/student_profile?accountId=${player.accountData.account.id}&courseId=${courseId}`;
            playerObj.img = player.accountData.account.profilePicture ? `${player.accountData.account.profilePicture.location}` :
              'assets/img/theme/team-6.png';
          }
        } else {
          playerObj.studentName = player.accountData.account.profilePicture
            ? `<a class="font-weight-bold text-blue" href="/student_profile?accountId=` +
            `${player.accountData.account.id}" target="_self">` +
            `<img src="${player.accountData.account.profilePicture.location}" class="rounded-circle mr-2" height="40"` +
            ` width="40"> ${player.accountData.account.name}</a>`
            : `<a class="font-weight-bold text-blue" href="/student_profile?accountId=${player.accountData.account.id}" target="_self">` +
            `<img src="assets/img/theme/team-6.png" class="rounded-circle mr-2" height="40" width="40"> ` +
            `${player.accountData.account.name}</a>`;
        }
        playerObj.age = `${moment().diff(`${player.accountData.account.accountDetail.dateOfBirth}`, 'years')}`;
        playerObj.actions = '';
        result.push(playerObj);
      });
    }
    return result;
  }

  studentInBatch(batchId: any, pageNumber: any, size: any) {
    const constrain: any = {
      where: { batchId: { inq: batchId }, isActive: true, account: { accountStatus: { neq: 4 } } },
      include: ['batch', { account: ['appUser', 'accountDetail', 'profilePicture', 'accountSessions',{ relation: 'orders', scope: { include: [{ relation: 'feePlanCourse', scope: { include: ['course'] } }] } }, 'batches', 'courses', 'studentTags', 'accountBatchtiming'] }],
      order: 'createdDate DESC'
    };
    if (pageNumber !== -1 && size !== -1) {
      constrain.skip = pageNumber * size;
      //constrain.limit= size;
    }
    return this.accountBatchApi.find(constrain).toPromise();
  }

  coachInCenter(centerId: any, pageNumber: any, size: any) {
    let constrain: any = {};
    let coachData:any=[];
    if (centerId !== undefined) {
      constrain = {
        where: { infrastructureId: centerId, isActive: true, account: { accountStatus: { neq: 4 }, type: 4 } },
        include: ['infrastructure', { account: ['appUser', 'accountDetail', 'profilePicture', 'orders', { relation: 'batches', scope: { where: { infrastructureId: centerId } } }, 'courses', 'studentTags', 'coachSports'] }],
        order: 'createdDate DESC'
      };
      if (pageNumber !== -1 && size !== -1) {
        constrain.skip = pageNumber * size;
        //constrain.limit= size;
      }
      return this.accountInfrastructureApi.find(constrain).toPromise();
    } else {
      constrain = {
        where: { accountStatus: { neq: 4 }, type: 4 },
        // include: ['infrastructure', { account: ['appUser', 'accountDetail', 'profilePicture', 'orders', 'batches', 'courses','infrastructures'] }],
        include: ['appUser', 'accountDetail', 'profilePicture', 'orders', 'batches', 'courses', 'infrastructures', 'studentTags', 'coachSports'],
        order: 'createdDate DESC'
      };
    }
    if (pageNumber !== -1 && size !== -1) {
      constrain.skip = pageNumber * size;
      //constrain.limit= size;
    }
    //return this.accountInfrastructureApi.find(constrain).toPromise();
   
    return this.accountApi.find(constrain).toPromise();

  }
  studentInCenter(centerId: any, pageNumber: any, size: any) {
    let constrain: any = {};
    if (centerId !== undefined) {
      constrain = {
        where: { infrastructureId: centerId, isActive: true, account: { accountStatus: 2, type: 1 } },
        include: ['infrastructure', { account: ['appUser', 'accountDetail', 'profilePicture', { relation: 'orders', scope: { include: [{ relation: 'feePlanCourse', scope: { include: ['course'] } }] } }, { relation: 'batches', scope: { where: { infrastructureId: centerId } } }, 'courses', 'studentTags', 'accountSessions', 'coachSports', { relation: 'accountBatchtiming', scope: { order: 'day ASC' } }] }],
        order: 'createdDate DESC'
      };
      if (pageNumber !== -1 && size !== -1) {
        constrain.skip = pageNumber * size;
        //constrain.limit= size;
      }
      return this.accountInfrastructureApi.find(constrain).toPromise();
    } else {
      constrain = {
        where: { accountStatus: 2, type: 1 },
        // include: ['infrastructure', { account: ['appUser', 'accountDetail', 'profilePicture', 'orders', 'batches', 'courses','infrastructures'] }],
        include: ['appUser', 'accountDetail', 'profilePicture', { relation: 'orders', scope: { include: [{ relation: 'feePlanCourse', scope: { include: ['course'] } }] } }, 'batches', 'courses', 'infrastructures', 'studentTags', 'coachSports'],
        order: 'createdDate DESC'
      };
    }
    if (pageNumber !== -1 && size !== -1) {
      constrain.skip = pageNumber * size;
      //constrain.limit= size;
    }
    //return this.accountInfrastructureApi.find(constrain).toPromise();
    return this.accountApi.find(constrain).toPromise();

  }

  filterAccounts(accountBatchs: any) {

    const promise = new Promise((resolve, reject) => {
      const accountsObj: any = {};
      const studentAccounts: any = [];
      const coachAccounts: any = [];
      accountBatchs.map((accountBatch: any) => {
        // console.log(accountBatch)  
        if (accountBatch !== undefined) {
          if (accountBatch.account && accountBatch.type === 1 && accountBatch.isActive && accountBatch.account.accountStatus == 2) {
            studentAccounts.push(accountBatch);
          } else if (accountBatch.type === 4 && accountBatch.isActive && accountBatch.accountStatus != 4) {
            coachAccounts.push(accountBatch);
          }
        }
        if (accountBatch.account !== undefined) {
          if (accountBatch.account.type === 1 && accountBatch.account.isActive && accountBatch.account.accountStatus == 2) {
            studentAccounts.push(accountBatch.account);
          } else if (accountBatch.account.type === 4 && accountBatch.account.isActive && accountBatch.account.accountStatus != 4) {
            coachAccounts.push(accountBatch.account);
          }
        }
      });
      accountsObj.studentAccounts = studentAccounts;
      accountsObj.coachAccounts = coachAccounts;
      return resolve(accountsObj);
    });
    return promise;
  }

  filterAccountInfrastureData(accountBatchs: any) {
    const promise = new Promise((resolve, reject) => {
      const accountsObj: any = {};
      const studentAccounts: any = [];
      const coachAccounts: any = [];
      accountBatchs.map((accountData: any) => {
        if (accountData !== undefined) {
          if (accountData.type === 1 && accountData.isActive) {
            studentAccounts.push({ accountData });
          } else if (accountData.account.type === 4 && accountData.account.isActive) {
            coachAccounts.push(accountData);
          }
        }
      });
      accountsObj.studentAccounts = studentAccounts;
      accountsObj.coachAccounts = coachAccounts;
      return resolve(accountsObj);
    });
    return promise;
  }

  studentsCountInBatch(batchId: any) {
    const constrain: any = {
      where: { batchId: batchId, isActive: true, account: { accountStatus: { neq: 4 }, type: 1 } },
      include: ['batch', { account: ['appUser', 'accountDetail', 'profilePicture', 'orders'] }],
      order: 'createdDate DESC'
    };
    return this.accountBatchApi
      .find(constrain)
      .toPromise()
      .then((accountBatchs: any) => {
        let count = 0;
        accountBatchs.map((accountBatch: any) => {
          if (accountBatch.account !== undefined) {
            if (accountBatch.account.type === 1) {
              count++;
            }
          }
        });
        return count;
      });
  }

  coachesCountInBatch(batchId: any) {
    const constrain: any = {
      where: { batchId, isActive: true, account: { accountStatus: { neq: 4 }, type: 4 } },
      include: { account: ['appUser', 'accountDetail', 'profilePicture', 'orders'] },
      order: 'createdDate DESC'
    };
    return this.accountBatchApi
      .find(constrain)
      .toPromise()
      .then((accountBatchs: any) => {
        let count = 0;
        accountBatchs.map((accountBatch: any) => {
          if (accountBatch.account !== undefined) {
            if (accountBatch.account.type === 4) {
              count++;
            }
          }
        });
        return count;
      });
  }

  coachesCountInCenter(centerId: any) {
    const constrain: any = {
      where: { isActive: true, infrastructureId: centerId, account: { accountStatus: { neq: 4 }, type: 4 } },
      include: { account: ['appUser', 'accountDetail', 'profilePicture', 'orders', 'coachSports'] },
      order: 'createdDate DESC'
    };
    return this.accountInfrastructureApi
      .find(constrain)
      .toPromise()
      .then((accountBatchs: any) => {
        let count = 0;
        let uid = [];
        accountBatchs.map((accountBatch: any) => {
          if (accountBatch.account !== undefined) {
            if (accountBatch.account.type === 4) {
              console.log(accountBatch.account.id);
              uid.push(accountBatch.account.id)
              //  count++;
            }
          }
        });
        const item = [...new Set(uid)]  //remove duplicate values from uid
        return item.length;
      });
  }

  studentsCountInCenter(centerId: any) {
    const constrain: any = {
      where: { isActive: true, infrastructureId: centerId, account: { accountStatus: { neq: 4 }, type: 1 } },
      include: ['infrastructure', { account: ['appUser', 'accountDetail', 'profilePicture', 'orders', 'batches'] }],
      order: 'createdDate DESC'
    };
    return this.accountInfrastructureApi
      .find(constrain)
      .toPromise()
      .then((accountBatchs: any) => {
        let count = 0;
        accountBatchs.map((accountBatch: any) => {
          if (accountBatch.account !== undefined) {
            if (accountBatch.account.type === 1) {
              count++;
            }
          }
        });
        return count;
      });
  }

  dateFormatter(dateString: Date) {
    if (!dateString) {
      return '';
    }
    let date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
  }

  getCoachesAndStaffInCenter(infrastructureId: number) {
    const promise = new Promise((resolve, reject) => {
      const accountsObj: any = {};
      const staffAccounts: any = [];
      const coachAccounts: any = [];
      this.accountInfrastructureApi
        .find({
          where: {
            infrastructureId
          },
          include: {
            account: [
              {
                appUser: 'appRoles'
              },
              'accountDetail',
              'infrastructures',
              'profilePicture',
              'batches',
              'coachSports'
            ]
          }
        })
        .toPromise()
        .then((accountInfrastructures: any) => {
          //console.log(accountInfrastructures)
          if (accountInfrastructures.length > 0) {
            return Promise.all(accountInfrastructures.map((accountInfrastructure: any) => {
              if (accountInfrastructure.account) {
                if (![1, 4, 7].includes(accountInfrastructure.account.type) && accountInfrastructure.account.isActive) {
                  staffAccounts.push(accountInfrastructure.account);
                } else if (accountInfrastructure.account.type === 4 && accountInfrastructure.account.isActive) {
                  coachAccounts.push(accountInfrastructure.account);
                }
              }
            })).then(() => {
              accountsObj.staffAccounts = staffAccounts;
              accountsObj.coachAccounts = coachAccounts;
              console.log('check ob', accountsObj)
              return resolve(accountsObj);
            });
          }

        });
    });
    return promise;
  }
}
