import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddressApi, CityApi, CountryApi, CourseApi, CourseInfrastructureApi, InfrastructureApi, OptionApi, StateApi } from 'src/app/shared/sdk';
import { NgForm, AbstractControl, ValidationErrors, Validators, FormBuilder } from '@angular/forms';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/class/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-center',
  templateUrl: './add-center.component.html',
  styleUrls: ['./add-center.component.scss']
})
export class AddCenterComponent implements OnInit {

  // Add Center
  public countries: any = [];
  public countryId = 0;
  public countryMessage = '';

  public states: any = [];
  public stateId = 0;
  public stateMessage = '';

  public cityId = 0;
  public cities: any = [];
  public cityMessage = '';

  public accountType = '';

  public types: any = [];
  public centerType = 0;
  public typeMessage = '';
  public capacityMessage = '';
  public gstMessage = '';
  public file: File;
  public isRequired = false;
  public message = '';
  public isNext = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private addressApi: AddressApi,
    private countryAPi: CountryApi,
    private stateApi: StateApi,
    private cityApi: CityApi,
    private infrastructureApi: InfrastructureApi,
    private fileUploadService: FileUploadService,
    private optionApi: OptionApi,
    private courseApi: CourseApi,
    private courseInfrastructureApi: CourseInfrastructureApi,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.file = null;
    this.countryAPi
      .find()
      .toPromise()
      .then((response) => {
        this.countries = response;
      });
    this.loadDropDownValues();
  }

  passBack() {
    // this.passEntry.emit(this.user);
    // this.activeModal.close(this.user);
  }

  addCenter(center: NgForm) {
    this.countryMessage = this.countryId === 0 ? 'Select a country' : '';
    this.stateMessage = this.stateId === 0 ? 'Select a state' : '';
    this.cityMessage = this.cityId === 0 ? 'Select a city' : '';
    this.typeMessage = this.centerType === 0 ? 'Select center type' : '';
    this.capacityMessage = parseInt(center.value.capacity, 10) === 0 ? 'Capacity can not be zero' : '';
    if (this.countryMessage !== '' || this.stateMessage !== ''
      || this.cityMessage !== '' || this.typeMessage !== '' || this.capacityMessage !== '') {
      return null;
    }
    this.spinner.show();
    this.addressApi
      .create({
        addressLine1: center.value.address1,
        addressLine2: center.value.address2,
        landmark: '',
        pincode: parseInt(center.value.zipcode, 10),
        cityId: this.cityId,
        addressType: 'permanent',
        isActive: true,
      })
      .toPromise()
      .then((response: any) => {
        this.infrastructureApi
          .create({
            name: center.value.centerName,
            image: '',
            description: '',
            capacity: parseInt(center.value.capacity, 10),
            contactName: center.value.pointContactName,
            contactEmail: center.value.pointContactEmail,
            contactMobile: center.value.pointContactNumber,
            isActive: true,
            organizationId: 1,
            addressId: response.id,
            gstNo: center.value.gstNo,
            type: this.centerType
          })
          .toPromise()
          .then((centers: any) => {
            if (this.file) {
              this.uploadProfilePic(centers.id);
            }
            this.linkCourseCenter(centers.id);
          })
          .then(() => {
            this.spinner.hide();
            // Reset the form
            center.reset();
            this.isNext = true;
            // this.toastr.success('Center has been added successfully');
          })
          .catch((err) => {
            this.message = err.message;
            this.spinner.hide();
            this.activeModal.close('error');
          });
      });
  }

  uploadProfilePic(centerId) {
    if (this.file) {
      this.fileUploadService.uploadPicture(centerId, 'Infrastructures_uploadInfrastructurePicture', this.file).then(() => {
        // location.reload();
      });
    }
  }

  // Limit the course and center
  linkCourseCenter(centerId: number) {
    this.courseApi.find({})
      .toPromise()
      .then((courses: any) => {
        if (courses.length > 0) {
          this.courseInfrastructureApi.upsertCourseInfrastructure(Number(courses[0].id,), Number(centerId), true)
            .toPromise()
            .then((res) => {
              console.log(res);
            });
        }
      })
  }

  loadStates($event: any) {
    console.log($event.target.value)
    this.states = [];
    this.cityId = 0;
    this.stateId = 0;
    this.countryId = parseInt($event.target.value, 10);
    this.countryMessage = '';
    this.stateApi
      .find({ where: { countryId: this.countryId } })
      .toPromise()
      .then((response) => {
        this.states = response;
      });
  }

  loadCities($event) {
    this.cities = [];
    this.cityId = 0;
    this.stateId = parseInt($event.target.value, 10);
    this.stateMessage = '';
    this.cityApi
      .find({ where: { stateId: this.stateId } })
      .toPromise()
      .then((response) => {
        this.cities = response;
      });
  }


  setCity($event) {
    this.cityId = $event.target.value;
    this.cityMessage = '';
  }


  // validateGSTNumber(control: AbstractControl): ValidationErrors | null {
  //   const gstinPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9]{1}$/;

  //   if (!gstinPattern.test(control.value)) {
  //     console.log('invalid GSTIN');
  //     return { invalidGST: true };
  //   }
  //   console.log('valid GSTIN');
  //   return { invalidGST: false };
  // }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ''); // Allow only numeric input
  }

  loadDropDownValues() {
    this.optionApi
      .find({
        where: {
          identifier: Constants.OPTIONS.centerType
        }
      })
      .toPromise()
      .then((typesData) => {
        this.types = typesData;
      })
  }

  setImage(image: any) {
    this.file = image;
  }

  setCenterType($event) {
    this.centerType = parseInt($event.target.value, 10);
    this.typeMessage = '';
    console.log(this.centerType)
  }

  next() {
    this.activeModal.close(true);
  }

}
