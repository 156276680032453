/* tslint:disable */

declare var Object: any;
export interface TournamentMealPlanInterface {
  "sport": number;
  "sportName": string;
  "3to4hoursbeforecompletion": string;
  "1to2hoursbeforecompletion": string;
  "lessthan1hourbeforecompletion": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy"?: number;
  "modifiedByName"?: string;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class TournamentMealPlan implements TournamentMealPlanInterface {
  "sport": number;
  "sportName": string;
  "3to4hoursbeforecompletion": string;
  "1to2hoursbeforecompletion": string;
  "lessthan1hourbeforecompletion": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy": number;
  "modifiedByName": string;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: TournamentMealPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TournamentMealPlan`.
   */
  public static getModelName() {
    return "TournamentMealPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TournamentMealPlan for dynamic purposes.
  **/
  public static factory(data: TournamentMealPlanInterface): TournamentMealPlan{
    return new TournamentMealPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TournamentMealPlan',
      plural: 'TournamentMealPlan',
      path: 'TournamentMealPlan',
      idName: 'id',
      properties: {
        "sport": {
          name: 'sport',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "3to4hoursbeforecompletion": {
          name: '3to4hoursbeforecompletion',
          type: 'string'
        },
        "1to2hoursbeforecompletion": {
          name: '1to2hoursbeforecompletion',
          type: 'string'
        },
        "lessthan1hourbeforecompletion": {
          name: 'lessthan1hourbeforecompletion',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "modifiedByName": {
          name: 'modifiedByName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
