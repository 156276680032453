/* tslint:disable */
import {
  ScheduleTest,
  Assessment
} from '../index';

declare var Object: any;
export interface MappingScheduleTestAssessmentInterface {
  "isResultPublished": boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "schedule_test_id"?: number;
  "assessmentIds"?: Array<any>;
  scheduleTest?: ScheduleTest;
  assessment?: Assessment[];
}

export class MappingScheduleTestAssessment implements MappingScheduleTestAssessmentInterface {
  "isResultPublished": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "schedule_test_id": number;
  "assessmentIds": Array<any>;
  scheduleTest: ScheduleTest;
  assessment: Assessment[];
  constructor(data?: MappingScheduleTestAssessmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MappingScheduleTestAssessment`.
   */
  public static getModelName() {
    return "MappingScheduleTestAssessment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MappingScheduleTestAssessment for dynamic purposes.
  **/
  public static factory(data: MappingScheduleTestAssessmentInterface): MappingScheduleTestAssessment{
    return new MappingScheduleTestAssessment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MappingScheduleTestAssessment',
      plural: 'MappingScheduleTestAssessments',
      path: 'MappingScheduleTestAssessments',
      idName: 'id',
      properties: {
        "isResultPublished": {
          name: 'isResultPublished',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "schedule_test_id": {
          name: 'schedule_test_id',
          type: 'number'
        },
        "assessmentIds": {
          name: 'assessmentIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        scheduleTest: {
          name: 'scheduleTest',
          type: 'ScheduleTest',
          model: 'ScheduleTest',
          relationType: 'belongsTo',
                  keyFrom: 'schedule_test_id',
          keyTo: 'id'
        },
        assessment: {
          name: 'assessment',
          type: 'Assessment[]',
          model: 'Assessment',
          relationType: 'referencesMany',
                  keyFrom: 'assessmentIds',
          keyTo: 'id'
        },
      }
    }
  }
}
