/* tslint:disable */
import {
  AppFile,
  AppUser
} from '../index';

declare var Object: any;
export interface BulkUploadInterface {
  "type": number;
  "status": number;
  "totalRecordCount": number;
  "importedRecordCount"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "appFileId"?: number;
  "appUserId"?: number;
  file?: AppFile;
  appUser?: AppUser;
}

export class BulkUpload implements BulkUploadInterface {
  "type": number;
  "status": number;
  "totalRecordCount": number;
  "importedRecordCount": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "appFileId": number;
  "appUserId": number;
  file: AppFile;
  appUser: AppUser;
  constructor(data?: BulkUploadInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BulkUpload`.
   */
  public static getModelName() {
    return "BulkUpload";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BulkUpload for dynamic purposes.
  **/
  public static factory(data: BulkUploadInterface): BulkUpload{
    return new BulkUpload(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BulkUpload',
      plural: 'BulkUploads',
      path: 'BulkUploads',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "totalRecordCount": {
          name: 'totalRecordCount',
          type: 'number'
        },
        "importedRecordCount": {
          name: 'importedRecordCount',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "appFileId": {
          name: 'appFileId',
          type: 'number'
        },
        "appUserId": {
          name: 'appUserId',
          type: 'number'
        },
      },
      relations: {
        file: {
          name: 'file',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'appFileId',
          keyTo: 'id'
        },
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'appUserId',
          keyTo: 'id'
        },
      }
    }
  }
}
