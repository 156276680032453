import { Component, OnChanges, Output, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-edit-dynamic-form',
  templateUrl: 'edit-dynamic-form.html'
})
export class EditDynamicFormComponent implements OnChanges {
  @Output() formItemEvent = new EventEmitter<string>();
  @Input() formElements: any;
  public formElementObj: any;
  selectedFileEvent: EventEmitter<File> = new EventEmitter<File>();
  constructor() {}

  ngOnChanges() {

  }

  editOrDelete(event, index, operationFlag) {
    const value: any = {};
    value.formElement = event;
    value.index = index;
    value.operationFlag = operationFlag;
    this.formItemEvent.emit(value);
  }
}
