import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TagInputModule } from 'ngx-chips';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PresentationModule } from './pages/presentation/presentation.module';

import { ConfirmEqualValidatorDirective } from 'src/app/validators/confirm-equal-validator.directive';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { StudentDashboardComponent } from './layouts/student-dashboard/student-dashboard.component';
import { AppAuthLayoutComponent } from './layouts/app-auth-layout/app-auth-layout.component';
import { CoachLayoutComponent } from './layouts/coach-layout/coach-layout.component';
import { LoopBackConfig, SDKBrowserModule } from './shared/sdk';
import { AuthService } from './services/auth.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/http-request-interceptor';
import { HttpResponseInterceptor } from './interceptors/http-response-interceptor';
import { environment } from 'src/environments/environment';
import { AuthenticationGuard } from './guards/authentication.guard';
import { StaffLayoutComponent } from './layouts/staff-layout/staff-layout.component';
import { StudentGuard } from './guards/student.guard';
import { AdminGuard } from './guards/admin.guard';
import { StaffGuard } from './guards/staff.guard';
import { CoachGuard } from './guards/coach.guard';
import { WindowRefService } from './services/window-ref.service';
import { SettingsComponent } from './settings/settings.component';
import { QuillModule } from 'ngx-quill'
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { StudentActivitiesComponent } from './pages/student-activities/student-activities.component';
// import { WorkoutPlansComponent } from './pages/workout-plans/workout-plans.component';
// import { BookandplayComponent } from './pages/bookandplay/bookandplay.component';
// import { ContactUsComponent } from './pages/contact-us/contact-us.component';
// import { CreateViewTestComponent } from './pages/create-view-test/create-view-test.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MediaUploadComponent } from './components/media-upload/media-upload.component';
import { StoreModule } from '@ngrx/store';
import { translateReducer } from './pages/state/translate.reducer ';
import { WarningModelComponent } from './pages/warning-model/warning-model.component';
import { DatePipe } from '@angular/common';
// import { PlayerDemoSignupComponent } from './pages/player-demo-signup/player-demo-signup.component';
// import { LiabilityPolicyComponent } from './pages/liability-policy/liability-policy.component';
// import { JoinOurTeamComponent } from './pages/join-our-team/join-our-team.component';
// import { FaqComponent } from './pages/faq/faq.component';
// import { FeaturesComponent } from './pages/features/features.component';
// Function to load translation files
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TagInputModule,
    SDKBrowserModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    QuillModule.forRoot(),
    AngularEditorModule,
    PresentationModule,
    NgxSpinnerModule,
    NgbModule,
    ImageCropperModule,
    CommonModule,
    NgxDatatableModule,
    CarouselModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot({ language: translateReducer })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    StudentDashboardComponent,
    AppAuthLayoutComponent,
    CoachLayoutComponent,
    StaffLayoutComponent,
    ConfirmEqualValidatorDirective,
    SettingsComponent,
    WarningModelComponent,
    // PlayerDemoSignupComponent,
    // LiabilityPolicyComponent,
    // JoinOurTeamComponent,
    // FaqComponent,
    // OurTeamComponent,
    // AboutUsComponent,
    // VideoSessionComponent,
    // BookandplayComponent,
    // PricingComponent,
    // FreeTrailComponent,
    // BookademoComponent,
    // ContactComponent,
    // ContactComponent,
    // ContactUsComponent,
    // CreateViewTestComponent,
    // TextEditorComponent,
    // MyprofileComponent
  ],
  providers: [
    AuthService,
    AuthenticationGuard,
    StudentGuard,
    AdminGuard,
    StaffGuard,
    CoachGuard,
    WindowRefService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    LoopBackConfig.setBaseURL(environment.baseUrl);
    LoopBackConfig.setApiVersion('api');
  }
}
