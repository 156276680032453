/* tslint:disable */

declare var Object: any;
export interface KpiFieldsMasterInterface {
  "name": string;
  "description": string;
  "type": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class KpiFieldsMaster implements KpiFieldsMasterInterface {
  "name": string;
  "description": string;
  "type": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: KpiFieldsMasterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `KpiFieldsMaster`.
   */
  public static getModelName() {
    return "KpiFieldsMaster";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of KpiFieldsMaster for dynamic purposes.
  **/
  public static factory(data: KpiFieldsMasterInterface): KpiFieldsMaster{
    return new KpiFieldsMaster(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'KpiFieldsMaster',
      plural: 'KpiFieldsMasters',
      path: 'KpiFieldsMasters',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
