import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AppUserApi,
  BatchApi,
  CourseInfrastructureApi,
  FeeInstallmentApi,
  FeePlanApi,
  FeeSubscriptionApi,
  InfrastructureApi, MapFeeplanCourseApi, OptionApi, OrderApi
} from 'src/app/shared/sdk';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/class/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/services/excel.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-fee-plans',
  templateUrl: './add-fee-plans.component.html',
  styleUrls: ['./add-fee-plans.component.scss']
})
export class AddFeePlansComponent implements OnInit {
  public isFeeTypeAssigned = false;
  public feeplanEdit: any;
  public feeplanId: any;
  public feeplanValues: any = {};
  public editInstallmentDetails: any = {};
  public subscriptionDetails: any = {};
  public subscriptionId: any;
  public centersData: any = [];
  public centers: any;
  public courses: any;
  public feePlanTypes: any;
  public recurringFrequencyOptionList: any;
  public renewalCriteriaOptionList: any;
  public currentUserAccounts: any;

  // Fee Plan List

  isEnable = false;
  isEnableLateFee = false;
  isEnableProrata = false;
  isEnableInstallmentLateFee = false;
  isEnableRecurring = false;
  isEnableRecurringLateFee = false;
  isEnableTimePeriodLateFee = false;
  isEnableRecurringBiMonthlyLateFee = false;
  isEnableRecurringQuarterlyLateFee = false;
  isEnableRecurringHalfyearlyLateFee = false;
  isEnableRecurringYearlyLateFee = false;
  isEnableInstallments = false;
  isEnableRecurringInstallments = false;
  isEnableSubscription = false;
  isInstallmentDiv = false;
  isSubscriptionSectionEnabled = false;

  // multiple center selection for one feePlan
  selectedCourses = [];
  dropdownSettings: any = {};
  dropdownList = [];
  selectedItems = [];
  dropdownCourseList = [];
  ShowFilter = false;
  requiredField = false;
  renewalCriteria: any;
  selectedTimePeriod: any[] = [];
  isSelectedTimePeriod = false;
  totalInstallmentAmount: any;
  monthlyInstlmntsCtValue: any;
  installmentAmtpart: any;
  noOfInstallments: any = [];
  selectedrecurringFrequency: any[] = [];
  isSelectedRecurringFreq = false;
  isEnableRecurringYearlyLateFee1 = false;
  isEnableRecurringQuarterlyLateFee1 = false;
  isEnableRecurringBiMonthlyLateFee1 = false;
  isEnableRecurringLateFee1 = false;
  public isNext = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private infrastructure: InfrastructureApi,
    private feePlanApi: FeePlanApi,
    private mapFeeplanCourseApi: MapFeeplanCourseApi,
    private orderApi: OrderApi,
    private subscriptionApi: FeeSubscriptionApi,
    private feeInsallmentApi: FeeInstallmentApi,
    private course: CourseInfrastructureApi,
    private optionAPI: OptionApi,
    private excelService: ExcelService,
    private uts: UtilityService,
    private toastr: ToastrService,
    private appUserApi: AppUserApi,
    public authService: AuthService,
  ) { }

  get f() {
    return this.addFeePlan.controls;
  }

  public addFeePlan = this.fb.group({
    name: ['', [Validators.required]],
    feeDescription: [''],
    feePeriod: [''],
    aging: ['', Validators.pattern('^[0-9]*')],
    centerId: [null, [Validators.required]],
    courseId: [null, [Validators.required]],
    feetype: [null, [Validators.required]],
    price: ['', Validators.pattern('^[0-9]*')],
    hasLateFee: [{ value: '', disabled: true }],
    hasProrata: [{ value: '' }],
    lateFeeAmount: [''],
    lateFeeDelay: [''],
    recurringFrequency: [''],
    renewalCriteria: [''],
    noOfSession: [''],
    fees: [''],
    timePeriodFees: [''],
    sessionFees: [''],
    timePeriod: [''],
    subscriptionHasLateFee: [{ value: '', disabled: true }],
    subscriptionLateFeeAmount: [''],
    subscriptionLateFeeDelay: [''],
    installmentHasLateFee: [{ value: '', disabled: true }],
    installmentLateFeeAmount: [''],
    installmentLateFeeDelay: [''],
    linkSession: [''],
    autoRenew: [''],
    totalInstallmentAmt: [''],
    noOfMonthlyInstallments: [''],
    repeatAnually: ['']
  });

  ngOnInit(): void {
    this.centersList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };

    this.infrastructure.find({
      where: { isActive: true }
    }).toPromise().then((infrastructures) => {
      this.centers = infrastructures;
    });
    this.feePlanOptions();
    this.recurringFrequencyOptions();
    this.renewalCriteriaOprtions();

    this.authService.fetchUserDetails().then(() => {
      this.currentUserAccounts = this.authService.getCurrentUser();
    });
  }

  // To sent a fee plan email to the admin
  sendFeePlanEmail() {
    if (this.currentUserAccounts.email) {
      this.appUserApi.sendPGEmailNewAdmin(this.currentUserAccounts.email, '').toPromise().then((response: any) => {
        console.log('Email sent');
      });
    } else {
      console.log('Email not sent');
    }

  }

  centersList() {
    this.infrastructure.find({}).toPromise().then((response: any) => {
      // console.log(response);
      this.centersData = response;
    })
  }

  feePlanOptions() {
    this.optionAPI.find({
      where: {
        identifier: Constants.OPTIONS.feePlanType
      }
    })
      .toPromise()
      .then((feeplanOptions: any) => {
        this.feePlanTypes = feeplanOptions; // .filter((options) => options.value !== 3 && options.value !== 4);
      });
  }

  recurringFrequencyOptions() {
    this.optionAPI.find({
      where: {
        identifier: Constants.OPTIONS.recurringFrequency
      },
      order: 'sequence'
    })
      .toPromise()
      .then((recurringFrequency) => {
        // console.log(recurringFrequency);
        this.recurringFrequencyOptionList = recurringFrequency;
      });
  }

  renewalCriteriaOprtions() {
    this.optionAPI.find({
      where: {
        identifier: Constants.OPTIONS.renewalCriteria
      }
    })
      .toPromise()
      .then((renewalCriteria) => {
        this.renewalCriteriaOptionList = renewalCriteria;
      });
  }

  feeTypeSelect(event: any) {
    if (parseInt(event, 10) === 1) {
      this.isEnable = true;
      this.isEnableRecurring = false;
      this.isEnableInstallments = false;
      this.isEnableSubscription = false;
      this.isSubscriptionSectionEnabled = false;
      if (this.isEnableRecurringLateFee === true) {
        this.isEnableRecurringLateFee = false;
      }
    } else {
      this.isEnable = false;
    }
    if (parseInt(event, 10) === 2) {
      this.isEnableRecurring = true;
      this.isEnable = false;
      this.isEnableInstallments = false;
      this.isEnableSubscription = false;
      this.isSubscriptionSectionEnabled = false;
      if (this.isEnableLateFee === true) {
        this.isEnableLateFee = false;
      }
    } else {
      this.isEnableRecurring = false;
    }
    if (parseInt(event, 10) === 3) {
      this.isEnableInstallments = true;
      this.isEnable = false;
      this.isEnableRecurring = false;
      this.isEnableSubscription = false;
      this.isSubscriptionSectionEnabled = false;
      if (this.isEnableLateFee === true) {
        this.isEnableLateFee = false;
      }
      if (this.isEnableRecurringLateFee === true) {
        this.isEnableRecurringLateFee = false;
      }
    } else {
      this.isEnableInstallments = false;
    }
    if (parseInt(event, 10) === 4) {
      this.isEnableSubscription = true;
      this.isEnable = false;
      this.isEnableRecurring = false;
      this.isEnableInstallments = false;
      this.isSubscriptionSectionEnabled = false;
      if (this.isEnableLateFee === true) {
        this.isEnableLateFee = false;
      }
      if (this.isEnableRecurringLateFee === true) {
        this.isEnableRecurringLateFee = false;
      }
    } else {
      this.isEnableSubscription = false;
      this.isEnableLateFee = false;
      this.isEnableRecurringLateFee = false;
    }
  }

  selectRenewalCriteria(event: any) {
    this.isSubscriptionSectionEnabled = true;
    if (parseInt(event, 10) === 1) {
      this.renewalCriteria = parseInt(event, 10);
    } else if (parseInt(event, 10) === 2) {
      this.renewalCriteria = parseInt(event, 10);
    } else if (parseInt(event, 10) === 3) {
      this.renewalCriteria = parseInt(event, 10);
    } else {
      this.isSubscriptionSectionEnabled = false;
    }
  }

  recurringFrequencySelectFun(event: any) {
    console.log(event);
    event = event.toString();
    let e: any;
    if (event.indexOf(':') === 1) {
      e = event.split(':')[1].trim();
    } else {
      e = event;
    }
    console.log(e, event, this.recurringFrequencyOptionList);

    this.recurringFrequencyOptionList.map((recurring: any) => {
      if (recurring.value === parseInt(e, 10)) {
        this.selectedrecurringFrequency = [];
        this.isSelectedRecurringFreq = true;
        this.selectedrecurringFrequency.push(recurring);
        this.addFeePlan.controls.recurringFrequency.setValue(parseInt(e, 10))
      } else {
        this.addFeePlan.controls.recurringFrequency.setValue(parseInt(e, 10));
      }
    });
    console.log(this.addFeePlan.value)
  }

  timePeriodSelectFun(event: any) {
    // console.log(event)
    this.recurringFrequencyOptionList.map((recurring: any) => {
      if (recurring.value === parseInt(event, 10)) {
        this.selectedTimePeriod = [];
        this.isSelectedTimePeriod = true;
        this.selectedTimePeriod.push(recurring);

      }
    });
  }

  onChangeInstallmentAmt(event) {
    if (event !== undefined && event !== '') {
      this.totalInstallmentAmount = event;
    } else {
      this.totalInstallmentAmount = 0;
    }
    if (this.monthlyInstlmntsCtValue !== undefined && this.totalInstallmentAmount !== 0) {
      this.installmentAmtpart = this.totalInstallmentAmount / this.monthlyInstlmntsCtValue;
      this.addFeePlan.controls.installmentHasLateFee.enable();
    } else {
      this.installmentAmtpart = 0;
      this.addFeePlan.controls.installmentHasLateFee.disable();
    }
  }

  monthlyInstlmntsCt(event) {
    this.monthlyInstlmntsCtValue = event;
    this.noOfInstallments = [];
    if (this.totalInstallmentAmount !== undefined && this.totalInstallmentAmount !== '') {
      this.installmentAmtpart = this.totalInstallmentAmount / event;
    } else {
      this.installmentAmtpart = 0;
    }
    if (event > 0) {
      this.isInstallmentDiv = true;
      for (let i = 1; i <= event; i++) {
        this.noOfInstallments.push(i);
      }
    } else {
      this.noOfInstallments = [];
      this.isInstallmentDiv = false;
    }
    if (this.monthlyInstlmntsCtValue !== undefined && this.totalInstallmentAmount !== 0) {
      this.installmentAmtpart = this.totalInstallmentAmount / this.monthlyInstlmntsCtValue;
      this.addFeePlan.controls.installmentHasLateFee.enable();
    } else {
      this.installmentAmtpart = 0;
      this.addFeePlan.controls.installmentHasLateFee.disable();
    }
  }

  onChange(event) {
    if (event) {
      this.addFeePlan.controls.hasLateFee.enable();
    } else {
      this.addFeePlan.controls.hasLateFee.disable();
    }
  }

  onChangeTimePeriodFees(event) {
    if (event) {
      this.addFeePlan.controls.subscriptionHasLateFee.enable();
    } else {
      this.addFeePlan.controls.subscriptionHasLateFee.disable();
    }
  }

  customProrata(event) {
    console.log('check Prorate', event)
    if (event) {
      this.isEnableProrata = true;
    } else {
      this.isEnableProrata = false;
    }
  }

  customOne(event) {
    if (event) {
      this.isEnableLateFee = true;
    } else {
      this.isEnableLateFee = false;
    }
  }
  customRecurring(event) {
    if (event) {
      this.isEnableRecurringLateFee = true;
    } else {
      this.isEnableRecurringLateFee = false;
    }
  }

  customTimePeriod(event) {
    if (event) {
      this.isEnableTimePeriodLateFee = true;
    } else {
      this.isEnableTimePeriodLateFee = false;
    }
  }

  linksessionCheck(event) {
    if (event) {
      console.log(event);
    } else {
      console.log(event);
    }
  }

  customInstallment(event) {
    if (event) {
      this.isEnableInstallmentLateFee = true;
    } else {
      this.isEnableInstallmentLateFee = false;
    }
  }

  autoRenewCheck(event) {
    if (event) {
      console.log(event);
    } else {
      console.log(event);
    }
  }

  repeatAnuallyValue(event) {
    if (event) {
      console.log(event);
    } else {
      console.log(event);
    }
  }
  addFeePlanFun() {
    this.addFeePlan.markAllAsTouched();
    const orderCourseId = [];
    let feeType = 0;
    if (this.isFeeTypeAssigned) {
      feeType = this.feeplanEdit.feetype;
    } else {
      feeType = Number(this.addFeePlan.value.feetype);
    }

    if (feeType === 1) {
      this.addFeePlan.controls.recurringFrequency.setValue(0)
    }
    if (feeType === 3) {
      this.addFeePlan.controls.recurringFrequency.setValue(3)
    }
    if (feeType === 4) {
      this.addFeePlan.controls.recurringFrequency.setValue(0);
    }
    if (feeType === 5) {
      this.addFeePlan.controls.price.setValue(0);
      this.addFeePlan.controls.recurringFrequency.setValue(0);
    }

    if (this.addFeePlan.valid) {
      this.addFeePlan.controls.courseId.setValue(null);
      if (this.feeplanId !== undefined && this.feeplanId !== '') {
        if (parseInt(this.addFeePlan.value.feetype, 10) !== 3 && parseInt(this.addFeePlan.value.feetype, 10) !== 4) {
          if (this.addFeePlan.controls.recurringFrequency.value
            && (parseInt(this.addFeePlan.controls.recurringFrequency.value, 10) !== 0)
            && this.addFeePlan.controls.recurringFrequency.value.toString().indexOf(':') === 1) {
            this.addFeePlan.controls.recurringFrequency.setValue(this.addFeePlan.controls.recurringFrequency.value.split(':')[1].trim());
          }
          this.feePlanApi.patchAttributes(this.feeplanId, this.addFeePlan.value).toPromise().then((feePlanEdit: any) => {
            const feePlanEditData = feePlanEdit;
            this.mapFeeplanCourseApi.find({ where: { feePlanId: this.feeplanId } }).toPromise().then((data: any) => {
              if (data && data.length > 0) {
                return Promise.all(data.map((i: any) => {
                  return this.orderApi.find({ where: { feePlanCourseId: i.id } }).toPromise().then((order: any) => {
                    if (order.length > 0) {
                      orderCourseId.push(i.courseId);
                    } else {
                      this.mapFeeplanCourseApi.deleteById(i.id).toPromise();
                    }
                  });
                })).then(() => {
                  console.log(this.selectedCourses, orderCourseId)
                  this.selectedCourses = this.selectedCourses.filter(val => !orderCourseId.includes(val));
                  this.selectedCourses.map((id: any) => {
                    this.mapFeeplanCourseApi.create({
                      feePlanId: this.feeplanId,
                      courseId: id
                    }).toPromise();
                  })
                })
              } else {
                this.selectedCourses.map((id: any) => {
                  this.mapFeeplanCourseApi.create({
                    feePlanId: this.feeplanId,
                    courseId: id
                  }).toPromise();
                })
              }
            })
          }).then(() => {
            this.isNext = true;
            // Reset the form
            this.addFeePlan.reset();
            this.sendFeePlanEmail();
            // this.toastr.success('Fee Plan has been added successfully');
          });
        } else {
          console.log('2P', this.feeplanValues);
          this.feeplanValues.name = this.addFeePlan.value.name;
          this.feeplanValues.feetype = this.addFeePlan.value.feetype;
          this.feeplanValues.feeDescription = this.addFeePlan.value.feeDescription;
          this.feeplanValues.feePeriod = this.addFeePlan.value.feePeriod;
          this.feeplanValues.aging = this.addFeePlan.value.aging ? Number(this.addFeePlan.value.aging) : null;
          this.feeplanValues.courseId = this.addFeePlan.value.courseId;
          this.feeplanValues.centerId = this.addFeePlan.value.centerId;
          this.feeplanValues.recurringFrequency = this.addFeePlan.value.recurringFrequency;
          if (this.addFeePlan.value.sessionFees !== undefined && this.addFeePlan.value.sessionFees !== null
            && this.addFeePlan.value.sessionFees !== 0) {
            this.feeplanValues.price = this.addFeePlan.value.sessionFees;
          } else if (this.addFeePlan.value.timePeriodFees !== undefined && this.addFeePlan.value.timePeriodFees !== null
            && this.addFeePlan.value.timePeriodFees !== '') {
            this.feeplanValues.price = this.addFeePlan.value.timePeriodFees;
          } else if (this.addFeePlan.value.totalInstallmentAmt !== undefined && this.addFeePlan.value.totalInstallmentAmt !== null
            && this.addFeePlan.value.totalInstallmentAmt !== '') {
            this.feeplanValues.price = parseInt(this.addFeePlan.value.totalInstallmentAmt, 10);
          }
          this.feeplanValues.hasLateFee = this.addFeePlan.value.hasLateFee;
          this.feeplanValues.lateFeeAmount = this.addFeePlan.value.lateFeeAmount != null ? this.addFeePlan.value.lateFeeAmount : 0;
          this.feeplanValues.lateFeeDelay = this.addFeePlan.value.lateFeeDelay != null ? this.addFeePlan.value.lateFeeDelay : 0;
          this.feeplanValues.hasProrata = this.isEnableProrata;

          //  console.log(this.feeplanValues);
          if (parseInt(this.addFeePlan.value.feetype, 10) === 3 || feeType === 3) {
            console.log('3R', this.addFeePlan.value);
            this.feeplanValues.recurringFrequency = 3;
            this.feeplanValues.price = parseInt(this.addFeePlan.value.totalInstallmentAmt, 10);
            this.feePlanApi.patchAttributes(this.feeplanId, this.feeplanValues).toPromise().then((feePlanEdit: any) => {
              const feePlanCreateInfo = feePlanEdit;
              this.mapFeeplanCourseApi.find({ where: { feePlanId: this.feeplanId } }).toPromise().then((data: any) => {
                if (data && data.length > 0) {
                  return Promise.all(data.map((i: any) => {
                    return this.orderApi.find({ where: { feePlanCourseId: i.id } }).toPromise().then((order: any) => {
                      if (order.length > 0) {
                        console.log('i m in', i.courseId)
                        orderCourseId.push(i.courseId);
                      } else {
                        this.mapFeeplanCourseApi.deleteById(i.id).toPromise();
                      }
                    });
                  })).then(() => {
                    this.selectedCourses = this.selectedCourses.filter(val => !orderCourseId.includes(val));
                    console.log(this.selectedCourses, orderCourseId)
                    this.selectedCourses.map((id: any) => {
                      this.mapFeeplanCourseApi.create({
                        feePlanId: this.feeplanId,
                        courseId: id
                      }).toPromise();
                    })
                  })
                } else {
                  this.selectedCourses.map((id: any) => {
                    this.mapFeeplanCourseApi.create({
                      feePlanId: this.feeplanId,
                      courseId: id
                    }).toPromise();
                  })
                }
              })
              this.feeInsallmentApi.find({ where: { feePlanId: this.feeplanId } }).toPromise().then((installmentFeeResponse: any) => {
                if (installmentFeeResponse.length > 0) {
                  installmentFeeResponse.map((installmentDetails: any) => {
                    this.editInstallmentDetails.totalInstallmentAmount = parseInt(this.addFeePlan.value.totalInstallmentAmt, 10);
                    this.editInstallmentDetails.noOfMonthlyInstallments = this.addFeePlan.value.noOfMonthlyInstallments;
                    this.editInstallmentDetails.installmentAmount =
                      parseInt(this.addFeePlan.value.totalInstallmentAmt, 10) / this.addFeePlan.value.noOfMonthlyInstallments;
                    this.editInstallmentDetails.hasLateFee = this.addFeePlan.value.installmentHasLateFee;
                    this.editInstallmentDetails.lateFeeAmount = this.addFeePlan.value.installmentLateFeeAmount;
                    this.editInstallmentDetails.lateFeeDelay = this.addFeePlan.value.installmentLateFeeDelay;
                    this.editInstallmentDetails.repeatAnually = this.addFeePlan.value.repeatAnually ? 'Y' : 'N';
                    this.feeInsallmentApi.patchAttributes(installmentDetails.id, this.editInstallmentDetails).toPromise()
                      .then((feeInstallmentResponse: any) => {
                        const feeInstallmentDetails = feeInstallmentResponse;
                        return feeInstallmentDetails;
                      }).then((installmentUpdated: any) => {
                        // console.log(installmentUpdated);
                        if (installmentUpdated) {
                          this.isNext = true;
                          // Reset the form
                          this.addFeePlan.reset();
                          this.sendFeePlanEmail();
                          // this.toastr.success('Fee Plan has been added successfully');
                        }
                      });
                  });
                } else {
                  for (let i = 1; i <= this.addFeePlan.value.noOfMonthlyInstallments; i++) {
                    this.feeInsallmentApi.create({
                      totalInstallmentAmount: this.addFeePlan.value.totalInstallmentAmt,
                      installmentAmount: this.addFeePlan.value.totalInstallmentAmt / this.addFeePlan.value.noOfMonthlyInstallments,
                      noOfMonthlyInstallments: this.addFeePlan.value.noOfMonthlyInstallments,
                      hasLateFee: this.addFeePlan.value.installmentHasLateFee,
                      lateFeeAmount: this.addFeePlan.value.lateFeeAmount != null ? this.addFeePlan.value.lateFeeAmount : 0,
                      lateFeeDelay: this.addFeePlan.value.installmentLateFeeDelay,
                      repeatAnually: this.addFeePlan.value.repeatAnually ? 'Y' : 'N',
                      feePlanId: this.feeplanId,
                    }).toPromise().then(() => {
                      this.isNext = true;
                      // Reset the form
                      this.addFeePlan.reset();
                      this.sendFeePlanEmail();
                      // this.toastr.success('Fee Plan has been added successfully');
                    })
                  }
                }
              });
            });
          } else if (parseInt(this.addFeePlan.value.feetype, 10) === 4) {
            console.log('4', this.addFeePlan.value, this.feeplanValues);
            this.feePlanApi.patchAttributes(this.feeplanId, this.feeplanValues).toPromise().then((feePlanEdit: any) => {
              const feePlanCreateInfo = feePlanEdit;
              this.mapFeeplanCourseApi.find({ where: { feePlanId: this.feeplanId } }).toPromise().then((data: any) => {
                if (data && data.length > 0) {
                  return Promise.all(data.map((i: any) => {
                    return this.orderApi.find({ where: { feePlanCourseId: i.id } }).toPromise().then((order: any) => {
                      if (order.length > 0) {
                        orderCourseId.push(i.courseId);
                      } else {
                        this.mapFeeplanCourseApi.deleteById(i.id).toPromise();
                      }
                    });
                  })).then(() => {
                    this.selectedCourses = this.selectedCourses.filter(val => !orderCourseId.includes(val));
                    this.selectedCourses.map((id: any) => {
                      this.mapFeeplanCourseApi.create({
                        feePlanId: this.feeplanId,
                        courseId: id
                      }).toPromise();
                    })
                  })
                } else {
                  this.selectedCourses.map((id: any) => {
                    this.mapFeeplanCourseApi.create({
                      feePlanId: this.feeplanId,
                      courseId: id
                    }).toPromise();
                  })
                }
              })
              this.subscriptionDetails.type = this.addFeePlan.value.renewalCriteria;
              if (this.subscriptionDetails.type === 1 || this.subscriptionDetails.type === 3) {
                this.subscriptionDetails.noOfSession = this.addFeePlan.value.noOfSession !== undefined
                  ? this.addFeePlan.value.noOfSession : 0;
              }

              if (this.subscriptionDetails.type === 2) {
                this.subscriptionDetails.noOfSession = 0;
              }
              console.log('999999999999999999999999999999999999999');
              console.log(this.addFeePlan.value.timePeriodFees, this.addFeePlan.value.sessionFees);

              if (this.addFeePlan.value.sessionFees !== undefined
                && this.addFeePlan.value.sessionFees !== null && this.addFeePlan.value.sessionFees !== 0) {
                this.subscriptionDetails.amount = this.addFeePlan.value.sessionFees;
              } else if (this.addFeePlan.value.timePeriodFees !== undefined
                && this.addFeePlan.value.timePeriodFees !== null && this.addFeePlan.value.timePeriodFees !== 0) {
                this.subscriptionDetails.amount = parseInt(this.addFeePlan.value.timePeriodFees, 10);
              }
              let period = '';
              switch (parseInt(this.addFeePlan.value.timePeriod, 10)) {
                case 1:
                  period = 'WEEKLY';
                  break;
                case 2:
                  period = 'BI MONTHLY';
                  break;
                case 3:
                  period = 'MONTHLY';
                  break;
                case 4:
                  period = 'QUARTERLY';
                  break;
                case 5:
                  period = 'HALF YEARLY';
                  break;
                case 6:
                  period = 'YEARLY';
                  break;

              }
              this.subscriptionDetails.timePeriod = period;
              this.subscriptionDetails.hasLateFee = this.addFeePlan.value.subscriptionHasLateFee;
              this.subscriptionDetails.lateFeeDelay = this.addFeePlan.value.subscriptionLateFeeDelay;
              this.subscriptionDetails.lateFeeAmount = this.addFeePlan.value.subscriptionLateFeeAmount;
              this.subscriptionDetails.linkToSessionCount = this.addFeePlan.value.linkSession ? 'Y' : 'N';
              this.subscriptionDetails.autoRenew = this.addFeePlan.value.autoRenew ? 'Y' : 'N';
              this.subscriptionDetails.feePlanId = this.feeplanId; // feePlanCreateInfo.id;
              console.log(this.subscriptionDetails);
              // this.subscriptionApi.patchAttributes(this.subscriptionId, this.subscriptionDetails)
              //   .toPromise().then((subscriptonResponse: any) => {
              //     const subscriptonResponseDetail = subscriptonResponse;
              //     this.isNext = true;
              //     // Reset the form
              //     this.addFeePlan.reset();
              //     // this.toastr.success('Fee Plan has been added successfully');
              //   });
            });
          }
        }
      } else {
        this.feeplanId = '';
        this.addFeePlan.controls.courseId.setValue(null);
        this.addFeePlan.controls.aging.setValue(Number(this.addFeePlan.value.aging))
        if (Number(this.addFeePlan.value.feetype) === 2) {
          if (this.addFeePlan.controls.recurringFrequency.value.toString().includes(':')
            && this.addFeePlan.controls.recurringFrequency.value.indexOf(':') === 1) {
            this.addFeePlan.controls.recurringFrequency.setValue(this.addFeePlan.controls.recurringFrequency.value.split(':')[1].trim());
          }
          return this.feePlanApi.create(this.addFeePlan.value).toPromise().then((feePlanCreate: any) => {
            console.log(feePlanCreate);
            if (this.selectedCourses.length > 0) {
              Promise.all(this.selectedCourses.map((id: any) => {
                this.mapFeeplanCourseApi.create({ feePlanId: feePlanCreate.id, courseId: id }).toPromise();
              })).then(() => {
                this.isNext = true;
                // Reset the form
                this.addFeePlan.reset();
                this.sendFeePlanEmail();
                // this.toastr.success('Fee Plan has been added successfully');
              })
            }
          }).catch((err: any) => {
            swal.fire({
              text: err.message,
              type: 'error',
              confirmButtonClass: 'btn btn-primary',
              buttonsStyling: false
            })
          });

        } else {
          console.log(this.addFeePlan.value);
          this.feeplanValues.name = this.addFeePlan.value.name;
          this.feeplanValues.feetype = this.addFeePlan.value.feetype;
          this.feeplanValues.feeDescription = this.addFeePlan.value.feeDescription;
          this.feeplanValues.feePeriod = this.addFeePlan.value.feePeriod;
          this.feeplanValues.aging = this.addFeePlan.value.aging ? parseInt(this.addFeePlan.value.aging, 10) : null;
          this.feeplanValues.courseId = parseInt(this.addFeePlan.value.courseId, 10);
          this.feeplanValues.centerId = parseInt(this.addFeePlan.value.centerId, 10);
          this.feeplanValues.recurringFrequency = this.addFeePlan.value.recurringFrequency;
          if (this.addFeePlan.value.sessionFees !== undefined && this.addFeePlan.value.sessionFees !== null) {
            this.feeplanValues.price = this.addFeePlan.value.sessionFees;
          } else if (this.addFeePlan.value.timePeriodFees !== undefined && this.addFeePlan.value.timePeriodFees !== null) {
            this.feeplanValues.price = this.addFeePlan.value.timePeriodFees;
          } else if (this.addFeePlan.value.totalInstallmentAmt !== undefined && this.addFeePlan.value.totalInstallmentAmt !== null) {
            this.feeplanValues.price = this.addFeePlan.value.totalInstallmentAmt;
          }
          this.feeplanValues.hasLateFee = this.addFeePlan.value.hasLateFee !== undefined ? this.addFeePlan.value.hasLateFee : false;
          this.feeplanValues.lateFeeAmount = this.addFeePlan.value.lateFeeAmount != null ? this.addFeePlan.value.lateFeeAmount : 0;
          this.feeplanValues.lateFeeDelay = this.addFeePlan.value.lateFeeDelay != null ? this.addFeePlan.value.lateFeeDelay : 0;
          this.feeplanValues.hasProrata = this.addFeePlan.value.hasProrata != null ? this.addFeePlan.value.hasProrata : 0;

          console.log('feeeplan', this.feeplanValues);
          if (parseInt(this.addFeePlan.value.feetype, 10) === 3) {
            const repeatAnual = this.addFeePlan.value.repeatAnually ? 'Y' : 'N';
            const inshasLateFee = this.addFeePlan.value.installmentHasLateFee ? this.addFeePlan.value.installmentHasLateFee : false;
            const inslateFeeAmount = this.addFeePlan.value.installmentLateFeeAmount != null
              ? parseInt(this.addFeePlan.value.installmentLateFeeAmount, 10) : 0;
            const inslateFeeDelay = this.addFeePlan.value.installmentLateFeeDelay != null
              ? parseInt(this.addFeePlan.value.installmentLateFeeDelay, 10) : 0;
            this.feeInsallmentApi.createFeeInstallment(this.addFeePlan.value.name, parseInt(this.addFeePlan.value.centerId, 10),
              this.selectedCourses, parseInt(this.feeplanValues.price, 10),
              parseInt(this.addFeePlan.value.noOfMonthlyInstallments, 10), inshasLateFee,
              inslateFeeAmount, inslateFeeDelay, repeatAnual, this.feeplanValues.aging)
              .toPromise().then((feeInstallmentDetails: any) => {
                const feeInstallmentInfo = feeInstallmentDetails;
                if (feeInstallmentInfo.message === 'success') {
                  this.isNext = true;
                  // Reset the form
                  this.addFeePlan.reset();
                  this.sendFeePlanEmail();
                  // this.toastr.success('Fee Plan has been added successfully');
                }
              });
          } else if (Number(this.addFeePlan.value.feetype) === 4) {
            console.log(this.addFeePlan.value);
            console.log(this.feeplanValues);
            this.feePlanApi.create(this.feeplanValues).toPromise().then((feePlanCreate: any) => {
              const feePlanCreateInfo = feePlanCreate;
              this.selectedCourses.map((i: any) => {
                this.mapFeeplanCourseApi.create({ feePlanId: feePlanCreateInfo.id, courseId: i }).toPromise();
              })
              this.subscriptionDetails.type = this.addFeePlan.value.renewalCriteria;
              this.subscriptionDetails.noOfSession = this.addFeePlan.value.noOfSession !== undefined
                ? this.addFeePlan.value.noOfSession : 0;
              if (Number(this.addFeePlan.value.sessionFees)) {
                this.subscriptionDetails.amount = this.addFeePlan.value.sessionFees;
              } else if (Number(this.addFeePlan.value.timePeriodFees)) {
                this.subscriptionDetails.amount = this.addFeePlan.value.timePeriodFees;
              }
              this.subscriptionDetails.timePeriod = this.addFeePlan.value.timePeriod;
              this.subscriptionDetails.hasLateFee = this.addFeePlan.value.subscriptionHasLateFee;
              this.subscriptionDetails.lateFeeDelay = this.addFeePlan.value.subscriptionLateFeeDelay;
              this.subscriptionDetails.lateFeeAmount = this.addFeePlan.value.subscriptionLateFeeAmount;
              this.subscriptionDetails.linkToSessionCount = this.addFeePlan.value.linkSession ? 'Y' : 'N';
              this.subscriptionDetails.autoRenew = this.addFeePlan.value.autoRenew ? 'Y' : 'N';
              this.subscriptionDetails.feePlanId = feePlanCreateInfo.id;
              this.subscriptionApi.create(this.subscriptionDetails).toPromise().then((subscriptonResponse: any) => {
                const subscriptonResponseDetail = subscriptonResponse;
                this.isNext = true;
                // Reset the form
                this.addFeePlan.reset();
                this.sendFeePlanEmail();
                // this.toastr.success('Fee Plan has been added successfully');
              });
            });
          } else if (parseInt(this.addFeePlan.value.feetype, 10) === 5 || parseInt(this.addFeePlan.value.feetype, 10) === 1) {
            return this.feePlanApi.create(this.addFeePlan.value).toPromise().then((feePlanCreate: any) => {
              console.log(feePlanCreate);
              if (this.selectedCourses.length > 0) {
                Promise.all(this.selectedCourses.map((id: any) => {
                  this.mapFeeplanCourseApi.create({ feePlanId: feePlanCreate.id, courseId: id }).toPromise();
                })).then(() => {
                  this.isNext = true;
                  // Reset the form
                  this.addFeePlan.reset();
                  this.sendFeePlanEmail();
                  // this.toastr.success('Fee Plan has been added successfully');
                })
              }
            }).catch((err: any) => {
              swal.fire({
                text: err.message,
                type: 'error',
                confirmButtonClass: 'btn btn-primary',
                buttonsStyling: false
              })
            });
          }
        }
      }
    }
  }

  onDeSelectAllCourses(items: any) {
    this.selectedCourses = [];
    this.selectedItems = [];
    this.dropdownCourseList = [];
    console.log(items, this.selectedItems);
    if (this.selectedCourses.length > 0) {
      this.requiredField = true;
    } else {
      this.requiredField = false;
    }
  }

  onCourseItemSelect(item: any) {
    console.log(item);
    this.selectedCourses.push(item.id)
    console.log(this.selectedCourses);
  }
  onSelectAllCourse(items: any) {
    this.selectedCourses = [];
    items.map((i: any) => {
      this.selectedCourses.push(i.id);
    })
    console.log(this.selectedCourses);
  }
  onDeSelectCourse(item: any) {
    console.log(item);
    if (this.selectedCourses.includes(item.id)) {
      this.selectedCourses = this.selectedCourses.filter((i: any) => i !== item.id)
    }
    console.log(this.selectedCourses);

  }

  customRecurring1(event) {
    if (event) {
      this.isEnableRecurringLateFee1 = true;
    } else {
      this.isEnableRecurringLateFee1 = false;
    }
  }

  customRecurringBiMonthly(event) {
    if (event) {
      this.isEnableRecurringBiMonthlyLateFee = true;
    } else {
      this.isEnableRecurringBiMonthlyLateFee = false;
    }
  }

  customRecurringBiMonthly1(event) {
    if (event) {
      this.isEnableRecurringBiMonthlyLateFee1 = true;
    } else {
      this.isEnableRecurringBiMonthlyLateFee1 = false;
    }
  }

  customRecurringQuarterly(event) {
    if (event) {
      this.isEnableRecurringQuarterlyLateFee = true;
    } else {
      this.isEnableRecurringQuarterlyLateFee = false;
    }
  }

  customRecurringQuarterly1(event) {
    if (event) {
      this.isEnableRecurringQuarterlyLateFee1 = true;
    } else {
      this.isEnableRecurringQuarterlyLateFee1 = false;
    }
  }
  customRecurringHalfyearly(event) {
    if (event) {
      this.isEnableRecurringHalfyearlyLateFee = true;
    } else {
      this.isEnableRecurringHalfyearlyLateFee = false;
    }
  }

  customRecurringYearly(event) {
    if (event) {
      this.isEnableRecurringYearlyLateFee = true;
    } else {
      this.isEnableRecurringYearlyLateFee = false;
    }
  }

  customRecurringYearly1(event) {
    if (event) {
      this.isEnableRecurringYearlyLateFee1 = true;
    } else {
      this.isEnableRecurringYearlyLateFee1 = false;
    }
  }

  customInstallments(event) {
    if (event) {
      this.isEnableRecurringInstallments = true;
    } else {
      this.isEnableRecurringInstallments = false;
    }
  }

  loadCourses(event: any) {
    console.log(event);
    this.selectedItems = [];
    this.selectedCourses = [];
    this.dropdownCourseList = [];
    this.course.find({ where: { isActive: true, infrastructureId: parseInt(event, 10) }, include: ['course'] })
      .toPromise().then((courses: any) => {
        const coursesData = [];
        courses.map((courseData: any) => {
          if (courseData.course !== undefined) {
            coursesData.push(courseData.course);
            this.dropdownCourseList = this.dropdownCourseList
              .concat({ id: courseData.course.id, name: courseData.course.name + ' ' + courseData.course.levelLabel });
          }
        });
        this.courses = coursesData;
        if (this.courses.length > 0 && this.feeplanEdit && this.feeplanEdit.feePlanCourse.length > 0) {
          console.log(this.feeplanEdit.feePlanCourse);
          const courseIds = [];
          Promise.all(this.feeplanEdit.feePlanCourse.map((i: any) => {
            courseIds.push(i.courseId);
          })).then(() => {
            this.courses.map((i: any) => {
              if (courseIds.includes(i.id)) {
                this.selectedItems = this.selectedItems.concat({ id: i.id, name: i.name + ' ' + i.levelLabel })
                this.selectedCourses.push(i.id);
              }
            })
          })
        }
      });
  }

  next() {
    this.activeModal.close(true);
  }

}
