/* tslint:disable */
import {
  AppUser,
  AppRole
} from '../index';

declare var Object: any;
export interface AppUserRoleInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appUser?: AppUser;
  appRole?: AppRole;
}

export class AppUserRole implements AppUserRoleInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appUser: AppUser;
  appRole: AppRole;
  constructor(data?: AppUserRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppUserRole`.
   */
  public static getModelName() {
    return "AppUserRole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppUserRole for dynamic purposes.
  **/
  public static factory(data: AppUserRoleInterface): AppUserRole{
    return new AppUserRole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppUserRole',
      plural: 'AppUserRoles',
      path: 'AppUserRoles',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
        appRole: {
          name: 'appRole',
          type: 'AppRole',
          model: 'AppRole',
          relationType: 'belongsTo',
                  keyFrom: 'app_role_id',
          keyTo: 'id'
        },
      }
    }
  }
}
