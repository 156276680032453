/* tslint:disable */
import {
  AppFile,
  Account,
  Sport
} from '../index';

declare var Object: any;
export interface CoachingDrillInterface {
  "drillName": string;
  "description": string;
  "sessionNo"?: number;
  "objective"?: string;
  "setup": string;
  "coachingPoints": string;
  "customUrl"?: string;
  "level": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "sport_id"?: number;
  coachingDrillPicture?: AppFile;
  account?: Account;
  sport?: Sport;
}

export class CoachingDrill implements CoachingDrillInterface {
  "drillName": string;
  "description": string;
  "sessionNo": number;
  "objective": string;
  "setup": string;
  "coachingPoints": string;
  "customUrl": string;
  "level": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "sport_id": number;
  coachingDrillPicture: AppFile;
  account: Account;
  sport: Sport;
  constructor(data?: CoachingDrillInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoachingDrill`.
   */
  public static getModelName() {
    return "CoachingDrill";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoachingDrill for dynamic purposes.
  **/
  public static factory(data: CoachingDrillInterface): CoachingDrill{
    return new CoachingDrill(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoachingDrill',
      plural: 'CoachingDrills',
      path: 'CoachingDrills',
      idName: 'id',
      properties: {
        "drillName": {
          name: 'drillName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "sessionNo": {
          name: 'sessionNo',
          type: 'number'
        },
        "objective": {
          name: 'objective',
          type: 'string'
        },
        "setup": {
          name: 'setup',
          type: 'string'
        },
        "coachingPoints": {
          name: 'coachingPoints',
          type: 'string'
        },
        "customUrl": {
          name: 'customUrl',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "sport_id": {
          name: 'sport_id',
          type: 'number'
        },
      },
      relations: {
        coachingDrillPicture: {
          name: 'coachingDrillPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        sport: {
          name: 'sport',
          type: 'Sport',
          model: 'Sport',
          relationType: 'belongsTo',
                  keyFrom: 'sport_id',
          keyTo: 'id'
        },
      }
    }
  }
}
