/* tslint:disable */
import {
  Assessment,
  Account,
  AssessmentResult
} from '../index';

declare var Object: any;
export interface AssessmentAssignmentInterface {
  "assignmentDate": Date;
  "assignmentDueDate": Date;
  "status"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_id"?: number;
  "account_id"?: number;
  "assigned_by_id"?: number;
  "assessment_result_id"?: number;
  assessment?: Assessment;
  account?: Account;
  assignedBy?: Account;
  assessmentResult?: AssessmentResult;
}

export class AssessmentAssignment implements AssessmentAssignmentInterface {
  "assignmentDate": Date;
  "assignmentDueDate": Date;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_id": number;
  "account_id": number;
  "assigned_by_id": number;
  "assessment_result_id": number;
  assessment: Assessment;
  account: Account;
  assignedBy: Account;
  assessmentResult: AssessmentResult;
  constructor(data?: AssessmentAssignmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentAssignment`.
   */
  public static getModelName() {
    return "AssessmentAssignment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentAssignment for dynamic purposes.
  **/
  public static factory(data: AssessmentAssignmentInterface): AssessmentAssignment{
    return new AssessmentAssignment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentAssignment',
      plural: 'AssessmentAssignments',
      path: 'AssessmentAssignments',
      idName: 'id',
      properties: {
        "assignmentDate": {
          name: 'assignmentDate',
          type: 'Date'
        },
        "assignmentDueDate": {
          name: 'assignmentDueDate',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "assessment_id": {
          name: 'assessment_id',
          type: 'number'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "assigned_by_id": {
          name: 'assigned_by_id',
          type: 'number'
        },
        "assessment_result_id": {
          name: 'assessment_result_id',
          type: 'number'
        },
      },
      relations: {
        assessment: {
          name: 'assessment',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        assignedBy: {
          name: 'assignedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'assigned_by_id',
          keyTo: 'id'
        },
        assessmentResult: {
          name: 'assessmentResult',
          type: 'AssessmentResult',
          model: 'AssessmentResult',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_result_id',
          keyTo: 'id'
        },
      }
    }
  }
}
