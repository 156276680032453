/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface AccountAcademicInterface {
  "academicYear": string;
  "grade": string;
  "term1CGPA"?: string;
  "term1Attend"?: string;
  "term2CGPA"?: string;
  "term2Attend"?: string;
  "term3CGPA"?: string;
  "term3Attend"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "created_by_id"?: number;
  account?: Account;
  createdBy?: Account;
}

export class AccountAcademic implements AccountAcademicInterface {
  "academicYear": string;
  "grade": string;
  "term1CGPA": string;
  "term1Attend": string;
  "term2CGPA": string;
  "term2Attend": string;
  "term3CGPA": string;
  "term3Attend": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "created_by_id": number;
  account: Account;
  createdBy: Account;
  constructor(data?: AccountAcademicInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountAcademic`.
   */
  public static getModelName() {
    return "AccountAcademic";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountAcademic for dynamic purposes.
  **/
  public static factory(data: AccountAcademicInterface): AccountAcademic{
    return new AccountAcademic(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountAcademic',
      plural: 'AccountAcademics',
      path: 'AccountAcademics',
      idName: 'id',
      properties: {
        "academicYear": {
          name: 'academicYear',
          type: 'string'
        },
        "grade": {
          name: 'grade',
          type: 'string'
        },
        "term1CGPA": {
          name: 'term1CGPA',
          type: 'string'
        },
        "term1Attend": {
          name: 'term1Attend',
          type: 'string'
        },
        "term2CGPA": {
          name: 'term2CGPA',
          type: 'string'
        },
        "term2Attend": {
          name: 'term2Attend',
          type: 'string'
        },
        "term3CGPA": {
          name: 'term3CGPA',
          type: 'string'
        },
        "term3Attend": {
          name: 'term3Attend',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
