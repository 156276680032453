/* tslint:disable */
import {
  Infrastructure,
  Account,
  AppFile,
  ProcurementItem
} from '../index';

declare var Object: any;
export interface ProcurementInterface {
  "status": number;
  "notes"?: string;
  "reject_reason"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "updated_by"?: number;
  infrastructure?: Infrastructure;
  account?: Account;
  procurementPicture?: AppFile;
  updatedBy?: Account;
  procurementItem?: ProcurementItem[];
}

export class Procurement implements ProcurementInterface {
  "status": number;
  "notes": string;
  "reject_reason": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "updated_by": number;
  infrastructure: Infrastructure;
  account: Account;
  procurementPicture: AppFile;
  updatedBy: Account;
  procurementItem: ProcurementItem[];
  constructor(data?: ProcurementInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Procurement`.
   */
  public static getModelName() {
    return "Procurement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Procurement for dynamic purposes.
  **/
  public static factory(data: ProcurementInterface): Procurement{
    return new Procurement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Procurement',
      plural: 'Procurements',
      path: 'Procurements',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'number'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "reject_reason": {
          name: 'reject_reason',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "updated_by": {
          name: 'updated_by',
          type: 'number'
        },
      },
      relations: {
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        procurementPicture: {
          name: 'procurementPicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        updatedBy: {
          name: 'updatedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'updated_by',
          keyTo: 'id'
        },
        procurementItem: {
          name: 'procurementItem',
          type: 'ProcurementItem[]',
          model: 'ProcurementItem',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'procurementId'
        },
      }
    }
  }
}
