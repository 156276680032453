/* tslint:disable */

declare var Object: any;
export interface RecurringInterface {
  "frequency"?: number;
  "endDate"?: Date;
  "weekDays"?: string;
  "noOfWeeks"?: number;
  "monthDay"?: number;
  "noOfMonths"?: number;
  "yearMonths"?: string;
  "yearDay"?: number;
  "noOfYears"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class Recurring implements RecurringInterface {
  "frequency": number;
  "endDate": Date;
  "weekDays": string;
  "noOfWeeks": number;
  "monthDay": number;
  "noOfMonths": number;
  "yearMonths": string;
  "yearDay": number;
  "noOfYears": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: RecurringInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Recurring`.
   */
  public static getModelName() {
    return "Recurring";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Recurring for dynamic purposes.
  **/
  public static factory(data: RecurringInterface): Recurring{
    return new Recurring(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Recurring',
      plural: 'Recurrings',
      path: 'Recurrings',
      idName: 'id',
      properties: {
        "frequency": {
          name: 'frequency',
          type: 'number'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "weekDays": {
          name: 'weekDays',
          type: 'string'
        },
        "noOfWeeks": {
          name: 'noOfWeeks',
          type: 'number'
        },
        "monthDay": {
          name: 'monthDay',
          type: 'number'
        },
        "noOfMonths": {
          name: 'noOfMonths',
          type: 'number'
        },
        "yearMonths": {
          name: 'yearMonths',
          type: 'string'
        },
        "yearDay": {
          name: 'yearDay',
          type: 'number'
        },
        "noOfYears": {
          name: 'noOfYears',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
