import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AccountApi,
  AccountBatchApi,
  AccountBatchtimingApi,
  AccountDetailApi,
  AccountInfrastructureApi,
  AccountUserApi,
  AddressApi,
  AppFileApi,
  AppUserApi,
  AppUserRoleApi,
  BatchApi,
  BatchTimingApi,
  CityApi,
  CountryApi,
  CourseInfrastructureApi,
  FeePlanApi,
  GuardianApi,
  InfrastructureApi, MapFeeplanCourseApi, OptionApi, OrderApi, StateApi
} from 'src/app/shared/sdk';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/class/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss']
})
export class AddStudentComponent implements OnInit {
  public maxDate = new Date();

  frmValues: any = {};
  frmStepper: FormGroup;

  // Dropdown Variables
  public bloodGroups: any = [];
  public dominantSides: any = [];
  public genders: any = [];

  public countries: any = [];
  public countryId = 0;
  public countryMessage = '';

  public nameMessage = '';

  public states: any = [];
  public stateId = 0;
  public stateMessage = '';

  public cities: any = [];
  public cityId = 0;
  public cityMessage = '';

  public centers: any = [];
  public centerId = 0;
  public centerMessage = '';


  public courses: any = [];
  public courseId = 0;

  public batches: any = [];
  public batchId = 0;
  public batchMessage = '';

  public gender = 0;
  public genderMessage = '';

  public bloodGroup = 1;
  public dominantSide = 1;

  public message = '';

  public currentBatchId = 0;
  public currentCenterId = 0;
  public centerType: any;
  public centerReadonly = false;

  get formArray(): AbstractControl {
    return this.frmStepper.get('steps');
  }

  accountType = this.authService.getCurrentAccountType();
  feeTypes: any;
  feePlans: any;
  paymentFrequency: any;
  fees: any;
  playerDetails: any;
  gaurdianDetails: any;
  feeSetup: any;
  addressId: any;
  countryCode: any;
  relationF: any;
  relationM: any;
  accountId: any;
  accountDetailId: any;
  orderId: any;
  public accountBatches: any[] = [];
  public isReadonly = false;
  public feeTypeSelected: any;
  public feePlanSelected: any;
  public paymentFrequencySelected: any;
  public isFeeTypeSelected = false;
  public ispaymentFrequency = false;
  public notFree = true;
  public today;
  public date;
  public motherId: any;
  public fatherId: any;
  public file: File | null = null;
  profilePicError: any;
  profilePictureUrl: any;
  addoreditProfilePic = true;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private authService: AuthService,
    private appUserApi: AppUserApi,
    private accountUserApi: AccountUserApi,
    private orderApi: OrderApi,
    private countryAPi: CountryApi,
    private cityApi: CityApi,
    private optionAPi: OptionApi,
    private accountApi: AccountApi,
    private accountDetailApi: AccountDetailApi,
    private accountInfrastructureApi: AccountInfrastructureApi,
    private accountBatchApi: AccountBatchApi,
    private batchTimingApi: BatchTimingApi,
    private accountbatchTimingApi: AccountBatchtimingApi,
    private addressApi: AddressApi,
    private stateApi: StateApi,
    private infrastructureApi: InfrastructureApi,
    private batchApi: BatchApi,
    private feePlanApi: FeePlanApi,
    private appUserRoleApi: AppUserRoleApi,
    private guardianApi: GuardianApi,
    public fileUploadService: FileUploadService,
    private courseInfraApi: CourseInfrastructureApi,
    private mapFeeplanCourseApi: MapFeeplanCourseApi,
    private activatedRouter: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private appFileApi: AppFileApi
  ) { }

  ngOnInit(): void {
    this.frmStepper = this.fb.group({
      steps: this.fb.array([
        this.fb.group({
          fullName: ['', Validators.compose([Validators.required, Validators.pattern('^(?![0-9 ]*$)[a-zA-Z0-9 ]+$')])],
          email: ['', Validators.compose([Validators.required, Validators.email,
          Validators.pattern('^[[a-zA-z][a-zA-z0-9_.+-]+@[a-zA-z0-9-]+.[a-zA-z0-9-.]+$')])],
          center: [0, Validators.compose([Validators.required])],
          batch: [0],
          course: [0, Validators.compose([Validators.required])],
          dateOfBirth: ['', Validators.compose([Validators.required])],
          mobile: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*')])],
          bloodGroup: [0],
          dominantSide: [0],
          medicalCondition: ['', Validators.compose([Validators.required])],
          gender: [0, Validators.compose([Validators.required])],
          grade: [''],
          isOptedTransport: [''],
          profile: ['', Validators.compose([Validators.pattern('^(?![0-9 ]*$)[a-zA-Z. ]+$')])],
          qualification: ['', Validators.compose([Validators.pattern('^(?![0-9 ]*$)[a-zA-Z. ]+$')])],
          country: [0],
          state: [0],
          city: [0],
          pincode: ['', Validators.compose([Validators.required, Validators.pattern('^[.]?[0-9]+[.]?[0-9]*$')])],
          address: [''],
        }),
        this.fb.group({
          fatherName: ['', Validators.compose([Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')])],
          fatherMobile: ['', Validators.compose([Validators.pattern('^[0-9]*')])],
          // fatherOccupation: ['', [Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')]],
          fatherOccupation: ['', [Validators.pattern('^[a-zA-Z 0-9._-]+$')]],
          fatherEmail: ['', Validators.compose([Validators.email,
          Validators.pattern('^[[a-zA-z][a-zA-z0-9_.+-]+@[a-zA-z0-9-]+.[a-zA-z0-9-.]+$')])],

          motherName: ['', Validators.compose([Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')])],
          motherMobile: ['', Validators.compose([Validators.pattern('^[0-9]*')])],
          // otherOccupation: ['', [Validators.pattern('^(?![0-9 ]*$)[a-zA-Z ]+$')]],
          motherOccupation: ['', [Validators.pattern('^[a-zA-Z 0-9._-]+$')]],
          motherEmail: ['', Validators.compose([Validators.email,
          Validators.pattern('^[[a-zA-z][a-zA-z0-9_.+-]+@[a-zA-z0-9-]+.[a-zA-z0-9-.]+$')])],
        }),
        this.fb.group({
          feeType: ['', Validators.compose([Validators.required])],
          feePlan: [''],
          paymentFrequency: [''],
          trainingStartDate: [''],
          paymentDueDate: [''],
          currency: [''],
          status: [''],
          attempts: [''],
          amountPaid: [''],
          amountDue: [''],
          date: [''],
          appUserId: [''],
          accountId: [''],
          accountDetailId: ['']
        }),
      ]),
    });
    this.loadDropDownValues();
    this.date = new Date();
    this.today = moment(this.date).format('YYYY-MM-DD');
    // date set to choose from past 5years from today's date for date of birth field
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 5);
  }

  loadCourses($event: any) {
    this.centerType = '';
    if (this.currentCenterId !== undefined && this.currentCenterId > 0 && this.currentCenterId !== null) {
      this.centerId = this.currentCenterId;
      this.infrastructureApi.findOne({ where: { id: this.centerId } }).toPromise().then((data: any) => {
        this.centerType = data.typeLabel;
      }).then(() => {
        console.log('loadcourse', this.centerId, this.centerType);
      })
    } else {
      this.centerId = parseInt($event.target.value, 10);
      this.infrastructureApi.findOne({ where: { id: this.centerId } }).toPromise().then((data: any) => {
        this.centerType = data.typeLabel;
      }).then(() => {
        console.log('load', this.centerId, this.centerType);
      })
    }
    this.courseInfraApi.find({
      where: { infrastructureId: this.centerId },
      include: ['course']
    }).toPromise()
      .then((courses: any) => {
        this.courses = courses;
      });
    // this.loadFeeTypes();
  }

  loadDropDownValues() {
    this.activatedRouter.params.subscribe((params) => {
      console.log('priyanka', params)
      this.currentCenterId = params.id;
      this.currentBatchId = Number(params.batchId);
      this.courseId = Number(params.courseId);
    });
    this.optionAPi
      .find({
        where: {
          identifier: Constants.OPTIONS.booldGroup
        }
      })
      .toPromise()
      .then((res) => {
        this.bloodGroups = res;
      })
      .then(() => {
        this.optionAPi
          .find({
            where: {
              identifier: Constants.OPTIONS.dominantSide
            }
          })
          .toPromise()
          .then((res1) => {
            this.dominantSides = res1;
          })
          .then(() => {
            this.optionAPi
              .find({
                where: {
                  identifier: Constants.OPTIONS.gender
                }
              })
              .toPromise()
              .then((res2: any) => {
                this.genders = res2;
                this.infrastructureApi
                  .find({
                    where: {
                      isActive: true
                    }
                  })
                  .toPromise()
                  .then((centers) => {
                    this.centers = centers;
                  });
              });
          }).then(() => {
            this.loadCourseWithoutEvent();
          })

      });

    this.countryAPi.find().toPromise()
      .then((response) => {
        this.countries = response;
      });
    // patch center and batch
    if (this.currentBatchId !== undefined && this.currentBatchId > 0 && this.currentBatchId !== null
      && this.courseId !== undefined && this.courseId > 0 && this.courseId !== null) {
      console.log('h1')
      this.centerId = this.currentCenterId;
      this.loadFeeTypes();
      this.frmStepper.controls.steps['controls'][0].controls.center.patchValue(this.centerId);
      this.frmStepper.controls.steps['controls'][0].controls.course.patchValue(this.courseId);
      this.frmStepper.controls.steps['controls'][0].controls.batch.patchValue(this.currentBatchId);
    } else {
      this.isReadonly = false;
      if (this.currentCenterId !== undefined && this.currentCenterId > 0 && this.currentCenterId !== null) {
        console.log('h2')
        this.centerId = this.currentCenterId;
        this.loadFeeTypes();
        this.frmStepper.controls.steps['controls'][0].controls.center.patchValue(this.centerId);
        this.centerReadonly = true;
      } else {
        console.log('h3')
        this.centerReadonly = false;
      }
    }
  }

  loadCourseWithoutEvent() {
    console.log('loading', this.centerId);
    this.courses = [];
    if (this.centerId != null && this.centerId !== 0) {
      this.courseInfraApi.find({
        where: { isActive: 1, infrastructureId: this.centerId },
        include: ['course']
      }).toPromise()
        .then((courses: any) => {
          this.courses = courses;
          // console.log(this.courses);
        });
    } else {
      this.courseInfraApi.find({
        where: { isActive: 1 },
        include: ['course']
      }).toPromise()
        .then((courses: any) => {
          this.courses = courses;
        });

    }
    this.loadFeeTypes();
  }

  loadFeeTypes() {
    console.log(this.centerId, this.courseId,)
    const feeTypesArray = [];
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] })
      .toPromise()
      .then((feeTypes: any) => {
        Promise.all(feeTypes.map((feeTypeData: any) => {
          if (feeTypeData.feePlanCourse) {
            feeTypeData.feePlanCourse.map((i: any) => {
              if (i.courseId === this.courseId)
                feeTypesArray.push({ id: feeTypeData.feetype, label: feeTypeData.feetypeLabel });
            })
          }
        })).then(() => {
          this.feeTypes = feeTypesArray.filter((thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i);
        })
      });
  }

  loadBatches($event: any) {
    this.courseId = parseInt($event.target.value, 10);
    console.log(this.courseId);
    this.batchApi
      .find({ where: { courseId: this.courseId, infrastructureId: this.centerId } })
      .toPromise()
      .then((response) => {
        this.batches = response;
      });
    const feeTypesArray = [];
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] })
      .toPromise()
      .then((feeTypes: any) => {
        feeTypes.map((feeTypeData: any) => {
          feeTypesArray.push({ id: feeTypeData.feetype, label: feeTypeData.feetypeLabel });
        });
        this.feeTypes = feeTypesArray.filter((thing, i, arr) => arr.findIndex((t) => t.id === thing.id) === i);
      });
    this.loadFeeTypes();
  }

  loadStates($event) {
    this.countryId = parseInt($event.target.value, 10);
    this.stateApi
      .find({ where: { country_id: this.countryId } })
      .toPromise()
      .then((response) => {
        this.states = response;
      });
  }

  loadCities($event) {
    this.stateId = parseInt($event.target.value, 10);
    this.cityApi
      .find({ where: { stateId: this.stateId } })
      .toPromise()
      .then((response) => {
        this.cities = response;
      });
  }
  loadFeePlans($event) {
    this.feeTypeSelected = parseInt($event.target.value, 10);
    if (this.feeTypeSelected !== undefined && this.feeTypeSelected !== '' && this.feeTypeSelected !== null && this.feeTypeSelected !== 0) {
      this.isFeeTypeSelected = true;
    } else {
      this.isFeeTypeSelected = false;
    }
    this.frmStepper.controls.steps['controls'][2].controls.feePlan.patchValue(0);
    this.feePlanFun(this.feeTypeSelected);
  }
  feePlanFun(feetype: any) {
    console.log(this.centerId, this.courseId)
    this.feePlanApi.find({ where: { isActive: true, centerId: this.centerId, feetype }, include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription'] }).toPromise()
      .then((feePlans: any) => {
        const fps = [];
        Promise.all(feePlans.map((fp: any) => {
          Promise.all(fp.feePlanCourse.map((f: any) => {
            if (fp.feePlanCourse && f.courseId === this.courseId) {
              fps.push(fp);
            }
          }));
        })).then(() => {
          this.feePlans = fps;
          this.fees = [];
          this.paymentFrequency = [];
          console.log(this.feePlans)
        })

      });
    if (this.feeTypeSelected === 5) {
      this.notFree = false;
    } else {
      this.notFree = true;
    }
  }
  loadPaymentFrequency($event: any) {
    this.feePlanSelected = parseInt($event.target.value, 10);
    console.log('selectedplan', this.feePlanSelected)
    this.paymentFrequencyFun(this.feeTypeSelected);
  }
  paymentFrequencyFun(feeTypeId) {
    // console.log("in paymentfre",feeTypeId,this.courseId,this.centerId)
    console.log('here', feeTypeId)
    if (feeTypeId === 2) {
      this.ispaymentFrequency = true;
      this.feePlanApi.find({
        where: { isActive: true, centerId: this.centerId, id: this.feePlanSelected, },
        include: ['center', 'feeInstallment', 'feeSubscription', 'feePlanCourse']
      }).toPromise()
        .then((paymentFrequency: any) => {
          paymentFrequency.map((pf: any) => {
            console.log(pf, this.courseId);
            Promise.all(pf.feePlanCourse.map((i: any) => {
              if (i.courseId === this.courseId)
                this.paymentFrequency = paymentFrequency;
            })).then(() => {
              // this.ispaymentFrequency = true;
              console.log('freq', this.paymentFrequency);
            })
          })
        });
      this.feePlanApi.find({
        where: { isActive: true, centerId: this.centerId, id: this.feePlanSelected },
        include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription']
      }).toPromise()
        .then((fees: any) => {
          console.log('fees:::::::::::::::::::::::::::::::::::::', fees);
          fees.map((f: any) => {
            f.feePlanCourse.map((i: any) => {
              if (i.courseId === this.courseId) {
                console.log('fees----------------------------------', fees[0]);
                this.fees = fees[0];
              }
            })
          })

        });
    } else {
      // console.log("elseeee")
      this.ispaymentFrequency = false;
      this.feePlanApi.find({
        where: { isActive: true, centerId: this.centerId, id: this.feePlanSelected },
        include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription']
      }).toPromise()
        .then((fees: any) => {
          console.log('fees:::::::::::::::::::::::::::::::::::::', fees);
          fees.map((f: any) => {
            Promise.all(f.feePlanCourse.map((i: any) => {
              //  console.log("pi",i)
              if (i.courseId === this.courseId)
                this.fees = fees[0];
            })).then(() => {
              //  console.log("feeee",this.fees)
            })
          })

        });
    }
  }

  loadFees($event: any) {
    console.log('ppp', $event.target.value)
    this.paymentFrequencySelected = parseInt($event.target.value, 10);
    this.feesFun(this.paymentFrequencySelected);
  }
  feesFun(payFreq: any) {
    this.feePlanApi.find({
      where: { id: this.feePlanSelected, isActive: true, centerId: this.centerId, recurringFrequency: payFreq },
      include: ['center', 'feePlanCourse', 'feeInstallment', 'feeSubscription']
    }).toPromise()
      .then((fees: any) => {
        this.fees = fees[0];
      });
  }

  setCity($event) {
    this.cityId = parseInt($event.target.value, 10);
  }

  setState($event) {
    this.stateId = parseInt($event.target.value, 10);
  }
  setAccountUserId(usrId: any) {
    // console.log(this.accountId)
    this.accountUserApi.find({ where: { accountId: this.accountId } }).toPromise().then((data: any) => {
      // console.log('no userdata',data);
      if (data.length === 0) {
        this.accountUserApi.create({ accountId: this.accountId, appUserId: usrId }).toPromise();
      }
    })
  }

  setBatchId($event: any) {
    this.batchId = parseInt($event.target.value, 10);
    this.accountBatchApi.find({
      where: { batchId: this.batchId },
      include: [{ relation: 'account', scope: { where: { type: 1 } } }, 'batch']
    }).toPromise().then(data => {
      // console.log(this.batchId)
      let count = 0;
      let capacity = 0;
      return Promise.all(data.map((item: any) => {
        capacity = item.batch.batchCapacity;
        if (item.account) {
          count++;
          // console.log(item.account);
        }
      })).then(() => {
        if (count > 0 && count >= capacity) {
          swal.fire({
            text: 'This batch is already full!',
            type: 'info',
            confirmButtonClass: 'btn btn-primary',
            buttonsStyling: false
          })
          this.batchId = 0;
        }
        console.log(count, capacity, this.batchId);
      });

    })
  }

  onGenderChange($event) {
    this.gender = parseInt($event.target.value, 10);
  }

  setBloodGroup($event) {
    this.bloodGroup = parseInt($event.target.value, 10);
  }

  setDominentSide($event) {
    this.dominantSide = parseInt($event.target.value, 10);
  }

  submit(): void {
    this.addStudent();
  }
  addStudent() {
    this.frmValues = this.frmStepper.value;
    this.playerDetails = this.frmStepper.value.steps[0];
    this.gaurdianDetails = this.frmStepper.value.steps[1];
    this.feeSetup = this.frmStepper.value.steps[2];

    if (this.frmStepper.valid) {
      this.spinner.show();
      this.addressApi.create({
        addressLine1: this.playerDetails.address,
        addressLine2: this.playerDetails.address,
        pincode: parseInt(this.playerDetails.pincode, 10),
        cityId: this.cityId,
        addressType: 4,
        isActive: true,
      }).toPromise().then((address: any) => {
        console.log('address', address);
        this.addressId = parseInt(address.id, 10);
        this.appUserApi.addStudent(
          this.playerDetails.fullName,
          parseInt(this.playerDetails.gender, 10),
          this.playerDetails.mobile,
          this.playerDetails.email,
          parseInt(this.playerDetails.batch, 10),
          parseInt(this.playerDetails.center, 10),
          this.addressId,
          this.playerDetails.dateOfBirth,
          parseInt(this.playerDetails.course, 10)
        ).toPromise().then((accountData: any) => {
          console.log('check', accountData)
          if (this.file !== undefined && this.file !== null) {
            this.uploadProfilePic(accountData.accountId);
          }
          this.frmStepper.controls.steps['controls'][2].controls.accountDetailId.patchValue(accountData.id);
          this.frmStepper.controls.steps['controls'][2].controls.accountId.patchValue(accountData.accountId);
          this.frmStepper.controls.steps['controls'][2].controls.appUserId.patchValue(accountData.appUserId);
          this.accountDetailApi.patchAttributes(accountData.id, {
            bloodGroup: parseInt(this.playerDetails.bloodGroup, 10),
            dominantSide: parseInt(this.playerDetails.dominantSide, 10),
            medicalCondition: this.playerDetails.medicalCondition,
            profile: this.playerDetails.profile,
            qualifications: this.playerDetails.qualification
          }).toPromise().then((accountDetail: any) => {
            this.countryCode = '1';
            this.relationF = 'Father';
            this.relationM = 'Mother';
            if (this.gaurdianDetails.fatherName !== '') {
              this.guardianApi.createGuardian(
                this.gaurdianDetails.fatherName,
                this.gaurdianDetails.fatherEmail,
                this.gaurdianDetails.fatherMobile,
                this.relationF,
                this.gaurdianDetails.fatherOccupation,
                this.countryCode,
                accountDetail.accountId
              ).toPromise().then((guardianDetails: any) => {
                const guardianData = guardianDetails;
              }).catch((error) => {
                this.message = error.message;
              });
            }
            if (this.gaurdianDetails.motherName !== '') {
              this.guardianApi.createGuardian(
                this.gaurdianDetails.motherName,
                this.gaurdianDetails.motherEmail,
                this.gaurdianDetails.motherMobile,
                this.relationM,
                this.gaurdianDetails.motherOccupation,
                this.countryCode,
                accountDetail.accountId
              )
                .toPromise().then((guardianDetails: any) => {
                  const guardianData = guardianDetails;
                }).catch((error) => {
                  this.message = error.message;
                });
            }
            console.log(this.feeSetup);
            const feeSetupData: any = {};
            feeSetupData.amount = this.fees.price;
            feeSetupData.accountId = accountDetail.accountId;
            feeSetupData.feePlanId = Number(this.feeSetup.feePlan);
            feeSetupData.trainingStartDate = this.feeSetup.trainingStartDate !== '' ? this.feeSetup.trainingStartDate : moment().format('YYYY-MM-DD');
            feeSetupData.dueDate = this.feeSetup.paymentDueDate !== '' ? this.feeSetup.paymentDueDate : moment().format('YYYY-MM-DD');
            feeSetupData.currency = 'INR';
            feeSetupData.attempts = this.feeSetup.attempts !== undefined && this.feeSetup.attempts !== '' ? this.feeSetup.attempts : 0;
            feeSetupData.amountPaid = (this.feeSetup.amountPaid !== undefined && this.feeSetup.amountPaid !== '')
              ? this.feeSetup.amountPaid : 0;
            feeSetupData.amountDue = this.feeSetup.amountDue !== undefined && this.feeSetup.amountDue !== ''
              ? this.feeSetup.amountDue : this.fees.price;
            feeSetupData.status = feeSetupData.amountDue != 0 ? 1 : 3;
            feeSetupData.date = this.feeSetup.paymentDueDate !== '' ? this.feeSetup.paymentDueDate : moment().format('YYYY-MM-DD');
            return this.mapFeeplanCourseApi
              .findOne({ where: { feePlanId: Number(this.feeSetup.feePlan), courseId: Number(this.playerDetails.course) } })
              .toPromise().then((fpc: any) => {
                feeSetupData.feePlanCourseId = fpc.id;
              }).then(() => {
                this.orderApi
                  .addOrder(feeSetupData.accountId, feeSetupData.amount, feeSetupData.amountDue, feeSetupData.amountPaid,
                    feeSetupData.attempts, feeSetupData.currency, feeSetupData.date, feeSetupData.dueDate,
                    feeSetupData.feePlanId, feeSetupData.status, feeSetupData.trainingStartDate,
                    Number(this.playerDetails.course), 0, 0).toPromise().then((feeSetupDetails: any) => {
                      const feeSetupInfo = feeSetupDetails;
                    }).catch((error) => {
                      this.message = error.message;
                      swal.fire({
                        text: this.message,
                        type: 'error',
                      })
                    });
              })
          }).then(() => {
            this.spinner.hide();
            swal.fire({
              title: 'Saved!',
              text: 'Student has been Added successfully.',
              type: 'success',
              confirmButtonClass: 'btn btn-primary',
              buttonsStyling: false
            }).then((result) => {
              if (result) {
                this.activeModal.close(true);
                // To Send Email To Admin for the payment KYC
              }
            });
          }).catch((error) => {
            this.spinner.hide();
            this.message = error.message;
            swal.fire({
              text: this.message,
              type: 'error',
            })
          });
        }).catch((error) => {
          this.spinner.hide();
          this.message = error.message;
          swal.fire({
            text: this.message,
            type: 'error',
          })
        });
      });
    }
  }

  uploadProfilePic(accountId: number) {
    this.fileUploadService.uploadPicture(accountId, 'Accounts_uploadProfilePicture', this.file,).then((result: any) => {
      console.log(result);
    })
  }

  showFileDiv(value: any) {
    if (value === false) {
      this.addoreditProfilePic = true;
    } else {
      this.addoreditProfilePic = false;
    }
  }

}
