/* tslint:disable */

declare var Object: any;
export interface WeekAtAGlanceAreaInterface {
  "area": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class WeekAtAGlanceArea implements WeekAtAGlanceAreaInterface {
  "area": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: WeekAtAGlanceAreaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WeekAtAGlanceArea`.
   */
  public static getModelName() {
    return "WeekAtAGlanceArea";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WeekAtAGlanceArea for dynamic purposes.
  **/
  public static factory(data: WeekAtAGlanceAreaInterface): WeekAtAGlanceArea{
    return new WeekAtAGlanceArea(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WeekAtAGlanceArea',
      plural: 'WeekAtAGlanceAreas',
      path: 'WeekAtAGlanceAreas',
      idName: 'id',
      properties: {
        "area": {
          name: 'area',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
      }
    }
  }
}
