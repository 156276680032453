/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface StudentTagInterface {
  "name": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id"?: number;
  account?: Account;
  accounts?: Account[];
}

export class StudentTag implements StudentTagInterface {
  "name": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id": number;
  account: Account;
  accounts: Account[];
  constructor(data?: StudentTagInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StudentTag`.
   */
  public static getModelName() {
    return "StudentTag";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StudentTag for dynamic purposes.
  **/
  public static factory(data: StudentTagInterface): StudentTag{
    return new StudentTag(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StudentTag',
      plural: 'StudentTags',
      path: 'StudentTags',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'MapStudentTag',
          keyThrough: 'accountId',
          keyFrom: 'id',
          keyTo: 'studentTagId'
        },
      }
    }
  }
}
