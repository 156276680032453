import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Constants } from '../class/constants';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  public authService: AuthService;

  constructor(private injector: Injector, private router: Router) {
    setTimeout(() => {
      this.authService = this.injector.get(AuthService);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService == null) {
      this.authService = this.injector.get(AuthService);
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err.error instanceof ArrayBuffer) {
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(err.error));
            err.error = JSON.parse(decodedString);
          } else if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              let loginRoute = '';
              if (localStorage.length > 0) {
                if (localStorage.getItem('appRole') === Constants.roles.STUDENT) {
                  loginRoute = Constants.PATHS.LOGIN;
                } else {
                  loginRoute = Constants.PATHS.OPSLOGIN;
                }
                this.authService.clear();
                this.router.navigate([loginRoute]);
              }
            }
          }
        }
      )
    );
  }
}
