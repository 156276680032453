/* tslint:disable */
import {
  AppUser,
  Account
} from '../index';

declare var Object: any;
export interface InjuryInterface {
  "title": string;
  "date": Date;
  "location"?: string;
  "activityAtInjuryTime"?: string;
  "caseOfInjury"?: string;
  "doctorName"?: string;
  "recoveryTime"?: number;
  "status"?: number;
  "comments"?: string;
  "painVas"?: string;
  "painType"?: string;
  "history"?: string;
  "observation"?: string;
  "examination"?: string;
  "palpitations"?: string;
  "test"?: string;
  "diagnosis"?: string;
  "treatment"?: string;
  "returnToPlay"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_user_id"?: number;
  "account_id"?: number;
  "created_by_id"?: number;
  appUser?: AppUser;
  account?: Account;
  createdBy?: Account;
}

export class Injury implements InjuryInterface {
  "title": string;
  "date": Date;
  "location": string;
  "activityAtInjuryTime": string;
  "caseOfInjury": string;
  "doctorName": string;
  "recoveryTime": number;
  "status": number;
  "comments": string;
  "painVas": string;
  "painType": string;
  "history": string;
  "observation": string;
  "examination": string;
  "palpitations": string;
  "test": string;
  "diagnosis": string;
  "treatment": string;
  "returnToPlay": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_user_id": number;
  "account_id": number;
  "created_by_id": number;
  appUser: AppUser;
  account: Account;
  createdBy: Account;
  constructor(data?: InjuryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Injury`.
   */
  public static getModelName() {
    return "Injury";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Injury for dynamic purposes.
  **/
  public static factory(data: InjuryInterface): Injury{
    return new Injury(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Injury',
      plural: 'Injuries',
      path: 'Injuries',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "location": {
          name: 'location',
          type: 'string'
        },
        "activityAtInjuryTime": {
          name: 'activityAtInjuryTime',
          type: 'string'
        },
        "caseOfInjury": {
          name: 'caseOfInjury',
          type: 'string'
        },
        "doctorName": {
          name: 'doctorName',
          type: 'string'
        },
        "recoveryTime": {
          name: 'recoveryTime',
          type: 'number',
          default: 0
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "painVas": {
          name: 'painVas',
          type: 'string'
        },
        "painType": {
          name: 'painType',
          type: 'string'
        },
        "history": {
          name: 'history',
          type: 'string'
        },
        "observation": {
          name: 'observation',
          type: 'string'
        },
        "examination": {
          name: 'examination',
          type: 'string'
        },
        "palpitations": {
          name: 'palpitations',
          type: 'string'
        },
        "test": {
          name: 'test',
          type: 'string'
        },
        "diagnosis": {
          name: 'diagnosis',
          type: 'string'
        },
        "treatment": {
          name: 'treatment',
          type: 'string'
        },
        "returnToPlay": {
          name: 'returnToPlay',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "app_user_id": {
          name: 'app_user_id',
          type: 'number'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
