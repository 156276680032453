import { Component, ViewChild,Output,EventEmitter,Input} from '@angular/core';
import { ImageCroppedEvent, LoadedImage,Dimensions, ImageTransform  } from 'ngx-image-cropper';
import { UtilityService } from 'src/app/services/utility.service'


@Component({
    selector: 'app-media-file-upload',
    templateUrl: './media-upload.component.html',
    styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent {
    @Input() isPost: boolean=false;
    @Input() isProfile: boolean=false;
    @Output()
    selectedFileEvent: EventEmitter<any> = new EventEmitter<any>();  
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    fileName:any;
    fileType:any;
    isError:boolean=false;
    errorMessage='';

    constructor(
    private utilityService: UtilityService,){}

    public allowedLogoTypes = ["image/jpg", "image/jpeg", "image/png"];

    fileChangeEvent(event: any): void {
        if(!(this.allowedLogoTypes.includes(event.target.files[0].type)))
        {  
            this.isError=true;
            this.errorMessage="Please upload only JPG/PNG images"
        
        }
        else
        {
            this.isError=false;
            this.errorMessage="";
            this.imageChangedEvent = event;
        this.fileName=event.srcElement.files[0].name;
        this.fileType=event.srcElement.files[0].type;
       
        }
        
    }

    imageCropped(event: ImageCroppedEvent) {
        if(!this.isError){
        this.croppedImage = event.base64;                    
        const imageFile = new File([this.utilityService.base64ToFile(event.base64)],this.fileName, { type: this.fileType });
   
        let imageData={'imageFile':imageFile,'imageSrc':this.croppedImage}
        this.selectedFileEvent.emit(imageData);   
        }
    }

    imageLoaded() {
        if(!this.isError){
        this.showCropper = true;

        }
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        
   
    }

    loadImageFailed() {
     
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
    // imageChangedEvent: any = '';
    // croppedImage: any = '';

    // fileChangeEvent(event: any): void {
    //     this.imageChangedEvent = event;
    // }
    // imageCropped(event: ImageCroppedEvent) {
    //     this.croppedImage = event.base64;
    // }
    // imageLoaded(image: LoadedImage) {
    //     // show cropper
    // }
    // cropperReady() {
    //     // cropper ready
    // }
    // loadImageFailed() {
    //     // show message
    // }
}