import { Injectable } from '@angular/core';
import { AppUserApi, AppUser, AppRole, AccountApi } from '../shared/sdk/index';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Constants } from '../class/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: AppUser; //= <AppUser>{};
  public currentClient: AppUser; //= <AppUser>{};
  public currentClientAccount: AppUser; //= <AppUser>{};
  public currentRole: AppRole; //= <AppRole>{};
  public currentAccount: any = {};
  public currentAccountId: number;
  public isLogoutTriggered = false;
  public showHeaderFilter = false;
  public hideDatePickerInHeaderFilter = false;
  public logoutId: any;

  constructor(private appUserApi: AppUserApi, private router: Router, private accountApi: AccountApi) {}

  logout() {
    const self = this;
    if (this.isAuthenticated()) {
      this.appUserApi
        .logout(localStorage.getItem('logoutId'), this.getAccessToken())
        .toPromise()
        .then((response) => {
          localStorage.setItem('accessToken', '');
          console.log('accessToken ', localStorage.getItem('accessToken'));
          this.router.navigate([Constants.PATHS.LOGIN]);
        })
        .catch((error) => {
          this.clear();
          this.router.navigate([Constants.PATHS.LOGIN]);
        });
      this.clear();
    } else {
      this.router.navigate([Constants.PATHS.LOGIN]);
      this.clear();
    }
  }

  async fetchUserDetails() {
    try {
      const response = await this.appUserApi.fetchUserDetails().toPromise();
      this.currentUser = response;
      if (localStorage.getItem('logoutId') === null && this.isAuthenticated()) {
        localStorage.setItem('logoutId', this.getCurrentUser().id.toString());
      }
    } catch (error) {
      if (error && error.error && error.error.status === 401) {
        this.clear();
        this.router.navigate([Constants.PATHS.LOGIN]);
      }
    }
  }

  getCurrentUser() {
    if (window.location.pathname === Constants.PATHS.OPSPLAYERDASHBOARD) {
      return this.currentClient;
    }
    return this.currentUser;
  }

  getCurrentRole() {
    return localStorage.getItem('appRole');
  }

  getCurrentAccountId() {
    return localStorage.getItem('currentAccountId');
  }

  accountValidator(accountType: string) {
    let allowToRoute = false;
    if (this.getCurrentAccountType() === accountType) {
      allowToRoute = true;
    }
    return allowToRoute;
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  setAccessToken(token: string) {
    return localStorage.setItem('accessToken', token);
  }

  setCurrentAccountId(accountId: any) {
    return localStorage.setItem('currentAccountId', accountId);
  }
  setCurrentAccountType(accountType: any) {
    return localStorage.setItem('currentAccountType', accountType);
  }
  setCountryCode(code: any) {
    return localStorage.setItem('countryCode', code);
  }
  getCountryCode() {
    return localStorage.getItem('countryCode');
  }
  getCurrentAccountType() {
    return localStorage.getItem('currentAccountType');
  }
  setCurrentAccountTypeId(accountTypeId: any) {
    return localStorage.setItem('currentAccountTypeId', accountTypeId);
  }
  getCurrentAccountTypeId() {
    return localStorage.getItem('currentAccountTypeId');
  }

  clear() {
    localStorage.clear();
    sessionStorage.clear();
    this.currentUser = new AppUser(); //<AppUser>{};
    this.currentRole = new AppRole(); //<AppRole>{};
  }

  isAuthenticated() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken !== '') {
      return true;
    }
    return false;
  }
  setSubDomain(name: any) {
    return localStorage.setItem('subdomain', name);
  }

  setOrganizationType(type: string) {
    let orgType: string = type !== '' ? type : 'Academy';
    return localStorage.setItem('organizationType', orgType);
  }
  getSubDomain() {
    if (localStorage.getItem('subdomain') === 'undefined') {
      return null;
    } else {
      return localStorage.getItem('subdomain');
    }
  }

  getOrganizationType(){
    if(localStorage.getItem('organizationType')==='undefined'){
      return null
    }else{
      return localStorage.getItem('organizationType')
    }
  }
}
