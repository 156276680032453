import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Constants } from 'src/app/class/constants';
import { OptionApi } from 'src/app/shared/sdk';

@Component({
  selector: 'app-custom-attendance',
  templateUrl: 'custom-attendance.component.html',
  styleUrls: ['./custom-attendance.component.scss']
})
export class CustomAttendanceComponent implements OnInit, OnChanges {
  @Input() input: any;
  @Output() customAttendanceEmitter = new EventEmitter<any>();
  // public selectedValue:number;
  public options: any = [];
  constructor(private optionAPi: OptionApi) {}
  ngOnInit() {}

  ngOnChanges() {
    this.optionAPi
      .find({ where: { identifier: Constants.OPTIONS.AttendanceType } })
      .toPromise()
      .then(async (res) => {
        this.options = res;
        // this.selectedValue = 2;
        return res;
      })
      .then((res: any) => {});
    // this.selectedValue = this.input.selectedValue;
  }

  onChange(event, accountId) {
    this.customAttendanceEmitter.emit({ type: event, accountId });
  }
}
