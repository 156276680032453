/* tslint:disable */
import {
  Country,
  City
} from '../index';

declare var Object: any;
export interface StateInterface {
  "name": string;
  "shortName"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  country?: Country;
  cities?: City[];
}

export class State implements StateInterface {
  "name": string;
  "shortName": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  country: Country;
  cities: City[];
  constructor(data?: StateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `State`.
   */
  public static getModelName() {
    return "State";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of State for dynamic purposes.
  **/
  public static factory(data: StateInterface): State{
    return new State(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'State',
      plural: 'States',
      path: 'States',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "shortName": {
          name: 'shortName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        country: {
          name: 'country',
          type: 'Country',
          model: 'Country',
          relationType: 'belongsTo',
                  keyFrom: 'country_id',
          keyTo: 'id'
        },
        cities: {
          name: 'cities',
          type: 'City[]',
          model: 'City',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'state_id'
        },
      }
    }
  }
}
