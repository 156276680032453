/* tslint:disable */
import {
  Assessment,
  AssessmentParameter
} from '../index';

declare var Object: any;
export interface MapAssessmentParameterInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_id"?: number;
  "assessment_parameter_Id"?: Array<any>;
  assessment?: Assessment;
  assessmentParameters?: AssessmentParameter[];
}

export class MapAssessmentParameter implements MapAssessmentParameterInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_id": number;
  "assessment_parameter_Id": Array<any>;
  assessment: Assessment;
  assessmentParameters: AssessmentParameter[];
  constructor(data?: MapAssessmentParameterInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapAssessmentParameter`.
   */
  public static getModelName() {
    return "MapAssessmentParameter";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapAssessmentParameter for dynamic purposes.
  **/
  public static factory(data: MapAssessmentParameterInterface): MapAssessmentParameter{
    return new MapAssessmentParameter(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapAssessmentParameter',
      plural: 'MapAssessmentParameters',
      path: 'MapAssessmentParameters',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "assessment_id": {
          name: 'assessment_id',
          type: 'number'
        },
        "assessment_parameter_Id": {
          name: 'assessment_parameter_Id',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        assessment: {
          name: 'assessment',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_id',
          keyTo: 'id'
        },
        assessmentParameters: {
          name: 'assessmentParameters',
          type: 'AssessmentParameter[]',
          model: 'AssessmentParameter',
          relationType: 'referencesMany',
                  keyFrom: 'assessment_parameter_Id',
          keyTo: 'id'
        },
      }
    }
  }
}
