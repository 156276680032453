/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUser } from '../../models/AppUser';
import { AppUserRole } from '../../models/AppUserRole';
import { AppRole } from '../../models/AppRole';
import { AccountUser } from '../../models/AccountUser';
import { Account } from '../../models/Account';
import { UserRoleScope } from '../../models/UserRoleScope';


/**
 * Api services for the `AppUser` model.
 *
 * **Details**
 *
 * This model represents the registered user information
 */
@Injectable()
export class AppUserApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Find a related item by id for appRoles.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for appRoles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public findByIdAppRoles(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for appRoles.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for appRoles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdAppRoles(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for appRoles.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for appRoles
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateByIdAppRoles(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Add a related item by id for appRoles.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for appRoles
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public linkAppRoles(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Remove the appRoles relation to an item by id.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for appRoles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkAppRoles(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check the existence of appRoles relation to an item by id.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for appRoles
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public existsAppRoles(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "HEAD";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Fetches hasOne relation accessTokens.
   *
   * @param {any} id AppUser id
   *
   * @param {boolean} refresh 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public getAccessTokens(id: any, refresh: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accessTokens";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof refresh !== 'undefined' && refresh !== null) _urlParams.refresh = refresh;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createAccessTokens(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accessTokens";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update accessTokens of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateAccessTokens(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accessTokens";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes accessTokens of this model.
   *
   * @param {any} id AppUser id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyAccessTokens(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accessTokens";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for accounts.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for accounts
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public findByIdAccounts(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for accounts.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for accounts
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdAccounts(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for accounts.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for accounts
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateByIdAccounts(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Add a related item by id for accounts.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for accounts
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public linkAccounts(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Remove the accounts relation to an item by id.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for accounts
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public unlinkAccounts(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Check the existence of accounts relation to an item by id.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for accounts
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public existsAccounts(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "HEAD";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/rel/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for userRoleScopes.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for userRoleScopes
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public findByIdUserRoleScopes(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for userRoleScopes.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for userRoleScopes
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdUserRoleScopes(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for userRoleScopes.
   *
   * @param {any} id AppUser id
   *
   * @param {any} fk Foreign key for userRoleScopes
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateByIdUserRoleScopes(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries appRoles of AppUser.
   *
   * @param {any} id AppUser id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public getAppRoles(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in appRoles of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createAppRoles(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all appRoles of this model.
   *
   * @param {any} id AppUser id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteAppRoles(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts appRoles of AppUser.
   *
   * @param {any} id AppUser id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countAppRoles(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries accounts of AppUser.
   *
   * @param {any} id AppUser id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public getAccounts(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in accounts of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createAccounts(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all accounts of this model.
   *
   * @param {any} id AppUser id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteAccounts(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts accounts of AppUser.
   *
   * @param {any} id AppUser id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countAccounts(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries userRoleScopes of AppUser.
   *
   * @param {any} id AppUser id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public getUserRoleScopes(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in userRoleScopes of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createUserRoleScopes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all userRoleScopes of this model.
   *
   * @param {any} id AppUser id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteUserRoleScopes(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts userRoleScopes of AppUser.
   *
   * @param {any} id AppUser id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countUserRoleScopes(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to login with OTP, returns access token
   *
   * @param {object} data Request data.
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `otp` – `{string}` - 
   *
   *  - `platform` – `{string}` - 
   *
   *  - `deviceToken` – `{string}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public loginWithOTP(mobileNumber: any, otp: any, platform: any = {}, deviceToken: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/loginWithOTP";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        mobileNumber: mobileNumber,
        otp: otp,
        platform: platform,
        deviceToken: deviceToken
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to login with password, returns access token
   *
   * @param {object} data Request data.
   *
   *  - `userName` – `{string}` - 
   *
   *  - `password` – `{string}` - 
   *
   *  - `platform` – `{string}` - 
   *
   *  - `deviceToken` – `{string}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public loginWithPassword(userName: any, password: any, platform: any = {}, deviceToken: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/loginWithPassword";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        userName: userName,
        password: password,
        platform: platform,
        deviceToken: deviceToken
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for fetching user details from access token
   *
   * @param {object} req 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public fetchUserDetails(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/fetchUserDetails";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for fetching user details from access token
   *
   * @param {number} id 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public fetchUserDetailsById(id: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/fetchUserDetailsById";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to logout
   *
   * @param {number} id 
   *
   * @param {object} req 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public logout(id: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/logout";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to change Password
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `oldPassword` – `{string}` - 
   *
   *  - `newPassword` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public changePassword(id: any, oldPassword: any, newPassword: any, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/changePassword";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for forgot Password
   *
   * @param {object} data Request data.
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `otp` – `{string}` - 
   *
   *  - `newPassword` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public forgotPassword(mobileNumber: any, otp: any, newPassword: any, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/forgotPassword";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        mobileNumber: mobileNumber,
        otp: otp,
        newPassword: newPassword
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new User
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `password` – `{string}` - 
   *
   *  - `dateOfBirth` – `{string}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `batchCourses` – `{any}` - 
   *
   *  - `batchDays` – `{any}` - 
   *
   *  - `isOptedTransport` – `{boolean}` - 
   *
   *  - `grade` – `{number}` - 
   *
   *  - `medicalCondition` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public registerUser(name: any, mobileNumber: any, email: any, gender: any, password: any, dateOfBirth: any, infrastructureId: any, dateOfJoining: any, batchCourses: any, batchDays: any, isOptedTransport: any = {}, grade: any = {}, medicalCondition: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/registerUser";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        mobileNumber: mobileNumber,
        email: email,
        gender: gender,
        password: password,
        dateOfBirth: dateOfBirth,
        infrastructureId: infrastructureId,
        dateOfJoining: dateOfJoining,
        batchCourses: batchCourses,
        batchDays: batchDays,
        isOptedTransport: isOptedTransport,
        grade: grade,
        medicalCondition: medicalCondition
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to generate OTP
   *
   * @param {object} data Request data.
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `signature` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public generateOTP(mobileNumber: any, signature: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/generateOTP";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        mobileNumber: mobileNumber,
        signature: signature
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to verify OTP
   *
   * @param {object} data Request data.
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `otp` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public verifyOTP(mobileNumber: any, otp: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/verifyOTP";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        mobileNumber: mobileNumber,
        otp: otp
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for changing app user status
   *
   * @param {object} data Request data.
   *
   *  - `appUserId` – `{number}` - 
   *
   *  - `status` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateAppUserStatus(appUserId: any, status: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/updateAppUserStatus";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        appUserId: appUserId,
        status: status
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to update role of an app user
   *
   * @param {object} data Request data.
   *
   *  - `appUserId` – `{number}` - 
   *
   *  - `deletedRoleIds` – `{any}` - 
   *
   *  - `newRolesIds` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateRoles(appUserId: any, deletedRoleIds: any, newRolesIds: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/updateRoles";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        appUserId: appUserId,
        deletedRoleIds: deletedRoleIds,
        newRolesIds: newRolesIds
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to create user for internal roles
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `password` – `{string}` - 
   *
   *  - `appRoleIds` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createAppUser(name: any, mobileNumber: any, email: any, password: any, appRoleIds: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/createAppUser";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        mobileNumber: mobileNumber,
        email: email,
        password: password,
        appRoleIds: appRoleIds
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get filtered limited AppUser List and count of total filtered AppUsers
   *
   * @param {object} data Request data.
   *
   *  - `roleFilterType` – `{string}` - 
   *
   *  - `pSearchTerm` – `{string}` - 
   *
   *  - `pOffset` – `{number}` - 
   *
   *  - `pLimit` – `{number}` - 
   *
   *  - `pOrderBy` – `{any}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public fetchAppUserByRole(roleFilterType: any = {}, pSearchTerm: any = {}, pOffset: any = {}, pLimit: any = {}, pOrderBy: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/fetchAppUserByRole";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        roleFilterType: roleFilterType,
        pSearchTerm: pSearchTerm,
        pOffset: pOffset,
        pLimit: pLimit,
        pOrderBy: pOrderBy
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to download profile picture
   *
   * @param {number} id 
   *
   * @param {object} req 
   *
   * @param {object} res 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public downloadProfilePicture(id: any, req: any = {}, res: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/downloadProfilePicture";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to confirm updated email and contact Details
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public confirmUpdatedDetails(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/confirmUpdatedDetails";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to reject updated email and contact Details
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public rejectUpdatedDetails(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/rejectUpdatedDetails";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to reject updated email and contact Details
   *
   * @param {number} id 
   *
   * @param {number} accountId 
   *
   * @param {object} data Request data.
   *
   *  - `userStatus` – `{number}` - 
   *
   *  - `reason` – `{string}` - 
   *
   *  - `effectiveDate` – `{string}` - 
   *
   *  - `deregisterdById` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public deRegisterUser(id: any, accountId: any, userStatus: any, reason: any = {}, effectiveDate: any = {}, deregisterdById: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/:accountId/deRegisterUser";
    let _routeParams: any = {
      id: id,
      accountId: accountId
    };
    let _postBody: any = {
      data: {
        userStatus: userStatus,
        reason: reason,
        effectiveDate: effectiveDate,
        deregisterdById: deregisterdById
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to handle Facebook login
   *
   * @param {string} code 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public handleFacebookCallback(code: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/auth/facebook";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof code !== 'undefined' && code !== null) _urlParams.code = code;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to handle Google login
   *
   * @param {string} code 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public handleGoogleCallback(code: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/auth/google";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof code !== 'undefined' && code !== null) _urlParams.code = code;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new student
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `batchId` – `{number}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `addressId` – `{number}` - 
   *
   *  - `dateOfBirth` – `{string}` - 
   *
   *  - `courseId` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addStudent(name: any, gender: any, mobileNumber: any, email: any, batchId: any = {}, infrastructureId: any, addressId: any, dateOfBirth: any, courseId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addStudent";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        batchId: batchId,
        infrastructureId: infrastructureId,
        addressId: addressId,
        dateOfBirth: dateOfBirth,
        courseId: courseId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new student
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `addressId` – `{number}` - 
   *
   *  - `dateOfBirth` – `{string}` - 
   *
   *  - `courseId` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addStudentWithoutBatch(name: any, gender: any, mobileNumber: any, email: any, infrastructureId: any, addressId: any, dateOfBirth: any, courseId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addStudentWithoutBatch";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        infrastructureId: infrastructureId,
        addressId: addressId,
        dateOfBirth: dateOfBirth,
        courseId: courseId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new coach
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `batchIds` – `{any}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `courseId` – `{any}` - 
   *
   *  - `experience` – `{string}` - 
   *
   *  - `qualifications` – `{string}` - 
   *
   *  - `level` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `profile` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addCoach(name: any, gender: any, mobileNumber: any, email: any, batchIds: any, infrastructureId: any, courseId: any, experience: any, qualifications: any, level: any, dateOfJoining: any, profile: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addCoach";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        batchIds: batchIds,
        infrastructureId: infrastructureId,
        courseId: courseId,
        experience: experience,
        qualifications: qualifications,
        level: level,
        dateOfJoining: dateOfJoining,
        profile: profile
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new staff
   *
   * @param {object} data Request data.
   *
   *  - `centerIds` – `{any}` - 
   *
   *  - `name` – `{string}` - 
   *
   *  - `roleType` – `{number}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `qualifications` – `{string}` - 
   *
   *  - `profile` – `{string}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `rolelabel` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addStaff(centerIds: any, name: any, roleType: any, gender: any, mobileNumber: any, email: any, qualifications: any = {}, profile: any = {}, dateOfJoining: any, rolelabel: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addStaff";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        centerIds: centerIds,
        name: name,
        roleType: roleType,
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        qualifications: qualifications,
        profile: profile,
        dateOfJoining: dateOfJoining,
        rolelabel: rolelabel
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new staff
   *
   * @param {object} data Request data.
   *
   *  - `centerIds` – `{any}` - 
   *
   *  - `name` – `{string}` - 
   *
   *  - `roleType` – `{number}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `qualifications` – `{string}` - 
   *
   *  - `profile` – `{string}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `rolelabel` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addNewAdmin(centerIds: any, name: any, roleType: any, gender: any, mobileNumber: any, email: any, qualifications: any = {}, profile: any = {}, dateOfJoining: any, rolelabel: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addNewAdmin";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        centerIds: centerIds,
        name: name,
        roleType: roleType,
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        qualifications: qualifications,
        profile: profile,
        dateOfJoining: dateOfJoining,
        rolelabel: rolelabel
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API for fetching count for dashboard
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public fetchDashboardCount(customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/fetchDashboardCount";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to update user details
   *
   * @param {object} data Request data.
   *
   *  - `accountId` – `{number}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `dateOfBirth` – `{string}` - 
   *
   *  - `bloodGroup` – `{number}` - 
   *
   *  - `dominantSide` – `{number}` - 
   *
   *  - `medicalCondition` – `{string}` - 
   *
   *  - `qualifications` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateUserDetails(accountId: any, gender: any = {}, dateOfJoining: any = {}, dateOfBirth: any = {}, bloodGroup: any = {}, dominantSide: any = {}, medicalCondition: any = {}, qualifications: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/updateUserDetails";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        accountId: accountId,
        gender: gender,
        dateOfJoining: dateOfJoining,
        dateOfBirth: dateOfBirth,
        bloodGroup: bloodGroup,
        dominantSide: dominantSide,
        medicalCondition: medicalCondition,
        qualifications: qualifications
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to send Email
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `signature` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public sendEmailToUser(email: any, signature: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/sendEmailToUser";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        email: email,
        signature: signature
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to send New Email for Admin
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `academyCode` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public sendEmailToNewAdmin(email: any, academyCode: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/sendEmailToNewAdmin";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        email: email,
        academyCode: academyCode
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to send payment gateway integration Email to admin
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `signature` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public sendPGEmailNewAdmin(email: any, signature: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/sendPGEmailNewAdmin";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        email: email,
        signature: signature
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to Send Birthday Email
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public sendBirthdayNotifications(customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/sendBirthdayNotifications";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to Send De-Registration Email
   *
   * @param {object} data Request data.
   *
   *  - `id` – `{number}` - 
   *
   *  - `accountId` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public deregistrationNotification(id: any, accountId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/deregistrationNotification";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        accountId: accountId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to Send Download Invities Notifications
   *
   * @param {object} data Request data.
   *
   *  - `userId` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public sendDownloadInvitiesNotifications(userId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/sendDownloadInvitiesNotifications";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        userId: userId
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to send Email to Admin
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `accountId` – `{number}` - 
   *
   *  - `signature` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public sendEmailToAdmin(email: any = {}, accountId: any = {}, signature: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/sendEmailToAdmin";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        email: email,
        accountId: accountId,
        signature: signature
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new coach
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `batchIds` – `{any}` - 
   *
   *  - `infrastructureIds` – `{any}` - 
   *
   *  - `experience` – `{string}` - 
   *
   *  - `qualifications` – `{string}` - 
   *
   *  - `level` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `profile` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addCoachWithMultipleCenterCourseBatch(name: any, gender: any, mobileNumber: any, email: any, batchIds: any, infrastructureIds: any, experience: any, qualifications: any, level: any, dateOfJoining: any, profile: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addCoachWithMultipleCenterCourseBatch";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        batchIds: batchIds,
        infrastructureIds: infrastructureIds,
        experience: experience,
        qualifications: qualifications,
        level: level,
        dateOfJoining: dateOfJoining,
        profile: profile
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new coach
   *
   * @param {object} data Request data.
   *
   *  - `gender` – `{number}` - 
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `batchIds` – `{any}` - 
   *
   *  - `infrastructureIds` – `{any}` - 
   *
   *  - `experience` – `{string}` - 
   *
   *  - `qualifications` – `{string}` - 
   *
   *  - `level` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `profile` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public updateWithMultipleCenterCourseBatch(gender: any, mobileNumber: any, email: any, batchIds: any, infrastructureIds: any, experience: any, qualifications: any, level: any, dateOfJoining: any, profile: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/updateWithMultipleCenterCourseBatch";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        gender: gender,
        mobileNumber: mobileNumber,
        email: email,
        batchIds: batchIds,
        infrastructureIds: infrastructureIds,
        experience: experience,
        qualifications: qualifications,
        level: level,
        dateOfJoining: dateOfJoining,
        profile: profile
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to send contact us form
   *
   * @param {object} data Request data.
   *
   *  - `email` – `{string}` - 
   *
   *  - `name` – `{string}` - 
   *
   *  - `message` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public contactUs(email: any, name: any, message: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/contactUs";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        email: email,
        name: name,
        message: message
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get firebase Token
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public getFirebaseToken(customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/getFirebaseToken";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get available feePlans for user
   *
   * @param {number} accountId 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public getFeePlansforRegisteredUser(accountId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/getFeePlansforRegisteredUser/:accountId";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to deactivate account
   *
   * @param {number} accountId 
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public deActivateAccount(accountId: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:accountId/deActivateAccount";
    let _routeParams: any = {
      accountId: accountId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to add or change new activity in existing players account
   *
   * @param {object} data Request data.
   *
   *  - `accountId` – `{number}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `batchCourses` – `{any}` - 
   *
   *  - `batchDays` – `{any}` - 
   *
   *  - `isOptedTransport` – `{boolean}` - 
   *
   *  - `grade` – `{number}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addOrchangeActivity(accountId: any, infrastructureId: any, dateOfJoining: any, batchCourses: any, batchDays: any, isOptedTransport: any = {}, grade: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addOrchangeActivity";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        accountId: accountId,
        infrastructureId: infrastructureId,
        dateOfJoining: dateOfJoining,
        batchCourses: batchCourses,
        batchDays: batchDays,
        isOptedTransport: isOptedTransport,
        grade: grade
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to add sibling account in existing user
   *
   * @param {object} data Request data.
   *
   *  - `appUserId` – `{number}` - 
   *
   *  - `name` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `dateOfBirth` – `{string}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `dateOfJoining` – `{string}` - 
   *
   *  - `batchCourses` – `{any}` - 
   *
   *  - `batchDays` – `{any}` - 
   *
   *  - `isOptedTransport` – `{boolean}` - 
   *
   *  - `grade` – `{number}` - 
   *
   *  - `medicalCondition` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addSibling(appUserId: any, name: any, gender: any, dateOfBirth: any, infrastructureId: any, dateOfJoining: any, batchCourses: any, batchDays: any, isOptedTransport: any = {}, grade: any = {}, medicalCondition: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addSibling";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        appUserId: appUserId,
        name: name,
        gender: gender,
        dateOfBirth: dateOfBirth,
        infrastructureId: infrastructureId,
        dateOfJoining: dateOfJoining,
        batchCourses: batchCourses,
        batchDays: batchDays,
        isOptedTransport: isOptedTransport,
        grade: grade,
        medicalCondition: medicalCondition
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new User for trial session
   *
   * @param {object} data Request data.
   *
   *  - `name` – `{string}` - 
   *
   *  - `mobileNumber` – `{number}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `gender` – `{number}` - 
   *
   *  - `dateOfBirth` – `{Date}` - 
   *
   *  - `infrastructureId` – `{number}` - 
   *
   *  - `batchId` – `{number}` - 
   *
   *  - `courseId` – `{number}` - 
   *
   *  - `medicalCondition` – `{string}` - 
   *
   *  - `parentMobile` – `{number}` - 
   *
   *  - `parentEmail` – `{string}` - 
   *
   *  - `parentName` – `{string}` - 
   *
   *  - `trialDate` – `{Date}` - 
   *
   *  - `remark` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public registerTrialUser(name: any, mobileNumber: any, email: any, gender: any, dateOfBirth: any, infrastructureId: any, batchId: any, courseId: any, medicalCondition: any, parentMobile: any = {}, parentEmail: any, parentName: any, trialDate: any, remark: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/registerTrialUser";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        name: name,
        mobileNumber: mobileNumber,
        email: email,
        gender: gender,
        dateOfBirth: dateOfBirth,
        infrastructureId: infrastructureId,
        batchId: batchId,
        courseId: courseId,
        medicalCondition: medicalCondition,
        parentMobile: parentMobile,
        parentEmail: parentEmail,
        parentName: parentName,
        trialDate: trialDate,
        remark: remark
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get the list count of centers, courses, Feeplans, Batches count (Provide Raw query)
   *
   * @param {object} req 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public onBoardingCount(req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/onBoardingCount";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to register new coach
   *
   * @param {object} data Request data.
   *
   *  - `mobileNumber` – `{string}` - 
   *
   *  - `email` – `{string}` - 
   *
   *  - `name` – `{string}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public addFreelanceCoach(mobileNumber: any, email: any, name: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/addFreelanceCoach";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        mobileNumber: mobileNumber,
        email: email,
        name: name
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in accessTokens of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createManyAccessTokens(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accessTokens";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in appRoles of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createManyAppRoles(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/appRoles";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in accounts of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createManyAccounts(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/accounts";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in userRoleScopes of this model.
   *
   * @param {any} id AppUser id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `AppUser` object.)
   * </em>
   */
  public createManyUserRoleScopes(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/AppUsers/:id/userRoleScopes";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `AppUser`.
   */
  public getModelName() {
    return "AppUser";
  }
}
