import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountApi, AppUserApi, BatchApi, CourseInfrastructureApi, InfrastructureApi, OptionApi } from 'src/app/shared/sdk';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/services/fileUpload.service';
import swal from 'sweetalert2';
import { Constants } from 'src/app/class/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-add-coach',
  templateUrl: './add-coach.component.html',
  styleUrls: ['./add-coach.component.scss']
})
export class AddCoachComponent implements OnInit {
  public addCoachForm = this.fb.group({
    fullName: ['', [Validators.required, Validators.pattern('^(?![0-9 ]*$)[a-zA-Z0-9 ]+$')]],
    batches: [{ value: '', disabled: false }],
    infrastructures: [{ value: '', disabled: false }],
    gender: ['', Validators.required],
    mobile: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]*')])],
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.email,
        Validators.pattern('^[[a-zA-z][a-zA-z0-9_.+-]+@[a-zA-z0-9-]+.[a-zA-z0-9-.]+$')
      ])
    ],
    dateOfJoining: ['', Validators.required],
    qualification: ['', [Validators.required, Validators.pattern('^(?![0-9 ]*$)[a-zA-Z0-9 ]+$')]],
    experience: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]+$')]],
    course: ['', Validators.required],
    level: ['', Validators.required]
  });
  public selectedBatches = [];
  public selectedBatchIds = [];
  public accountType: any;
  public selectedCenters = [];
  public batchMessage = '';
  public selectedCenterIds = [];
  public selectedBatchId: any;
  public file: File;
  public dropdownCenterList = [];
  public infrastructuresList: any = [];
  public dropdownSettings: any = {};
  public dropdownList = [];
  public dropdownCourseList = [];
  public dropdownBatchList = [];
  public requiredCenterField = false;
  public courses: any = [];
  public batchList: any = [];
  public courseList: any = [];
  public courseIds = [];
  public selectedCourses = [];
  public selectedCourseIds = [];
  public infrastructures: any = [];
  public ShowFilter = false;
  public coachCourseIds: any = [];
  public requiredCourseField = false;
  public batchIds = [];
  public courseId: any;
  public changeSelectedBatch = false;
  public requiredBatchField = false;
  public genders: any = [];
  public levels: any = [];
  public profilePictureUrl = '';
  public isNext = false;
  get af() {
    return this.addCoachForm.controls;
  }
  constructor(
    private activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private batchApi: BatchApi,
    private infrastructureApi: InfrastructureApi,
    private fb: FormBuilder,
    private appUserApi: AppUserApi,
    private fileUploadService: FileUploadService,
    private courseInfra: CourseInfrastructureApi,
    private optionAPi: OptionApi,
    private toastr: ToastrService,
    private accountApi: AccountApi,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.patchValue();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };
    this.optionAPi
      .find({
        where: {
          identifier: Constants.OPTIONS.coachLevel
        }
      })
      .toPromise()
      .then((levelOptions) => {
        this.levels = levelOptions;
      });
    this.optionAPi
      .find({
        where: {
          identifier: Constants.OPTIONS.gender
        }
      })
      .toPromise()
      .then((res) => {
        this.genders = res;
      });
    this.getInfrastructures();
    this.selectedCenters = [];
  }
  addCoach() {
    this.addCoachForm.markAllAsTouched();
    const form = this.addCoachForm.valid;
    const coach: any = this.addCoachForm.value;
    const batchIds = [];
    if (this.selectedBatches) {
      this.selectedBatchIds.map((batchId: any) => {
        batchIds.push(batchId);
      });
    } else {
      return null;
    }
    const InfrastructuresIds = [];
    let InfrasId = '';
    if (this.selectedCenters) {
      this.selectedCenterIds.map((InfrastructuresId: any) => {
        InfrastructuresIds.push(Number(InfrastructuresId));
        InfrasId = InfrastructuresId;
      });
    } else {
      return null;
    }
    this.batchMessage = this.selectedBatchId === 0 ? 'Select a batch' : '';
    if (this.batchMessage !== '') {
      return null;
    } else {
      this.spinner.show();
      if(this.authService.getOrganizationType() =='freelanceCoach')
      {
        alert('hello')
        this.appUserApi
        .updateWithMultipleCenterCourseBatch(
          Number(coach.gender),
          coach.mobile,
          coach.email,
          batchIds,
          InfrastructuresIds,
          coach.experience,
          coach.qualification,
          Number(coach.level),
          String(coach.dateOfJoining),
          ''
        )
        .toPromise()
        .then((response: any) => {
          if (this.file) {
            this.uploadProfilePic(response.accountId, 'Coach Profile has been Addedd successfully');
          }
          this.spinner.hide();
          this.isNext = true;
          // Reset the form
          this.addCoachForm.reset();
          // this.toastr.success('Coach has been added successfully');
        })
        .catch((error) => {
          this.spinner.hide();
          swal
            .fire({
              text: error.message,
              type: 'error',
              confirmButtonClass: 'btn btn-primary',
              buttonsStyling: false
            })
            .then((result) => {
              this.addCoachForm.reset();
              this.activeModal.close(false);
            });
        });

      }else{
      this.appUserApi
        .addCoachWithMultipleCenterCourseBatch(
          coach.fullName,
          Number(coach.gender),
          coach.mobile,
          coach.email,
          batchIds,
          InfrastructuresIds,
          coach.experience,
          coach.qualification,
          Number(coach.level),
          coach.dateOfJoining,
          ''
        )
        .toPromise()
        .then((response: any) => {
          if (this.file) {
            this.uploadProfilePic(response.accountId, 'Coach Profile has been Addedd successfully');
          }
          this.spinner.hide();
          this.isNext = true;
          // Reset the form
          this.addCoachForm.reset();
          // this.toastr.success('Coach has been added successfully');
        })
        .catch((error) => {
          this.spinner.hide();
          swal
            .fire({
              text: error.message,
              type: 'error',
              confirmButtonClass: 'btn btn-primary',
              buttonsStyling: false
            })
            .then((result) => {
              this.addCoachForm.reset();
              this.activeModal.close(false);
            });
        });
      }
    }
  }
  uploadProfilePic(accountId: number, succussMessage: string) {
    this.fileUploadService.uploadPicture(accountId, 'Accounts_uploadProfilePicture', this.file).then(() => {
      this.spinner.hide();
    });
  }
  getInfrastructures() {
    this.dropdownCenterList = [];
    this.infrastructuresList = [];
    this.infrastructureApi
      .find()
      .toPromise()
      .then((infraDetails: any) => {
        infraDetails.map((infraObj: any) => {
          const infra: any = {};
          infra.text = infraObj.name;
          infra.value = infraObj.id;
          // this.infrastructuresList.push(infra);
          this.dropdownCenterList = this.dropdownCenterList.concat({ id: infra.value, name: infra.text });
        });
      });
  }
  onCenterSelect(item: any) {
    this.dropdownCourseList = [];
    this.dropdownBatchList = [];
    if (!this.selectedCenterIds.includes(item.id)) this.selectedCenterIds.push(item.id);
    this.getCourseInfrastructure(this.selectedCenterIds);
    if (this.selectedCenterIds.length > 0) {
      this.requiredCenterField = true;
    } else {
      this.requiredCenterField = false;
    }
  }
  onSelectAllCenters(items: any) {
    this.dropdownCourseList = [];
    this.dropdownBatchList = [];
    this.selectedCenterIds = [];
    items.map((i: any) => {
      this.selectedCenterIds.push(i.id);
    });
    this.getCourseInfrastructure(this.selectedCenterIds);
    if (this.selectedCenterIds.length > 0) {
      this.requiredCenterField = true;
    } else {
      this.requiredCenterField = false;
    }
    //  console.log(items);
  }
  onDeSelectAllCenters(items: any) {
    this.dropdownCourseList = [];
    this.dropdownBatchList = [];
    this.selectedCenterIds = [];
    this.selectedCourseIds = [];
    this.selectedBatchIds = [];
    this.selectedCenters = [];
    if (this.selectedCenterIds.length > 0) {
      this.requiredCenterField = true;
    } else {
      this.requiredCenterField = false;
    }
  }
  onDeSelectCenter(item: any) {
    this.dropdownCourseList = [];
    if (this.selectedCenterIds.includes(item.id)) {
      this.selectedCenterIds = this.selectedCenterIds.filter((i: any) => i !== item.id);
    }
    this.getCourseInfrastructure(this.selectedCenterIds);
    if (this.selectedBatchIds.length > 0 && this.dropdownBatchList.length > 0) {
      this.selectedBatches = this.selectedBatches.filter((i: any) => this.dropdownBatchList.includes(i));
    }
    if (this.selectedCenterIds.length > 0) {
      this.requiredCenterField = true;
    } else {
      this.requiredCenterField = false;
    }
  }
  getCourseInfrastructure(centerIds: any) {
    this.courses = [];
    this.courseList = [];
    this.courseIds = [];
    this.dropdownCourseList = [];
    this.courseInfra
      .find({ where: { infrastructureId: { inq: centerIds }, isActive: true }, include: ['course', 'infrastructure'] })
      .toPromise()
      .then((courseInfra: any) => {
        this.courses = courseInfra;
        this.courseList = [];
        Promise.all(
          courseInfra.map((item) => {
            const course: any = {};
            course.text = item.course.name + ' ' + item.course.levelLabel + ' ' + item.infrastructure.name;
            course.value = '{"courseId":' + item.course.id + ',"centerId":' + item.infrastructure.id + '}';
            this.courseList.push(course);
            this.dropdownCourseList = this.dropdownCourseList.concat({ id: course.value, name: course.text });
            this.courseIds.push(course.value);
          })
        ).then(() => {
          if (this.selectedCourseIds.length > 0) {
            const c = [];
            Promise.all(
              this.selectedCourseIds.map((i: any) => {
                if (this.courseIds.includes(i)) {
                  c.push(i);
                }
              })
            ).then(() => {
              // console.log(this.selectedCourses,c)
              this.selectedCourses = this.selectedCourses.filter((item: any) => c.includes(item.id));
              this.selectedCourseIds = c;
            });
          }
        });
      });
  }
  onCourseSelect(item: any) {
    const coachcourse = [];
    if (!this.selectedCourseIds.includes(item.id)) this.selectedCourseIds.push(item.id);
    this.loadBatches();
    if (this.selectedCourseIds.length > 0) {
      Promise.all(
        this.selectedCourseIds.map((cid) => {
          const item1 = JSON.parse(cid);
          coachcourse.push(item1.courseId);
          // console.log(item);
        })
      ).then(() => {
        this.coachCourseIds = coachcourse;
      });
      this.requiredCourseField = true;
    } else {
      this.requiredCourseField = false;
    }
  }
  onSelectAllCourses(items: any) {
    this.dropdownBatchList = [];
    this.selectedCourseIds = [];
    const coachcourse = [];
    items.map((i: any) => {
      this.selectedCourseIds.push(i.id);
    });
    this.loadBatches();
    if (this.selectedCourseIds.length > 0) {
      Promise.all(
        this.selectedCourseIds.map((cid) => {
          const item = JSON.parse(cid);
          coachcourse.push(item.courseId);
          // console.log(item);
        })
      ).then(() => {
        this.coachCourseIds = coachcourse;
      });
      this.requiredCourseField = true;
    } else {
      this.requiredCourseField = false;
    }
    //  console.log(items);
  }
  onDeSelectCourse(item: any) {
    this.dropdownBatchList = [];
    this.batchIds = [];
    this.changeSelectedBatch = true;
    const coachcourse = [];
    //  console.log(item,this.selectedCourseId,this.dropdownBatchList,this.selectedBatchItems,this.selectedBatchId);
    if (this.selectedCourseIds.includes(item.id)) {
      this.selectedCourseIds = this.selectedCourseIds.filter((i: any) => i !== item.id);
    }
    if (this.selectedCourseIds.length === 0) {
      this.selectedBatches = [];
      this.selectedBatchIds = [];
    } else {
      this.loadBatches();
    }
    if (this.selectedCourseIds.length > 0) {
      Promise.all(
        this.selectedCourseIds.map((cid) => {
          const item1 = JSON.parse(cid);
          coachcourse.push(item1.courseId);
          // console.log(item1);
        })
      ).then(() => {
        this.coachCourseIds = coachcourse;
      });
      this.requiredCourseField = true;
    } else {
      this.requiredCourseField = false;
    }
  }
  onDeSelectAllCourses(items: any) {
    this.selectedBatchIds = [];
    this.dropdownBatchList = [];
    this.batchIds = [];
    this.selectedBatches = [];
    this.selectedCourses = [];
    this.selectedCourseIds = [];
    this.coachCourseIds = [];
    // this.selectedBatchItems=[];
    // this.dropdownBatchList=[];
    // console.log(items,this.selectedItems);
    if (this.selectedCourseIds.length > 0) {
      this.requiredCenterField = true;
    } else {
      this.requiredCenterField = false;
    }
  }
  loadBatches() {
    const courses = this.selectedCourseIds;
    this.courseId = [];
    Promise.all(
      courses.map(async (item: any) => {
        const obj = JSON.parse(item);
        return this.batchApi
          .find({
            where: {
              isActive: true,
              infrastructureId: obj.centerId,
              courseId: obj.courseId
            },
            include: ['infrastructure', 'course']
          })
          .toPromise()
          .then((batchDetails: any) => {
            return Promise.all(
              batchDetails.map((batchObj: any) => {
                const course: any = {};
                if (!this.batchIds.includes(batchObj.id)) {
                  course.text = `${batchObj.name}-${batchObj.course.name} ${batchObj.course.levelLabel}, ${batchObj.infrastructure.name}`;
                  course.value = '{"batchId":' + batchObj.id + ',"courseId":' + batchObj.courseId + '}';
                  this.dropdownBatchList = this.dropdownBatchList.concat({ id: course.value, name: course.text });
                  this.batchIds.push(batchObj.id);
                  this.courseId.push(course.value);
                }
              })
            );
          });
      })
    ).then(() => {
      if (this.selectedBatchIds.length > 0 && this.changeSelectedBatch) {
        const c = [];
        Promise.all(
          this.selectedBatchIds.map((i: any) => {
            if (this.courseId.includes(i)) {
              c.push(i);
            }
          })
        ).then(() => {
          this.selectedBatches = this.selectedBatches.filter((item: any) => c.includes(item.id));
          this.selectedBatchIds = c;
          this.changeSelectedBatch = false;
        });
      }
    });
  }
  onBatchSelect(item: any) {
    if (!this.selectedBatchIds.includes(item.id)) this.selectedBatchIds.push(item.id);
    if (this.selectedBatchIds.length > 0) {
      this.requiredBatchField = true;
    } else {
      this.requiredBatchField = false;
    }
  }
  onSelectAllBatches(items: any) {
    this.selectedBatchIds = [];
    items.map((i: any) => {
      this.selectedBatchIds.push(i.id);
    });
    if (this.selectedBatchIds.length > 0) {
      this.requiredBatchField = true;
    } else {
      this.requiredBatchField = false;
    }
    //  console.log(items);
  }
  onDeSelectBatch(item: any) {
    //  console.log(item,this.selectedCourseId,this.dropdownBatchList,this.selectedBatchItems,this.selectedBatchId);
    if (this.selectedBatchIds.includes(item.id)) {
      this.selectedBatchIds = this.selectedBatchIds.filter((i: any) => i !== item.id);
    }
    if (this.selectedBatchIds.length > 0) {
      this.requiredBatchField = true;
    } else {
      this.requiredBatchField = false;
    }
    // console.log(this.selectedCourseId,this.dropdownBatchList,this.selectedBatchId);
  }
  onDeSelectAllBatches(items: any) {
    this.selectedBatchIds = [];
    this.selectedBatches = [];
    // this.selectedBatchItems=[];
    // this.dropdownBatchList=[];
    // console.log(items,this.selectedItems);
    if (this.selectedBatchIds.length > 0) {
      this.requiredBatchField = true;
    } else {
      this.requiredBatchField = false;
    }
  }
  setImage(image) {
    this.file = image;
    this.profilePictureUrl = '';
  }
  next() {
    this.activeModal.close(true);
  }
  private async patchValue() {
    if (this.authService.getOrganizationType() === 'freelanceCoach') {
      const freeLanceDetails: any = await this.accountApi
        .find({
          where: { remarks: 'freeLance coach', isActive: true },
          include: [{ appUser: 'appRoles' }, 'accountDetail', 'infrastructures', 'profilePicture'],
          order: 'createdDate DESC'
        })
        .toPromise();
      if (freeLanceDetails && freeLanceDetails?.length > 0) {
        this.addCoachForm.patchValue({
          fullName: freeLanceDetails[0].name,
          mobile: freeLanceDetails[0].appUser.mobileNumber,
          email: freeLanceDetails[0].appUser.email
        });
      }
    }
  }
}
