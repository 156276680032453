/* tslint:disable */
import {
  Account,
  Batch,
  Infrastructure
} from '../index';

declare var Object: any;
export interface AttendanceInterface {
  "date"?: Date;
  "type"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by"?: number;
  "infrastructure_id"?: number;
  "accountId"?: number;
  account?: Account;
  createdBy?: Account;
  batch?: Batch;
  center?: Infrastructure;
}

export class Attendance implements AttendanceInterface {
  "date": Date;
  "type": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by": number;
  "infrastructure_id": number;
  "accountId": number;
  account: Account;
  createdBy: Account;
  batch: Batch;
  center: Infrastructure;
  constructor(data?: AttendanceInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Attendance`.
   */
  public static getModelName() {
    return "Attendance";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Attendance for dynamic purposes.
  **/
  public static factory(data: AttendanceInterface): Attendance{
    return new Attendance(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Attendance',
      plural: 'Attendances',
      path: 'Attendances',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'Date'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "created_by": {
          name: 'created_by',
          type: 'number'
        },
        "infrastructure_id": {
          name: 'infrastructure_id',
          type: 'number'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by',
          keyTo: 'id'
        },
        batch: {
          name: 'batch',
          type: 'Batch',
          model: 'Batch',
          relationType: 'belongsTo',
                  keyFrom: 'batch_id',
          keyTo: 'id'
        },
        center: {
          name: 'center',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
      }
    }
  }
}
