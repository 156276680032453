/* tslint:disable */
import {
  WorkoutPlan,
  WorkoutPlanSchedule
} from '../index';

declare var Object: any;
export interface MapWorkoutPlanScheduleInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "workoutPlanScheduleId"?: number;
  workoutPlan?: WorkoutPlan;
  workoutPlanSchedule?: WorkoutPlanSchedule;
}

export class MapWorkoutPlanSchedule implements MapWorkoutPlanScheduleInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "workoutPlanScheduleId": number;
  workoutPlan: WorkoutPlan;
  workoutPlanSchedule: WorkoutPlanSchedule;
  constructor(data?: MapWorkoutPlanScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapWorkoutPlanSchedule`.
   */
  public static getModelName() {
    return "MapWorkoutPlanSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapWorkoutPlanSchedule for dynamic purposes.
  **/
  public static factory(data: MapWorkoutPlanScheduleInterface): MapWorkoutPlanSchedule{
    return new MapWorkoutPlanSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapWorkoutPlanSchedule',
      plural: 'MapWorkoutPlanSchedules',
      path: 'MapWorkoutPlanSchedules',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "workoutPlanScheduleId": {
          name: 'workoutPlanScheduleId',
          type: 'number'
        },
      },
      relations: {
        workoutPlan: {
          name: 'workoutPlan',
          type: 'WorkoutPlan',
          model: 'WorkoutPlan',
          relationType: 'belongsTo',
                  keyFrom: 'workout_plan_id',
          keyTo: 'id'
        },
        workoutPlanSchedule: {
          name: 'workoutPlanSchedule',
          type: 'WorkoutPlanSchedule',
          model: 'WorkoutPlanSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'workout_plan_schedule_id',
          keyTo: 'id'
        },
      }
    }
  }
}
