/* tslint:disable */
import {
  FeePlan
} from '../index';

declare var Object: any;
export interface FeeInstallmentInterface {
  "totalInstallmentAmount": number;
  "installmentAmount"?: number;
  "noOfMonthlyInstallments": number;
  "hasLateFee"?: boolean;
  "lateFeeDelay"?: number;
  "lateFeeAmount"?: number;
  "status"?: number;
  "repeatAnually"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "feePlanId"?: number;
  feePlan?: FeePlan;
}

export class FeeInstallment implements FeeInstallmentInterface {
  "totalInstallmentAmount": number;
  "installmentAmount": number;
  "noOfMonthlyInstallments": number;
  "hasLateFee": boolean;
  "lateFeeDelay": number;
  "lateFeeAmount": number;
  "status": number;
  "repeatAnually": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "feePlanId": number;
  feePlan: FeePlan;
  constructor(data?: FeeInstallmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FeeInstallment`.
   */
  public static getModelName() {
    return "FeeInstallment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FeeInstallment for dynamic purposes.
  **/
  public static factory(data: FeeInstallmentInterface): FeeInstallment{
    return new FeeInstallment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FeeInstallment',
      plural: 'FeeInstallments',
      path: 'FeeInstallments',
      idName: 'id',
      properties: {
        "totalInstallmentAmount": {
          name: 'totalInstallmentAmount',
          type: 'number'
        },
        "installmentAmount": {
          name: 'installmentAmount',
          type: 'number'
        },
        "noOfMonthlyInstallments": {
          name: 'noOfMonthlyInstallments',
          type: 'number'
        },
        "hasLateFee": {
          name: 'hasLateFee',
          type: 'boolean',
          default: false
        },
        "lateFeeDelay": {
          name: 'lateFeeDelay',
          type: 'number'
        },
        "lateFeeAmount": {
          name: 'lateFeeAmount',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "repeatAnually": {
          name: 'repeatAnually',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "feePlanId": {
          name: 'feePlanId',
          type: 'number'
        },
      },
      relations: {
        feePlan: {
          name: 'feePlan',
          type: 'FeePlan',
          model: 'FeePlan',
          relationType: 'belongsTo',
                  keyFrom: 'fee_plan_id',
          keyTo: 'id'
        },
      }
    }
  }
}
