/* tslint:disable */
import {
  Account,
  AppUser
} from '../index';

declare var Object: any;
export interface AccountUserInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account?: Account;
  appUser?: AppUser;
}

export class AccountUser implements AccountUserInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account: Account;
  appUser: AppUser;
  constructor(data?: AccountUserInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountUser`.
   */
  public static getModelName() {
    return "AccountUser";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountUser for dynamic purposes.
  **/
  public static factory(data: AccountUserInterface): AccountUser{
    return new AccountUser(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountUser',
      plural: 'AccoutUsers',
      path: 'AccoutUsers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
      }
    }
  }
}
