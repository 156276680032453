/* tslint:disable */
import {
  Account,
  Excercise
} from '../index';

declare var Object: any;
export interface ExcercisesAttendeeInterface {
  "attendeesId"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "excercise_id"?: number;
  account?: Account;
  excercise?: Excercise;
}

export class ExcercisesAttendee implements ExcercisesAttendeeInterface {
  "attendeesId": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "excercise_id": number;
  account: Account;
  excercise: Excercise;
  constructor(data?: ExcercisesAttendeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ExcercisesAttendee`.
   */
  public static getModelName() {
    return "ExcercisesAttendee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ExcercisesAttendee for dynamic purposes.
  **/
  public static factory(data: ExcercisesAttendeeInterface): ExcercisesAttendee{
    return new ExcercisesAttendee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ExcercisesAttendee',
      plural: 'ExcercisesAttendees',
      path: 'ExcercisesAttendees',
      idName: 'id',
      properties: {
        "attendeesId": {
          name: 'attendeesId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "excercise_id": {
          name: 'excercise_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        excercise: {
          name: 'excercise',
          type: 'Excercise',
          model: 'Excercise',
          relationType: 'belongsTo',
                  keyFrom: 'excercise_id',
          keyTo: 'id'
        },
      }
    }
  }
}
