import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
countryCode: any;
isAccordionOpen1: any;
paymentHandler:any=null;
toggle(arg0: string) {
throw new Error('Method not implemented.');
}
isAccordionOpen2: any;
isAccordionOpen3: any;
isAccordionOpen4: any;

  constructor( private translate: TranslateService,private http: HttpClient,) { }

  ngOnInit(): void {
    // this.invokeStripe();
  }
  makePayment(amount:number){   
    let uri = environment.baseUrl+"/api/Orders/checkoutST";  
    this.http.post(uri,{token:'',amount:amount*100,currency:'inr',planName:'Basketball Sport 5-6 PM',email:'Mahesh@yopmail.com'}).subscribe((res:any)=>{
      console.log(res); 
      if(res.status) {
        window.location.href=res.data.url;
      }      
    })
  }
  makeStripePayment(amount:number){
    const paymentHandler=(<any>window).StripeCheckout.configure({
      key: 'pk_test_51OD2R8SGkBvovawH7fLmRAkvbuFmoVPueE1qyVGQZebIfUDESy72zMG40yYPTwmfGta4gibwlZJlKUrnGy62cHRR00yeZg1v4l',
      locale:'auto',
      currency:'inr',
      token:function(stripeToken:any){
        console.log(stripeToken);
        paymentStripe(stripeToken);
      }
    });
    const  paymentStripe=(stripeToken:any)=>{
      let uri = "http://localhost:4200/api/Orders/checkoutST";  
      this.http.post(uri,{token:stripeToken,amount:amount*100,currency:'inr'}).subscribe((res)=>{
        console.log(res);        
      })
    }    
    paymentHandler.open({
      name:"1Sports",
      description:"All in One Sports Management Platform",
      amount:amount*100
    })
  }
  
  invokeStripe(){
    if(!window.document.getElementById('stripe-script')){
      const script = window.document.createElement('script');
      script.id='stripe-script';
      script.type='text/javascript';
      script.src='https://checkout.stripe.com/checkout.js';
      script.onload=()=>{
        this.paymentHandler =(<any>window).StripeCheckout.configure({
          key: 'pk_test_51OD2R8SGkBvovawH7fLmRAkvbuFmoVPueE1qyVGQZebIfUDESy72zMG40yYPTwmfGta4gibwlZJlKUrnGy62cHRR00yeZg1v4l',
          locale:'auto',
          currency:'inr',
          token:function(stripeToken:any){
            console.log(stripeToken);
          }
        });
      };
      window.document.body.appendChild(script);
    }
  }
}
