/* tslint:disable */
import {
  WorkoutPlan,
  FixedTimeDuration,
  Recurring,
  Account,
  MapWorkoutPlanSchedule
} from '../index';

declare var Object: any;
export interface WorkoutPlanScheduleInterface {
  "scheduleName": string;
  "startDate": Date;
  "endDate"?: Date;
  "status"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  workoutPlan?: WorkoutPlan;
  fixedTime?: FixedTimeDuration;
  recurring?: Recurring;
  createdBy?: Account;
  workoutSchedule?: MapWorkoutPlanSchedule[];
}

export class WorkoutPlanSchedule implements WorkoutPlanScheduleInterface {
  "scheduleName": string;
  "startDate": Date;
  "endDate": Date;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  workoutPlan: WorkoutPlan;
  fixedTime: FixedTimeDuration;
  recurring: Recurring;
  createdBy: Account;
  workoutSchedule: MapWorkoutPlanSchedule[];
  constructor(data?: WorkoutPlanScheduleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `WorkoutPlanSchedule`.
   */
  public static getModelName() {
    return "WorkoutPlanSchedule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of WorkoutPlanSchedule for dynamic purposes.
  **/
  public static factory(data: WorkoutPlanScheduleInterface): WorkoutPlanSchedule{
    return new WorkoutPlanSchedule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'WorkoutPlanSchedule',
      plural: 'WorkoutPlanSchedules',
      path: 'WorkoutPlanSchedules',
      idName: 'id',
      properties: {
        "scheduleName": {
          name: 'scheduleName',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
      },
      relations: {
        workoutPlan: {
          name: 'workoutPlan',
          type: 'WorkoutPlan',
          model: 'WorkoutPlan',
          relationType: 'belongsTo',
                  keyFrom: 'workout_plan_id',
          keyTo: 'id'
        },
        fixedTime: {
          name: 'fixedTime',
          type: 'FixedTimeDuration',
          model: 'FixedTimeDuration',
          relationType: 'belongsTo',
                  keyFrom: 'fixed_time_id',
          keyTo: 'id'
        },
        recurring: {
          name: 'recurring',
          type: 'Recurring',
          model: 'Recurring',
          relationType: 'belongsTo',
                  keyFrom: 'recurring_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        workoutSchedule: {
          name: 'workoutSchedule',
          type: 'MapWorkoutPlanSchedule[]',
          model: 'MapWorkoutPlanSchedule',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'workoutPlanScheduleId'
        },
      }
    }
  }
}
