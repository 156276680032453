/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface MessageInterface {
  "messageType"?: number;
  "subject"?: string;
  "message"?: string;
  "notificationType"?: number;
  "status"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sender_id"?: number;
  "accountIds"?: Array<any>;
  sender?: Account;
  accounts?: Account[];
}

export class Message implements MessageInterface {
  "messageType": number;
  "subject": string;
  "message": string;
  "notificationType": number;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sender_id": number;
  "accountIds": Array<any>;
  sender: Account;
  accounts: Account[];
  constructor(data?: MessageInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Message`.
   */
  public static getModelName() {
    return "Message";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Message for dynamic purposes.
  **/
  public static factory(data: MessageInterface): Message{
    return new Message(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Message',
      plural: 'Messages',
      path: 'Messages',
      idName: 'id',
      properties: {
        "messageType": {
          name: 'messageType',
          type: 'number'
        },
        "subject": {
          name: 'subject',
          type: 'string'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "notificationType": {
          name: 'notificationType',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "sender_id": {
          name: 'sender_id',
          type: 'number'
        },
        "accountIds": {
          name: 'accountIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        sender: {
          name: 'sender',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'sender_id',
          keyTo: 'id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'referencesMany',
                  keyFrom: 'accountIds',
          keyTo: 'id'
        },
      }
    }
  }
}
