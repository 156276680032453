/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface TermsAndConditionsInterface {
  "termsandconditions": string;
  "isPublished": number;
  "presentToNewRegistrants": number;
  "presentToExistingMembers": number;
  "publishedOn"?: Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id"?: number;
  "modified_by_id"?: number;
  createdBy?: Account;
  lastModifiedBy?: Account;
}

export class TermsAndConditions implements TermsAndConditionsInterface {
  "termsandconditions": string;
  "isPublished": number;
  "presentToNewRegistrants": number;
  "presentToExistingMembers": number;
  "publishedOn": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "created_by_id": number;
  "modified_by_id": number;
  createdBy: Account;
  lastModifiedBy: Account;
  constructor(data?: TermsAndConditionsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TermsAndConditions`.
   */
  public static getModelName() {
    return "TermsAndConditions";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TermsAndConditions for dynamic purposes.
  **/
  public static factory(data: TermsAndConditionsInterface): TermsAndConditions{
    return new TermsAndConditions(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TermsAndConditions',
      plural: 'TermsAndConditions',
      path: 'TermsAndConditions',
      idName: 'id',
      properties: {
        "termsandconditions": {
          name: 'termsandconditions',
          type: 'string'
        },
        "isPublished": {
          name: 'isPublished',
          type: 'number'
        },
        "presentToNewRegistrants": {
          name: 'presentToNewRegistrants',
          type: 'number'
        },
        "presentToExistingMembers": {
          name: 'presentToExistingMembers',
          type: 'number'
        },
        "publishedOn": {
          name: 'publishedOn',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "created_by_id": {
          name: 'created_by_id',
          type: 'number'
        },
        "modified_by_id": {
          name: 'modified_by_id',
          type: 'number'
        },
      },
      relations: {
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'created_by_id',
          keyTo: 'id'
        },
        lastModifiedBy: {
          name: 'lastModifiedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'modified_by_id',
          keyTo: 'id'
        },
      }
    }
  }
}
