/* tslint:disable */
import {
  Form
} from '../index';

declare var Object: any;
export interface FormElementInterface {
  "description"?: string;
  "name": string;
  "type": number;
  "default": string;
  "sequence": number;
  "maxPoints"?: number;
  "placeHolder": string;
  "isActive": boolean;
  "id"?: number;
  "createdDate": Date;
  "lastModifiedDate": Date;
  form?: Form;
}

export class FormElement implements FormElementInterface {
  "description": string;
  "name": string;
  "type": number;
  "default": string;
  "sequence": number;
  "maxPoints": number;
  "placeHolder": string;
  "isActive": boolean;
  "id": number;
  "createdDate": Date;
  "lastModifiedDate": Date;
  form: Form;
  constructor(data?: FormElementInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `FormElement`.
   */
  public static getModelName() {
    return "FormElement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of FormElement for dynamic purposes.
  **/
  public static factory(data: FormElementInterface): FormElement{
    return new FormElement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'FormElement',
      plural: 'FormElements',
      path: 'FormElements',
      idName: 'id',
      properties: {
        "description": {
          name: 'description',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "default": {
          name: 'default',
          type: 'string'
        },
        "sequence": {
          name: 'sequence',
          type: 'number'
        },
        "maxPoints": {
          name: 'maxPoints',
          type: 'number'
        },
        "placeHolder": {
          name: 'placeHolder',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        form: {
          name: 'form',
          type: 'Form',
          model: 'Form',
          relationType: 'belongsTo',
                  keyFrom: 'form_id',
          keyTo: 'id'
        },
      }
    }
  }
}
