/* tslint:disable */
import {
  Account,
  AppFile
} from '../index';

declare var Object: any;
export interface MapWagFileAccountInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account?: Account;
  appFile?: AppFile;
}

export class MapWagFileAccount implements MapWagFileAccountInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account: Account;
  appFile: AppFile;
  constructor(data?: MapWagFileAccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapWagFileAccount`.
   */
  public static getModelName() {
    return "MapWagFileAccount";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapWagFileAccount for dynamic purposes.
  **/
  public static factory(data: MapWagFileAccountInterface): MapWagFileAccount{
    return new MapWagFileAccount(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapWagFileAccount',
      plural: 'MapWagFileAccounts',
      path: 'MapWagFileAccounts',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        appFile: {
          name: 'appFile',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
