import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PresentationComponent } from "./presentation.component";

export const appRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: PresentationComponent
      }
    ]
  }
];
@NgModule({
  imports:[
    RouterModule.forChild(appRoutes)
  ],
  exports:[RouterModule]
})
export class PresentationRoutingModule{}
