/* tslint:disable */

declare var Object: any;
export interface MealPlanInterface {
  "date": string;
  "fromTime": string;
  "toTime": string;
  "mealSession": string;
  "mealToTake": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy"?: number;
  "modifiedByName"?: string;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class MealPlan implements MealPlanInterface {
  "date": string;
  "fromTime": string;
  "toTime": string;
  "mealSession": string;
  "mealToTake": string;
  "createdBy": number;
  "createdByName": string;
  "modifiedBy": number;
  "modifiedByName": string;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: MealPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MealPlan`.
   */
  public static getModelName() {
    return "MealPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MealPlan for dynamic purposes.
  **/
  public static factory(data: MealPlanInterface): MealPlan{
    return new MealPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MealPlan',
      plural: 'MealPlan',
      path: 'MealPlan',
      idName: 'id',
      properties: {
        "date": {
          name: 'date',
          type: 'string'
        },
        "fromTime": {
          name: 'fromTime',
          type: 'string'
        },
        "toTime": {
          name: 'toTime',
          type: 'string'
        },
        "mealSession": {
          name: 'mealSession',
          type: 'string'
        },
        "mealToTake": {
          name: 'mealToTake',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "modifiedByName": {
          name: 'modifiedByName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
