// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  staging: false,
  production: true,
  development: false, 
  //baseUrl: '',
  baseUrl: ' https://archive.prd.1sports.app',
  apiUrl:'https://api.1sports.app/api',  //laravel apis
  notificationApiUrl: `https://api.1sports.app/api/notifications-templates/`,
  brandingPreferenceApiUrl: `https://api.1sports.app/api/branding-preference/`,
  //clientApiUrl:`http://localhost:3000/api/Tenants`,
  clientApiUrl:`https://clients.1sports.app/api/Tenants`,
  videoSdkUrl: `https://api.videosdk.live/v2/`,
  calendly: 'https://calendly.com/1sports/1-sports-application-demo',
  countryUrl: 'https://countriesnow.space/api/v0.1/',
  countryBaseEndPoint: 'countries',
  courseFinder: 'https://coursefinder.1sports.app/api/v1',
  courseFinder_home: 'https://coursefinder.1sports.app',
  blogsUrl: 'https://superadmin.1sports.app',
  googlClientId: '28589062371-dan5m0k3e36cqe63nad6opesbdngo6il.apps.googleusercontent.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
