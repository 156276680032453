/* tslint:disable */
import {
  AppFile,
  Sport,
  Amenity,
  Batch,
  Infrastructure,
  MapFeeplanCourse
} from '../index';

declare var Object: any;
export interface CourseInterface {
  "name": string;
  "category": number;
  "description"?: string;
  "isCertificable": boolean;
  "level"?: number;
  "isMedicalRequired"?: boolean;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sport_id"?: number;
  coursePicture?: AppFile;
  sport?: Sport;
  amenities?: Amenity[];
  batches?: Batch[];
  infrastructures?: Infrastructure[];
  courseFeePlans?: MapFeeplanCourse[];
}

export class Course implements CourseInterface {
  "name": string;
  "category": number;
  "description": string;
  "isCertificable": boolean;
  "level": number;
  "isMedicalRequired": boolean;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "sport_id": number;
  coursePicture: AppFile;
  sport: Sport;
  amenities: Amenity[];
  batches: Batch[];
  infrastructures: Infrastructure[];
  courseFeePlans: MapFeeplanCourse[];
  constructor(data?: CourseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Course`.
   */
  public static getModelName() {
    return "Course";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Course for dynamic purposes.
  **/
  public static factory(data: CourseInterface): Course{
    return new Course(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Course',
      plural: 'Courses',
      path: 'Courses',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "category": {
          name: 'category',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "isCertificable": {
          name: 'isCertificable',
          type: 'boolean',
          default: true
        },
        "level": {
          name: 'level',
          type: 'number'
        },
        "isMedicalRequired": {
          name: 'isMedicalRequired',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "sport_id": {
          name: 'sport_id',
          type: 'number'
        },
      },
      relations: {
        coursePicture: {
          name: 'coursePicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        sport: {
          name: 'sport',
          type: 'Sport',
          model: 'Sport',
          relationType: 'belongsTo',
                  keyFrom: 'sport_id',
          keyTo: 'id'
        },
        amenities: {
          name: 'amenities',
          type: 'Amenity[]',
          model: 'Amenity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'course_id'
        },
        batches: {
          name: 'batches',
          type: 'Batch[]',
          model: 'Batch',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'course_id'
        },
        infrastructures: {
          name: 'infrastructures',
          type: 'Infrastructure[]',
          model: 'Infrastructure',
          relationType: 'hasMany',
          modelThrough: 'CourseInfrastructure',
          keyThrough: 'infrastructure_id',
          keyFrom: 'id',
          keyTo: 'course_id'
        },
        courseFeePlans: {
          name: 'courseFeePlans',
          type: 'MapFeeplanCourse[]',
          model: 'MapFeeplanCourse',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'courseId'
        },
      }
    }
  }
}
