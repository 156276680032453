/* tslint:disable */
import {
  Account,
  AppFile
} from '../index';

declare var Object: any;
export interface RankingClassificationInterface {
  "classification": number;
  "category": string;
  "date": Date;
  "rank": number;
  "points": number;
  "rankStatus"?: boolean;
  "pointsStatus"?: boolean;
  "status": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  "app_file_id"?: number;
  owner?: Account;
  icon?: AppFile;
}

export class RankingClassification implements RankingClassificationInterface {
  "classification": number;
  "category": string;
  "date": Date;
  "rank": number;
  "points": number;
  "rankStatus": boolean;
  "pointsStatus": boolean;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  "app_file_id": number;
  owner: Account;
  icon: AppFile;
  constructor(data?: RankingClassificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RankingClassification`.
   */
  public static getModelName() {
    return "RankingClassification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RankingClassification for dynamic purposes.
  **/
  public static factory(data: RankingClassificationInterface): RankingClassification{
    return new RankingClassification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RankingClassification',
      plural: 'RankingClassifications',
      path: 'RankingClassifications',
      idName: 'id',
      properties: {
        "classification": {
          name: 'classification',
          type: 'number'
        },
        "category": {
          name: 'category',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date'
        },
        "rank": {
          name: 'rank',
          type: 'number'
        },
        "points": {
          name: 'points',
          type: 'number'
        },
        "rankStatus": {
          name: 'rankStatus',
          type: 'boolean'
        },
        "pointsStatus": {
          name: 'pointsStatus',
          type: 'boolean'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
      },
      relations: {
        owner: {
          name: 'owner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        icon: {
          name: 'icon',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
