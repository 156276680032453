/* tslint:disable */

declare var Object: any;
export interface PreWorkoutRoutineInterface {
  "academicYear": string;
  "quarter"?: string;
  "sport": string;
  "workoutName": string;
  "workoutRitual": string;
  "createdBy": number;
  "createdByName": string;
  "coachComments"?: string;
  "coachId"?: number;
  "coachName"?: string;
  "coachCommentedOn"?: string;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class PreWorkoutRoutine implements PreWorkoutRoutineInterface {
  "academicYear": string;
  "quarter": string;
  "sport": string;
  "workoutName": string;
  "workoutRitual": string;
  "createdBy": number;
  "createdByName": string;
  "coachComments": string;
  "coachId": number;
  "coachName": string;
  "coachCommentedOn": string;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: PreWorkoutRoutineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `PreWorkoutRoutine`.
   */
  public static getModelName() {
    return "PreWorkoutRoutine";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of PreWorkoutRoutine for dynamic purposes.
  **/
  public static factory(data: PreWorkoutRoutineInterface): PreWorkoutRoutine{
    return new PreWorkoutRoutine(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PreWorkoutRoutine',
      plural: 'PreWorkoutRoutines',
      path: 'PreWorkoutRoutines',
      idName: 'id',
      properties: {
        "academicYear": {
          name: 'academicYear',
          type: 'string'
        },
        "quarter": {
          name: 'quarter',
          type: 'string'
        },
        "sport": {
          name: 'sport',
          type: 'string'
        },
        "workoutName": {
          name: 'workoutName',
          type: 'string'
        },
        "workoutRitual": {
          name: 'workoutRitual',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "coachComments": {
          name: 'coachComments',
          type: 'string'
        },
        "coachId": {
          name: 'coachId',
          type: 'number'
        },
        "coachName": {
          name: 'coachName',
          type: 'string'
        },
        "coachCommentedOn": {
          name: 'coachCommentedOn',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
