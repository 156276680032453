import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-failedpayment',
  templateUrl: 'failedpayment.component.html',
  styleUrls: ['./failedpayment.component.scss']
})
export class FailedPaymentComponent implements OnInit {

  paymentMode: string = 'Online Payment';
  transactionReferenceNumber: string = 'WCIT6447594408';
  orderId: string = '12345';
  transactionAmount: number = 10000.00;
  transactionStatus: string = 'Failed';
  purposeOfPayment: string = 'Sports Coaching Fee ';
  statusDesc: string = 'Payment failed due to insufficient funds.';
  transactionDateTime: Date = new Date();
  merchantLogo: string = '../../../assets/img/academy-logo/failed.png';
  brandLogo:any;
  orgName:any;
  subdomain:any;
  clientUrl=environment.baseUrl;  
  

  constructor(private route: ActivatedRoute,private http:HttpClient) { }

  ngOnInit() { 
    this.route.queryParams.subscribe((params) => {     
      // this.transactionReferenceNumber = params['transactionId'];
      // this.orderId=params['orderId'];
      // this.transactionAmount=params['amount'];
      // this.transactionDateTime=params['date']
      // this.statusDesc=params['desc']
      // this.subdomain=params['tenant']
      this.transactionReferenceNumber = params['transactionId']?params['transactionId']:'';
      this.orderId=params['orderId']?params['orderId']:'';
      this.transactionAmount=params['amount']?params['amount']:'';
      this.transactionDateTime=params['date']?params['date']:'';
      this.statusDesc=params['desc']?params['desc']:'';
      this.subdomain=params['tenant']
    });
    let headers = {"headers" : {"version": '1.0.0' ,"source":'app',"x-sub-domain":this.subdomain}};
    let queryParamsData: any = {
      "include":
      {
        relation: "organizationLogoFile",                                      
      }
    };
    queryParamsData = JSON.stringify(queryParamsData);
    this.http.get(this.clientUrl + '/api/BrandingPreferences?filter=' + queryParamsData,headers).toPromise().then((response: any) => { 
      this.brandLogo = response.length>0?response[0].organizationLogoFile.location:'';
    });
    this.http.get(this.clientUrl + '/api/Organizations',headers).toPromise().then((orgData: any) => {
      this.orgName = orgData.length > 0 ? orgData[0].name : '';
    }); 
    
  }    
}
