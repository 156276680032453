/* tslint:disable */
import {
  StudentTag,
  Account
} from '../index';

declare var Object: any;
export interface MapStudentTagInterface {
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountId"?: number;
  studentTag?: StudentTag;
  account?: Account;
}

export class MapStudentTag implements MapStudentTagInterface {
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountId": number;
  studentTag: StudentTag;
  account: Account;
  constructor(data?: MapStudentTagInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapStudentTag`.
   */
  public static getModelName() {
    return "MapStudentTag";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapStudentTag for dynamic purposes.
  **/
  public static factory(data: MapStudentTagInterface): MapStudentTag{
    return new MapStudentTag(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapStudentTag',
      plural: 'MapStudentTags',
      path: 'MapStudentTags',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "accountId": {
          name: 'accountId',
          type: 'number'
        },
      },
      relations: {
        studentTag: {
          name: 'studentTag',
          type: 'StudentTag',
          model: 'StudentTag',
          relationType: 'belongsTo',
                  keyFrom: 'student_tag_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
