/* tslint:disable */
import {
  Account,
  CoachingSchedule
} from '../index';

declare var Object: any;
export interface MapCoachingScheduleStudentInterface {
  "type": number;
  "status"?: number;
  "comment"?: string;
  "reason"?: string;
  "completionDate"?: Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "coaching_schedule_id"?: number;
  account?: Account;
  coachingSchedule?: CoachingSchedule;
}

export class MapCoachingScheduleStudent implements MapCoachingScheduleStudentInterface {
  "type": number;
  "status": number;
  "comment": string;
  "reason": string;
  "completionDate": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "coaching_schedule_id": number;
  account: Account;
  coachingSchedule: CoachingSchedule;
  constructor(data?: MapCoachingScheduleStudentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MapCoachingScheduleStudent`.
   */
  public static getModelName() {
    return "MapCoachingScheduleStudent";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MapCoachingScheduleStudent for dynamic purposes.
  **/
  public static factory(data: MapCoachingScheduleStudentInterface): MapCoachingScheduleStudent{
    return new MapCoachingScheduleStudent(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MapCoachingScheduleStudent',
      plural: 'MapCoachingScheduleStudents',
      path: 'MapCoachingScheduleStudents',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "comment": {
          name: 'comment',
          type: 'string'
        },
        "reason": {
          name: 'reason',
          type: 'string'
        },
        "completionDate": {
          name: 'completionDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "coaching_schedule_id": {
          name: 'coaching_schedule_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        coachingSchedule: {
          name: 'coachingSchedule',
          type: 'CoachingSchedule',
          model: 'CoachingSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'coaching_schedule_id',
          keyTo: 'id'
        },
      }
    }
  }
}
