/* tslint:disable */
import {
  Account,
  Order
} from '../index';

declare var Object: any;
export interface TransactionInterface {
  "paymentMethod": string;
  "utr"?: string;
  "date": Date;
  "status": number;
  "gatewayPaymentId"?: string;
  "gatewayOrderId": string;
  "gatewaySignature"?: string;
  "remarks"?: string;
  "payMethodName"?: string;
  "gatewayName"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "orderIds"?: Array<any>;
  account?: Account;
  orders?: Order[];
}

export class Transaction implements TransactionInterface {
  "paymentMethod": string;
  "utr": string;
  "date": Date;
  "status": number;
  "gatewayPaymentId": string;
  "gatewayOrderId": string;
  "gatewaySignature": string;
  "remarks": string;
  "payMethodName": string;
  "gatewayName": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "orderIds": Array<any>;
  account: Account;
  orders: Order[];
  constructor(data?: TransactionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Transaction`.
   */
  public static getModelName() {
    return "Transaction";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Transaction for dynamic purposes.
  **/
  public static factory(data: TransactionInterface): Transaction{
    return new Transaction(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Transaction',
      plural: 'Transactions',
      path: 'Transactions',
      idName: 'id',
      properties: {
        "paymentMethod": {
          name: 'paymentMethod',
          type: 'string'
        },
        "utr": {
          name: 'utr',
          type: 'string'
        },
        "date": {
          name: 'date',
          type: 'Date',
          default: new Date(0)
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "gatewayPaymentId": {
          name: 'gatewayPaymentId',
          type: 'string'
        },
        "gatewayOrderId": {
          name: 'gatewayOrderId',
          type: 'string'
        },
        "gatewaySignature": {
          name: 'gatewaySignature',
          type: 'string'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "payMethodName": {
          name: 'payMethodName',
          type: 'string'
        },
        "gatewayName": {
          name: 'gatewayName',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "orderIds": {
          name: 'orderIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        orders: {
          name: 'orders',
          type: 'Order[]',
          model: 'Order',
          relationType: 'referencesMany',
                  keyFrom: 'orderIds',
          keyTo: 'id'
        },
      }
    }
  }
}
