/* tslint:disable */
import {
  Account,
  Order
} from '../index';

declare var Object: any;
export interface AccountDetailInterface {
  "gender"?: number;
  "dateOfJoining"?: Date;
  "dateOfBirth"?: Date;
  "bloodGroup"?: number;
  "dominantSide"?: number;
  "medicalCondition"?: string;
  "qualifications"?: string;
  "grade"?: number;
  "profile"?: string;
  "experience"?: string;
  "level"?: number;
  "coachType"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id"?: number;
  account?: Account;
  orders?: Order[];
}

export class AccountDetail implements AccountDetailInterface {
  "gender": number;
  "dateOfJoining": Date;
  "dateOfBirth": Date;
  "bloodGroup": number;
  "dominantSide": number;
  "medicalCondition": string;
  "qualifications": string;
  "grade": number;
  "profile": string;
  "experience": string;
  "level": number;
  "coachType": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "account_id": number;
  account: Account;
  orders: Order[];
  constructor(data?: AccountDetailInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountDetail`.
   */
  public static getModelName() {
    return "AccountDetail";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountDetail for dynamic purposes.
  **/
  public static factory(data: AccountDetailInterface): AccountDetail{
    return new AccountDetail(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountDetail',
      plural: 'AccountDetails',
      path: 'AccountDetails',
      idName: 'id',
      properties: {
        "gender": {
          name: 'gender',
          type: 'number'
        },
        "dateOfJoining": {
          name: 'dateOfJoining',
          type: 'Date'
        },
        "dateOfBirth": {
          name: 'dateOfBirth',
          type: 'Date'
        },
        "bloodGroup": {
          name: 'bloodGroup',
          type: 'number'
        },
        "dominantSide": {
          name: 'dominantSide',
          type: 'number'
        },
        "medicalCondition": {
          name: 'medicalCondition',
          type: 'string'
        },
        "qualifications": {
          name: 'qualifications',
          type: 'string'
        },
        "grade": {
          name: 'grade',
          type: 'number'
        },
        "profile": {
          name: 'profile',
          type: 'string'
        },
        "experience": {
          name: 'experience',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'number'
        },
        "coachType": {
          name: 'coachType',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        orders: {
          name: 'orders',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'account_id'
        },
      }
    }
  }
}
