/* tslint:disable */
import {
  Certification
} from '../index';

declare var Object: any;
export interface CertificationAttendeeInterface {
  "attendeesId": number;
  "attendeesType": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "certificationId"?: number;
  certification?: Certification;
}

export class CertificationAttendee implements CertificationAttendeeInterface {
  "attendeesId": number;
  "attendeesType": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "certificationId": number;
  certification: Certification;
  constructor(data?: CertificationAttendeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CertificationAttendee`.
   */
  public static getModelName() {
    return "CertificationAttendee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CertificationAttendee for dynamic purposes.
  **/
  public static factory(data: CertificationAttendeeInterface): CertificationAttendee{
    return new CertificationAttendee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CertificationAttendee',
      plural: 'CertificationAttendees',
      path: 'CertificationAttendees',
      idName: 'id',
      properties: {
        "attendeesId": {
          name: 'attendeesId',
          type: 'number'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "certificationId": {
          name: 'certificationId',
          type: 'number'
        },
      },
      relations: {
        certification: {
          name: 'certification',
          type: 'Certification',
          model: 'Certification',
          relationType: 'belongsTo',
                  keyFrom: 'certification_id',
          keyTo: 'id'
        },
      }
    }
  }
}
