/* tslint:disable */
import {
  Account,
  VideoSession
} from '../index';

declare var Object: any;
export interface VideoSessionAttendeesInterface {
  "attendeesType"?: number;
  "attendeeIds": any;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountIds"?: Array<any>;
  "video_session_id"?: number;
  account?: Account[];
  videoSession?: VideoSession;
}

export class VideoSessionAttendees implements VideoSessionAttendeesInterface {
  "attendeesType": number;
  "attendeeIds": any;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountIds": Array<any>;
  "video_session_id": number;
  account: Account[];
  videoSession: VideoSession;
  constructor(data?: VideoSessionAttendeesInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VideoSessionAttendees`.
   */
  public static getModelName() {
    return "VideoSessionAttendees";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VideoSessionAttendees for dynamic purposes.
  **/
  public static factory(data: VideoSessionAttendeesInterface): VideoSessionAttendees{
    return new VideoSessionAttendees(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VideoSessionAttendees',
      plural: 'VideoSessionAttendeess',
      path: 'VideoSessionAttendeess',
      idName: 'id',
      properties: {
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "attendeeIds": {
          name: 'attendeeIds',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "accountIds": {
          name: 'accountIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "video_session_id": {
          name: 'video_session_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account[]',
          model: 'Account',
          relationType: 'referencesMany',
                  keyFrom: 'accountIds',
          keyTo: 'id'
        },
        videoSession: {
          name: 'videoSession',
          type: 'VideoSession',
          model: 'VideoSession',
          relationType: 'belongsTo',
                  keyFrom: 'video_session_id',
          keyTo: 'id'
        },
      }
    }
  }
}
