import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;
@Component({
  selector: 'app-single-file-dropzone',
  templateUrl: 'singleDropzone.component.html'
})
export class SingleDropZoneComponent implements OnInit {
  @Output()
  selectedFileEvent: EventEmitter<File> = new EventEmitter<File>(); 
  constructor() {}
  ngOnInit() {
    const that = this;
    let currentSingleFile:any;
    new Dropzone(document.getElementById('dropzone-single'), {
      url: '/',
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName('dz-preview-single')[0],
      previewTemplate: document.getElementsByClassName('dz-preview-single')[0].innerHTML,
      maxFiles: 1,
      acceptedFiles: 'image/*,video/*',
      init() {       
        this.on('addedfile', function(file:any) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
          that.passFile(file);
        });
      }
    });    
    document.getElementsByClassName('dz-preview-single')[0].innerHTML = '';
  }

  passFile(file:any) {   
    this.selectedFileEvent.emit(file);
  }
}
