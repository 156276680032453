/* tslint:disable */
import {
  CoachingSchedule,
  Account
} from '../index';

declare var Object: any;
export interface CoachingScheduleAttendeeInterface {
  "attendeesId"?: number;
  "attendeesType"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  coachingSchedule?: CoachingSchedule;
  account?: Account;
}

export class CoachingScheduleAttendee implements CoachingScheduleAttendeeInterface {
  "attendeesId": number;
  "attendeesType": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  coachingSchedule: CoachingSchedule;
  account: Account;
  constructor(data?: CoachingScheduleAttendeeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CoachingScheduleAttendee`.
   */
  public static getModelName() {
    return "CoachingScheduleAttendee";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CoachingScheduleAttendee for dynamic purposes.
  **/
  public static factory(data: CoachingScheduleAttendeeInterface): CoachingScheduleAttendee{
    return new CoachingScheduleAttendee(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CoachingScheduleAttendee',
      plural: 'CoachingScheduleAttendee',
      path: 'CoachingScheduleAttendee',
      idName: 'id',
      properties: {
        "attendeesId": {
          name: 'attendeesId',
          type: 'number'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        coachingSchedule: {
          name: 'coachingSchedule',
          type: 'CoachingSchedule',
          model: 'CoachingSchedule',
          relationType: 'belongsTo',
                  keyFrom: 'coaching_schedule_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
