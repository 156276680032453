/* tslint:disable */

declare var Object: any;
export interface SportsPsychologyTrackingInterface {
  "studentId": number;
  "psychologyTrackingDate": string;
  "psychologyTrackingMonth": string;
  "psychologyTrackingWeek": number;
  "skillLevel": string;
  "session": string;
  "personResponsible": string;
  "psychologicalComponent": string;
  "personalityComponent": string;
  "observation": string;
  "recommendation": string;
  "planOfAction": string;
  "followUp_Review_Feedback": string;
  "createdBy": number;
  "modifiedBy"?: number;
  "id"?: string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
}

export class SportsPsychologyTracking implements SportsPsychologyTrackingInterface {
  "studentId": number;
  "psychologyTrackingDate": string;
  "psychologyTrackingMonth": string;
  "psychologyTrackingWeek": number;
  "skillLevel": string;
  "session": string;
  "personResponsible": string;
  "psychologicalComponent": string;
  "personalityComponent": string;
  "observation": string;
  "recommendation": string;
  "planOfAction": string;
  "followUp_Review_Feedback": string;
  "createdBy": number;
  "modifiedBy": number;
  "id": string;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  constructor(data?: SportsPsychologyTrackingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SportsPsychologyTracking`.
   */
  public static getModelName() {
    return "SportsPsychologyTracking";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SportsPsychologyTracking for dynamic purposes.
  **/
  public static factory(data: SportsPsychologyTrackingInterface): SportsPsychologyTracking{
    return new SportsPsychologyTracking(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SportsPsychologyTracking',
      plural: 'SportsPsychologyTrackings',
      path: 'SportsPsychologyTrackings',
      idName: 'id',
      properties: {
        "studentId": {
          name: 'studentId',
          type: 'number'
        },
        "psychologyTrackingDate": {
          name: 'psychologyTrackingDate',
          type: 'string'
        },
        "psychologyTrackingMonth": {
          name: 'psychologyTrackingMonth',
          type: 'string'
        },
        "psychologyTrackingWeek": {
          name: 'psychologyTrackingWeek',
          type: 'number'
        },
        "skillLevel": {
          name: 'skillLevel',
          type: 'string'
        },
        "session": {
          name: 'session',
          type: 'string'
        },
        "personResponsible": {
          name: 'personResponsible',
          type: 'string'
        },
        "psychologicalComponent": {
          name: 'psychologicalComponent',
          type: 'string'
        },
        "personalityComponent": {
          name: 'personalityComponent',
          type: 'string'
        },
        "observation": {
          name: 'observation',
          type: 'string'
        },
        "recommendation": {
          name: 'recommendation',
          type: 'string'
        },
        "planOfAction": {
          name: 'planOfAction',
          type: 'string'
        },
        "followUp_Review_Feedback": {
          name: 'followUp_Review_Feedback',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "modifiedBy": {
          name: 'modifiedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
