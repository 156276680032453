/* tslint:disable */
import {
  AppUser
} from '../index';

declare var Object: any;
export interface AppRoleInterface {
  "id"?: number;
  "name": string;
  "type": string;
  "description"?: string;
  "scope"?: Array<any>;
  "createdDate": Date;
  "isActive": boolean;
  "lastModifiedDate": Date;
  appUsers?: AppUser[];
}

export class AppRole implements AppRoleInterface {
  "id": number;
  "name": string;
  "type": string;
  "description": string;
  "scope": Array<any>;
  "createdDate": Date;
  "isActive": boolean;
  "lastModifiedDate": Date;
  appUsers: AppUser[];
  constructor(data?: AppRoleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AppRole`.
   */
  public static getModelName() {
    return "AppRole";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AppRole for dynamic purposes.
  **/
  public static factory(data: AppRoleInterface): AppRole{
    return new AppRole(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AppRole',
      plural: 'AppRoles',
      path: 'AppRoles',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "scope": {
          name: 'scope',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date',
          default: new Date(0)
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        appUsers: {
          name: 'appUsers',
          type: 'AppUser[]',
          model: 'AppUser',
          relationType: 'hasMany',
          modelThrough: 'AppUserRole',
          keyThrough: 'app_user_id',
          keyFrom: 'id',
          keyTo: 'app_role_id'
        },
      }
    }
  }
}
