/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dashboard } from '../../models/Dashboard';


/**
 * Api services for the `Dashboard` model.
 */
@Injectable()
export class DashboardApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  models, auth, errorHandler);
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id Dashboard id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get age wise data.
   *
   * @param {object} data Request data.
   *
   * This method does not accept any data. Supply an empty object.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getAgeWiseData(customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getAgeWiseData";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get De Registered Students data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getDeRegisteredStudentsData(keyword: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getDeRegisteredStudentsData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get in out report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{string}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `fromDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getInOutReportData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, fromDate: any = {}, toDate: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getInOutReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        fromDate: fromDate,
        toDate: toDate
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get session attended report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getSessionAttendedReportData(keyword: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getSessionAttendedReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get payment tracking data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{string}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `fromMonth` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toMonth` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getPaymentTrackingData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, fromMonth: any = {}, toMonth: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getPaymentTrackingData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        fromMonth: fromMonth,
        toMonth: toMonth,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get overdue fees report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{string}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `fromDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getOverDueFeesReportData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, fromDate: any = {}, toDate: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getOverDueFeesReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        fromDate: fromDate,
        toDate: toDate
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get payment received report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{any}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{any}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `PaymentMode` – `{string}` - 
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getPaymentReceivedReportData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, PaymentMode: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getPaymentReceivedReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        PaymentMode: PaymentMode,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get cheque tracking report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{string}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getChequeTrackingReportData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getChequeTrackingReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get sales order report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `fromMonth` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toMonth` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getSalesOrderReportData(keyword: any = {}, centerIds: any = {}, fromMonth: any = {}, toMonth: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getSalesOrderReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        fromMonth: fromMonth,
        toMonth: toMonth,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get equated montly income report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{string}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `fromDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `paymentStatus` – `{string}` - 
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getEquatedMontlyIncomeReportData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, fromDate: any = {}, toDate: any = {}, paymentStatus: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getEquatedMontlyIncomeReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        fromDate: fromDate,
        toDate: toDate,
        paymentStatus: paymentStatus,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get student tag report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getStudentTagReportData(keyword: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getStudentTagReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get daily activities report data.
   *
   * @param {object} data Request data.
   *
   *  - `accountId` – `{number}` - 
   *
   *  - `date` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getDailyActivitiesReportData(accountId: any = {}, date: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getDailyActivitiesReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        accountId: accountId,
        date: date
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get ranking tracker report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `fromDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toDate` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getRankingTrackerReportData(keyword: any = {}, fromDate: any = {}, toDate: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getRankingTrackerReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        fromDate: fromDate,
        toDate: toDate,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get assessment report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `batchIds` – `{string}` - If multiple batch is selected then pass data like 1,2,3
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getAssessmentReportData(keyword: any = {}, centerIds: any = {}, batchIds: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getAssessmentReportData";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        batchIds: batchIds,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get competition tracker report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `studentIds` – `{string}` - 
   *
   *  - `startDate` – `{string}` - 
   *
   *  - `endDate` – `{string}` - 
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getCompetitionTrackerReport(keyword: any = {}, studentIds: any = {}, startDate: any = {}, endDate: any = {}, page: any, limit: any, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getCompetitionTrackerReport";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        studentIds: studentIds,
        startDate: startDate,
        endDate: endDate,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get User Login Staus report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getUserLoginStatusReport(keyword: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getUserLoginStatusReport";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * API to get total revenue report data.
   *
   * @param {object} data Request data.
   *
   *  - `keyword` – `{string}` - 
   *
   *  - `centerIds` – `{string}` - If multiple center is selected then pass data like 1,2,3
   *
   *  - `fromMonth` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `toMonth` – `{string}` - Need to pass date in YYYY-MM-DD format
   *
   *  - `page` – `{number}` - 
   *
   *  - `limit` – `{number}` - 
   *
   *  - `req` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Dashboard` object.)
   * </em>
   */
  public getTotalRevenueReport(keyword: any = {}, centerIds: any = {}, fromMonth: any = {}, toMonth: any = {}, page: any = {}, limit: any = {}, req: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Dashboards/getTotalRevenueReport";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        keyword: keyword,
        centerIds: centerIds,
        fromMonth: fromMonth,
        toMonth: toMonth,
        page: page,
        limit: limit
      }
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Dashboard`.
   */
  public getModelName() {
    return "Dashboard";
  }
}
