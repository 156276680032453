/* tslint:disable */
import {
  Account,
  CertificationAttendee,
  CertificationResult,
  MapCertificationStudent
} from '../index';

declare var Object: any;
export interface CertificationInterface {
  "level"?: number;
  "name": string;
  "sports"?: string;
  "startDate": Date;
  "endDate": Date;
  "type"?: number;
  "teamName"?: string;
  "webURL"?: string;
  "description"?: string;
  "attendeesType"?: number;
  "status"?: number;
  "venue"?: string;
  "city"?: string;
  "state"?: string;
  "country"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account?: Account;
  certificationAttendee?: CertificationAttendee[];
  certificationResult?: CertificationResult[];
  mapCertificationStudent?: MapCertificationStudent[];
}

export class Certification implements CertificationInterface {
  "level": number;
  "name": string;
  "sports": string;
  "startDate": Date;
  "endDate": Date;
  "type": number;
  "teamName": string;
  "webURL": string;
  "description": string;
  "attendeesType": number;
  "status": number;
  "venue": string;
  "city": string;
  "state": string;
  "country": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account: Account;
  certificationAttendee: CertificationAttendee[];
  certificationResult: CertificationResult[];
  mapCertificationStudent: MapCertificationStudent[];
  constructor(data?: CertificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Certification`.
   */
  public static getModelName() {
    return "Certification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Certification for dynamic purposes.
  **/
  public static factory(data: CertificationInterface): Certification{
    return new Certification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Certification',
      plural: 'Certifications',
      path: 'Certifications',
      idName: 'id',
      properties: {
        "level": {
          name: 'level',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "sports": {
          name: 'sports',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "teamName": {
          name: 'teamName',
          type: 'string'
        },
        "webURL": {
          name: 'webURL',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "venue": {
          name: 'venue',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        certificationAttendee: {
          name: 'certificationAttendee',
          type: 'CertificationAttendee[]',
          model: 'CertificationAttendee',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'certificationId'
        },
        certificationResult: {
          name: 'certificationResult',
          type: 'CertificationResult[]',
          model: 'CertificationResult',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'certificationId'
        },
        mapCertificationStudent: {
          name: 'mapCertificationStudent',
          type: 'MapCertificationStudent[]',
          model: 'MapCertificationStudent',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'certificationId'
        },
      }
    }
  }
}
