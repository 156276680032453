/* tslint:disable */
import {
  Account
} from '../index';

declare var Object: any;
export interface GuardianInterface {
  "relation": string;
  "occupation"?: string;
  "name": string;
  "mobileNumber"?: string;
  "email"?: string;
  "countryCode"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account?: Account;
}

export class Guardian implements GuardianInterface {
  "relation": string;
  "occupation": string;
  "name": string;
  "mobileNumber": string;
  "email": string;
  "countryCode": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  account: Account;
  constructor(data?: GuardianInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Guardian`.
   */
  public static getModelName() {
    return "Guardian";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Guardian for dynamic purposes.
  **/
  public static factory(data: GuardianInterface): Guardian{
    return new Guardian(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Guardian',
      plural: 'Guardians',
      path: 'Guardians',
      idName: 'id',
      properties: {
        "relation": {
          name: 'relation',
          type: 'string'
        },
        "occupation": {
          name: 'occupation',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "mobileNumber": {
          name: 'mobileNumber',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "countryCode": {
          name: 'countryCode',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
