/* tslint:disable */
import {
  Account,
  Batch,
  Course,
  Infrastructure,
  BatchTiming,
  FeePlan
} from '../index';

declare var Object: any;
export interface ActivityRequestInterface {
  "requestStatus": number;
  "dateOfJoining": Date;
  "grade"?: number;
  "isOptedTransport"?: boolean;
  "rejectReason"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountBatchtimingIds"?: Array<any>;
  "feeplan_id"?: number;
  account?: Account;
  batch?: Batch;
  course?: Course;
  infrastructure?: Infrastructure;
  accountBatchtimings?: BatchTiming[];
  feePlan?: FeePlan;
}

export class ActivityRequest implements ActivityRequestInterface {
  "requestStatus": number;
  "dateOfJoining": Date;
  "grade": number;
  "isOptedTransport": boolean;
  "rejectReason": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "accountBatchtimingIds": Array<any>;
  "feeplan_id": number;
  account: Account;
  batch: Batch;
  course: Course;
  infrastructure: Infrastructure;
  accountBatchtimings: BatchTiming[];
  feePlan: FeePlan;
  constructor(data?: ActivityRequestInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ActivityRequest`.
   */
  public static getModelName() {
    return "ActivityRequest";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ActivityRequest for dynamic purposes.
  **/
  public static factory(data: ActivityRequestInterface): ActivityRequest{
    return new ActivityRequest(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ActivityRequest',
      plural: 'ActivityRequests',
      path: 'ActivityRequests',
      idName: 'id',
      properties: {
        "requestStatus": {
          name: 'requestStatus',
          type: 'number',
          default: 0
        },
        "dateOfJoining": {
          name: 'dateOfJoining',
          type: 'Date'
        },
        "grade": {
          name: 'grade',
          type: 'number'
        },
        "isOptedTransport": {
          name: 'isOptedTransport',
          type: 'boolean'
        },
        "rejectReason": {
          name: 'rejectReason',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "accountBatchtimingIds": {
          name: 'accountBatchtimingIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "feeplan_id": {
          name: 'feeplan_id',
          type: 'number'
        },
      },
      relations: {
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        batch: {
          name: 'batch',
          type: 'Batch',
          model: 'Batch',
          relationType: 'belongsTo',
                  keyFrom: 'batch_id',
          keyTo: 'id'
        },
        course: {
          name: 'course',
          type: 'Course',
          model: 'Course',
          relationType: 'belongsTo',
                  keyFrom: 'course_id',
          keyTo: 'id'
        },
        infrastructure: {
          name: 'infrastructure',
          type: 'Infrastructure',
          model: 'Infrastructure',
          relationType: 'belongsTo',
                  keyFrom: 'infrastructure_id',
          keyTo: 'id'
        },
        accountBatchtimings: {
          name: 'accountBatchtimings',
          type: 'BatchTiming[]',
          model: 'BatchTiming',
          relationType: 'referencesMany',
                  keyFrom: 'accountBatchtimingIds',
          keyTo: 'id'
        },
        feePlan: {
          name: 'feePlan',
          type: 'FeePlan',
          model: 'FeePlan',
          relationType: 'belongsTo',
                  keyFrom: 'feeplan_id',
          keyTo: 'id'
        },
      }
    }
  }
}
