import { Component, OnInit, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AccountApi, AppUserApi, BellNotificationApi, OrganizationApi, AppFileApi, BrandingPreferenceApi } from 'src/app/shared/sdk';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
// import { WebsocketService } from 'src/app/services/websocket.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  // providers: [WebsocketService]
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen = true;
  public userName = '';
  public userProfilePic: any;

  public currentUserAccounts: any;
  public enableAccountSwitcher = false;
  public currentUserType: any;

  public bellNotifications: any;
  public totalUnreadNotifications: any = 0;
  public bellNotificationIds: any = [];
  public orgName: any;
  public timerId: any;
  // Notification Array
  public userNotificationArray: any = [];
  public paymentNotificationArray: any = [];
  public scheduleNotificationArray: any = [];
  public commonNotificationArray: any = [];

  public bellUserNotificationIds: any = [];
  public bellPaymentNotificationIds: any = [];
  public bellScheduleNotificationIds: any = [];
  public bellCommonNotificationIds: any = [];

  @ViewChild('viewAllBellNotificationsModal') bellNotificationsRef: TemplateRef<any>;
  formModal: BsModalRef;
  public brandLogo: any;
  bandLogoUrl = environment.apiUrl;
  modalOptions =
    {
      animated: true,
      keyboard: true,
      class: 'modal-dialog-centered modal-md',
      backdrop: true,
      ignoreBackdropClick: true
    };

  constructor(
    location: Location,
    private element: ElementRef,
    private modalService: BsModalService,
    private router: Router,
    private appUserApi: AppUserApi,
    public authService: AuthService,
    public accountApi: AccountApi,
    public organizationApi: OrganizationApi,
    public bellNotificationApi: BellNotificationApi,
    public brandingPreferenceApi: BrandingPreferenceApi,
    private datepipe: DatePipe,
    private http: HttpClient,
    private appFileApi: AppFileApi,
    public uts:UtilityService,

    // private WebsocketService: WebsocketService
  ) {
    const cuurentaccountId = this.authService.getCurrentAccountId();

    this.accountApi
      .findById(cuurentaccountId, { include: ['profilePicture'] })
      .toPromise()
      .then((response: any) => {
        this.userName = response.name;
       this.userProfilePic = response.profilePicture ? response.profilePicture.location : 'assets/img/theme/team-6.png';
        if (response.profilePicture) {         
          this.appFileApi.readFileFromContainer(encodeURIComponent(response.profilePicture.containerName),
            response.profilePicture.name).toPromise().then((res: any) => {
              if (res && res.success) {
                this.userProfilePic = res.data;
              }
            })
        }
      })
      .then(() => {
        // this.appUserApi.fetchUserDetails().toPromise().then((response)=>{
        //   this.userName = response.accounts[0].name;
        // }).then(()=>{
        this.location = location;
        this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationStart) {
            // Show loading
          }
          if (event instanceof NavigationEnd) {
            // Hide loading indicator

            if (window.innerWidth < 1200) {
              document.body.classList.remove('g-sidenav-pinned');
              document.body.classList.add('g-sidenav-hidden');
              this.sidenavOpen = false;
            }
          }

          if (event instanceof NavigationError) {
            // Hide loading indicator

            // Present error to user
          }
        });
      });
    this.organizationApi.find().toPromise().then((orgData: any) => {
      this.orgName = orgData.length > 0 ? orgData[0].name : '';
    });




    // WebsocketService.messages.subscribe(msg => {
    //   // this.received.push(msg);

    // });

  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.currentUserType = this.authService.getCurrentAccountType();

    if (this.authService.isAuthenticated()) {
      this.authService.fetchUserDetails().then(() => {
        this.currentUserAccounts = this.authService.getCurrentUser().accounts;
      });
      if (this.currentUserAccounts !== undefined) {
        if (this.currentUserAccounts?.length > 1) {
          this.enableAccountSwitcher = true;
        } else {
          this.enableAccountSwitcher = false;
        }
      }
    }

    this.getBellNotifications(false);

    this.timerId = setInterval(() => {
      this.getBellNotifications(false);
    }, 30000);
    this.fetchLogo();

    // BellNotificationTypeCount

  }
  fetchLogo() {
    // this.http.get(this.bandLogoUrl + '/branding-preference/fetch-logo').subscribe((showResult: any) => {
 
    //   this.brandLogo = showResult.data;
    // })
    this.brandingPreferenceApi.find({ where: { isActive: 1 }, include: ['organizationLogoFile'] }).toPromise().then((response: any) => { 
      this.brandLogo = response;          
    })
  }

  openFormModal(modalForm: TemplateRef<any>) {
    this.formModal = this.modalService.show(modalForm, this.modalOptions);

    this.getBellNotifications(true);
  }

  navigateBellNotifications(notificationId: any, notificationType: any, isViewAll: any, accountUserId: any) {

    if (notificationId != '' && notificationId > 0) {
      this.bellNotificationApi.patchAttributes(notificationId, { notificationStatus: 1 }).toPromise().then((resp) => {

        if (isViewAll)
          this.getBellNotifications(true);
        else {
          this.getBellNotifications(false);
        }
      });
    }

    if (notificationType === 'resourceRequest') {
      this.router.navigate(['/request']);
      this.formModal?.hide();
    }
    else if (notificationType === 'newregistration') {
      this.router.navigate(['/student']);
      this.formModal?.hide();
    }
    else if (notificationType === 'paymentreceived') {
      this.router.navigate(['/student'], { queryParams: { accId: 158 } });
      this.formModal?.hide();
    }
    else if (notificationType === 'staffratingacademy' || notificationType === 'staffratingparents') {
      this.router.navigate(['/coach']);
      this.formModal?.hide();
    } else if (notificationType === 'newactivityrequest') {
      this.router.navigate(['student/activity-requests'], { queryParams: { accountId: accountUserId, requstId: notificationId } });
      this.formModal?.hide();
    }
  }

  getBellNotifications(viewAll: boolean) {
    // this.totalUnreadNotifications = 0;
    // const whereCondition: any = {};
    // if (viewAll === false) {     // whereCondition.notificationStatus = 1;
    //   whereCondition.notificationStatus = 0;
    // }

    // this.bellNotificationApi.find({ where: whereCondition, order: 'created_date DESC' }).toPromise().then((resp: any) => {
    //   if (resp.length > 0) {
    //     resp.forEach((value: any, key: any) => {
    //       this.bellNotificationIds.push(value.id);
    //       value.createdDate = this.datepipe.transform(value.createdDate, 'dd MMM yyyy HH:mm:ss');
    //       if (value.notificationStatus == 0) {
    //         this.totalUnreadNotifications++;
    //       }
    //     });
    //     this.bellNotifications = resp;
    //   }

    this.bellNotificationApi.BellNotificationTypeCount().toPromise().then((resp: any) => {
      this.userNotificationArray = [];
      this.paymentNotificationArray = [];
      this.scheduleNotificationArray = [];
      this.commonNotificationArray = [];

      this.bellUserNotificationIds = [];
      this.bellPaymentNotificationIds = [];
      this.bellScheduleNotificationIds = [];
      this.bellCommonNotificationIds = [];

      if (resp.length > 0) {
 
        resp.forEach((val: any, key: any) => {
          if (val.notification_type === 'newregistration'
            || val.notification_type === 'newactivityrequest'
            || val.notification_type === 'parentfeedback') {
            // concat array
            if (val.notification_list && val.notification_list.length > 0) {
              this.userNotificationArray = [...this.userNotificationArray, ...JSON.parse(val.notification_list)];
              this.userNotificationArray=this.uts.sortObjectByKey(this.userNotificationArray,'created_date')
            }

          } else if (val.notification_type === 'paymentreceived') {
            if (val.notification_list && val.notification_list.length > 0) {
              this.paymentNotificationArray = [...this.paymentNotificationArray, ...JSON.parse(val.notification_list)];
              this.paymentNotificationArray=this.uts.sortObjectByKey(this.paymentNotificationArray,'created_date')

            }
          } else if (val.notification_type === 'CoachAttendance' || val.notification_type === 'resourceRequest') {
            if (val.notification_list && val.notification_list.length > 0) {
              this.scheduleNotificationArray = [...this.scheduleNotificationArray, ...JSON.parse(val.notification_list)];
              this.scheduleNotificationArray=this.uts.sortObjectByKey(this.scheduleNotificationArray,'created_date')

            }
          } else {
            if (val.notification_list && val.notification_list.length > 0) {
              this.commonNotificationArray = [...this.commonNotificationArray, ...JSON.parse(val.notification_list)];
              this.commonNotificationArray=this.uts.sortObjectByKey(this.commonNotificationArray,'created_date')

            }
          }
        });
      }
      // push all notification ids in array

      for (const notificationArray of this.userNotificationArray) {
        this.bellUserNotificationIds.push(notificationArray.id);
      }

      for (const notificationArray of this.paymentNotificationArray) {
        this.bellPaymentNotificationIds.push(notificationArray.id);
      }

      for (const notificationArray of this.scheduleNotificationArray) {
        this.bellScheduleNotificationIds.push(notificationArray.id);
      }

      for (const notificationArray of this.commonNotificationArray) {
        this.bellCommonNotificationIds.push(notificationArray.id);
      }

    }).catch((error: any) => {


      localStorage.clear();
      this.appUserlogout();
      // clearTimeout(this.timerId);
    })
  }

  deleteBellNotification(notificationId: any, notificationIds: any) {


    if (notificationId != '') {
      if (notificationId === 'all' && notificationIds !== 0) {

        if (this[notificationIds].length > 0) {
          this[notificationIds].forEach((val: any, key: any) => {
            this.bellNotificationApi.patchAttributes(val, { isActive: 0 }).toPromise().then((resp: any) => {

              this.getBellNotifications(false);
            });
          });
        }
      }
      else {
        this.bellNotificationApi.patchAttributes(notificationId, { isActive: 0 }).toPromise().then((resp) => {

          this.getBellNotifications(false);
        });
      }

    }
  }

  getTimeDiff(date: any) {
    const localDate = moment.utc(date).local();
    const diff = localDate.fromNow();
    return diff;
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    this.listTitles.map((listTitles) => {
      if (listTitles.path === titlee) {
        return listTitles.title;
      }
    });
    // for (let item = 0; item < this.listTitles.length; item++) {
    //   if (this.listTitles[item].path === titlee) {
    //     return this.listTitles[item].title;
    //   }
    // }
    return 'Dashboard';
  }

  openSearch() {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(() => {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
      this.sidenavOpen = false;
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
      this.sidenavOpen = true;
    }
  }

  appUserlogout() {
    clearTimeout(this.timerId);
 
    this.authService.logout();
  }
}
