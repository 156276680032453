/* tslint:disable */

declare var Object: any;
export interface SessionPlanInterface {
  "title": string;
  "sportId": number;
  "sportName": string;
  "otherSport"?: string;
  "level"?: string;
  "week": number;
  "plandate": string;
  "venueId": number;
  "venueName": string;
  "duration": string;
  "noOfAthletes": number;
  "ability": string;
  "equipment": string;
  "comments": string;
  "reflection": string;
  "sections": string;
  "createdBy": number;
  "createdByName": string;
  "updatedBy"?: number;
  "updatedByName"?: string;
  "createdDate"?: Date;
  "updatedDate"?: Date;
  "id"?: string;
  "isActive": boolean;
  "lastModifiedDate": Date;
}

export class SessionPlan implements SessionPlanInterface {
  "title": string;
  "sportId": number;
  "sportName": string;
  "otherSport": string;
  "level": string;
  "week": number;
  "plandate": string;
  "venueId": number;
  "venueName": string;
  "duration": string;
  "noOfAthletes": number;
  "ability": string;
  "equipment": string;
  "comments": string;
  "reflection": string;
  "sections": string;
  "createdBy": number;
  "createdByName": string;
  "updatedBy": number;
  "updatedByName": string;
  "createdDate": Date;
  "updatedDate": Date;
  "id": string;
  "isActive": boolean;
  "lastModifiedDate": Date;
  constructor(data?: SessionPlanInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `SessionPlan`.
   */
  public static getModelName() {
    return "SessionPlan";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of SessionPlan for dynamic purposes.
  **/
  public static factory(data: SessionPlanInterface): SessionPlan{
    return new SessionPlan(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'SessionPlan',
      plural: 'SessionPlans',
      path: 'SessionPlans',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "sportId": {
          name: 'sportId',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "otherSport": {
          name: 'otherSport',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "week": {
          name: 'week',
          type: 'number'
        },
        "plandate": {
          name: 'plandate',
          type: 'string'
        },
        "venueId": {
          name: 'venueId',
          type: 'number'
        },
        "venueName": {
          name: 'venueName',
          type: 'string'
        },
        "duration": {
          name: 'duration',
          type: 'string'
        },
        "noOfAthletes": {
          name: 'noOfAthletes',
          type: 'number'
        },
        "ability": {
          name: 'ability',
          type: 'string'
        },
        "equipment": {
          name: 'equipment',
          type: 'string'
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "reflection": {
          name: 'reflection',
          type: 'string'
        },
        "sections": {
          name: 'sections',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "createdByName": {
          name: 'createdByName',
          type: 'string'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "updatedByName": {
          name: 'updatedByName',
          type: 'string'
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "updatedDate": {
          name: 'updatedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
