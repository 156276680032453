/* tslint:disable */

declare var Object: any;
export interface StrengthAndConditioningStudentScoreInterface {
  "sncId": string;
  "sportId": number;
  "sportName": string;
  "otherSport"?: string;
  "level": string;
  "venueId": number;
  "venueName": string;
  "sessionNumber": number;
  "date": string;
  "fromTime": string;
  "toTime": string;
  "sessionObjective": string;
  "focusArea"?: string;
  "exercises"?: string;
  "comments"?: string;
  "reflection"?: string;
  "studentId": number;
  "studentName": string;
  "studentScores"?: string;
  "createdDate"?: Date;
  "updatedDate"?: Date;
  "id"?: string;
  "isActive": boolean;
  "lastModifiedDate": Date;
}

export class StrengthAndConditioningStudentScore implements StrengthAndConditioningStudentScoreInterface {
  "sncId": string;
  "sportId": number;
  "sportName": string;
  "otherSport": string;
  "level": string;
  "venueId": number;
  "venueName": string;
  "sessionNumber": number;
  "date": string;
  "fromTime": string;
  "toTime": string;
  "sessionObjective": string;
  "focusArea": string;
  "exercises": string;
  "comments": string;
  "reflection": string;
  "studentId": number;
  "studentName": string;
  "studentScores": string;
  "createdDate": Date;
  "updatedDate": Date;
  "id": string;
  "isActive": boolean;
  "lastModifiedDate": Date;
  constructor(data?: StrengthAndConditioningStudentScoreInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StrengthAndConditioningStudentScore`.
   */
  public static getModelName() {
    return "StrengthAndConditioningStudentScore";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StrengthAndConditioningStudentScore for dynamic purposes.
  **/
  public static factory(data: StrengthAndConditioningStudentScoreInterface): StrengthAndConditioningStudentScore{
    return new StrengthAndConditioningStudentScore(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StrengthAndConditioningStudentScore',
      plural: 'StrengthAndConditioningStudentScores',
      path: 'StrengthAndConditioningStudentScores',
      idName: 'id',
      properties: {
        "sncId": {
          name: 'sncId',
          type: 'string'
        },
        "sportId": {
          name: 'sportId',
          type: 'number'
        },
        "sportName": {
          name: 'sportName',
          type: 'string'
        },
        "otherSport": {
          name: 'otherSport',
          type: 'string'
        },
        "level": {
          name: 'level',
          type: 'string'
        },
        "venueId": {
          name: 'venueId',
          type: 'number'
        },
        "venueName": {
          name: 'venueName',
          type: 'string'
        },
        "sessionNumber": {
          name: 'sessionNumber',
          type: 'number'
        },
        "date": {
          name: 'date',
          type: 'string'
        },
        "fromTime": {
          name: 'fromTime',
          type: 'string'
        },
        "toTime": {
          name: 'toTime',
          type: 'string'
        },
        "sessionObjective": {
          name: 'sessionObjective',
          type: 'string'
        },
        "focusArea": {
          name: 'focusArea',
          type: 'string'
        },
        "exercises": {
          name: 'exercises',
          type: 'string'
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "reflection": {
          name: 'reflection',
          type: 'string'
        },
        "studentId": {
          name: 'studentId',
          type: 'number'
        },
        "studentName": {
          name: 'studentName',
          type: 'string'
        },
        "studentScores": {
          name: 'studentScores',
          type: 'string'
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "updatedDate": {
          name: 'updatedDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date',
          default: new Date(0)
        },
      },
      relations: {
      }
    }
  }
}
