/* tslint:disable */
import {
  State,
  Rating
} from '../index';

declare var Object: any;
export interface CountryInterface {
  "name": string;
  "shortName"?: string;
  "countryCode"?: string;
  "currency"?: string;
  "currencySymbol"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  states?: State[];
  rating?: Rating[];
}

export class Country implements CountryInterface {
  "name": string;
  "shortName": string;
  "countryCode": string;
  "currency": string;
  "currencySymbol": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  states: State[];
  rating: Rating[];
  constructor(data?: CountryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Country`.
   */
  public static getModelName() {
    return "Country";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Country for dynamic purposes.
  **/
  public static factory(data: CountryInterface): Country{
    return new Country(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Country',
      plural: 'Countries',
      path: 'Countries',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "shortName": {
          name: 'shortName',
          type: 'string'
        },
        "countryCode": {
          name: 'countryCode',
          type: 'string'
        },
        "currency": {
          name: 'currency',
          type: 'string'
        },
        "currencySymbol": {
          name: 'currencySymbol',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        states: {
          name: 'states',
          type: 'State[]',
          model: 'State',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'country_id'
        },
        rating: {
          name: 'rating',
          type: 'Rating[]',
          model: 'Rating',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'rating_id'
        },
      }
    }
  }
}
