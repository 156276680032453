import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private toastr: ToastrService) {}

  sortOrdersByDate = (arr: any = []) => {
    //sorting json object by date
    const sorter = (a: any, b: any) => {
      return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(); //sorting orders duedate
    };
    return arr.sort(sorter);
  };

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  toast(message: any, type: any) {
    if (type == 'Success') {
      this.toastr.success(
        '<span class="alert-icon" data-notify="icon"></span> <div class="alert-text"</div>' +
          ' <span class="alert-title" data-notify="title">' +
          '</span> <span data-notify="message">' +
          message +
          '</span></div>',
        '',
        {
          timeOut: 8000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: 'ngx-toastr alert alert-dismissible alert-danger alert-notify'
        }
      );
    }
    if (type == 'error') {
      this.toastr.error(
        '<span class="alert-icon" data-notify="icon"></span> <div class="alert-text"</div>' +
          ' <span class="alert-title" data-notify="title">' +
          '</span> <span data-notify="message">' +
          message +
          '</span></div>',
        '',
        {
          timeOut: 8000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: 'ngx-toastr alert alert-dismissible alert-danger alert-notify'
        }
      );
    }
    if (type == 'Warning') {
      this.toastr.warning(
        '<span class="alert-icon" data-notify="icon"></span> <div class="alert-text"</div>' +
          ' <span class="alert-title" data-notify="title">' +
          '</span> <span data-notify="message">' +
          message +
          '</span></div>',
        '',
        {
          timeOut: 8000,
          closeButton: true,
          enableHtml: true,
          tapToDismiss: false,
          titleClass: 'alert-title',
          positionClass: 'toast-top-center',
          toastClass: 'ngx-toastr alert alert-dismissible alert-danger alert-notify'
        }
      );
    }
  }
  //Comparer Function
  GetSortOrder(prop: any) {
    return function (a: any, b: any) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  sortObjectByKey = (arr: any = [], key: any) => {
    //sorting json object by key
    const sorter = (a: any, b: any) => {
      return new Date(b[key]).getTime() - new Date(a[key]).getTime(); //sorting orders by given key
    };
    return arr.sort(sorter);
  };
 

  // remove the duplicates
  // Function to remove duplicate objects from an array based on a specific property
  public removeDuplicates(array:any, property:any) {
    return array.filter((obj:any, index:any, self:any) => index === self.findIndex((o:any) => o[property] === obj[property]));
  }

  sortObjectByDateKey = (arr: any = [], key: any) => {
    const sorter = (a: any, b: any) => {
      const dateA = new Date(this.getDateFromString(a[key]));
      const dateB = new Date(this.getDateFromString(b[key]));
      return dateB.getTime() - dateA.getTime(); //desc order
    };
    return arr.sort(sorter);
  };
  
  getDateFromString = (dateStr: string) => {
    const [day, month, year] = dateStr.split('-');
    // Months in JavaScript are 0-indexed, so we need to subtract 1 from the month
    return `${year}-${parseInt(month, 10) - 1}-${day}`;
  };
}
