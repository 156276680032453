import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-warning-model',
  templateUrl: './warning-model.component.html',
  styleUrls: ['./warning-model.component.scss']
})
export class WarningModelComponent implements OnInit {
  @Input() showSubscriptionWarning: boolean = false;
  @Input() noAccessWaring: boolean = false;
  @Input() differenceDays: number = 0;

  constructor(private activeModal:NgbActiveModal,private router:Router) {}

  ngOnInit(): void {}

  public onView() {
      this.activeModal.close({closed:true,subscribe:false})
  }


  public routeToPayment(){
    this.activeModal.close({closed:true,subscribe:true})
    this.router.navigate(['/subscription-payment'])
  }
}
