/* tslint:disable */
import {
  AppUser
} from '../index';

declare var Object: any;
export interface ActivityTrackerInterface {
  "name": string;
  "type": string;
  "status": number;
  "startDate": Date;
  "endDate": Date;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appUser?: AppUser;
}

export class ActivityTracker implements ActivityTrackerInterface {
  "name": string;
  "type": string;
  "status": number;
  "startDate": Date;
  "endDate": Date;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  appUser: AppUser;
  constructor(data?: ActivityTrackerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ActivityTracker`.
   */
  public static getModelName() {
    return "ActivityTracker";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ActivityTracker for dynamic purposes.
  **/
  public static factory(data: ActivityTrackerInterface): ActivityTracker{
    return new ActivityTracker(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ActivityTracker',
      plural: 'ActivityTrackers',
      path: 'ActivityTrackers',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        appUser: {
          name: 'appUser',
          type: 'AppUser',
          model: 'AppUser',
          relationType: 'belongsTo',
                  keyFrom: 'app_user_id',
          keyTo: 'id'
        },
      }
    }
  }
}
