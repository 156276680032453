import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private router: Router) { }

  notificationTypes = [
    {
      type: 'Branding preference',
      subtype: 'Acadamic settings',
      path: 'branding-preference'
    },
    {
      type: 'Notifications',
      subtype: 'Birthday wishes, payment,Download invite message,post,cheque status,Attendance,calender,Deregitration,Batch capacity,Pay due date Extension,Staff rating & sely-Registration',
      path: 'notifications/birthday-notification'
    },
    {
      type: 'Terms & Conditions',
      subtype: 'Terms & Conditions',
      path: 'terms-conditions'
    },
    {
      type: 'Dashboard Configuration',
      subtype: 'Dashboard Entitlement',
      path: 'dashboard-configuration'
    },
    {
      type: 'Fee Settings',
      subtype: 'Fee Settings',
      path: 'fee-settings'
    },
    // {
    //   type: "Cascade Activities",
    //   subtype: "Cascade Activities",
    //   path:''
    // },
    {
      type: 'Competition / Rank Master',
      subtype: 'Competition / Rank Master',
      path: 'competition-rankmaster'
    },
    {
      type: 'Student Rating Categories',
      subtype: 'setup scale for 5 star ratings',
      path: 'student-rating-category'
    },

  ]

  ngOnInit(): void {
  }

  navigate(url: any) {
    console.log(url)
    this.router.navigateByUrl(`settings/${url}`)
  }

}
