/* tslint:disable */
import {
  Organization,
  Address,
  AppFile,
  Amenity,
  Batch,
  Course,
  Account
} from '../index';

declare var Object: any;
export interface InfrastructureInterface {
  "name": string;
  "description"?: string;
  "capacity"?: number;
  "type"?: number;
  "contactName"?: string;
  "contactEmail"?: string;
  "contactMobile"?: string;
  "gstNo"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  organization?: Organization;
  address?: Address;
  infrastructurePicture?: AppFile;
  amenities?: Amenity[];
  batches?: Batch[];
  courses?: Course[];
  accounts?: Account[];
}

export class Infrastructure implements InfrastructureInterface {
  "name": string;
  "description": string;
  "capacity": number;
  "type": number;
  "contactName": string;
  "contactEmail": string;
  "contactMobile": string;
  "gstNo": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  organization: Organization;
  address: Address;
  infrastructurePicture: AppFile;
  amenities: Amenity[];
  batches: Batch[];
  courses: Course[];
  accounts: Account[];
  constructor(data?: InfrastructureInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Infrastructure`.
   */
  public static getModelName() {
    return "Infrastructure";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Infrastructure for dynamic purposes.
  **/
  public static factory(data: InfrastructureInterface): Infrastructure{
    return new Infrastructure(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Infrastructure',
      plural: 'Infrastructures',
      path: 'Infrastructures',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "capacity": {
          name: 'capacity',
          type: 'number'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "contactName": {
          name: 'contactName',
          type: 'string'
        },
        "contactEmail": {
          name: 'contactEmail',
          type: 'string'
        },
        "contactMobile": {
          name: 'contactMobile',
          type: 'string'
        },
        "gstNo": {
          name: 'gstNo',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
      },
      relations: {
        organization: {
          name: 'organization',
          type: 'Organization',
          model: 'Organization',
          relationType: 'belongsTo',
                  keyFrom: 'organization_id',
          keyTo: 'id'
        },
        address: {
          name: 'address',
          type: 'Address',
          model: 'Address',
          relationType: 'belongsTo',
                  keyFrom: 'address_id',
          keyTo: 'id'
        },
        infrastructurePicture: {
          name: 'infrastructurePicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        amenities: {
          name: 'amenities',
          type: 'Amenity[]',
          model: 'Amenity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'infrastructure_id'
        },
        batches: {
          name: 'batches',
          type: 'Batch[]',
          model: 'Batch',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'infrastructure_id'
        },
        courses: {
          name: 'courses',
          type: 'Course[]',
          model: 'Course',
          relationType: 'hasMany',
          modelThrough: 'CourseInfrastructure',
          keyThrough: 'course_id',
          keyFrom: 'id',
          keyTo: 'infrastructure_id'
        },
        accounts: {
          name: 'accounts',
          type: 'Account[]',
          model: 'Account',
          relationType: 'hasMany',
          modelThrough: 'AccountInfrastructure',
          keyThrough: 'account_id',
          keyFrom: 'id',
          keyTo: 'infrastructureId'
        },
      }
    }
  }
}
