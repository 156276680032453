/* tslint:disable */
import {
  AppFile,
  Account
} from '../index';

declare var Object: any;
export interface ExcerciseInterface {
  "attendeesType"?: number;
  "name": string;
  "focusOn": string;
  "countType": number;
  "repsMinValue"?: number;
  "repsMaxValue"?: number;
  "setsMinValue"?: number;
  "setsMaxValue"?: number;
  "fileType": number;
  "fileName"?: string;
  "equipment": string;
  "timmingminutes"?: number;
  "note": string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_file_id"?: number;
  "account_id"?: number;
  excercisePicture?: AppFile;
  createdBy?: Account;
}

export class Excercise implements ExcerciseInterface {
  "attendeesType": number;
  "name": string;
  "focusOn": string;
  "countType": number;
  "repsMinValue": number;
  "repsMaxValue": number;
  "setsMinValue": number;
  "setsMaxValue": number;
  "fileType": number;
  "fileName": string;
  "equipment": string;
  "timmingminutes": number;
  "note": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "app_file_id": number;
  "account_id": number;
  excercisePicture: AppFile;
  createdBy: Account;
  constructor(data?: ExcerciseInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Excercise`.
   */
  public static getModelName() {
    return "Excercise";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Excercise for dynamic purposes.
  **/
  public static factory(data: ExcerciseInterface): Excercise{
    return new Excercise(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Excercise',
      plural: 'Excercises',
      path: 'Excercises',
      idName: 'id',
      properties: {
        "attendeesType": {
          name: 'attendeesType',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "focusOn": {
          name: 'focusOn',
          type: 'string'
        },
        "countType": {
          name: 'countType',
          type: 'number'
        },
        "repsMinValue": {
          name: 'repsMinValue',
          type: 'number'
        },
        "repsMaxValue": {
          name: 'repsMaxValue',
          type: 'number'
        },
        "setsMinValue": {
          name: 'setsMinValue',
          type: 'number'
        },
        "setsMaxValue": {
          name: 'setsMaxValue',
          type: 'number'
        },
        "fileType": {
          name: 'fileType',
          type: 'number'
        },
        "fileName": {
          name: 'fileName',
          type: 'string'
        },
        "equipment": {
          name: 'equipment',
          type: 'string'
        },
        "timmingminutes": {
          name: 'timmingminutes',
          type: 'number'
        },
        "note": {
          name: 'note',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
      },
      relations: {
        excercisePicture: {
          name: 'excercisePicture',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
        createdBy: {
          name: 'createdBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
      }
    }
  }
}
