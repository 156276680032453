/* tslint:disable */
import {
  AssessmentKpi,
  Account,
  AssessmentSection
} from '../index';

declare var Object: any;
export interface AssessmentSubmissionInterface {
  "rating": number;
  "remarks"?: string;
  "status"?: number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_kpi_id"?: number;
  "account_id"?: number;
  "rated_by_id"?: number;
  "assessment_section_id"?: number;
  assessmentKpi?: AssessmentKpi;
  account?: Account;
  ratedBy?: Account;
  assessmentSection?: AssessmentSection;
}

export class AssessmentSubmission implements AssessmentSubmissionInterface {
  "rating": number;
  "remarks": string;
  "status": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_kpi_id": number;
  "account_id": number;
  "rated_by_id": number;
  "assessment_section_id": number;
  assessmentKpi: AssessmentKpi;
  account: Account;
  ratedBy: Account;
  assessmentSection: AssessmentSection;
  constructor(data?: AssessmentSubmissionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentSubmission`.
   */
  public static getModelName() {
    return "AssessmentSubmission";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentSubmission for dynamic purposes.
  **/
  public static factory(data: AssessmentSubmissionInterface): AssessmentSubmission{
    return new AssessmentSubmission(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentSubmission',
      plural: 'AssessmentSubmissions',
      path: 'AssessmentSubmissions',
      idName: 'id',
      properties: {
        "rating": {
          name: 'rating',
          type: 'number'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "assessment_kpi_id": {
          name: 'assessment_kpi_id',
          type: 'number'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "rated_by_id": {
          name: 'rated_by_id',
          type: 'number'
        },
        "assessment_section_id": {
          name: 'assessment_section_id',
          type: 'number'
        },
      },
      relations: {
        assessmentKpi: {
          name: 'assessmentKpi',
          type: 'AssessmentKpi',
          model: 'AssessmentKpi',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_kpi_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        ratedBy: {
          name: 'ratedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'rated_by_id',
          keyTo: 'id'
        },
        assessmentSection: {
          name: 'assessmentSection',
          type: 'AssessmentSection',
          model: 'AssessmentSection',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_section_id',
          keyTo: 'id'
        },
      }
    }
  }
}
