import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-coach-layout',
  templateUrl: './coach-layout.component.html',
  styleUrls: ['./coach-layout.component.scss']
})
export class CoachLayoutComponent implements OnInit {
  isMobileResolution: boolean;
  isFreeLanceEnabled: boolean = false;

  constructor() {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }


  }
  @HostListener('window:resize', ['$event'])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }
  ngOnInit() {}
}
