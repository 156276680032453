/* tslint:disable */
import {
  Assessment,
  Account,
  ScheduleTest,
  AssessmentParameter
} from '../index';

declare var Object: any;
export interface AccountAssessmentParameterResultInterface {
  "status"?: boolean;
  "overall_score"?: any;
  "remarks"?: string;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "rated_by_id"?: number;
  "test_id"?: number;
  "assessment_parameter_id"?: number;
  assessment?: Assessment;
  account?: Account;
  ratedBy?: Account;
  test?: ScheduleTest;
  assessmentParameter?: AssessmentParameter;
}

export class AccountAssessmentParameterResult implements AccountAssessmentParameterResultInterface {
  "status": boolean;
  "overall_score": any;
  "remarks": string;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "rated_by_id": number;
  "test_id": number;
  "assessment_parameter_id": number;
  assessment: Assessment;
  account: Account;
  ratedBy: Account;
  test: ScheduleTest;
  assessmentParameter: AssessmentParameter;
  constructor(data?: AccountAssessmentParameterResultInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AccountAssessmentParameterResult`.
   */
  public static getModelName() {
    return "AccountAssessmentParameterResult";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AccountAssessmentParameterResult for dynamic purposes.
  **/
  public static factory(data: AccountAssessmentParameterResultInterface): AccountAssessmentParameterResult{
    return new AccountAssessmentParameterResult(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AccountAssessmentParameterResult',
      plural: 'AccountAssessmentParameterResult',
      path: 'AccountAssessmentParameterResult',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'boolean'
        },
        "overall_score": {
          name: 'overall_score',
          type: 'any'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "rated_by_id": {
          name: 'rated_by_id',
          type: 'number'
        },
        "test_id": {
          name: 'test_id',
          type: 'number'
        },
        "assessment_parameter_id": {
          name: 'assessment_parameter_id',
          type: 'number'
        },
      },
      relations: {
        assessment: {
          name: 'assessment',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_id',
          keyTo: 'id'
        },
        account: {
          name: 'account',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        ratedBy: {
          name: 'ratedBy',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'rated_by_id',
          keyTo: 'id'
        },
        test: {
          name: 'test',
          type: 'ScheduleTest',
          model: 'ScheduleTest',
          relationType: 'belongsTo',
                  keyFrom: 'test_id',
          keyTo: 'id'
        },
        assessmentParameter: {
          name: 'assessmentParameter',
          type: 'AssessmentParameter',
          model: 'AssessmentParameter',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_parameter_id',
          keyTo: 'id'
        },
      }
    }
  }
}
