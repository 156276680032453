import { Injectable, Inject, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { AuthService } from '../services/auth.service';
import { Constants } from '../class/constants';
import { DOCUMENT } from '@angular/common';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  public authService: AuthService;
  public domain = '';
  public subdomain = '';

  constructor(private injector: Injector, @Inject(DOCUMENT) private document: Document) {
    setTimeout(() => {
      this.authService = this.injector.get(AuthService);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService == null) {
      this.authService = this.injector.get(AuthService);
    }   
 // console.log('kkk',this.authService.getSubDomain(),request.headers.has('x-sub-domain'),request.headers.get('x-sub-domain'));
  if(request.headers.has('x-sub-domain')){
    this.subdomain=request.headers.get('x-sub-domain');
  }else{  
    this.subdomain = this.authService.getSubDomain() !== null ? this.authService.getSubDomain() : 'development';
  }

    const newParams = new HttpParams({ fromString: request.params.toString() });
    request = request.clone({
      headers: request.headers
        .set('source', 'web').set('version', '1.0.0').set('x-sub-domain', this.subdomain),
      params: newParams
    });

    // Downloadble data
    const url = request.url.substring(request.url.indexOf('api') - 1).replace(/\d+/g, ':id');
    // https://github.com/Microsoft/TypeScript/issues/10642. Probably need to change the coding style henceforth.
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && accessToken.trim() !== '') {
      request = request.clone({
        setHeaders: { Authorization: accessToken, 'onesports-dev-key': 'AdvD2QgZX' }
      });
    }

    return next.handle(request);
  }
}
