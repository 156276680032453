import { Component, OnChanges, Input } from '@angular/core';
import { AccountApi, AttendanceApi, BatchApi } from 'src/app/shared/sdk';
import { AuthService } from 'src/app/services/auth.service';
import * as _moment from 'moment';
import Swal from 'sweetalert2';
import { info } from 'console';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import { ExcelService } from 'src/app/services/excel.service';
@Component({
  selector: 'app-attendance-grid',
  templateUrl: 'attendance-grid.component.html',
  styleUrls: ['./attendance-grid.component.scss']
})
export class AttedanceGridComponent implements OnChanges {
  @Input() inputs: any;
  public columns: any = [];
  public attendanceData: any = {};
  public stdentAttendances: any = [];
  public totalDaysInMonth: any = [];
  public currentAccountType: any;
  public accountId: any;
  public currentStudentId: any;
  public totalCountData:any=[];

  constructor(
    private attendanceApi: AttendanceApi,
    private accountApi: AccountApi,
    private batchApi: BatchApi,
    private authService: AuthService,
    private excelService: ExcelService,
  ) {
    this.currentAccountType = this.authService.getCurrentAccountType();
    this.accountId = parseInt(this.authService.getCurrentAccountId(), 10);
  }

  ngOnChanges() {
    this.columns = [];
    this.attendanceData = {};
    this.stdentAttendances = [];
    this.totalDaysInMonth = [];
    this.prepareColumns(new Date(this.inputs.year, this.inputs.month));
  }

  prepareColumns(iteratingDate: Date) {
    const nextMonth = iteratingDate.getMonth() + 1;
    const monthName = `${iteratingDate.toLocaleString('default', { month: 'long' })} ${iteratingDate.getFullYear()}`.slice(0, 3);
    let index = 1;
    const col: any = [];
    const today = new Date();
  
    while (iteratingDate.getMonth() < nextMonth) {    
      iteratingDate.setDate(index++);
      const dayName=`${iteratingDate.toLocaleString('en-us', {weekday:'short'})}`;
      const prom = new Promise((resolve, reject) => resolve({ name: `${monthName} ${iteratingDate.getDate()}`+'\n'+`${dayName}`}));    
      col.push(prom);
      iteratingDate.setDate(index);
      if(nextMonth==12 && iteratingDate.getMonth()<11){      
        break;
       
      }    
      if (this.datesComparator(today, iteratingDate)) {       
        break;
      }
    }
    Promise.all(col)
    .then((res: any) => {
     // console.log(res)
      this.columns = [
        // {name :"Sr.no."},
        { name: 'Name' },
        { name: 'Center Name', prop: 'centerName', sortable: false },
        { name: 'Days Attended', prop: 'daysAttended', sortable: false },
        ...res
      ].reverse();
    })
    .then(() => this.caluculateTotalDaysInMonth(new Date(this.inputs.year, this.inputs.month)));
  }

  datesComparator(currentDate: Date, iteratingDate: Date) {      
    if (
      currentDate.getFullYear() > iteratingDate.getFullYear() &&
      currentDate.getMonth() > iteratingDate.getMonth() &&
      currentDate.getDate() > iteratingDate.getDate()
    ) {     
      return true;
    }
    return false;
  }

  caluculateTotalDaysInMonth(iteratingDate: Date) {
    //console.log(iteratingDate)
    const nextMonth = iteratingDate.getMonth() + 1;
    const promises = [];
    let index = 1;  
    while (iteratingDate.getMonth() < nextMonth) {
      iteratingDate.setDate(index++);
      //console.log(iteratingDate.getMonth())     
      const prom = new Promise((resolve, reject) =>
        resolve({
          year: iteratingDate.getFullYear(),
          month: iteratingDate.getMonth(),
          date: iteratingDate.getDate()
        })
      );
      promises.push(prom);
      iteratingDate.setDate(index);
      if(nextMonth==12 && iteratingDate.getMonth()<11){      
        break;
       
      }     
    }
    Promise.all(promises)
    .then((res: any) => (this.totalDaysInMonth = res))
    .then(() => {
  //console.log(this.totalDaysInMonth)

      this.getStudents();
    });
  }

  loadAttendance(studentAccounts) {   
   console.log("checking inputs",this.inputs)
   // let start_date=`${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 1 : this.inputs.month}-1`;
    //let end_date= `${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 2 : this.inputs.month + 1}-${this.inputs.month !== 11 ? `1`: `31`}`
    const startDate = `${this.inputs.year}-${this.inputs.month + 1}-01`;
    //const endDate = new Date(this.inputs.year, this.inputs.month + 1, 0).toISOString().split('T')[0];
    const endDate = new Date(this.inputs.year, this.inputs.month + 1, 0, 23, 59, 59, 999).toISOString().split('T')[0];
    console.log("printdate",startDate,endDate,this.inputs.batchId)
    let filter :any;
    if(this.inputs.accountType==1){
      if(parseInt(this.inputs.batchId, 10) == 0){      
        filter={
          where: { 
            centerId: this.inputs.centerId!=undefined?parseInt(this.inputs.centerId, 10):'',
            type: {inq:[1,3]},     
            date: {
              between: [startDate, endDate]
              // between: [`${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 1 : this.inputs.month}-1`,
              // `${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 2 : this.inputs.month + 1}-${this.inputs.month !== 11 ? `1`: `31`}`]
            }
          },
          include: 'account'}
      }else{
         filter={
          where: { 
            batchId: this.inputs.batchId!=undefined?parseInt(this.inputs.batchId, 10):'',
            centerId: this.inputs.centerId!=undefined?parseInt(this.inputs.centerId, 10):'',
            type: {inq:[1,3]},     
            date: {
              between: [startDate, endDate]
              // between: [`${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 1 : this.inputs.month}-1`,
              // `${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 2 : this.inputs.month + 1}-${this.inputs.month !== 11 ? `1`: `31`}`]
            }
          },
          include: 'account'}
      }
    }
    if(this.inputs.accountType==4){
      filter=
      {
        where: { 
          centerId: this.inputs.centerId!=undefined?parseInt(this.inputs.centerId, 10):'',
          type: {inq:[1,3]}, 
          date: {
            between: [startDate, endDate]
            // between: [`${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 1 : this.inputs.month}-1`,
            // `${this.inputs.year}-${this.inputs.month !== 11 ? this.inputs.month + 2 : this.inputs.month + 1}-${this.inputs.month !== 11 ? `1`: `31`}`]
          }
        },
        include: 'account'}
   }
 //  console.log('printfiltre',filter);
    this.attendanceApi
      .find(filter)
      .toPromise()
      .then((response: any) => {
        const promises = [];
        //console.log("atten",response);
        response.map((attandence: any) => {
          if (attandence.account &&attandence.account.type === this.inputs.accountType) {
            promises.push(attandence);
          }
        });
        Promise.all(promises)
          .then((response1: any) => {
           // console.log(response1)
            // var filteredAttendance = response1.filter((arr:any, index:any, self:any) =>
            // index === self.findIndex((t:any) => (moment(t.date).format('YYYY-MM-DD') === moment(arr.date).format('YYYY-MM-DD') && t.accountId === arr.accountId)))
            var filteredAttendance = response1.filter((arr:any, index:any, self:any) =>
                index === self.findIndex(t =>
                    moment.utc(t.date).startOf('day').isSame(moment.utc(arr.date).startOf('day')) && t.accountId === arr.accountId
                )
            );
           // console.log(filteredAttendance);
        
            filteredAttendance.map((res: any) => {
              const key = res.accountId + '';
              let tempArray = [];
              if (this.attendanceData[key]) {
                tempArray = this.attendanceData[key];
              }
              tempArray.push(res);
              this.attendanceData[key] = tempArray;
            });
          })
          .then(() => {
            const stdentAttendance: any = [];
         
           // console.log(this.attendanceData)
            if (this.currentAccountType === 'Student' || this.currentAccountType === 'Coach') {
              let studentAccountDetails: any = studentAccounts.filter((student) => student.isActive);
              // this.accountId === 
              // studentAccountDetails = new Set(studentAccountDetails);
              // studentAccountDetails = Array.from(studentAccountDetails);
              studentAccountDetails.map((filteredStudentAccounts) => {
                this.totalCountData =this.attendanceData[`${filteredStudentAccounts.id}`].filter(data=>data.type==1);
             //   console.log('totdalcount',this.totalCountData);
                if (this.accountId === filteredStudentAccounts.id) {
                  stdentAttendance.push(filteredStudentAccounts.name);
                  stdentAttendance.push(this.inputs.centerName);
                  const totalData = this.attendanceData[`${filteredStudentAccounts.id}`]
                    ? this.attendanceData[`${filteredStudentAccounts.id}`].length
                    : 0;
                  this.parseAttendance(stdentAttendance, totalData, filteredStudentAccounts, (attendances, daysAttended) => {
                    Promise.resolve(attendances)
                      .then((attendances1) => {
                        this.stdentAttendances.push([...stdentAttendance, `${daysAttended}`, ...attendances1].reverse());                      
                      })
                      .then(() => {
                      });
                  });
                }                
              });
            }

            if (this.currentAccountType !== 'Student' && this.currentAccountType !== 'Coach') {
             // console.log("profiles",studentAccounts)
              studentAccounts.map((studentAccount) => {
                this.totalCountData=[];
                this.totalCountData =this.attendanceData[`${studentAccount.id}`]?this.attendanceData[`${studentAccount.id}`].filter(data=>data.type==1):[];              
                const stdentAttendance1: any = [];
                stdentAttendance1.push(studentAccount.name);
                stdentAttendance1.push(this.inputs.centerName);
              //  const totalData = this.attendanceData[`${studentAccount.id}`] ? this.attendanceData[`${studentAccount.id}`].length : 0;
               const totalData = this.attendanceData[`${studentAccount.id}`] ? this.totalCountData.length : 0;
                this.parseAttendance(stdentAttendance1, totalData, studentAccount, (attendances, daysAttended) => {
                  Promise.resolve(attendances)
                    .then((attendances2) => {
                      this.stdentAttendances.push([...stdentAttendance1, `${daysAttended}`, ...attendances2].reverse());
                    })
                    .then(() => {
                     // console.log("check",this.stdentAttendances)
                    });
                });
              });
            }          
          });
      });
  }

  getStudents() {
    if (this.inputs.accountType == 1) {
      if (this.inputs.batchId) {
        this.batchApi
          .fetchStudentByBatchId(this.inputs.batchId)
          .toPromise()
          .then((accountsData: any) => {
            const accountsDetails = [];
            accountsData.map((accounts: any) => {
              accountsDetails.push({
                accountStatus: accounts.account.accountStatus,
                accountStatusLabel: accounts.account.accountStatusLabel,
                activationDate: accounts.account.activationDate,
                addressId: accounts.account.addressId,
                appUserId: accounts.account.appUserId,
                createdDate: accounts.account.createdDate,
                id: accounts.account.id,
                isActive: accounts.account.isActive,
                lastModifiedDate: accounts.account.lastModifiedDate,
                name: accounts.account.name,
                remarks: accounts.account.remarks,
                type: accounts.account.type,
                typeLabel: accounts.account.typeLabel
              });
            })
            const filteredAccounts = [];
            accountsDetails.map((account: any) => {
              if (account.type === this.inputs.accountType && account.isActive && account.accuntStatus!=4) {
                filteredAccounts.push(account);
              }
            });
            this.loadAttendance(filteredAccounts);
          }).catch(err => {
           // console.log(err);
            Swal.fire({
              text: err.message,
              type: 'warning',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning'
            });
          })
      } else {
       // console.log('---------------')
        this.batchApi
          .fetchStudentByCenterId(this.inputs.centerId)
          .toPromise()
          .then((accountsData: any) => {
            const accountsDetails = [];
            accountsData.map((accounts: any) => {
              accountsDetails.push({
                accountStatus: accounts.account.accountStatus,
                accountStatusLabel: accounts.account.accountStatusLabel,
                activationDate: accounts.account.activationDate,
                addressId: accounts.account.addressId,
                appUserId: accounts.account.appUserId,
                createdDate: accounts.account.createdDate,
                id: accounts.account.id,
                isActive: accounts.account.isActive,
                lastModifiedDate: accounts.account.lastModifiedDate,
                name: accounts.account.name,
                remarks: accounts.account.remarks,
                type: accounts.account.type,
                typeLabel: accounts.account.typeLabel
              });
            })
            const filteredAccounts = [];
            accountsDetails.map((account: any) => {
              if (account.type === this.inputs.accountType && account.isActive && account.accountStatus!=4) {
                filteredAccounts.push(account);
              }
            });
            this.loadAttendance(filteredAccounts);
          }).catch(err => {
            console.log(err);
            Swal.fire({
              text: err.message,
              type: 'warning',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning'
            });
          })
      }
    } else {
      this.batchApi.fetchCoachByCenterId(this.inputs.centerId)
        .toPromise()
        .then((accountsData: any) => {
          const accountsDetails = [];
          accountsData.map((accounts: any) => {
            accountsDetails.push({
              accountStatus: accounts.account.accountStatus,
              accountStatusLabel: accounts.account.accountStatusLabel,
              activationDate: accounts.account.activationDate,
              addressId: accounts.account.addressId,
              appUserId: accounts.account.appUserId,
              createdDate: accounts.account.createdDate,
              id: accounts.account.id,
              isActive: accounts.account.isActive,
              lastModifiedDate: accounts.account.lastModifiedDate,
              name: accounts.account.name,
              remarks: accounts.account.remarks,
              type: accounts.account.type,
              typeLabel: accounts.account.typeLabel
            });
          })
          const filteredAccounts = [];
          accountsDetails.map((account: any) => {
            if (account.type === this.inputs.accountType && account.isActive) {
              filteredAccounts.push(account);
            }
          });
          this.loadAttendance(filteredAccounts);
        }).catch(err => {
          console.log(err);
          Swal.fire({
            text: err.message,
            type: 'warning',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-warning'
          });
        });
    }
  } 

  parseAttendance(stdentAttendance: any, totalData: number, studentAccount: any, callback) {
    const attendances = [];
    const promises = [];  
    let daysAttended = 0;
    if (totalData !== 0) {
      let saveDate = '';
      let data;
      if (this.currentAccountType === 'Coach' || this.currentAccountType === 'Student') {
        data = this.attendanceData[`${studentAccount[0].id}`];
      }
      if (this.currentAccountType !== 'Coach' && this.currentAccountType !== 'Student') {
        data = this.attendanceData[`${studentAccount.id}`];
      }
      this.totalDaysInMonth.map((totalDaysInMonth) => {      
        const today: any = new Date();
        if (totalDaysInMonth.year > today.getFullYear() && totalDaysInMonth.month > today.getMonth() &&
          totalDaysInMonth.date > today.getDate()) {
          return null;
        } else {
          saveDate = '';         
          data.map((datas) => {
            const dateArray = `${datas.date.split('T')[0]}`.split('-');
            if (
              parseInt(dateArray[0], 10) === totalDaysInMonth.year &&
              parseInt(dateArray[1], 10) === totalDaysInMonth.month + 1 &&
              parseInt(dateArray[2], 10) === totalDaysInMonth.date
            ) {
              if (datas.type === 1 || datas.type === 3 || datas.type === 4 || datas.type === 2) {
                daysAttended=totalData;
                saveDate = datas.type;
              }
              return null;
            }
          });
          promises.push(saveDate);
        }
      });
    } else {
      this.totalDaysInMonth.map((totalDaysInMonths) => {
        const today: any = new Date();
        if (totalDaysInMonths.year > today.getFullYear() && totalDaysInMonths.month > today.getMonth() &&
          totalDaysInMonths.date > today.getDate()) {
          return null;
        } 
        let data = this.attendanceData[`${studentAccount.id}`];
        let saveDate = '';
        if(totalData==0 && data && data.length>0){
         
          data.map((datas) => {
           // console.log(moment(datas.date).format('DD'),totalDaysInMonths.date)
            if(moment(datas.date).format('DD')== totalDaysInMonths.date)
            if (datas.type === 1 || datas.type === 3 || datas.type === 4 || datas.type === 2) {
              daysAttended=totalData;
              saveDate = datas.type;
            }
          });
        }     
        attendances.push(saveDate);
      });
    }

    if (promises.length !== 0) {     
      Promise.all(promises).then((res) => callback(res, daysAttended));    
    } else {
      return callback(attendances, daysAttended);
    }
  }

  exportData() {
    const filename = 'Attendance.xlsx';
    let ExportData = [];
    let tmp_header = this.columns;
   // tmp_header = tmp_header.reverse();
    let header = [];
    tmp_header.map((h: any) => {
      header.push(h.name)
    });
    let tmp_Data = this.stdentAttendances;
    tmp_Data.forEach(Data => {     
      Data.forEach((i,index)=>{       
        if(index<31){
          if(Data[index]==1)
           Data[index]='P';
          if(Data[index]==3)
          Data[index]='A';                    
        }     
      })         
      let Tmp_Data = Data; 
      Tmp_Data = Tmp_Data.toString();
      Tmp_Data = Tmp_Data.replace(/false/g, "");
      Tmp_Data = Tmp_Data.split(",");   
      Tmp_Data.reverse();
      ExportData.push(Tmp_Data);
    });
    var dataItems = {
      data: ExportData,
      header: header.reverse(),
      fileName: filename,
    };
    console.log(dataItems)
    this.excelService.exportAttendanceInExcel(dataItems); 
  } 
}
