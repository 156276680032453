/* tslint:disable */
import {
  Assessment,
  Account,
  AppFile
} from '../index';

declare var Object: any;
export interface AssessmentSectionInterface {
  "name": string;
  "description": string;
  "maxMarks": number;
  "parentId": number;
  "id"?: number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_id"?: number;
  "account_id"?: number;
  "app_file_id"?: number;
  assessment?: Assessment;
  owner?: Account;
  icon?: AppFile;
}

export class AssessmentSection implements AssessmentSectionInterface {
  "name": string;
  "description": string;
  "maxMarks": number;
  "parentId": number;
  "id": number;
  "isActive": boolean;
  "createdDate": Date;
  "lastModifiedDate": Date;
  "assessment_id": number;
  "account_id": number;
  "app_file_id": number;
  assessment: Assessment;
  owner: Account;
  icon: AppFile;
  constructor(data?: AssessmentSectionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentSection`.
   */
  public static getModelName() {
    return "AssessmentSection";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentSection for dynamic purposes.
  **/
  public static factory(data: AssessmentSectionInterface): AssessmentSection{
    return new AssessmentSection(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentSection',
      plural: 'AssessmentSections',
      path: 'AssessmentSections',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "maxMarks": {
          name: 'maxMarks',
          type: 'number'
        },
        "parentId": {
          name: 'parentId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "createdDate": {
          name: 'createdDate',
          type: 'Date'
        },
        "lastModifiedDate": {
          name: 'lastModifiedDate',
          type: 'Date'
        },
        "assessment_id": {
          name: 'assessment_id',
          type: 'number'
        },
        "account_id": {
          name: 'account_id',
          type: 'number'
        },
        "app_file_id": {
          name: 'app_file_id',
          type: 'number'
        },
      },
      relations: {
        assessment: {
          name: 'assessment',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'belongsTo',
                  keyFrom: 'assessment_id',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'Account',
          model: 'Account',
          relationType: 'belongsTo',
                  keyFrom: 'account_id',
          keyTo: 'id'
        },
        icon: {
          name: 'icon',
          type: 'AppFile',
          model: 'AppFile',
          relationType: 'belongsTo',
                  keyFrom: 'app_file_id',
          keyTo: 'id'
        },
      }
    }
  }
}
